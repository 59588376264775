import React from "react";
import footerImage from "../../images/01-logo-collegia.png";

const SetupFooter = () => (
	<div className="section-9">
		<div className="div-block-3">
			<img
				src={footerImage}
				width="119"
				alt=""
				className="image-3"
			/>
			<div className="text-block-2">
				© {new Date().getFullYear()} Collegia Partners Limited. All rights reserved.
			</div>
		</div>
	</div>
);

export default SetupFooter;