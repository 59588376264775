import {GET_ALL_UPCOMING_TRANSACTIONS, GET_UPCOMING_TRANSACTIONS} from "./types";
import UpcomingTransactions from "../../services/UpcomingTransactions";


/**
 * @param page
 * @returns {function(...[*]=)}
 */
export const paginate = (page: number = 1) => dispatch => {
	dispatch({
		type: GET_UPCOMING_TRANSACTIONS,
		transaction_loading: true,
	});

	new UpcomingTransactions()
		.paginate(page)
		.then(response => {
			dispatch({
				type: GET_UPCOMING_TRANSACTIONS,
				transaction_loading: false,
				...response.data.data,
			});
		})
		.catch(error => {
			if (error.response) {
				if (error.response.status === 401) {
					localStorage.clear();
					window.location = "/login";
				}
			}
			dispatch({
				type: GET_UPCOMING_TRANSACTIONS,
				transaction_loading: false,
			});
			console.error(error);
		});
};

/**
 * @returns {function(...[*]=)}
 */
export const all = () => dispatch => {
	new UpcomingTransactions()
		.all()
		.then(response => {
			dispatch({
				type: GET_ALL_UPCOMING_TRANSACTIONS,
				transactions: response.data.data,
			});
		})
		.catch(error => {

			console.error(error);
			if (error.response) {
				if (error.response.status === 401) {
					localStorage.clear();
					window.location = "/login";
				}
			}
		});
};