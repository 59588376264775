import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 *  @param data
 * @returns {Promise<AxiosResponse<*>>}
 */
const OptOutPromise = (data: any[] = false): Promise<AxiosResponse<any>> => (
	Request.post("/profile/my-employers/opt-out", data)
);

export default OptOutPromise;