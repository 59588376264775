import NotificationProvider from "../components/NotificationProvider";
import Wrapper from "../layout/Logged/Wrapper";
import {connect} from "react-redux";
import {generateHMRCAccessTokenState, returnUser} from "../actions/Profile";
import Grid from "@mui/material/Grid";
import GeneralInformation from "../components/AllowHMRCPastEmployersInfo/GeneralInformation";
import AuthorizationInformation from "../components/AllowHMRCPastEmployersInfo/AuthorizationInformation";
import Loading from "../components/Loading";
import {useState} from "react";
import {OutlinedInput} from "@mui/material";
import Button from "@mui/material/Button";


const AllowHMRCPastEmployersInfo = (
	{
		history = {
			push: (url: string = "") => url,
		},
		profile_data = {
			utr: null,
		},
		token_loading = false,
		generateHMRCAccessTokenState = (data) => data,
	}
) => {

	const [utr, setUtr] = useState(profile_data.utr ?? "");

	if (token_loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<NotificationProvider>
			<Wrapper
				history={history}
				profile_data={profile_data}
			>
				<Grid
					xs={12}
					md={12}
					sm={12}
					xl={12}
					id={"allow-hmrc-past-employers-page"}
					item
					container
				>
					<GeneralInformation/>
					<AuthorizationInformation/>

					<Grid
						xs={12}
						md={12}
						sm={12}
						xl={12}
						id={"utr-text-field-container"}
						item
						container
					>
						<OutlinedInput
							placeholder={"Type your UTR (Unique Taxpayer Reference Number, min 10 characters)"}
							value={utr}
							onChange={(event) => setUtr(event.target.value)}
							name={"utr"}
							id={"utr"}
							error={utr.length < 10}
							fullWidth
						/>
						<small>
							{
								utr.length < 10 && (
									"Your UTR (Unique Taxpayer Reference Number) must have at least min 10 characters"
								)
							}
						</small>
					</Grid>
					{
						utr.length >= 10 && (
							<Grid
								xs={12}
								md={12}
								sm={12}
								xl={12}
								id={"allow-button-container"}
								item
								container
							>
								<Button
									variant={"outlined"}
									onClick={() => generateHMRCAccessTokenState({
										profiles: {
											utr,
										}
									})}
								>
									Grant authorization
								</Button>
							</Grid>
						)
					}
				</Grid>
			</Wrapper>
		</NotificationProvider>
	);
};

const mapStateToProps = state => state.Profile;

const mapDispatchToProps = dispatch => ({
	returnUser: dispatch(returnUser()),
	generateHMRCAccessTokenState: (data = {}) => dispatch(generateHMRCAccessTokenState(data)),
});


export default connect(mapStateToProps, mapDispatchToProps)(AllowHMRCPastEmployersInfo);