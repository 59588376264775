import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from "react";

/**
 * @param typeOfContribution
 * @param contribution
 * @param img
 * @param titleCollegiaFee
 * @param collegiaFee
 * @param date
 * @returns {JSX.Element}
 */
const renderContributions = (
	typeOfContribution,
	contribution,
	img,
	titleCollegiaFee,
	collegiaFee,
	date,
): JSX.Element => {
	return (
		<Grid
			direction={"row"}
			justifyContent={"space-between"}
			item
			container
			xs={12}
			sm={12}
			lg={12}
			md={12}
			alignContent={"center"}
			alignItems={"center"}
			spacing={2}
		>
			<Grid
				textAlign={"center"}
				item
				xs={1}
				marginBottom={3.5}
			>
				<img
					className={"contributions-image-style"}
					src={img}
					alt={""}
				/>
			</Grid>

			<Grid
				item
				xs={11}
			>
				<Grid
					direction={"row"}
					justifyContent={"space-between"}
					item
					container
					xs={12}
					sm={12}
					lg={12}
					md={12}
					marginBottom={3}
				>
					<Grid
						item
						xs={6}
						sm={6}
						lg={6}
						md={6}
						marginBottom={1}
					>
						<Typography
							textAlign={"start"}
							className={"typography-title-contributions-history"}
							fontSize={"3vw"}
						>
							{typeOfContribution}
						</Typography>

					</Grid>

					<Grid
						item
						xs={6}
						sm={6}
						lg={6}
						md={6}
					>
						<Typography
							textAlign={"center"}
							className={"typography-contributions-amount-history"}
						>
							{contribution}
						</Typography>
					</Grid>

					<Grid
						justifyContent={"space-between"}
						item
						container
						xs={12}
						sm={12}
						lg={12}
						md={12}
						maxHeight={"25px"}
						marginBottom={1}
					>
						<Grid
							item
							xs={6}
							sm={6}
							lg={6}
							md={6}
							container
						>
							<Typography
								textAlign={"start"}
								className={"typography-fee-contributions-history"}
							>
								{titleCollegiaFee}
							</Typography>
						</Grid>

						<Grid
							item
							xs={6}
							sm={6}
							lg={6}
							md={6}
						>
							<Typography
								textAlign={"center"}
								className={"typography-fee-contributions-history"}
							>
								{collegiaFee}
							</Typography>

						</Grid>
					</Grid>
					<Grid
						direction={"column"}
						justifyContent={"space-between"}
						item
						container
						xs={6}
						sm={6}
						lg={6}
						md={6}
						minWidth={"300px"}
					>
						<Typography
							textAlign={"start"}
							className={"typography-contributions-one-off-history"}
						>
							{date}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ContributionsModalList = (props = {
	history: () => {},
	employerContribution: 0,
	employerFeeValue: 0,
	governmentTopUpContribution: 0,
	governmentTopUpFeeValue: 0,
	individualContribution: 0,
	individualFeeValue: 0,
	payrollStartDate: "",
	payrollEndDate: "",
	employerTitle: "",
	contributionClockImage: null,
}): JSX.Element => {
	let payrollStartDate = "";
	let payrollEndDate = "";

	if(props?.payrollStartDate && props?.payrollEndDate) {
		let payroll_start_date_split = props?.payrollStartDate?.split("-");
		let payroll_end_date_split = props?.payrollEndDate?.split("-");
		payrollStartDate = payroll_start_date_split[2] + "/" +
			payroll_start_date_split[1] + "/" +
			payroll_start_date_split[0];
		payrollEndDate = payroll_end_date_split[2] + "/" +
			payroll_end_date_split[1] + "/" +
			payroll_end_date_split[0];
	}

	return (
		<Grid
			xs={12}
			sm={12}
			lg={12}
			md={12}
			item
			container
			paddingLeft={2}
			paddingRight={3}
		>
			{
				renderContributions(
					props?.employerTitle,
					"+ £ " + props?.employerContribution,
					props?.contributionClockImage,
					"Collegia Fee",
					props?.employerFeeValue === null ?
						"- £ " + 0
						:
						"- £ " + props?.employerFeeValue,
					"Pay Period: " + payrollStartDate + " - " + payrollEndDate,
				)
			}

			{
				renderContributions(
					"Your Contribution",
					"+ £ " + props?.individualContribution,
					props?.contributionClockImage,
					"Collegia Fee",
					props?.individualFeeValue === null ?
						"- £ " + 0
						:
						"- £ " + props?.individualFeeValue,
					"",
				)
			}

			{
				renderContributions(
					"UK Gov. Top Up",
					"+ £ " + props?.governmentTopUpContribution,
					props?.contributionClockImage,
					"Collegia Fee",
					props?.governmentTopUpFeeValue === null ?
						"- £ " + 0
							:
						"- £ " + props?.governmentTopUpFeeValue,
					""
				)
			}

		</Grid>
	)
};

export default ContributionsModalList;