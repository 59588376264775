import {FETCH_OTHER_PENSIONS} from "../../actions/OtherPensions/types";
import {resolveReduxState} from "../../utils/Helpers";

const initial_state = {
	other_pensions: [],
};

const OtherPensionsStore = (state: any = initial_state, action: any = false) => {

	if (action) {
		const {
			type = false
		} = action;
		if (type) {
			if (type === FETCH_OTHER_PENSIONS) return resolveReduxState(state, action);
		}
	}

	return state;
};

export default OtherPensionsStore;