import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param code
 * @param token
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
const DenyRequest = (code: string = "", token: string = ""): Promise<AxiosResponse<any>> =>
	Request.post(`/individual/activation/deny/${code}/${token}`);

export default DenyRequest;