import React, {useEffect, useState} from 'react';
import Wrapper from "../layout/Logged/Wrapper";
import Slide from '@mui/material/Slide';
import {connect} from "react-redux";
import {returnUser, update} from "../actions/Profile";
import Grid from "@mui/material/Grid";
import EmailForm from "../components/ActiveYourPension/EmailForm";
import {confirmCode, resendCode} from "../actions/ActivationCode";
import EmailChangeForm from "../components/ActiveYourPension/EmailChangeForm";
import Loading from "../components/Loading";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";


/**
 * @param profile_data
 * @param email
 * @param stepErrorText
 * @param code
 * @param requestMailChange
 * @param errorMessage
 * @param setEmail
 * @param setStepErrorText
 * @param setCode
 * @param setRequestMailChange
 * @param setFlagToBack
 * @param resendCode
 * @param confirmCode
 * @param update
 * @param history
 * @param t
 * @param current_email
 * @returns {JSX.Element}
 */
const renderContent = (
	profile_data: any = [],
	email: string = "",
	stepErrorText: string = "",
	code: string = "",
	requestMailChange: boolean = true,
	errorMessage: string = "",
	setEmail = () => {
	},
	setStepErrorText = () => {
	},
	setCode = () => {
	},
	setRequestMailChange = () => {
	},
	setFlagToBack = () => {
	},
	resendCode = () => {
	},
	confirmCode = () => {
	},
	update = () => {
	},
	history = () => {
	},
	t = key => key,
	current_email = "",
): JSX.Element => (
	requestMailChange ?
		<EmailChangeForm
			email={email}
			setEmail={setEmail}
			setFlagToBack={setFlagToBack}
			update={update}
			setRequestMailChange={setRequestMailChange}
			profile_data={profile_data}
			errorMessage={errorMessage}
			current_email={current_email}
			t={t}
		/>
		:
		<EmailForm
			profile_data={profile_data}
			errorMessage={errorMessage}
			code={code}
			setCode={setCode}
			setRequestMailChange={setRequestMailChange}
			setFlagToBack={setFlagToBack}
			resendCode={resendCode}
			confirmCode={confirmCode}
			history={history}
			t={t}
		/>
);

/**
 * @param profile_data
 * @param activationCodeData
 * @param canProceedEmail
 * @param activation_code_loading
 * @param errorMessage
 * @param resendCode
 * @param confirmCode
 * @param update
 * @param history
 * @returns {JSX.Element}
 * @constructor
 */
const ActiveYourPension = (
	{
		profile_data = [],
		activationCodeData = [],
		canProceedEmail = false,
		activation_code_loading = false,
		errorMessage = "",
		resendCode = () => {
		},
		confirmCode = () => {
		},
		update = () => {
		},
	}
): JSX.Element => {

	const [email, setEmail] = useState(""),
		[stepErrorText, setStepErrorText] = useState(""),
		[current_email, setCurrentEmail] = useState(""),
		[code, setCode] = useState(""),
		[requestMailChange, setRequestMailChange] = useState(true),
		[flagToBack, setFlagToBack] = useState(false),
		{t} = useTranslation(),
		history = useNavigate();
	useEffect(() => {

		if (profile_data?.email_confirmation && profile_data?.mobile_confirmation) {
			history("/main-page");
		}

		if (profile_data?.email_confirmation && !profile_data?.mobile_confirmation) {
			history("/confirm-your-mobile");
		}

		if (profile_data?.email !== null && !profile_data?.email?.isEmpty) {
			setEmail(profile_data?.email);
			setCurrentEmail(profile_data?.email);
		}

		if (activationCodeData?.canProceedStepTwo) {
			history("/confirm-your-mobile");
		}

		if (canProceedEmail && requestMailChange && flagToBack) {
			setRequestMailChange(false);
			if (profile_data?.id) {
				resendCode({
					activation_codes: {
						individual_id: profile_data?.id,
					}
				});
			}
		}

	}, [
		profile_data?.id,
		profile_data.email,
		profile_data?.email_confirmation,
		profile_data?.mobile_confirmation,
		activationCodeData?.canProceedStepTwo,
		flagToBack,
		canProceedEmail,
		requestMailChange,
		resendCode,
		history
	]);

	if (activation_code_loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<Wrapper
			hideFooter={true}
			history={history}
			t={t}
		>
			<Slide direction={"up"} in={true}>
				<Grid
					xs={12}
					lg={12}
					md={12}
					xl={12}
					container
				>
					{
						renderContent(
							profile_data,
							email,
							stepErrorText,
							code,
							requestMailChange,
							errorMessage,
							setEmail,
							setStepErrorText,
							setCode,
							setRequestMailChange,
							setFlagToBack,
							resendCode,
							confirmCode,
							update,
							history,
							t,
							current_email,
						)
					}
				</Grid>
			</Slide>
		</Wrapper>
	);
}


/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => {
	const {
		Profile = [],
		ActivationCode = [],
	} = state;

	return {
		...Profile,
		...ActivationCode,
	}
};

/**
 * @param dispatch
 * @returns {{returnUser: (function(): *)}}
 */
const mapDispatchToProps = dispatch => ({
	returnUser: dispatch(returnUser()),
	resendCode: (data = false) => dispatch(resendCode(data)),
	confirmCode: (data = false) => dispatch(confirmCode(data)),
	update: (id = null, data = {}) => dispatch(update(id, data, false)),
});


export default connect(mapStateToProps, mapDispatchToProps)(ActiveYourPension);