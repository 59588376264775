import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 *
 * @param token
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const SentMobileCodePromise = (token = false, data = {}): Promise<AxiosResponse<any>> =>
	Request.post(`individual/activation/token/${token}/sent-mobile-code`, data);

export default SentMobileCodePromise;