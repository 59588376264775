// importing the actions.
import {MOBILE_CODE_SENT, NEXT_STEP, UPDATE_INDIVIDUAL_DATA, UPDATE_STEP} from "../../actions/Steps/types";
import {resolveReduxState} from "../../utils/Helpers";
import {READ_TERMS_AND_CONDITIONS} from "../../actions/SetUpModal/types";

const initial_state = {
	current_step: 0,
	step_loading: false,
	canProceedStepTwo: false,
	canProceedStepTree: false,
	canProceedStepOne: false,
};

const StepsStore = (state = initial_state, action = {
	type: "",
}) => {
	if (action) {
		const {type = false} = action;

		if (type) {
			if (type === UPDATE_STEP) return resolveReduxState(state, action);
			else if (type === NEXT_STEP) return resolveReduxState(state, action);
			else if (type === UPDATE_INDIVIDUAL_DATA) return resolveReduxState(state, action);
			else if (type === READ_TERMS_AND_CONDITIONS) return resolveReduxState(state, action);
			else if (type === MOBILE_CODE_SENT) return resolveReduxState(state, action);
		}
	}

	return state;
}

export default StepsStore;