import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import Badge from "../../images/your-pension-badge.svg";
import Comfortable from "../../images/newComfortableTree.svg";
import House from "../../images/house-icon.svg";
import Transport from "../../images/transport-icon.svg";
import Drink from "../../images/drink-icon.svg";
import Gift from "../../images/gift-icon.svg";
import Clothing from "../../images/clothing-icon.svg";
import Holiday from "../../images/holiday-icon.svg";

/**
 * @param active
 * @param t
 * @param comfortRulerProjectionParameters
 * @param history
 * @returns {JSX.Element}
 * @constructor
 */
const ComfortableCard = (
	{
		active = 0,
		t=key=>key,
		comfortRulerProjectionParameters = {
			active: true,
			comfortable: 43100,
			created_at: "",
			id: null,
			lower_bound: 0,
			minimum: 14400,
			moderate: 31300,
			upper_limit: 64000,
		},
		history = () => {},
	}
): JSX.Element => (
	<Grid
		lg={12}
		xl={12}
		sm={12}
		md={12}
		item
		className={"comfortable-grid"}
	>
		{
			active === 3 && (
				<Grid
					lg={12}
					xl={12}
					sm={12}
					xs={12}
					md={12}
					item
					textAlign={"end"}
					paddingRight={2}
				>
					<img
						src={Badge}
						alt={"Badge"}
					/>
				</Grid>
			)
		}
		<img
			src={Comfortable}
			alt={"Comfortable"}
		/>
		<Grid
			lg={12}
			xl={12}
			sm={12}
			xs={12}
			md={12}
			item
		>
			<Typography
				className={"title"}
			>
				{t("individual.understand_lifestyle.comfortable.title")}
			</Typography>
		</Grid>
		<Grid
			lg={11}
			xl={10}
			sm={10}
			xs={10}
			md={10}
			item
		>
			<Typography
				className={"sub-title"}
			>
				{t("individual.understand_lifestyle.comfortable.financial_info.title")}!
			</Typography>
			<Typography
				className={"description"}
			>
				{t("individual.understand_lifestyle.comfortable.financial_info.information")}
			</Typography>
		</Grid>
		<Grid
			lg={12}
			xl={12}
			sm={12}
			xs={12}
			md={12}
			item
		>
			<Typography
				className={"single"}
			>
				{t("individual.understand_lifestyles.single")} {" "}
				<span
					className={"value"}
				>
						{comfortRulerProjectionParameters.comfortable.toLocaleString('en-GB',{
							style: 'currency',
							currency: 'GBP',
						})} / {t("individual.year")}
					</span>
			</Typography>
		</Grid>
		<Grid
			lg={11}
			xl={12}
			sm={12}
			xs={12}
			md={12}
			item
		>
			<Typography
				className={"house"}
			>
				<img
					src={House}
					alt={t("individual.understand_lifestyle.comfortable.house.title")}
					className={"icon"}
				/>
				{t("individual.understand_lifestyle.comfortable.house.title")}
			</Typography>
			<Typography
				className={"house-description"}
			>
				{t("individual.understand_lifestyle.comfortable.house.subtitle")}
			</Typography>
		</Grid>
		<Grid
			lg={12}
			xl={12}
			sm={12}
			xs={12}
			md={12}
			item
		>
			<Typography
				className={"house"}
			>
				<img
					src={Drink}
					alt={"Drink"}
					className={"icon"}
				/>
				{t("individual.understand_lifestyle.comfortable.food_drink.title")}
			</Typography>
			<Typography
				className={"house-description"}
			>
				{t("individual.understand_lifestyle.comfortable.food_drink.subtitle")}
			</Typography>
		</Grid>
		<Grid
			lg={12}
			xl={12}
			sm={12}
			xs={10}
			md={12}
			item
		>
			<Typography
				className={"house"}
			>
				<img
					src={Transport}
					alt={"Transport"}
					className={"icon"}
				/>
				{t("individual.understand_lifestyle.comfortable.transport.title")}
			</Typography>
			<Typography
				className={"house-description"}
			>
				{t("individual.understand_lifestyle.comfortable.transport.message")}
			</Typography>
		</Grid>
		<Grid
			lg={12}
			xl={12}
			sm={12}
			xs={12}
			md={12}
			item
		>
			<Typography
				className={"house"}
			>
				<img
					src={Holiday}
					alt={"Holiday"}
					className={"icon"}
				/>
				{t("individual.understand_lifestyle.holiday_leisure.title")}
			</Typography>
			<Typography
				className={"house-description"}
			>
				{t("individual.understand_lifestyle.holiday_leisure.subtitle")}
			</Typography>
		</Grid>
		<Grid
			lg={12}
			xl={11}
			sm={12}
			xs={12}
			md={12}
			item
		>
			<Typography
				className={"house"}
			>
				<img
					src={Clothing}
					alt={"Clothing"}
					className={"icon"}
				/>
				{t("individual.understand_lifestyle.clothing_&_personal.title")}
			</Typography>
			<Typography
				className={"house-description"}
			>
				{t("individual.understand_lifestyle.clothing_&_personal.clothing_and_footwear")}
			</Typography>
		</Grid>
		<Grid
			lg={12}
			xl={12}
			sm={12}
			xs={12}
			md={12}
			item
		>
			<Typography
				className={"house"}
			>
				<img
					src={Gift}
					alt={"Gift"}
					className={"icon"}
				/>
				{t( "individual.understand_lifestyles.comfortable.helping_others")}
			</Typography>
			<Typography
				className={"house-description"}
			>
				{t("individual.understand_lifestyles.comfortable.birthday_present")}
			</Typography>
		</Grid>
		{
			active < 3 && (
				<Grid
					lg={10}
					xl={10}
					sm={10}
					xs={10}
					md={10}
					item
					textAlign={"center"}
					className={"button-improve-grid"}
					onClick={() => {
						history("/extra-contributions")
					}}
				>
					<Typography
						className={"button-improve"}
					>
						{t("individual.improve_your_pension")}
					</Typography>
				</Grid>
			)
		}
	</Grid>
);

export default ComfortableCard;