import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Divider from "@mui/material/Divider";
import moment from "moment/moment";

/**
 * @param token
 * @param proceed
 * @param history
 * @param individual
 * @param insideApp
 * @param optOut
 * @param code
 * @returns {JSX.Element}
 * @constructor
 */
const FirstConfirmation = (
	{
		token = "",
		proceed = () => {
		},
		history = {
			push: () => {
			},
		},
		individual = false,
		insideApp = false,
		optOut = () => {
		},
		code = "",
	}
): JSX.Element => {

	const [agree1, setAgree1] = useState(false);
	const [agree2, setAgree2] = useState(false);
	const [agree3, setAgree3] = useState(false);

	let Nin = !individual?.national_insurance_number ? "N/A" : individual?.national_insurance_number;


	return (
		<Grid
			xl={8.5}
			lg={9.5}
			md={9.5}
			sm={10}
			xs={12}
			item
			container
			id={'opt-out-inside-app-container'}
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				className={"opt-out-title-container"}
				paddingBottom={7}
				item
			>
				<Typography
					className={"upper-grid-title"}
				>
					What you need to know:
				</Typography>
			</Grid>
			<Grid
				item
				className={"opt-out-info-container"}
			>
				<Typography
					className={'upper-grid-subtitle'}
					align={"left"}
				>
					<ul>
						<li>Your employer cannot ask you or force you to opt out.</li>
						<li>If you are asked or forced to opt out, you can tell The Pensions Regulator.</li>
						<li>If you change your mind, you may be able to opt back in – write to your employer if you want
							to do this.
						</li>
						<li>If you stay opted out of the scheme, your employer will normally put you back into pension
							saving in around three years.
						</li>
						<li>If you change your job, your new employer will normally put you back into pension saving
							straight away.
						</li>
						<li>If you have another job, your other employer might also put you into pension saving, now or
							in the future.<br/> The notice only allows you to opt out of pension saving with the
							employer you
							name in the notice. <br/>A separate notice must be filled out and given to any other
							employer you
							work for, if you wish to opt out of that employer’s pension saving as well.
						</li>
					</ul>
				</Typography>
				<Divider/>
			</Grid>
			<Grid
				xs={12}
				sm={12}
				md={12}
				lg={12}
				direction={"column"}
				alignItems={"center"}
				alignContent={"flex-start"}
				textAlign={'left'}
				paddingLeft={"30px"}
				paddingTop={"20px"}
				container
				item
			>
				<FormControlLabel
					className={"checkbox"}
					control={
						<Checkbox
							checked={agree3}
							onChange={() => setAgree3(!agree3)}
							name={"agree1"}
							id={"agree1"}
							color={"primary"}
						/>
					}
					label={
						"My name is " +
						individual?.forename +
						" " +
						individual?.surname +
						" and I was born on the " +
						moment(individual?.birthdate).format("DD/MM/YYYY") +
						", my NIN is " +
						Nin +
						" and I confirm that I have understood and agree with the above information."
					}
				/>
			</Grid>
			<Grid
				xs={12}
				sm={12}
				md={12}
				lg={12}
				direction={"column"}
				alignItems={"center"}
				alignContent={"flex-start"}
				textAlign={'left'}
				paddingLeft={"30px"}
				paddingTop={"20px"}
				container
				item
			>
				<FormControlLabel
					className={"checkbox"}
					control={
						<Checkbox
							checked={agree1}
							onChange={() => setAgree1(!agree1)}
							name={"agree1"}
							id={"agree1"}
							color={"primary"}
						/>
					}
					label={"I understand that if I opt out I will lose the right to pension contributions from my employer"}
				/>
			</Grid>
			<Grid
				xs={12}
				sm={12}
				md={12}
				lg={12}
				direction={"column"}
				alignItems={"center"}
				alignContent={"flex-start"}
				justifyContent={"left"}
				textAlign={'left'}
				paddingLeft={"30px"}
				paddingTop={"20px"}
				container
				item
			>
				<FormControlLabel
					className={"checkbox"}
					control={
						<Checkbox
							checked={agree2}
							onChange={() => setAgree2(!agree2)}
							name={"agree1"}
							id={"agree1"}
							color={"primary"}
						/>
					}
					label={"I understand that if I opt out I may have a lower income when I retire"}
				/>
			</Grid>
			<Grid
				xs={12}
				md={12}
				lg={12}
				sm={12}
				xl={12}
				item
				container
				paddingTop={6}
				justifyContent={"space-between"}
			>
				<Button
					className={"want-to-opt-out-button"}
					type={'button'}
					onClick={() =>
						!insideApp ?
							proceed()
							:
							optOut({
									profiles: {
										code
									}
								}
							)
					}
					fullWidth
					disabled={!agree1 || !agree2 || !agree3}
				>
					I wish to opt out of the pension scheme
				</Button>
				<Button
					type={'button'}
					className={"want-to-stay-button"}
					onClick={() => token !== "" ?  history(`/activation/setup/${token}`) : history(`/auto-enrolment`)}
					fullWidth
				>
					I wish to keep my pension
				</Button>
			</Grid>
		</Grid>
	);

}

export default FirstConfirmation;