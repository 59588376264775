import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param employerId
 * @returns {Promise<AxiosResponse<any>>}
 */
const ProjectionsPromise = (employerId: mixed = null): Promise<AxiosResponse<any>> => {
	if (employerId) {
		return Request.get(`/profile/projections/${employerId}`);
	}

	return Request.get(`/profile/projections/`);
}

export default ProjectionsPromise;