import PhoneInput from "react-phone-input-2";
import Grid from "@mui/material/Grid";

/**
 * @param event
 * @param setMobileNumber
 * @param onlyNumbers
 * @param customValue
 */
const phoneNumberFormat = (
	event = null,
	setMobileNumber = () => {
	},
	onlyNumbers: boolean = false,
	customValue: string = "",
) => {
	let value = "";

	if (customValue !== "") {
		value = customValue;
	}

	if (event && setMobileNumber) {
		if (event.target) {

			if (value === "") {
				value = event.target.value;
			}

			if (onlyNumbers) {
				if (value !== undefined) {
					if (typeof value.replace === "function") {
						value = value.replace(/[^0-9]/gi, '');
						value = value.replace(/^0+/, '')
					}
				}
			}
			setMobileNumber(value);
		}
	}
}

/**
 * @param individual
 * @param mobileNumber
 * @param stepErrorText
 * @param setMobileNumber
 * @param setStepErrorText
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInput = (
	{
		individual = {
			countryCode: "GB",
		},
		mobileNumber = "",
		stepErrorText = "",
		setMobileNumber = () => {
		},
		setStepErrorText = () => {
		},
		t = key => key,
		color = false,
	}
): JSX.Element => {
	let individualCountryCode = "gb";

	if (mobileNumber === "") {
		mobileNumber = "+44";
	}

	if (individual?.countryCode) {
		individualCountryCode = individual?.countryCode?.toLowerCase();
	}

	let className = 'textfield2';
	if (color) {
		className = 'textfield2 color-extra';
	}

	return (
		<Grid
			xl={12}
			lg={12}
			md={12}
			sm={12}
			item
			className={"extra"}
		>
			<PhoneInput
				country={individualCountryCode}
				inputProps={{
					name: 'mobile_number',
					id: "mobile_number",
					required: true,
					value: mobileNumber,
				}}
				countryCodeEditable={false}
				value={mobileNumber}
				searchPlaceholder={t("individual.country_name")}
				searchNotFound={t("individual.errors.country_not_found")}
				onChange={
					(value, country, e, formattedValue) => {
						phoneNumberFormat(e, setMobileNumber, false, formattedValue);
						if (stepErrorText) {
							setStepErrorText("");
						}
					}
				}

				inputClass={className}
				containerClass={"extra-1"}
				enableSearch
			/>
			<small>
				{t("individual.zero_at_front_phone_number")}
			</small>
		</Grid>
	);
}

export default MobileInput;
