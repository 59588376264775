import React from "react";

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Footer from "./Footer";
import {capitalizeFirstLetter} from "../../utils/Helpers";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const TaxFreeWithdraw = (
	props: any[] = {
		options_value: [],
		t: () => false
	},
): JSX.Element => (
	<Paper
		id={"tax-free-container"}
	>
		<Grid
			xs={12}
			sm={12}
			lg={12}
			md={12}
			container
			item
			className={"main-title"}
		>
			<Typography paragraph>
				{props.t("individual.options.tax_free_withdraw_plus_monthly_income")}
			</Typography>
		</Grid>

		<Grid
			xs={12}
			sm={12}
			lg={12}
			md={12}
			container
			item
			className={"financial-information"}
			style={{
				marginTop: "34.9px"
			}}
		>
			<Grid
				alignItems={"flex-start"}
				alignContent={"flex-start"}
				direction={"column"}
				className={"black size-12"}
				xs={6}
				sm={6}
				lg={6}
				md={6}
				container
				item
			>
				<Typography paragraph>
					{props.t("individual.options.tax_free_witthdraw")}
				</Typography>
			</Grid>
			<Grid
				alignItems={"flex-end"}
				alignContent={"flex-end"}
				direction={"column"}
				className={"black size-16 bold"}
				xs={6}
				sm={6}
				lg={6}
				md={6}
				container
				item
			>
				<Typography paragraph>
					£ {props.options_value.tax_free_withdraw}
				</Typography>
			</Grid>

			<Grid
				alignItems={"flex-start"}
				alignContent={"flex-start"}
				direction={"column"}
				className={"black bold size-12"}
				xs={6}
				sm={6}
				lg={6}
				md={6}
				container
				item
			>
				<Typography paragraph>
					{capitalizeFirstLetter(props.t("individual.options.total_withdraw").toLowerCase())}
				</Typography>
			</Grid>
			<Grid
				alignItems={"flex-end"}
				alignContent={"flex-end"}
				direction={"column"}
				className={"blue size-16 bold"}
				xs={6}
				sm={6}
				lg={6}
				md={6}
				container
				item
			>
				<Typography paragraph>
					£ {props.options_value.total_withdraw}
				</Typography>
			</Grid>
		</Grid>

		<Grid
			xs={12}
			sm={12}
			lg={12}
			md={12}
			container
			item
			className={"financial-information"}
		>
			<Grid
				alignItems={"flex-start"}
				alignContent={"flex-start"}
				direction={"column"}
				className={"black size-12"}
				xs={6}
				sm={6}
				lg={6}
				md={6}
				container
				item
			>
				<Typography paragraph>
					{props.t("individual.options.state_pension_income")}
				</Typography>
			</Grid>
			<Grid
				alignItems={"flex-end"}
				alignContent={"flex-end"}
				direction={"column"}
				className={"black size-16"}
				xs={6}
				sm={6}
				lg={6}
				md={6}
				container
				item
			>
				<Typography paragraph>
					£ {props.options_value.state_pension_income}
				</Typography>
			</Grid>
			<Grid
				alignItems={"flex-start"}
				alignContent={"flex-start"}
				direction={"column"}
				className={"black size-12"}
				xs={6}
				sm={6}
				lg={6}
				md={6}
				container
				item
			>
				<Typography paragraph>
					{props.t("individual.options.pension_monthly_income")}
				</Typography>
			</Grid>
			<Grid
				alignItems={"flex-end"}
				alignContent={"flex-end"}
				direction={"column"}
				className={"black size-16"}
				xs={6}
				sm={6}
				lg={6}
				md={6}
				container
				item
			>
				<Typography paragraph>
					£ {props.options_value.pension_monthly_income}
				</Typography>
			</Grid>
		</Grid>

		<Grid
			xs={12}
			sm={12}
			lg={12}
			md={12}
			container
			item
			className={"financial-information"}
		>
			<Grid
				alignItems={"flex-start"}
				alignContent={"flex-start"}
				direction={"column"}
				className={"black size-12"}
				xs={6}
				sm={6}
				lg={6}
				md={6}
				container
				item
			>
				<Typography paragraph>
					{props.t("individual.options.income_tax")}
				</Typography>
			</Grid>
			<Grid
				alignItems={"flex-end"}
				alignContent={"flex-end"}
				direction={"column"}
				className={"red size-16 bold"}
				xs={6}
				sm={6}
				lg={6}
				md={6}
				container
				item
			>
				<Typography paragraph>
					£ {props.options_value.income_tax}
				</Typography>
			</Grid>
		</Grid>

		<Grid
			xs={12}
			sm={12}
			lg={12}
			md={12}
			container
			item
			className={"financial-information"}
		>
			<Grid
				alignItems={"flex-start"}
				alignContent={"flex-start"}
				direction={"column"}
				className={"black bold size-12"}
				xs={6}
				sm={6}
				lg={6}
				md={6}
				container
				item
			>
				<Typography paragraph>
					{props.t("individual.options.total_monthly_income")}
				</Typography>
			</Grid>
			<Grid
				alignItems={"flex-end"}
				alignContent={"flex-end"}
				direction={"column"}
				className={"blue size-16 bold"}
				xs={6}
				sm={6}
				lg={6}
				md={6}
				container
				item
			>
				<Typography paragraph>
					£ {props.options_value.total_monthly_income}
				</Typography>
			</Grid>
		</Grid>

		<Footer t={props.t} />
	</Paper>
);

export default TaxFreeWithdraw;