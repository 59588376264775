import {
	CHANGE_SELECTED_COUNTRY,
	RETRIEVE_AVAILABLE_COUNTRIES,
	RETRIEVE_LANGUAGE_RESOURCES
} from "../../actions/Languages/types";
import {resolveReduxState} from "../../utils/Helpers";

const initial_state = {
	selected_country: "GB",
	language: "en",
	available_countries: [
		"GB",
		"BR",
		"PL",
		"ES",
	],
	resources: {},
	loading: false,
};

const LanguageStore = (state = initial_state, action = {
	type: "",
}) => {
	switch (action.type) {

		case CHANGE_SELECTED_COUNTRY:
		case RETRIEVE_LANGUAGE_RESOURCES:
		case RETRIEVE_AVAILABLE_COUNTRIES:
			return resolveReduxState(state, action);

		default: return state;
	}
};

export default LanguageStore;