import React, {useState, useEffect} from 'react';

import Grid from '@mui/material/Grid';
import {proceedNextSteep} from "../../actions/SetUpModal";
import {connect} from "react-redux";
import {retrieveByToken, updateSoftwareConnection} from "../../actions/ActivationToken";
import Loading from "../../components/Loading";
import MobileNumber from "../../components/Home/SetUpPage/MobileNumber";
import EmailValidation from "../../components/Home/SetUpPage/EmailValidation";
import SoftwareConnection from "../../components/Home/SetUpPage/SoftwareConnection";
import {agreedTermsAndConditions, proceed} from "../../actions/Steps";
import GenericTermsAndConditions from "../../components/GenericTermsAndConditions";
import BodyText from "../../components/Home/SetUpPage/TermsAndConditions/BodyText";
import {useTranslation} from "react-i18next";
import DefaultHeader from "../../layout/Header/DefaultHeader";
import {useNavigate, useParams} from "react-router-dom";

/**
 * @param steps
 * @param activeStep
 * @returns {boolean}
 */
function filterFinishedStep(
	steps = false,
	activeStep = false,
) {
	let actualStep = activeStep;
	if (steps && activeStep !== undefined) {

		if (steps.filter((s) => !s?.completed).length > 0) {
			actualStep = steps.filter((s) => !s?.completed)[0].step_id;
		} else {
			if (steps.filter(step => step.step_id === 3 && step.completed).length > 0) {
				actualStep = steps.filter(step => step.step_id === 3 && step.completed)[0].step_id;
			} else if (steps.filter(step => step.step_id === 2).length > 0) {
				actualStep = steps.filter(step => step.step_id === 2)[0].step_id;
			} else if (steps.filter(step => step.step_id === 1 && step.completed).length > 0) {
				actualStep = steps.filter(step => step.step_id === 1 && step.completed)[0].step_id;
			} else if (steps.filter(step => step.step_id === 0 && step.completed).length > 0) {
				actualStep = steps.filter(step => step.step_id === 0 && step.completed)[0].step_id;
			}
		}
	}

	return actualStep;
}

const _finishSignUp = (
	expanded: any = [],
	individual: any = [],
	agreedTermsAndConditions: Function = () => {
	}
) => {
	agreedTermsAndConditions({
		steps: {
			individual_id: individual.id,
			activeStep: 3,
			read_terms: expanded
		}
	})
};

/**
 * @param activeStep
 * @param token
 * @param token_data
 * @param individual
 * @param expanded
 * @param sectionTitle
 * @param handleNext
 * @param retrieveByToken
 * @param agreedTermsAndConditions
 * @param buttonActions
 * @param proceed
 * @param setExpanded
 * @param setSectionTitle
 * @param setActiveStep
 * @param history
 * @param t
 * @returns {JSX.Element}
 * @private
 */
const _renderContent = (
	activeStep: number = 0,
	token: boolean = false,
	token_data: boolean = false,
	individual: any = [],
	expanded: any = [],
	sectionTitle: string = "",
	handleNext: Function = () => {
	},
	retrieveByToken: Function = () => {
	},
	agreedTermsAndConditions: Function = () => {
	},
	buttonActions: Function = () => {
	},
	proceed: Function = () => {
	},
	setExpanded: Function = () => {
	},
	setSectionTitle: Function = () => {
	},
	setActiveStep: Function = () => {
	},
	history: Function = () => {
	},
	t = key => key,
): JSX.Element => {

	switch (activeStep) {
		case 0:
			return (
				<MobileNumber
					individual={individual}
					token={token}
					handleNext={() =>
						_finishSignUp(expanded, individual, agreedTermsAndConditions)
					}
					retrieveByToken={retrieveByToken}
					t={t}
				/>
			);

		case 1:
			return (
				<EmailValidation
					handleNext={handleNext}
					token={token}
					activeStep={activeStep}
					individual={individual}
					history={history}
					t={t}
				/>
			);

		case 2:
			return (
				<SoftwareConnection
					activeStep={activeStep}
					individual={individual}
					token={token}
					setActiveStep={setActiveStep}
					buttonActions={buttonActions}
					retrieveByToken={retrieveByToken}
					proceed={() =>
						proceed({
							steps: {
								individual_id: individual.id,
								step_id: 2,
								gotToNextStep: true,
							}
						}, token)
					}
					t={t}
				/>
			);

		case 3:
			return (
				<Grid
					id={"signup-container"}
					xl={7.6}
					lg={8.8}
					md={9}
					sm={9.8}
					xs={11}
					justifyContent={"center"}
					item
					container
				>
					<Grid
						xs={12}
						md={12}
						lg={12}
						sm={12}
						item
						container
					>
						<BodyText t={t}/>
					</Grid>
					<GenericTermsAndConditions
						sectionTitle={sectionTitle}
						expanded={expanded ?? []}
						trialPeriod={individual.trial_period}
						setExpanded={setExpanded}
						setSectionTitle={setSectionTitle}
						proceed={() =>
							agreedTermsAndConditions({
								steps: {
									individual_id: individual.id,
									activeStep: 3,
									read_terms: expanded
								}
							}, token)
						}
						noProceed={() => history(`/opt-out/${token}`)}
						history={history}
						t={t}
					/>
				</Grid>
			);

		default:
			history("/login");
			break;
	}
};

/**
 * @param token_data
 * @param token_loading
 * @param step_loading
 * @param handleNext
 * @param retrieveByToken
 * @param agreedTermsAndConditions
 * @param buttonActions
 * @param proceed
 * @param setCountry
 * @param available_countries
 * @param selected_country
 * @param language
 * @returns {JSX.Element|*[]}
 * @constructor
 */
const SetUpPage = (
	{
		token_data = {
			token: "",
		} ?? false,
		token_loading = false,
		step_loading = false,
		handleNext = () => {
		},
		retrieveByToken = () => {
		},
		agreedTermsAndConditions = () => {
		},
		buttonActions = () => {
		},
		proceed = () => {
		},
		setCountry = (newCountry = "GB") => newCountry,
		available_countries = [],
		selected_country = "GB",
		language = "en",
	}
): JSX.Element => {

	const {
			individual = {
				mobile: "",
			}
		} = token_data,
		{
			steps = false
		} = individual,
		token = useParams().token,
		history = useNavigate();

	const [activeStep, setActiveStep] = useState(0),
		[expanded, setExpanded] = useState([]),
		[sectionTitle, setSectionTitle] = useState(""),
		{t,i18n} = useTranslation();

	useEffect(() => {
		i18n.changeLanguage(language);
	}, [
		language,
		i18n,
	]);

	useEffect(() => {
		retrieveByToken(token);
	}, [retrieveByToken, token]);

	useEffect(() => {
		if (steps)
			setActiveStep(filterFinishedStep(steps, 0));
	}, [activeStep, steps]);

	if (token_loading || !steps || step_loading) {
		return (
			<Loading absolute/>
		);
	}

	if (token_data) {
		return (
			<Grid
				xs={12}
				sm={12}
				md={12}
				lg={12}
				xl={12}
				id={"signup-wrapper"}
				direction={"row"}
				justifyContent={"center"}
				alignContent={"flex-start"}
				container
				item
			>
				<DefaultHeader
					history={history}
					isLogin={true}
					language={language}
					setCountry={setCountry}
					available_countries={available_countries}
					selected_country={selected_country}
				/>
				{
					_renderContent(
						activeStep,
						token,
						token_data,
						individual,
						expanded,
						sectionTitle,
						handleNext,
						retrieveByToken,
						agreedTermsAndConditions,
						buttonActions,
						proceed,
						setExpanded,
						setSectionTitle,
						setActiveStep,
						history,
						t,
					)
				}
			</Grid>
		);
	}

	return [];
}


/**
 * @param state
 * @returns {{valueOf(): boolean}}
 */
const mapStateToProps = state => {
	const {
		SetUpModal = false,
		ActivationToken = false,
		Languages = false,
		Steps,
	} = state;

	return {
		...SetUpModal,
		...ActivationToken,
		...Languages,
		Steps,
	};
};

/**
 * @param dispatch
 * @returns {{retrieveByToken: (function(*=, *=): *), handleNext: (function(*=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	handleNext: (nextStep = 0) => dispatch(proceedNextSteep(nextStep)),
	retrieveByToken: (token = false, modalLoading = true) => dispatch(retrieveByToken(token, modalLoading)),
	buttonActions: (token: string = "", code: string = "", action: string = "authorise") =>
		dispatch(updateSoftwareConnection(token ?? false, code ?? false, action ?? false)),
	proceed: (data: Object = {}, token = "") => dispatch(proceed(data ?? false, token)),
	agreedTermsAndConditions: (data = false, token = "") => dispatch(agreedTermsAndConditions(data, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetUpPage);
