import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
const GetContributionsHistoryYears = (data): Promise<AxiosResponse<any>> => (
	Request.post("/profile/transactions/get-contributions-history-years", data)
);

export default GetContributionsHistoryYears;