import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const CreateLeadPromise = (data: any[] = false): Promise<AxiosResponse<any>> => (
	Request.post(`/individual/employers/create-lead`, data)
);

export default CreateLeadPromise;