import React from "react";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import OptOutContentImage1 from "../../images/background-15.svg";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FirstWarning = (props): JSX.Element => (
	<React.Fragment>
		<Grid
			item
		>
			<img
				alt={"Opt out and cancellation"}
				src={OptOutContentImage1}
				id={"OptOutContentImage1"}
			/>
		</Grid>
		<Grid
			className={"opt-out-title-container"}
			item
		>
			<Typography
				component={"h6"}
				variant={"subtitle1"}
				align={"center"}
			>
				We’re sorry to see you go
			</Typography>
		</Grid>
		<Grid
			item
			className={"opt-out-info-container"}
		>
			<Typography
				component={"p"}
				variant={"caption"}
				align={"center"}
			>
				<strong>Important notice:</strong> {" "}
				When you opt out, you lose the right to your employer’s pension contributions.
				<br/>
				<strong className={"italic"}>
					Your employer cannot force you to opt out.
				</strong>
			</Typography>
			<br/>
			<Typography
				component={"p"}
				variant={"caption"}
				align={"center"}
				className={"italic"}
			>
				Since you are opting out before the 30 days Auto Enrolment deadline,
				contributions will be refunded to your employer.
			</Typography>
		</Grid>
		<Grid
			xs={12}
			lg={12}
			md={12}
			id={"firstStepOptOutContainer"}
			item
		>

			<Grid
				className={"dialog-contained-button"}
				item
			>
				<Button
					className={"want-to-opt-out-button"}
					type={'button'}
					variant="contained"
					onClick={() => props.proceed()}
				>
					Yes, I want to stop contributions
				</Button>
			</Grid>
			<Grid
				className={'dialog-contained-button'}
				item
			>
				<Button
					color="primary"
					variant={"outlined"}
					type={'button'}
					className={"want-to-stay-button"}
					onClick={() => props.history(`/my-account`)}
				>
					No, I want to stay
				</Button>
			</Grid>
		</Grid>
	</React.Fragment>
);

export default FirstWarning;