import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @request
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const UpdatePasswordPromise = (data: any[] = false): Promise<AxiosResponse<any>> => (
	Request.post(`/individual/forgot-password/update-password`, data)
);

export default UpdatePasswordPromise;