import React from "react";

import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';

import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import inactiveExtraContributionIcon from '../../../images/ExtraMonthlyContributionInactive.svg';
import activeExtraContributionIcon from '../../../images/ExtraMonthlyContributionActive.svg';
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import CountUp from "react-countup";
import {calculateNextOnDate, capitalizeFirstLetter} from "../../../utils/Helpers";
import moment from "moment/moment";

/**
 * @param props
 * @returns {JSX.Element}
 * @private
 */
const _renderContributionValue = (props: any[] = {
	extra_contributions: [],
}): JSX.Element => {
	if (props.extra_contributions.length > 0) {

		return (
			<Grid
				className={"line-padding"}
				xs={6}
				sm={6}
				md={6}
				lg={6}
				item
				container
			>
				<Grid
					xs={1}
					sm={1}
					md={1}
					lg={1}
					item
				>
					<LockOutlinedIcon className={"lock-icon"}/>
				</Grid>
				<Grid
					xs={10}
					sm={10}
					md={10}
					lg={10}
					item
				>
					<CountUp
						className={"count-number"}
						start={0}
						end={parseFloat(_retrieveContributionObject(props).contribution_value ?? 0)}
						separator=","
						decimals={2}
						decimal="."
						duration={1}
						prefix={`£ `}
						suffix={" "}
					/>
				</Grid>
			</Grid>
		);
	}

	return (
		<Grid
			className={`${props.extra_contributions.length > 0 ? "active" : "inactive"} line-padding`}
			xs={6}
			sm={6}
			md={6}
			lg={6}
			item
			container
		>
			<Grid
				xs={1}
				sm={1}
				md={1}
				lg={1}
				item
			>
				<ScheduleOutlinedIcon
					className={`lock-icon ${props.extra_contributions.length > 0 ? "active" : "inactive"}`}/>
			</Grid>
			<Grid
				xs={10}
				sm={10}
				md={10}
				lg={10}
				item
			>
                <span
	                className={props.extra_contributions.length > 0 ? "active" : "inactive"}
	                style={{
		                paddingLeft: "5px",
	                }}
                >
                    N/A
                </span>
			</Grid>
		</Grid>
	);
};

/**
 * @param props
 * @returns {{contribution_value: number}}
 * @private
 */
const _retrieveContributionObject = (props: any[] = {
	extra_contributions: [{
		contribution_type: "",
		provider_status: "",
	}],
}) => {
	let hasMonthly = props.extra_contributions.filter(e => e.contribution_type === "Monthly" && e.provider_status === "ACTIVE"),
		contributionObject = {
			contribution_value: 0,
		};

	if (hasMonthly.length > 0) {
		contributionObject = hasMonthly[hasMonthly.length - 1];
	} else {
		let hasOneOff = props.extra_contributions.filter(e => e.contribution_type === "One-off").sort(
			function (a,b){
				return a.id - b.id;
			});
		if (hasOneOff.length > 0) {
			contributionObject = hasOneOff[hasOneOff.length - 1];
		}
	}

	return contributionObject;
}


/**
 * @param props
 * @returns {string|`Last on ${*}`|`Last on ${string}`|`Next on ${*}`|`Next on ${string}`}
 * @private
 */
const _renderHeaderContents = (props: any[] = {
	extra_contributions: [{
		contribution_type: "",
		provider_status: "",
		next_contribution_date: "",
		contribution_date_formatted: "",
	}],
	t: key => key,
}): string => {
	if (props.extra_contributions.length > 0) {
		let hasMonthly = props.extra_contributions.filter((e = {
				contribution_type: "",
				provider_status: "",
			}) =>
				e.contribution_type === "Monthly" && e.provider_status === "ACTIVE"
		);

		if (hasMonthly.length > 0) {
			return props.t("individual.your_pension.contribution_card.next_on", {
				date: calculateNextOnDate(
					_retrieveContributionObject(props)?.contribution_date
				).format("DD MMM").toUpperCase(),
			});
		} else {
			let hasOneOff = props.extra_contributions.filter(e => e.contribution_type === "One-off");
			if (hasOneOff.length > 0) {
				return props.t("individual.your_pension.contribution_card.last_on", {
					date: moment(_retrieveContributionObject(props)?.created_at).format("DD MMM").toUpperCase(),
				});
			}
		}
	}

	return capitalizeFirstLetter(props.t("individual.tap_to_include").toLowerCase());
};

/**
 * @param props
 * @returns {JSX.Element}
 * @private
 */
function _renderContent(props: any[] = {
	t: key => key,
}): JSX.Element {
	return (
		<Card
			id={"extra-contributions-card"}
			onClick={() => props.history("/extra-contributions")}
		>
			<CardHeader
				className={props.extra_contributions.length > 0 ? "active" : "inactive"}
				action={
					<IconButton
						aria-label="settings"
						disableTouchRipple
						disableFocusRipple
						disableRipple
					>
						<KeyboardArrowRightIcon/>
					</IconButton>
				}
				title={props.t("individual.add_contributions")}
				subheader={_renderHeaderContents(props ?? false)}
			/>
			<CardContent>
				<Grid
					xs={12}
					sm={12}
					md={12}
					lg={12}
					item
					container
				>
					{_renderContributionValue(props ?? false)}
					<Grid
						xs={6}
						sm={6}
						md={6}
						lg={6}
						alignItems={"flex-end"}
						alignContent={"flex-end"}
						direction={"column"}
						item
						container
					>
						<img
							src={props.extra_contributions.length > 0 ? activeExtraContributionIcon : inactiveExtraContributionIcon}
							alt={"Extra contributions"}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ExtraContributionsCard = (props): JSX.Element => _renderContent(props ?? false);

export default ExtraContributionsCard;