import React from "react";

// default material ui components import.
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import CountUp from "react-countup";

// importing icons
import StandardActiveIcon from "../../../images/StandardActiveIcon.svg";
import StandardInactiveIcon from "../../../images/StandardInactiveIcon.svg";
import PrinciplesBasedActiveIcon from "../../../images/PrinciplesBasedActiveIcon.svg";
import PrinciplesBasedInactiveIcon from "../../../images/PrinciplesBasedInactiveIcon.svg";
import HighGrowthActiveIcon from "../../../images/HighGrowthActiveIcon.svg";
import HighGrowthInactiveIcon from "../../../images/HighGrowthInactiveIcon.svg";
import LowGrowthActiveIcon from "../../../images/LowGrowthActive.svg";
import LowGrowthInactiveIcon from "../../../images/LowGrowthInactive.svg";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";

/**
 * @param props
 * @returns {string|*}
 */
function renderApproachLabel(props: any = false): string {
	let currentInvestment = [];

	if (props && props.investment_approaches) {
		if (props.individual_approach) {
			currentInvestment = props.investment_approaches.filter(
				i => props.individual_approach.investment_approach_id === i.id
			);
		}

		if (currentInvestment.length > 0) {
			const investment = currentInvestment[0];

			return investment.name;
		}
	}

	return "Standard";
}

/**
 * @param props
 * @returns {number|*}
 */
function renderYearlyCharge(props: any = false): number {
	let currentInvestment = [];
	if (props && props.investment_approaches) {
		if (props.individual_approach) {
			currentInvestment = props.investment_approaches.filter(
				i => props.individual_approach.investment_approach_id === i.id
			);
		}

		if (currentInvestment.length > 0) {
			const investment = currentInvestment[0];

			return parseFloat(investment.yearly_charge);
		}
	}

	return 0;
}

/**
 * @param props
 * @returns {*[]|*}
 */
function renderInvestmentDetailsIcon(props: any = false) {
	let currentInvestment = [];

	if (props && props.investment_approaches) {
		if (props.individual_approach) {
			currentInvestment = props.investment_approaches.filter(
				i => props.individual_approach.investment_approach_id === i.id
			);
		}

		if (currentInvestment.length > 0) {
			const investment = currentInvestment[0];
			const active = props.individual_approach.investment_approach_id === investment.id;

			switch (investment.name) {
				case "Standard":
					if (active) {
						return <img
							src={StandardActiveIcon}
							alt={investment.name}
						/>;
					}
					return <img
						src={StandardInactiveIcon}
						alt={investment.name}
					/>;

				case "Sustainable":
					if (active) {
						return <img
							src={PrinciplesBasedActiveIcon}
							alt={investment.name}
						/>;
					}
					return <img
						src={PrinciplesBasedInactiveIcon}
						alt={investment.name}
					/>;

				case "High Growth":
					if (active) {
						return <img
							src={HighGrowthActiveIcon}
							alt={investment.name}
						/>;
					}
					return <img
						src={HighGrowthInactiveIcon}
						alt={investment.name}
					/>;

				case "Low Growth":
					if (active) {
						return <img
							src={LowGrowthActiveIcon}
							alt={investment.name}
						/>;
					}
					return <img
						src={LowGrowthInactiveIcon}
						alt={investment.name}
					/>;

				default:
					return <LocalAtmIcon/>;
			}
		}
	}

	return [];
}

/**
 * @stateless
 * @returns {*}
 */
const InvestmentApproachCard = props => (
	<Card
		id={"investment-approach-card"}
		onClick={() => props.history("/investment-approach")}
	>
		<CardHeader
			action={
				<IconButton
					aria-label="settings"
					disableTouchRipple
					disableFocusRipple
					disableRipple
				>
					<KeyboardArrowRightIcon/>
				</IconButton>
			}
			title={props.t("individual.investment_approach")}
			subheader={renderApproachLabel(props)}
		/>
		<CardContent>
			<Grid
				xs={12}
				sm={12}
				md={12}
				lg={12}
				item
				container
			>
				<Grid
					className={"line-padding"}
					xs={6}
					sm={6}
					md={6}
					lg={6}
					item
				>
					<CountUp
						className={"count-number"}
						start={0}
						end={renderYearlyCharge(props)}
						separator=","
						decimals={2}
						decimal="."
						duration={1}
						suffix={"% "}
					/>
					<span
						className={"count-number-no-bolder"}
					>
                        {props.t("individual.yearly_charge")}
                    </span>
				</Grid>
				<Grid
					xs={6}
					sm={6}
					md={6}
					lg={6}
					alignItems={"flex-end"}
					alignContent={"flex-end"}
					direction={"column"}
					item
					container
				>
					{renderInvestmentDetailsIcon(props)}
				</Grid>
			</Grid>
		</CardContent>
	</Card>
);

export default InvestmentApproachCard;