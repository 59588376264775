import React from "react";

import {Grid, Typography} from "@mui/material";

/**
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const BodyText = (
	{
		t = key => key,
	}
): JSX.Element => (
	<Grid
		xl={12}
		lg={12}
		md={12}
		xs={12}
		sm={12}
		direction={"column"}
		alignItems={"center"}
		justifyContent={"center"}
		container
		item
	>
		<Typography
			className={"page-title-terms"}
			align={"center"}
		>
			{t("individual.terms_and_conditions.title")}
		</Typography>
		<Typography
			className={"page-subtitle-terms"}
			align={"center"}
		>
			{t("individual.terms_and_conditions.subtitle")}
		</Typography>
	</Grid>
);

export default BodyText;