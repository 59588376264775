import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {checkPassword, validateEmail} from "../../../../utils/Helpers";
import {connect} from "react-redux";
import Loading from "../../../Loading";
import {sentEmailCode} from "../../../../actions/ActivationToken";
import React, {useState} from "react";
import PasswordCheck from "../../../PasswordCheck";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";

/**
 * @returns {*}
 */
const CreateLogin = ({
	title = "",
	email = "",
	password = "",
	confirmPassword = "",
	step_loading = false,
	individual = [],
	token = "",
	stepErrorText = "",
	setEmail = () => {
	},
	setPassword = () => {
	},
	setConfirmPassword = () => {
	},
	sentEmailCode = () => {
	}
 }): JSX.Element => {

	const [showPassword, setShowPassword] = useState(false);

	if (step_loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<Grid
			xl={12}
			lg={12}
			md={12}
			container
			item
			direction={"row"}
			justifyContent={"center"}
			id={"change-email-wrapper"}
		>
			<Grid
				xl={9.5}
				lg={9.5}
				md={12}
				sm={12}
				xs={12}
				item
				direction={"row"}
				container
				justifyContent={"center"}
				className={"grid"}
			>
				<Grid
					xl={6.55}
					lg={7.2}
					md={8}
					sm={12}
					xs={12}
					item
					container
					direction={"row"}
					display={"inline-block"}
					textAlign={"-webkit-center"}
				>
					<Typography
						className={"title"}
					>
						Verify Account
					</Typography>
					<Typography
						className={"subtitle"}
					>
						<strong>USE YOUR PERSONAL E-MAIL</strong>, so that if you change jobs in the future you
						can still
						access your account.
					</Typography>
					<Grid
						xl={8.2}
						lg={8.2}
						md={8.2}
						sm={6}
						xs={12}
						item
						container
					>
						<Typography
							className={"label-code"}
						>
							Email
						</Typography>
						<TextField
							id={"email"}
							className={"textfield2 extra color-extra"}
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							fullWidth
						/>
						<Typography
							className={"label-code"}
						>
							Password
						</Typography>
						<OutlinedInput
							id={"password"}
							className={"textfield2 extra color-extra"}
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							type={showPassword ? 'text' : 'password'}
							fullWidth
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										onClick={() => setShowPassword(!showPassword)}
										aria-label="toggle password visibility"
										disableFocusRipple
										disableRipple
										disableTouchRipple
									>
										{showPassword ? <Visibility/> : <VisibilityOff/>}
									</IconButton>
								</InputAdornment>
							}
						/>
						<PasswordCheck
							password={password}
						/>
						<Grid container marginTop={"20px"} marginBottom={"20px"}>
							<Typography
								className={"label-code"}
							>
								Confirm Password
							</Typography>
							<OutlinedInput
								id={"confirm-password"}
								className={"textfield2 color-extra"}
								value={confirmPassword}
								onChange={(e) => setConfirmPassword(e.target.value)}
								type={showPassword ? 'text' : 'password'}
								error={password !== confirmPassword && confirmPassword.length > 0}
								fullWidth
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											onClick={() => setShowPassword(!showPassword)}
											aria-label="toggle password visibility"
											disableFocusRipple
											disableRipple
											disableTouchRipple
										>
											{showPassword ? <Visibility/> : <VisibilityOff/>}
										</IconButton>
									</InputAdornment>
								}
							/>
							<small className={"collegia-text-danger"}>
								{
									(password !== confirmPassword) && password.length > 0 && confirmPassword.length > 0 ?
										"Password doesn't match"
										:
										""
								}
							</small>
						</Grid>
						<Button
							fullWidth
							onClick={() =>
								sentEmailCode({
										activation_tokens: {
											individual_id: individual.id,
											email,
											password,
											title
										}
									},
									token
								)
							}
							disabled={
								!email ||
								!password ||
								!confirmPassword ||
								!validateEmail(email) ||
								!checkPassword(password) ||
								password !== confirmPassword
							}
						>
							Continue
						</Button>
						{
							stepErrorText.length > 0 &&
							<small className={"red-text"}>
								{stepErrorText}
							</small>
						}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => {
	const {
		Steps
	} = state;

	return {
		...Steps,
	};
};

/**
 * @param dispatch
 * @returns {{updateIndividualData: (function(*=, *=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	sentEmailCode: (data = false, token = false) => dispatch(sentEmailCode(data, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateLogin);