// importing the default redux store creators.
import { configureStore, combineReducers, applyMiddleware } from '@reduxjs/toolkit';
import {thunk} from 'redux-thunk';

// importing the application store.
import Menu from './Layout/Menu';
import SnackBar from "./SnackBar";
import ActivationToken from "./ActivationToken";
import Steps from './Steps';
import ActivationCode from './ActivationCode';
import SetUpModal from './SetUpModal';
import Profile from './Profile';
import Transactions from './Transactions';
import GenericProjectionParameters from "./GenericProjectionParameters";
import RealProjectionParameters from "./RealProjectionParameters";
import ComfortRulerProjectionParameters from "./ComfortRulerProjectionParameters";
import PotToggle from './PotToggle';
import Employers from './Employers';
import InvestmentApproach from './InvestmentApproach';
import OtherPensions from './OtherPensions';
import ExtraContributions from "./ExtraContributions";
import SignUp from "./SignUp";
import Connections from './Connections';
import Beneficiaries from "./Beneficiaries";
import SourceOfFunds from "./SourceOfFunds";
import EmploymentStatus from "./EmploymentStatus";
import UpcomingTransactions from "./UpcomingTransactions";
import Notifications from "./Notifications";
import AssetsLocation from "./AssetsLocation";
import Invitations from "./Invitations";
import Forbidden from './Forbbiden';
import Languages from "./Languages";

const reducers = combineReducers({
	Menu,
	SnackBar,
	ActivationToken,
	Steps,
	ActivationCode,
	SetUpModal,
	Profile,
	Transactions,
	GenericProjectionParameters,
	RealProjectionParameters,
	ComfortRulerProjectionParameters,
	PotToggle,
	Employers,
	InvestmentApproach,
	OtherPensions,
	ExtraContributions,
	SignUp,
	Connections,
	Beneficiaries,
	SourceOfFunds,
	EmploymentStatus,
	UpcomingTransactions,
	Notifications,
	AssetsLocation,
	Invitations,
	Forbidden,
	Languages,
});

/**
 * @return {store}
 */
export default configureStore({
	reducer: reducers,
}, applyMiddleware(thunk))
