import MyAccount from "./MyAccount";
import PersonalInformation from "./PersonalInformation";
import LoginDetails from "./LoginDetails";
import SendProfileChangeConfirmationCode from "./LoginDetails/SendProfileChangeConfirmationCode";
import UpdateLoginDetails from './UpdateLoginDetails';
import Address from "./Address";
import Beneficiaries from "./Beneficiaries";
import TaxRelief from "./TaxRelief";
import FirstPassword from "./FirstPassword";
import YourDocuments from "./YourDocuments";

const ProfilePages = {
	MyAccount,
	PersonalInformation,
	LoginDetails,
	SendProfileChangeConfirmationCode,
	UpdateLoginDetails,
	Address,
	Beneficiaries,
	TaxRelief,
	FirstPassword,
	YourDocuments,
};

export default ProfilePages;