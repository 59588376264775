import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param id
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const UpdatePromise = (id: any = false, data: any[] = false): Promise<AxiosResponse<any>> => (
	Request.put(`/profile/${id}`, data)
);

export default UpdatePromise;