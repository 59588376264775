import React, {useState, useEffect} from 'react';
import Wrapper from "../../../layout/Logged/Wrapper";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {connect} from "react-redux";
import {
	requestChangeEmail,
	returnUser,
	updateMyEmail,
	updateMyPassword
} from "../../../actions/Profile";
import {useTranslation} from "react-i18next";
import MyAccountLeftMenu from "../../../components/MyAccountLeftMenu";
import TextField from "@mui/material/TextField";
import Loading from "../../../components/Loading";
import {useNavigate, useParams} from "react-router-dom";
import PasswordCheck from "../../../components/PasswordCheck";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import {checkPassword} from "../../../utils/Helpers";

/**
 * @param setMessage
 * @param requestChangeEmail
 * @param t
 * @param operation
 * @private
 */
const _sentItAgain = (
	setMessage = () => {
	},
	requestChangeEmail = () => {
	},
	t = (key) => key,
	operation = "E-mail",
) => {
	setMessage(t("individual.sent_another_code_email_15_minutes_expire"));

	requestChangeEmail({
		profiles: {
			operation,
			renew: true,
		}
	});
};

/**
 * @param operation
 * @param code
 * @param password
 * @param confirm_password
 * @returns {boolean}
 * @private
 */
const _checkIfTheButtonIsEnabled = (
	operation = "E-mail",
	code = "",
	password = "",
	confirm_password = ""
) => {

	if (!code) {
		return true;
	}

	if (operation !== "E-mail") {
		if (password === "" || confirm_password === "" || !checkPassword(password)) {
			return true;
		}

		if (password !== confirm_password) {
			return true;
		}
	}

	return false;
}

/**
 * @param t
 * @param password
 * @param confirmPassword
 * @param showPassword
 * @param setPassword
 * @param setConfirmNewPassword
 * @param setShowPassword
 * @returns {JSX.Element}
 * @private
 */
const _renderPasswordForm = (
	t = key => key,
	password = "",
	confirmPassword = "",
	showPassword = false,
	setPassword = password => password,
	setConfirmNewPassword = confirmPassword => confirmPassword,
	setShowPassword = () => {},
): JSX.Element => (
	<>
		<Grid
			xl={8.2}
			lg={8.2}
			md={8.2}
			sm={6}
			xs={12}
			item
			container
		>
			<Typography
				className={"label-code"}
			>
				{t("individual.reset_password.set_new_password")}
			</Typography>
			<OutlinedInput
				type={showPassword ? 'text' : 'password'}
				id={"new_password"}
				name={"new_password"}
				className={"textfield2"}
				value={password}
				onChange={e => setPassword(e.target.value)}
				placeholder={t("individual.reset_password.set_new_password")}
				fullWidth
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							onClick={() => setShowPassword(!showPassword)}
							aria-label="toggle password visibility"
							disableFocusRipple
							disableRipple
							disableTouchRipple
						>
							{showPassword ? <Visibility/> : <VisibilityOff/>}
						</IconButton>
					</InputAdornment>
				}
			/>
		</Grid>
		<Grid
			xl={8.2}
			lg={8.2}
			md={8.2}
			sm={6}
			xs={12}
			item
			container
		>
			<PasswordCheck
				password={password}
			/>
		</Grid>
		<Grid
			xl={8.2}
			lg={8.2}
			md={8.2}
			sm={6}
			xs={12}
			item
			container
		>
			<Grid sx={{paddingTop: "10px"}}>
				<Typography
					className={"label-code"}
				>
					{t("individual.confirm_password_input")}
				</Typography>
			</Grid>

			<OutlinedInput
				type={showPassword ? 'text' : 'password'}
				id={"confirm_password"}
				name={"confirm_password"}
				className={"textfield2"}
				value={confirmPassword ?? ""}
				onChange={e => setConfirmNewPassword(e.target.value)}
				placeholder={t("individual.confirm_password_input")}
				fullWidth
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							onClick={() => setShowPassword(!showPassword)}
							aria-label="toggle password visibility"
							disableFocusRipple
							disableRipple
							disableTouchRipple
						>
							{showPassword ? <Visibility/> : <VisibilityOff/>}
						</IconButton>
					</InputAdornment>
				}
			/>
			{
				confirmPassword !== password && (password.length > 0 || confirmPassword.length > 0) &&
				<Grid>
					<Grid paddingBottom={"10px"}>
						<small className={"red-text"}>
							{t("individual.errors.passwords_does_not_match")}
						</small>
					</Grid>
				</Grid>
			}
		</Grid>

	</>
);

/**
 * @param profile_data
 * @param new_email
 * @param setNewEmail
 * @param t
 * @returns {JSX.Element}
 * @private
 */
const _renderEmailChangeForm = (
	profile_data = {
		email: "",
	},
	new_email = "",
	setNewEmail = (email = "") => email,
	t = key => key,
): JSX.Element => (
	<>
		<Grid
			xl={8.2}
			lg={8.2}
			md={8.2}
			sm={6}
			xs={12}
			item
			container
		>
			<Typography
				className={"label-code"}
			>
				{t("individual.my_account.login_details.current_email")}
			</Typography>
			<TextField
				id={"current_email"}
				name={"current_email"}
				className={"textfield2"}
				value={profile_data.email}
				placeholder={t("individual.my_account.login_details.current_email")}
				disabled
				fullWidth
			/>
		</Grid>
		<Grid
			xl={8.2}
			lg={8.2}
			md={8.2}
			sm={6}
			xs={12}
			item
			container
			paddingTop={3}
			paddingBottom={3}
		>
			<Typography
				className={"label-code"}
			>
				{t("individual.my_account.login_details.new_email")}
			</Typography>
			<TextField
				id={"new_email"}
				name={"new_email"}
				className={"textfield2"}
				value={new_email}
				onChange={e => setNewEmail( e.target.value)}
				placeholder={t("individual.my_account.login_details.new_email")}
				fullWidth
			/>
		</Grid>
	</>
);

/**
 * @param code_confirmed
 * @param request_change_email
 * @param request_change_password
 * @param request_change_email_code_confirmed
 * @param request_change_password_code_confirmed
 * @param message_error
 * @param requestChangeEmail
 * @param profile_loading
 * @param profile_data
 * @param updateMyEmail
 * @param updateMyPassword
 * @returns {JSX.Element}
 * @constructor
 */
const SendProfileChangeConfirmationCode = (
	{
		code_confirmed = null,
		request_change_email = false,
		request_change_password = false,
		request_change_email_code_confirmed = false,
		request_change_password_code_confirmed = false,
		message_error = "",
		requestChangeEmail = () => {
		},
		profile_loading = false,
		profile_data = {
			email: "",
		},
		updateMyEmail = data => data,
		updateMyPassword = data => data,
	}
): JSX.Element => {
	const {t} = useTranslation();
	const [code, setCode] = useState(""),
		[message, setMessage] = useState(
			t("individual.sent_another_code_email_15_minutes_expire")
		),
		operation = useParams().operation ?? false,
		[new_email, setNewEmail] = useState(""),
		[password, setPassword] = useState(""),
		[confirmPassword, setConfirmPassword] = useState(""),
		history = useNavigate(),
		[errorMessage, setErrorMessage] = useState(""),
		[showPassword, setShowPassword] = useState(false);

	useEffect(() => {
		requestChangeEmail({
			profiles: {
				operation: operation,
			}
		});
	}, [
		requestChangeEmail,
		operation,
	]);


	useEffect(() => {
		if (code_confirmed) {
			history(`/my-account/login-details/update/${code}`);
		}
		setErrorMessage(message_error);
		setMessage(t("individual.sent_another_code_email_15_minutes_expire"));
	}, [
		message_error,
		request_change_email_code_confirmed,
		request_change_password_code_confirmed,
		code_confirmed,
		request_change_email,
		request_change_password,
		code,
		history,
		t,
	]);

	if (profile_loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<Wrapper
			hideFooter={true}
			history={history}
			t={t}
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				container
				item
				direction={"row"}
				id={"change-email-wrapper"}
			>
				<MyAccountLeftMenu
					history={history}
					currentPage={"LoginDetails"}
				/>
				<Grid
					xl={9.5}
					lg={9.5}
					md={12}
					sm={12}
					xs={12}
					item
					direction={"row"}
					container
					justifyContent={"center"}
					className={"grid"}
				>
					<Grid
						xl={6.55}
						lg={7.2}
						md={8}
						sm={12}
						xs={12}
						item
						container
						direction={"row"}
						display={"inline-block"}
						textAlign={"-webkit-center"}
					>
						<Typography
							className={"title"}
						>
							{t("individual.my_account.login_details.verify_account.title")}
						</Typography>
						<Typography
							className={"subtitle"}
						>
							{t("individual.my_account.login_details.verify_account.security_reasons_message")}
						</Typography>
						<Grid
							xl={8.2}
							lg={8.2}
							md={8.2}
							sm={6}
							xs={12}
							item
							container
						>
							<Typography
								className={"label-code"}
							>
								{t("individual.code_sent_by_email")}
							</Typography>
							<TextField
								id={"code"}
								className={"textfield2"}
								value={code ?? ""}
								onChange={(e) => setCode(e.target.value)}
								fullWidth
							/>
							<small className={"collegia-text-danger"}>
								{errorMessage}
							</small>
							<small className={"collegia-text-danger"}>
								{message}
							</small>
							<Typography
								className={"label-send-again"}
							>
								{t("individual.did_not_receive_the_code")}
								<span
									onClick={() =>
										_sentItAgain(
											setMessage,
											requestChangeEmail,
											t,
											operation,
										)
									}
								>
									{t("individual.send_again")}
								</span>
							</Typography>
						</Grid>
						{
							operation === "E-mail" ?
								_renderEmailChangeForm(
									profile_data,
									new_email,
									setNewEmail,
									t,
								)
								:
								_renderPasswordForm(
									t,
									password,
									confirmPassword,
									showPassword,
									setPassword,
									setConfirmPassword,
									setShowPassword,
								)
						}
						<Grid
							xl={8.2}
							lg={8.2}
							md={8.2}
							sm={6}
							xs={12}
							item
							container
							marginTop={2}
						>
							<Button
								fullWidth
								onClick={() => operation === "E-mail" ?
									updateMyEmail({
										profiles: {
											email: new_email,
											code,
										},
									})
									:
									updateMyPassword({
										profiles: {
											password,
											confirmPassword,
											code,
										}
									})
								}
								disabled={_checkIfTheButtonIsEnabled(operation, code, password, confirmPassword)}
							>
								{
									operation === "E-mail" ?
										t("individual.my_account.login_details.change_my_email_button")
										:
										t("individual.my_account.login_details.change_my_password_button")
								}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Wrapper>
	);

};

const mapStateToProps = state => state.Profile;

const mapDispatchToProps = dispatch => ({
	requestChangeEmail: (data: any[] = false) => dispatch(requestChangeEmail(data)),
	returnUser: dispatch(returnUser()),
	updateMyEmail: (data: any[] = false) => dispatch(updateMyEmail(data)),
	updateMyPassword: (data = {}) => dispatch(updateMyPassword(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SendProfileChangeConfirmationCode);
