import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import StandardIcon from "../../images/investment-approaches/standard-icon.svg";
import ABIcon from "../../images/investment-approaches/ab.svg";
import React from "react";
import StickerIcon from "../../images/investment-approaches/sticker-strategy.svg";

/**
 * @param currentStrategy
 * @param name
 * @param updateInvestmentApproach
 * @param investment
 * @param hasIfa
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const StandardDetails = (
	{
		currentStrategy = "",
		name = "",
		updateInvestmentApproach = () => {
		},
		investment = "",
		hasIfa = false,
		t = key => key,
	}
): JSX.Element => (
	<Grid
		container
		item
		justifyContent={"center"}
		id={"standard-details"}
	>
		<Grid
			id={'upper-component'}
			xl={9}
			lg={8}
			md={8}
			sm={11}
			xs={11}
			item
		>
			<Grid>
				<img
					src={StandardIcon}
					alt={"StandardIcon"}
					className={"icon"}
				/>
				{
					currentStrategy === name && (
						<img
							src={StickerIcon}
							alt={"StickerIcon"}
							className={"sticker"}
						/>
					)
				}
			</Grid>
			<Grid>
				<Typography
					className={"title"}
				>
					{t("individual.investment_approaches.details.seeks_to", {
						strategy: name,
					})}
				</Typography>
			</Grid>
			<Grid
				xl={7}
				lg={9}
				md={10}
				sm={10}
				xs={10}
				item
			>
				<Typography
					className={"sub-title"}
				>
					{t("individual.investment_approaches.details.investment_allocation.standard.subtitle")}
				</Typography>
			</Grid>
			{
				(currentStrategy !== name && hasIfa === false) && (
					<Grid
						item
						xl={3.5}
						lg={3.5}
						md={5}
						sm={6}
						xs={12}
						textAlign={"center"}
						onClick={() => updateInvestmentApproach({
							individuals: {
								investment_approach_id: investment.id,
							}
						})}
					>
						<Typography
							className={"button"}
						>
							{t("individual.investment_approaches.details.select_this_strategy")}
						</Typography>
					</Grid>
				)
			}
		</Grid>
		<Grid
			id={'body'}
			xl={9}
			lg={8}
			md={8}
			sm={11}
			xs={11}
			item
			container
			justifyContent={"center"}
		>
			<Grid
				xl={10}
				lg={10}
				md={10}
				sm={11}
				xs={11}
				item
			>
				<Grid>
					<Typography
						className={"title-1"}
					>
						{t("individual.investment_approaches.details.investment_allocation.standard.supervision_and_administration.title")}
					</Typography>
				</Grid>
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
				>
					<Typography
						className={"sub-title-1"}
					>
						{t("individual.investment_approaches.details.investment_allocation.standard.supervision_and_administration.description")}
					</Typography>
					<img
						src={ABIcon}
						alt={""}
					/>
				</Grid>
				<Grid>
					<Typography
						className={"title-2"}
					>
						{t("individual.investment_approaches.details.investment_allocation.title")}
					</Typography>
				</Grid>
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
				>
					<Typography
						className={"sub-title-2"}
						dangerouslySetInnerHTML={{
							__html: t("individual.investment_approaches.details.investment_allocation.standard.investment_allocation.description")
						}}
					/>
				</Grid>
				{
					(currentStrategy !== name && hasIfa === false) && (
						<Grid
							container
							item
							justifyContent={"center"}
						>
							<Grid
								item
								xl={3.5}
								lg={3.5}
								md={5}
								sm={6}
								xs={12}
								textAlign={"center"}
								className={'button-grid'}
								onClick={() => updateInvestmentApproach({
									individuals: {
										investment_approach_id: investment.id,
									}
								})}
							>
								<Typography
									className={"button"}
								>
									{t("individual.investment_approaches.details.select_this_strategy")}
								</Typography>
							</Grid>
						</Grid>
					)
				}
			</Grid>
		</Grid>
	</Grid>
);

export default StandardDetails;