import React, {useState} from "react";

import Container from '@mui/material/Container';
import Header from './Header';
import RightNavigationMenu from "./Header/RightNavigationMenu";


/**
 * @param t
 * @param individual_setup
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const Wrapper = (
	{
		t = key => key,
		individual_setup = false,
		children,
	}
): JSX.Element => {
	const [drawer, setDrawer] = useState(false);

	const handleDrawer = () => {
		setDrawer(!drawer);
	};

	return (
		<Container
			className={"no-padding"}
			fixed
		>
			<Header
				drawer={drawer}
				handleDrawer={handleDrawer}
				individual_setup={individual_setup}
				t={t}
			/>
			{children}
			{
				!individual_setup && (
					<RightNavigationMenu
						drawer={drawer}
						handleDrawerClose={handleDrawer}
						t={t}
					/>
				)
			}
		</Container>
	);
}

export default Wrapper;
