import Grid from "@mui/material/Grid";
import HelpOutline from "@mui/icons-material/HelpOutline";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import GenericModal from "../GenericModal";
import {useState} from "react";
import { useNavigate} from "react-router-dom";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const PrimaryHeader = (props = {
	backIcon: false,
	helpIcon: false,
	helpModal: "",
	modalTitle: "",
	modalBody: {},
	backPage: "",
}): JSX.Element => {
	const [show, setShow] = useState(true);
	const [isModalVisible, setModalVisible] = useState(false);
	let history = useNavigate();

	return (
		<Grid
			xs={12}
			sm={12}
			lg={12}
			md={12}
			className={"primary-header"}
			justifyContent={"space-evenly"}
			alignItems={"center"}
			alignContent={"center"}
			container
			item
		>
			<Grid
				justifyContent={"center"}
				alignItems={"center"}
				sm={1}
				xs={1}
				container
				item
			>
				{props?.backIcon ?
					<ArrowBackIos
						className={"margin-arrow-primary-header-icon"}
						cursor={"pointer"}
						onClick={() => props?.backPage ? props?.history(props?.backPage) : history(-1)}
					/>
					: ""
				}
			</Grid>

			<Grid
				justifyContent={"center"}
				alignItems={"center"}
				sm={10}
				xs={10}
				container
				item
			>
				{props.children}
			</Grid>

			{isModalVisible &&
				<GenericModal
					show={show}
					setShow={setShow}
					setModalVisible={setModalVisible}
					title={props?.helpModal?.title}
					body={props?.helpModal?.body()}
				/>
			}

			<Grid
				item
				container
				justifyContent={"center"}
				alignItems={"center"}
				sm={1}
				xs={1}
			>
				{props?.helpIcon ?
					<HelpOutline
						cursor={"pointer"}
						onClick={() => setModalVisible(true)}
					/>
					: ""
				}
			</Grid>
		</Grid>
	);
}