import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import CancellationImage from '../images/background-17.svg';
import Footer from "../layout/Footer";
import Wrapper from "../layout/Wrapper";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const AccountDelete = (): JSX.Element => {
	const menu = document.getElementById("menu");

	if (menu) {
		menu.remove();
	}

	setTimeout(() => window.location = "https://www.collegia.co.uk", 5000);

	return (
		<Wrapper>
			<Grid
				xs={12}
				lg={12}
				md={12}
				id={"account-deleted-container"}
				direction={"column"}
				alignContent={"center"}
				alignItems={"center"}
				item
				container
			>
				<Grid
					xs={12}
					lg={12}
					md={12}
					className={'img-container'}
					item
				>
					<img
						src={CancellationImage}
						alt={"We so sorry to see you go"}
					/>
				</Grid>

				<Grid
					xs={12}
					mg={12}
					lg={12}
					className={"padding-content"}
					item
				>
					<Typography
						align={"center"}
						variant={"h6"}
						component={"h6"}
					>
						We really hope to see you again in the future
					</Typography>
					<Typography
						align={"center"}
						variant={"subtitle1"}
						component={"p"}
						className={"info-text"}
					>
						You’ve cancelled your Collegia account and gave up your
						employer’s current and future pension contributions.
					</Typography>
				</Grid>

				<Grid
					xs={12}
					mg={12}
					lg={12}
					className={"redirect-container padding-content"}
					item
				>
					<Typography
						align={"center"}
						variant={"body1"}
						component={"p"}
					>
						You will be redirected to Collegia’s website automatically…
					</Typography>
				</Grid>
			</Grid>
			<Footer/>
		</Wrapper>
	);
}

export default AccountDelete;