import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CompaniesList from "./CompaniesList";
import ConfirmEmailRefer from "./ConfirmEmailRefer";
import ReferEmployerModal from "../ReferEmployerModal";
import ReferEmployerManually from "./ReferEmployerManually";

/**
 * @param search_results
 * @param employerTrigger
 * @param email
 * @param selectedEmployer
 * @param search_loading
 * @param setEmail
 * @param setSelectedEmployer
 * @param setEmployerTrigger
 * @param setSearchCompany
 * @param renderCompanyNumber
 * @param renderCompanyName
 * @param renderIncorporatedInfo
 * @param renderAddressSnippet
 * @param createLead
 * @param t
 * @param open
 * @param setOpen
 * @returns {JSX.Element}
 * @constructor
 */
const ReferEmployerDesktop = (
	{
		search_results = [],
		employerTrigger = false,
		email = "",
		selectedEmployer = {},
		search_loading = false,
		setEmail = () => {
		},
		setSelectedEmployer = () => {
		},
		setEmployerTrigger = () => {
		},
		setSearchCompany = () => {
		},
		renderCompanyNumber = () => {
		},
		renderCompanyName = () => {
		},
		renderIncorporatedInfo = () => {
		},
		renderAddressSnippet = () => {
		},
		createLead = () => {
		},
		t = key => key,
		open = false,
		setOpen = () => {
		},
	}
): JSX.Element => {

	const [referManually, setReferManually] = useState(false);

	return (
		<Grid
			id={"refer-your-employer-desktop"}
		>
			{
				employerTrigger ?
					<Grid>
						<Grid
							xs={8}
							sm={8}
							md={8}
							lg={8}
							xl={8}
							item
							textAlign={"center"}
						>
							<Typography className={"title"}>
								{t("individual.refer_employer.title")}
							</Typography>
						</Grid>
						<Grid
							paddingRight={"5%"}
							paddingLeft={"5%"}
							width={"100%"}
						>
							<ConfirmEmailRefer
								email={email}
								selectedEmployer={selectedEmployer}
								setEmail={setEmail}
								renderCompanyName={renderCompanyName}
								renderCompanyNumber={renderCompanyNumber}
								renderIncorporatedInfo={renderIncorporatedInfo}
								renderAddressSnippet={renderAddressSnippet}
								createLead={createLead}
								t={t}
							/>
						</Grid>
					</Grid>
					:
					<Grid
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
						item
						id={"refer-your-employer"}
						justifyContent={"center"}
						container
					>
						<Grid
							xs={8}
							sm={8}
							md={8}
							lg={8}
							xl={8}
							item
							textAlign={"center"}
						>
							<Typography className={"title"}>
								{t("individual.refer_employer.workplace_pension")}
							</Typography>
						</Grid>
						<Grid
							xs={8}
							sm={8}
							md={8}
							lg={8}
							xl={8}
							item
							textAlign={"center"}
							marginTop={"75px"}
						>
							<CompaniesList
								search_results={search_results}
								search_loading={search_loading}
								setSearchCompany={setSearchCompany}
								setEmployerTrigger={setEmployerTrigger}
								setReferManually={setReferManually}
								setSelectedEmployer={setSelectedEmployer}
								renderCompanyNumber={renderCompanyNumber}
								renderCompanyName={renderCompanyName}
								renderIncorporatedInfo={renderIncorporatedInfo}
								renderAddressSnippet={renderAddressSnippet}
								t={t}
							/>
							<Typography className={"refer-company-typography-style"}>
								{t("individual.refer_employer.did_not_find_employer_question")}
								<span
									className={"refer-company-span-style"}
									onClick={() => setReferManually(true)}
								>
									{" "} {t("individual.refer_employer.refer_manually_link")}
								</span>
							</Typography>

							{
								referManually &&
								<ReferEmployerManually
									setOpen={setOpen}
								/>
							}
						</Grid>
					</Grid>
			}

			<ReferEmployerModal
				open={open}
				setOpen={setOpen}
			/>
		</Grid>
	)
};

export default ReferEmployerDesktop;