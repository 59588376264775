import React, {useEffect} from 'react';

import {connect} from "react-redux";
import {buttonActions, getByCode} from "../../actions/Connections";

import Wrapper from "../../layout/Wrapper";
import Slide from "@mui/material/Slide";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Logo from '../../images/logo-icon.svg';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Loading from "../../components/Loading";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

/**
 * @param software
 * @param t
 * @returns {JSX.Element}
 */
const renderCompanyName = (
	software = {
		code: "",
		active: false,
	}, t = key => key
): JSX.Element => (
	<p className={"title"} dangerouslySetInnerHTML={{
		__html: t("individual.authorise_connection.question", {
			softwareName: software.name ?? ""
		})
	}}/>
);

/**
 * @param loading
 * @param software
 * @param match
 * @param getByCode
 * @param buttonActions
 * @returns {JSX.Element}
 * @constructor
 */
const AuthoriseConnection = (
	{
		loading = false,
		software = {
			name: "",
			active: false
		},
		match = {
			params: {
				token: ""
			}
		},
		getByCode = () => {
		},
		buttonActions = () => {
		}
	}
): JSX.Element => {
	const code = match.params && match.params.code ?
			match.params.code
			:
			false,
		{t} = useTranslation(),
		history = useNavigate();

	useEffect(() => {
		if (code) {
			getByCode(code);
		}
	}, [
		getByCode,
		code
	]);


	if (loading) {
		return <Loading absolute/>;
	}

	return (
		<Wrapper t={t} history={history()}>
			<Slide direction="up" in={true} mountOnEnter unmountOnExit>
				<div id={"login-wrapper"}>
					<Modal
						id={"authorization-modal"}
						aria-labelledby="transition-modal-title"
						aria-describedby="transition-modal-description"
						open={true}
						onClose={() => false}
						BackdropComponent={Backdrop}
						BackdropProps={{
							timeout: 500,
						}}
						closeAfterTransition
					>
						<Fade in={true}>
							<Grid
								xs={12}
								md={12}
								lg={12}
								sm={12}
								id={"content"}
								item
								container
							>
								<Grid
									xs={12}
									sm={12}
									lg={12}
									md={12}
									id={"header"}
									item
								>
									<img src={Logo} alt={"Collegia logo"} onClick={history("/")}/>
								</Grid>
								<Grid
									id={"body"}
									xs={12}
									sm={12}
									lg={12}
									md={12}
									item
								>
									{renderCompanyName(software ?? {name: "", t})}
									<p className={"subtitle"}>
										{t("individual.authorise_connection.able_to_list.title")}
									</p>
									<ul>
										<li>
											{t("individual.authorise_connection.able_to_list.option_1")}
										</li>
										<li>
											{t("individual.authorise_connection.able_to_list.option_2")}
										</li>
										<li>
											{t("individual.authorise_connection.able_to_list.option_3")}
										</li>
									</ul>
								</Grid>
								<Grid
									id={"button-container"}
									direction={"row-reverse"}
									alignContent={"flex-start"}
									alignItems={"flex-start"}
									xs={12}
									sm={12}
									lg={12}
									md={12}
									item
									container
								>
									<Button
										variant={"outlined"}
										color={"primary"}
										className={"authorise"}
										onClick={() => buttonActions(code ?? false, "authorize")}
									>
										{t("individual.authorise_connection.authorise_button")}
									</Button>
									<Button
										variant={"outlined"}
										className={"deny"}
										onClick={() => buttonActions(code ?? false, "deny")}
									>
										{t("individual.authorise_connection.deny_button")}
									</Button>
								</Grid>
							</Grid>
						</Fade>
					</Modal>
				</div>
			</Slide>
		</Wrapper>
	);
}


const mapStateToProps = state => {
	const {
		Connections = {
			intermediary_token: "",
			active: false,
			accepted_at: null,
			deny_at: null,
			software: {
				name: "",
				active: true,
			},
			loading: false,
		},
	} = state;

	return {
		...Connections
	};
};

const mapDispatchToProps = dispatch => ({
	getByCode: (code: string = "") => dispatch(getByCode(code ?? false)),
	buttonActions: (code: string = "", action: string = "authorise") => dispatch(buttonActions(code ?? false, action ?? false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthoriseConnection);