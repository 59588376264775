import React, {useState, useEffect} from "react";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {connect} from "react-redux";
import {validateEmail} from "../utils/Helpers";
import Loading from "../components/Loading";
import {login} from "../actions/Profile";
import DefaultHeader from "../layout/Header/DefaultHeader";
import Switch from "@mui/material/Switch";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from "@mui/material/OutlinedInput";
import LoginSelection from "../components/LoginSelection";
import {useTranslation} from 'react-i18next';
import {setCountry} from "../actions/Languages";
import {useNavigate, useParams} from "react-router-dom";

/**
 * @param location
 * @returns {string}
 */
const mountRedirectRoute = (location: any = {
	from: {
		hash: "",
		pathname: "",
		search: "",
		state: false,
	},
}): string => {
	if (location.from) {
		if (location.from.pathname !== "/login" && location.from.pathname !== "") {
			return location.from.pathname;
		}
	}

	return "/main-page";
};

/**
 * @param slider_loading
 * @param login_text
 * @param location
 * @param login
 * @param language
 * @param setCountry
 * @param available_countries
 * @param selected_country
 * @param loading
 * @returns {Element}
 * @constructor
 */
const Login = (
	{
		slider_loading = false,
		login_text = "",
		location = {
			from: {
				hash: "",
				pathname: "",
				search: "",
				state: false,
			}
		},
		login = () => {
		},
		language = "",
		setCountry = (newCountry = "GB") => newCountry,
		available_countries = [],
		selected_country = "GB",
		loading = false,
	}
) => {
	const [email, setEmail] = useState(localStorage.getItem("userEmail") ?? ""),
		[password, setPassword] = useState(""),
		[showPassword, setShowPassword] = useState(false),
		[rememberAccount, setRememberAccount] = useState(true),
		{t, i18n} = useTranslation(),
		history = useNavigate(),
		token = useParams()?.token ?? "";

	useEffect(() => {
		window.$crisp.push(["set", "session:data", [
			[
				["category", "Individual"],
				["trial_period", "null"]
			]
		]]);

		i18n.changeLanguage(language);

		if (token !== "") {
			setTimeout(() => {
				login(
					{
						individuals: {
							sso: true,
							token,
						}
					},
					mountRedirectRoute("/main-page")
				);
			}, 5000);
		}

		if (login_text !== "") {
			setPassword("");
		}

	}, [
		language,
		i18n,
		token,
		history,
		login,
		login_text,
	]);

	useEffect(() => {
		if (rememberAccount && email) {
			localStorage.setItem("userEmail", email);
		} else {
			localStorage.clear();
		}
	}, [rememberAccount, email]);

	if (slider_loading || token !== "" || loading) {
		return (
			<Loading absolute/>
		);
	}

	const handleKeyDown = (event) => {
		if (event.nativeEvent.code === "Enter")
			login({
				individuals: {
					email,
					password,
				}
			}, mountRedirectRoute(location ?? false));
	};

	return (
		<Grid
			xl={12}
			lg={12}
			xs={12}
			md={12}
			sm={12}
			item
			container
			justifyContent={"center"}
			alignContent={"flex-start"}
			id={"login-wrapper"}
		>
			<DefaultHeader
				history={history}
				language={language}
				setCountry={setCountry}
				available_countries={available_countries}
				selected_country={selected_country}
			/>
			<Grid
				xl={3.7}
				lg={4.5}
				md={5.5}
				sm={10}
				xs={10}
				container
				item
			>
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
				>
					<FormControl
						fullWidth
					>
						<Typography className={"collegia-welcome"}>
							{t("individual.login.welcome_header")}
						</Typography>
					</FormControl>
					<FormControl
						fullWidth
					>
						<Typography className={"collegia-access-account"}>
							{t("individual.login.subheader")}
						</Typography>
					</FormControl>
				</Grid>
				<FormControl
					fullWidth
					className={!email ? "form-style-empty" : "form-style-fill"}
					margin={"normal"}
				>
					<TextField
						type={"email"}
						onChange={event => setEmail(event.target.value.toLocaleLowerCase())}
						variant={"outlined"}
						label={t("individual.login.email_input")}
						name={"email"}
						id={"email"}
						placeholder={"E-mail"}
						value={email}
						error={(!validateEmail(email) && email !== "")}
						onKeyDown={(event) => {
							handleKeyDown(event)
						}}
					/>
				</FormControl>
				<FormControl
					margin={"normal"}
					fullWidth
					className={!password ? "form-style-empty" : "form-style-fill"}
				>
					<OutlinedInput
						type={showPassword ? "text" : "password"}
						value={password}
						label={t("individual.login.password_input")}
						name={"password"}
						id={"password"}
						placeholder={t("individual.login.password_input")}
						onChange={event => setPassword(event.target.value)}
						error={login_text.length > 0}
						endAdornment={
							password ?
								<InputAdornment position={"end"}>
									<IconButton
										aria-label={"toggle password visibility"}
										onClick={() => setShowPassword(!showPassword)}
									>
										{showPassword ? <Visibility/> : <VisibilityOff/>}
									</IconButton>
								</InputAdornment>
								:
								<InputAdornment position={"end"}>
									<Typography
										className={"typography-forgot-password"}
										onClick={() => history("/forgot-password")}
									>
										{t("individual.login.forgot_password_link")}
									</Typography>
								</InputAdornment>
						}
						onKeyDown={(event) => {
							handleKeyDown(event)
						}}
					/>
				</FormControl>
				{
					login_text.length > 0 && (
						<Typography
							color={"error"}
							width={"100%"}
						>
							{login_text}
						</Typography>
					)
				}
				<FormControl>
					<Typography
						className={"login-dont-have-account"}
						onClick={() => history("/enrolled-by-employer")}
					>
						{t("individual.login.dont_have_account_text")}
					</Typography>
				</FormControl>
				<Grid
					marginTop={"40px"}
					marginBottom={"33px"}
					direction={"row"}
					container
					textAlign={"center"}
				>
					<Grid>
						<Switch
							defaultChecked
							onChange={() => setRememberAccount(!rememberAccount)}
						/>
					</Grid>
					<Grid
						display={"flex"}
						alignItems={"center"}
					>
						<Typography className={"typography-remember-account"}>
							{t("individual.login.remember_this_account")}
						</Typography>
					</Grid>
				</Grid>
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
					textAlign={"center"}
					className={"button-grid-style"}
				>
					<Button
						className={"login-button-style"}
						onClick={() =>
							login(
								{
									individuals: {
										email,
										password,
									}
								}, mountRedirectRoute(location ?? false))
						}
						disabled={!email || !password}
						fullWidth
					>
						{t("individual.login.login_word")}
					</Button>
				</Grid>
				<LoginSelection/>
			</Grid>
		</Grid>
	);
}


/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => {
	return {
		...state.Profile,
		...state.Languages,
	}
};

/**
 * @param dispatch
 * @returns {{login: (function(*[]=, string=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	login: (
		data: any[] = false,
		redirectUrl: string = "/main-page"
	) => dispatch(login(data ?? false, redirectUrl ?? "/main-page")),
	setCountry: (newCountry = "GB") => dispatch(setCountry(newCountry, null, false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);