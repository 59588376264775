import Request from "../../utils/Request";

/**
 * @param orderId
 * @param type
 * @returns {Promise<AxiosResponse<any>>}
 */
const RetrievePaymentInfoPromise = (orderId: string = "", type: string = "") => (
	Request.get(`/individual/contributions/payment-info/${orderId}/${type}`)
);

export default RetrievePaymentInfoPromise;