import {CHANGE_SELECTED_COUNTRY, RETRIEVE_AVAILABLE_COUNTRIES, RETRIEVE_LANGUAGE_RESOURCES} from "./types";
import clm from 'country-locale-map';
import Request from "../../utils/Request";
import {update} from "../Profile";

/**
 * @param newCountry
 * @param id
 * @param loading
 * @returns {(function(*): void)|*}
 */
export const setCountry = (newCountry = "GB", id = null, loading = false) => dispatch => {
	const language = clm.getCountryByAlpha2(newCountry)?.default_locale ?? "en";
	dispatch({
		type: CHANGE_SELECTED_COUNTRY,
		selected_country: newCountry,
		language: newCountry,
		profile_loading: loading,
		loading,
	});

	if (id) {

		dispatch(
			update(
				id,
				{
					profiles: {
						language,
						country_code: newCountry,
					}
				},
				false,
				window.location.href,
			),
		);
	}
}

export const getTranslations = () => dispatch => {

	dispatch({
		type: RETRIEVE_LANGUAGE_RESOURCES,
		loading: true,
		profile_loading: true,
	});

	Request.get("individual/translations")
		.then(response => {
			localStorage.removeItem('translations');
			localStorage.setItem("translations", JSON.stringify(response.data));
			dispatch({
				type: RETRIEVE_LANGUAGE_RESOURCES,
				resources: response.data,
			});
		})
		.finally(() =>
			dispatch({
				type: RETRIEVE_LANGUAGE_RESOURCES,
				loading: false,
				profile_loading: false,
			}));
};

export const getAvailableCountries = () => dispatch => Request.get(`individual/get-translated-countries`)
	.then(response => {
			if (response?.data) {
				dispatch({
					type: RETRIEVE_AVAILABLE_COUNTRIES,
					available_countries: response?.data?.map(c => c === "en" ? "GB" : c.toUpperCase()) ?? "en",
				});
			} else {
				dispatch({
					type: RETRIEVE_AVAILABLE_COUNTRIES,
					available_countries: "en",
				});
			}
		}
	)
	.finally(() => dispatch({
		type: RETRIEVE_AVAILABLE_COUNTRIES,
		loading: false,
		profile_loading: false,
	}));
