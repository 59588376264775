import Grid from "@mui/material/Grid";
import enroled_by_employer from "../../images/signup/enroled_by_employer.svg";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import React from "react";
import PropTypes from "prop-types";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const WereYouEnrolledByEmployer = (
	props: {
		history: {},
		setStep: () => {},
		t: key => key,
	}
): JSX.Element => (
	<>
		<Grid
			id={"enrolled-by-employer"}
			container
			justifyContent={"center"}
		>
			<img
				id={"enrolled-by-employer-img"}
				src={enroled_by_employer}
				alt="enroled by employer"
			/>
		</Grid>

		<Typography
			align={"center"}
			variant={"body1"}
		>
			{props.t("individual.enrolled_by_employer.title")}
		</Typography>

		<Grid
			id={"enrolled-by-employer-buttons"}
			container
			justifyContent={"center"}
		>
			<FormControl
				fullWidth
			>
				<Button
					variant={"contained"}
					color={"primary"}
					onClick={() => props?.setStep(1)}
				>
					{props.t("individual.enrolled_by_employer.yes_button")}
				</Button>
			</FormControl>

			<FormControl
				fullWidth
			>
				<Button
					variant={"outlined"}
					color={"primary"}
					onClick={() => props?.history("/sign-up")}
				>
					{props.t("individual.enrolled_by_employer.no_button")}
				</Button>
			</FormControl>
		</Grid>
	</>
);

WereYouEnrolledByEmployer.propTypes = {
	history: PropTypes.object.isRequired,
	setStep: PropTypes.func.isRequired,
};

export default WereYouEnrolledByEmployer;