import {GET_GENERIC_PROJECT_PARAMETER} from "../../actions/GenericProjectionParemeters/types";
import {resolveReduxState} from "../../utils/Helpers";

const initial_state = {
	genericProjectionParameters: [],
};

/**
 * @param state
 * @param action
 * @returns {{genericProjectionParameters: []}|{valueOf, (): boolean}|boolean}
 */
const GenericProjectionParametersStore = (state = initial_state, action = false) => {
	if (action && action.type) {
		const {
			type = false
		} = action;

		if (type === GET_GENERIC_PROJECT_PARAMETER) return resolveReduxState(state, action);
	}


	return state;
};

export default GenericProjectionParametersStore;