import React, {useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from "@mui/material/OutlinedInput";
import {connect} from "react-redux";
import {checkForgotPasswordToken, passwordChangeTokenRequest} from "../actions/Profile";
import DefaultHeader from "../layout/Header/DefaultHeader";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import PasswordCheck from "../components/PasswordCheck";
import {checkPassword} from "../utils/Helpers";

/**
 * @param match
 * @param passwordChangeTokenRequest
 * @param checkForgotPasswordToken
 * @returns {JSX.Element}
 * @constructor
 */
const ResetPassword = (
	{
		passwordChangeTokenRequest = () => {
		},
		checkForgotPasswordToken = () => {
		},
	}
): JSX.Element => {
	const [showPassword, setShowPassword] = useState(false),
		[password, setPassword] = useState(""),
		[confirmPassword, setConfirmPassword] = useState(""),
		token = useParams().token,
		{t} = useTranslation();

	useEffect(() => {
		checkForgotPasswordToken(token);
	}, [checkForgotPasswordToken, token]);

	const history = useNavigate();

	return (
		<Grid
			xl={12}
			lg={12}
			xs={12}
			md={12}
			sm={12}
			item
			container
			justifyContent={"center"}
			alignContent={"flex-start"}
			id={"login-wrapper"}
		>
			<DefaultHeader
				history={history}
			/>
			<Grid
				xl={3.7}
				lg={4.5}
				md={5.5}
				sm={10}
				xs={10}
				container
				item
			>
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
				>
					<FormControl
						fullWidth
					>
						<Typography
							className={"collegia-welcome"}
						>
							{t("individual.reset_password.set_new_password")}
						</Typography>
					</FormControl>
					<FormControl
						fullWidth
					>
						<Typography
							className={"collegia-access-account"}
						>
							{t("individual.reset_password.please_create_new_password")}
						</Typography>
					</FormControl>
				</Grid>
				<FormControl
					className={!password ? "form-style-empty" : "form-style-fill"}
					fullWidth
					margin={"normal"}
				>
					<OutlinedInput
						id={"password"}
						name={"password"}
						type={showPassword ? 'text' : 'password'}
						placeholder={t("individual.login.password_input")}
						error={password !== "" && confirmPassword !== "" && password !== confirmPassword}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									onClick={() => setShowPassword(!showPassword)}
									aria-label="toggle password visibility"
									disableFocusRipple
									disableRipple
									disableTouchRipple
								>
									{showPassword ? <Visibility/> : <VisibilityOff/>}
								</IconButton>
							</InputAdornment>
						}
						value={password}
						onChange={(event) =>
							setPassword(event.target.value)
						}
					/>
				</FormControl>
				<FormControl
					className={!password ? "form-style-empty" : "form-style-fill"}
					fullWidth
					margin={"normal"}
				>
					<OutlinedInput
						error={confirmPassword !== "" && password !== "" && password !== confirmPassword}
						id={"confirmPassword"}
						name={"confirmPassword"}
						type={showPassword ? 'text' : 'password'}
						placeholder={t("individual.confirm_password_input")}
						value={confirmPassword}
						onChange={(event) =>
							setConfirmPassword(event.target.value)
						}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									onClick={() => setShowPassword(!showPassword)}
									aria-label="toggle password visibility"
									disableFocusRipple
									disableRipple
									disableTouchRipple
								>
									{showPassword ? <Visibility/> : <VisibilityOff/>}
								</IconButton>
							</InputAdornment>
						}
					/>

				</FormControl>
				<Grid>
					<small className={"red-text"}>
						{
							password !== "" && confirmPassword !== "" && password !== confirmPassword ?
								t("individual.errors.passwords_does_not_match")
								:
								""
						}
					</small>
					<PasswordCheck
						password={password}
					/>
				</Grid>
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
					textAlign={"center"}
					className={"button-grid-style"}
				>
					<Button
						onClick={() => {
							passwordChangeTokenRequest({
								forgot_password: {
									token,
									password,
								}
							});
							history("/login");
						}}
						disabled={
							!password ||
							!confirmPassword ||
							confirmPassword !== password ||
							!checkPassword(password)
						}
						className={"login-button-style"}
						fullWidth
					>
						{t("individual.my_account.address.save_button")}
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
}


/**
 * @param dispatch
 * @returns {{checkForgotPasswordToken: (function(string=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	checkForgotPasswordToken: (token: string = "") => dispatch(checkForgotPasswordToken(token ?? "")),
	passwordChangeTokenRequest: (data: any[] = false) => dispatch(passwordChangeTokenRequest(data ?? false)),
});

export default connect(null, mapDispatchToProps)(ResetPassword);