import {SIGNUP_VERIFY_EMAIL_ADDRESS} from "./type";
import SignUp from "../../services/SignUp";

/**
 * @param data
 * @param proceedStep
 * @returns {(function(*): void)|*}
 */
export const verifyEmail = (data, proceedStep = () => {}) => dispatch => {
	dispatch({
		type: SIGNUP_VERIFY_EMAIL_ADDRESS,
		verify_email: false,
	});
	if (data) {
		new SignUp()
			.verifyEmail(data)
			.then(response => {
				dispatch({
					type: SIGNUP_VERIFY_EMAIL_ADDRESS,
					verify_email: response.data.data.data,
				});
				if (!response.data.data.data) {
					proceedStep();
				}
			})
			.catch(error => {
				dispatch({
					type: SIGNUP_VERIFY_EMAIL_ADDRESS,
					verify_email: error.response.data.data.data,
				});
				console.error(error);
			});
	}
};

/**
 * @param data
 * @returns {function(*): void}
 */
export const signUp = (data: any[] = false) => dispatch => {
	if (data) {
		new SignUp()
			.insert(data ?? false)
			.then(response => {

				const {
					data = false
				} = response;

				if (data.data) {
					const {
						token = ""
					} = data.data;

					if (token) {
						localStorage.clear();
						localStorage.setItem("collegiaToken", token);
						window.location.href = "/main-page";
					}
				}
			})
			.catch(error => console.error(error));
	}
}