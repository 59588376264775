import PropTypes from "prop-types";

import GetBeneficiaryTypes from './GetBeneficiaryTypes';
import GetRelationshipTypes from './GetRelationshipTypes';
import Create from "./Create";
import Get from './Get';
import Delete from "./Delete";
import Find from './Find';
import Update from "./Update";

const BeneficiariesRequests = {
	GetBeneficiaryTypes,
	GetRelationshipTypes,
	Create,
	Get,
	Delete,
	Find,
	Update
};

BeneficiariesRequests.propTypes = {
	GetBeneficiaryTypes: PropTypes.func.isRequired,
	GetRelationshipTypes: PropTypes.func.isRequired,
	Create: PropTypes.func.isRequired,
	Get: PropTypes.func.isRequired,
	Delete: PropTypes.func.isRequired,
	Find: PropTypes.func.isRequired,
	Update: PropTypes.func.isRequired,
};

export default BeneficiariesRequests;