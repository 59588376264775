import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import ContributionMenuIconGray from "../../images/side-menu/contribution-menu-icon-gray.svg";
import ContributionMenuIconBlue from '../../images/side-menu/contribution-menu-icon-blue.svg';

import PortfolioIconBlue from "../../images/side-menu/portfolio-menu-icon-blue.svg";
import PortfolioIconGray from '../../images/side-menu/portfolio-menu-icon-gray.svg';

import ContributionsHistoryMenuIconGray from "../../images/side-menu/contribution-history-menu-icon-gray.svg";
import ContributionsHistoryMenuBlue from '../../images/side-menu/contribution-menu-icon-blue.svg';

/**
 * @param history
 * @param t
 * @param contributions
 * @param portfolio
 * @param contributionsHistory
 * @returns {JSX.Element}
 * @constructor
 */
const SideMenu = (
	{
		history = {
			push: url => url,
		},
		t = key => key,
		contributions = "-disabled",
		portfolio = "-disabled",
		contributionsHistory = "-disabled",
	}

): JSX.Element => (
	<Grid
		xl={2.5}
		lg={2.5}
		item
		direction={"column"}
		container
		className={"side-menu"}
	>
		<Grid
			className={"side-grid"}
			justifyContent={"left"}
			borderRight={contributions === "" ? "3px solid #0060FD" : ""}
			item
			container
			onClick={() => history("/collegia-contribution")}
		>
			<img
				src={contributions === "" ? ContributionMenuIconBlue : ContributionMenuIconGray}
				alt={"ContributionMenuIconGray"}
			/>
			<Typography
				className={`side-text${contributions}`}
			>
				{t("individual.collegia_account.inner_menu.contributions")}
			</Typography>
		</Grid>
		<Grid
			className={"side-grid"}
			justifyContent={"left"}
			borderRight={portfolio === "" ? "3px solid #0060FD" : ""}
			item
			container
			onClick={() => history("/portfolio")}
		>
			<img
				src={portfolio === "" ? PortfolioIconBlue : PortfolioIconGray}
				alt={"PortfolioIconBlue"}
			/>
			<Typography
				className={`side-text${portfolio}`}
			>
				{t("individual.collegia_account.inner_menu.portfolio")}
			</Typography>
		</Grid>
		<Grid
			className={"side-grid"}
			justifyContent={"left"}
			borderRight={contributionsHistory === "" ? "3px solid #0060FD" : ""}
			item
			container
			onClick={() => history("/contributions-history")}
		>
			<img
				src={contributionsHistory === "" ? ContributionsHistoryMenuBlue : ContributionsHistoryMenuIconGray}
				alt={"ContributionsHistoryMenuIconGray"}
			/>
			<Typography
				className={`side-text${contributionsHistory}`}
			>
				{t("individual.contribution_history.title")}
			</Typography>
		</Grid>
	</Grid>
);

export default SideMenu;