import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @returns {Promise<AxiosResponse<any>>}
 * @param date
 */
const GetContributionByDate = (date = ""): Promise<AxiosResponse<any>> => (
	Request.post("/profile/transactions/get-contributions-by-year", date)
);

export default GetContributionByDate;