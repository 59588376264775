import {TOGGLE_INCOME_BUTTON} from "../../actions/PotToggle/Type";
import {resolveReduxState} from "../../utils/Helpers";

const initial_state = {
	potChecked: true,
};

/**
 * @param state
 * @param action
 * @returns {{valueOf, (): boolean}|boolean|{potChecked: boolean}}
 */
const PotToggleStore = (state = initial_state, action = false) => {
	if (action) {
		const {
			type = false
		} = action;
		if (type) {
			if (type === TOGGLE_INCOME_BUTTON) return resolveReduxState(state, action);
		}
	}

	return state;
};

export default PotToggleStore;