import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @returns {Promise<AxiosResponse<any>>}
 * @param id
 */
const AssetsLocationPromise = (id: number = 0): Promise<AxiosResponse<any>> =>
	Request.get(`/individual/assets-location/${id}`);

export default AssetsLocationPromise;