import AbstractServiceRequestClass from "../../classes/AbstractServiceRequestsClass";

import Request from '../../request';

export default class Beneficiaries extends AbstractServiceRequestClass {
	constructor() {
		super(Request.Beneficiaries);
	}

	getRelationshipTypes() {
		if (this?.request) {
			return this?.request?.GetRelationshipTypes();
		}

		return Promise.reject("Something went wrong.");
	}

	getBeneficiaryTypes() {
		if (this?.request) {
			return this?.request?.GetBeneficiaryTypes();
		}

		return Promise.reject("Something went wrong.");
	}
}