import React from "react";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const LetsStart = (props = {
	history: {
		push: () => {
		},
	},
	token: "",
}): JSX.Element => (
	<div className="section-8 wf-section">
		<div className="text-block">Ready to start?</div>

		<button
			onClick={() => props?.history(`/activation/setup/${props?.token ?? ""}`)}
			className="button w-button">
			LET&#x27;S START
		</button>
	</div>
);

export default LetsStart;