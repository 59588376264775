import {FETCH_CONNECTION_CODE} from "../../actions/Connections/types";
import {resolveReduxState} from "../../utils/Helpers";

const initial_state = {
	intermediary_token: "",
	active: false,
	accepted_at: null,
	deny_at: null,
	software: {
		name: "",
		active: true,
	},
	loading: false,
}

/**
 * @param state
 * @param action
 * @returns {{
 *      software: {
 *          name: string,
 *          active: boolean
 *      },
 *      intermediary_token: string,
 *      active: boolean,
 *      deny_at: null,
 *      accepted_at: null,
 *      loading: false,
 * }}
 * @constructor
 */
const ConnectionsStore = (state = initial_state, action = false) => {
	if (!action) return state;
	const {type = false} = action;

	switch (type) {
		case FETCH_CONNECTION_CODE:
			return resolveReduxState(state, action);

		default:
			return state;
	}
}

export default ConnectionsStore;