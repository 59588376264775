import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

/**
 * @param companies
 * @param setSelectedEmployer
 * @param setEmployerTrigger
 * @param renderCompanyName
 * @param renderCompanyNumber
 * @param renderIncorporatedInfo
 * @param renderAddressSnippet
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const EmployerSearchResultsCards = (
	{
		companies = [],
		setSelectedEmployer = () => {
		},
		setEmployerTrigger = () => {
		},
		renderCompanyName = () => {
		},
		renderCompanyNumber = () => {
		},
		renderIncorporatedInfo = () => {
		},
		renderAddressSnippet = () => {
		},
		t = key => key,
	}
): JSX.Element =>
	<Card
		className={"list-card-mobile"}
		variant={"outlined"}
		onClick={() => {
			setSelectedEmployer(companies);
			setEmployerTrigger(true);
		}}
	>
		<CardContent>
			<Typography className={"list-card-mobile-title"}>
				<strong>
					{renderCompanyName(companies ?? false, t)}
				</strong>
			</Typography>
			<Typography
				className={"list-card-mobile-subtitle"}
			>
				{renderCompanyNumber(companies ?? false, t)} {" - "}
				{renderIncorporatedInfo(companies ?? false, t)}
			</Typography>
			<Typography
				className={"list-card-mobile-subtitle"}
			>
				{renderAddressSnippet(companies ?? false)}
			</Typography>
		</CardContent>
	</Card>

export default EmployerSearchResultsCards;