import React from "react";

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PayContributionButton = (props: any = {
	submitContribution: () => {},
	t: key => key,
}): JSX.Element => (
	<Grid
		xs={12}
		sm={12}
		md={12}
		lg={12}
		item
		container
		className={"continue-button"}
	>
		<Button
			color={"primary"}
			variant={"contained"}
			onClick={() => props.submitContribution()}
		>
			{props.t("individual.login.continue_button")}
		</Button>
	</Grid>
);

export default PayContributionButton;