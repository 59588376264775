import React from "react";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {onChange, range} from "../../../utils/Helpers";
import Button from "@mui/material/Button";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import moment from "moment/moment";

/**
 * @returns {string}
 * @private
 */
const _renderCurrentData = (): string => `Today (${moment().format("D MMMM Y")})`;

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SelectDay = (props: any): JSX.Element => (
	<Grid
		xs={12}
		sm={12}
		md={12}
		lg={12}
		item
		container
		id={"add-contribution-select-container"}
	>
		<Grid
			className={"security-card"}
			xs={12}
			sm={12}
			md={12}
			lg={12}
			item
		>
			<Typography paragraph>
				<strong>For security reasons,</strong> the first contribution will be made instantly via Open Banking.
				This way we can verify that you are the account holder.
			</Typography>
		</Grid>
		<Grid
			className={"first-contribution-date"}
			xs={12}
			sm={12}
			md={12}
			lg={12}
			item
		>
			<Typography paragraph>
				First contribution will happen at:
			</Typography>
			<OutlinedInput
				value={_renderCurrentData()}
				endAdornment={
					<InputAdornment position="end">
						<LockOutlinedIcon/>
					</InputAdornment>
				}
				fullWidth
				readOnly
			/>
		</Grid>
		<Grid
			xs={12}
			sm={12}
			md={12}
			lg={12}
			item
			className={"select-range-holder"}
		>
			<Typography paragraph>
				Now, select the day, starting from the <strong>next month</strong>, in which you want contributions to
				be taken.
			</Typography>
			<select
				id={"selectedDay"}
				name={"selectedDay"}
				selected={props.component.state.selectedDay}
				onChange={event => onChange(event, props.component)}
			>
				<option
					value={""}
				>
					Select the date
				</option>
				{range(1, 31).map(r =>
					<option
						key={r}
						value={r}
					>
						{r}
					</option>
				)}
			</select>

			<Grid
				xs={12}
				sm={12}
				md={12}
				lg={12}
				item
				id={"continue-button-container"}
			>
				<Button
					variant={"outlined"}
					color={"primary"}
					onClick={() => props.component.proceed()}
				>
					CONTINUE
				</Button>
				<div className={"disclaimer"}>
					<Typography paragraph>
						<strong>NOTE:</strong> For the month of February, in case you choose either the 29 or 30, the
						contribution
						will happen in the next working day of the month.
					</Typography>
				</div>
			</Grid>
		</Grid>
	</Grid>
);

export default SelectDay;