import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 *
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const isValidNationalInsuranceNumberPromise = (data: {} = {}): Promise<AxiosResponse<any>> => (
	Request.post("/profile/is-valid-nin", data)
);

export default isValidNationalInsuranceNumberPromise;