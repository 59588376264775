import Request from "../../utils/Request";
import PropTypes from "prop-types";
import {AxiosResponse} from "axios";

/**
 * @param relationships
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
const GetBeneficiariesPromise = (relationships = []): Promise<AxiosResponse<any>> => {
	if (relationships.length > 0) {
		return Request.get(`/individual/beneficiaries?${Request.parseParams(relationships)}`);
	}

	return Request.get("/individual/beneficiaries");
}

GetBeneficiariesPromise.propTypes = {
	relationships: PropTypes.array,
}


export default GetBeneficiariesPromise;