import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import {updateIndividualData} from "../../../actions/Steps";
import Loading from "../../Loading";
import CreateLogin from "./EmailValidation/CreateLogin";
import ConfirmCode from "./EmailValidation/ConfirmCode";


/**
 * @param step
 * @param activeStep
 * @param token
 * @param title
 * @param titleOption
 * @param email
 * @param password
 * @param confirmPassword
 * @param code
 * @param individual
 * @param stepErrorText
 * @param setTitle
 * @param setEmail
 * @param setPassword
 * @param setConfirmPassword
 * @param setInternal
 * @param setCode
 * @param handleNext
 * @param t
 * @returns {null}
 * @private
 */
const renderAccordinglyWithInternalStep = (
	step: number = 0,
	individual: any = [],
	token: string = "",
	title: string = "",
	titleOption: any = [],
	email: string = "",
	password: string = "",
	confirmPassword: string = "",
	code: string = "",
	activeStep: number = 0,
	stepErrorText: string = "",
	setTitle: Function = () => {
	},
	setEmail: Function = () => {
	},
	setPassword: Function = () => {
	},
	setConfirmPassword: Function = () => {
	},
	setInternal: Function = () => {
	},
	setCode: Function = () => {
	},
	handleNext: Function = () => {
	},
	t = (key: string) => key,
) => (
	step === 0 ?
		<CreateLogin
			title={title}
			titleOption={titleOption}
			email={email}
			password={password}
			confirmPassword={confirmPassword}
			individual={individual}
			token={token}
			stepErrorText={stepErrorText}
			setTitle={setTitle}
			setEmail={setEmail}
			setPassword={setPassword}
			setConfirmPassword={setConfirmPassword}
			setInternal={setInternal}
			handleNext={handleNext}
		/>
		:
		<ConfirmCode
			individual={individual}
			activeStep={activeStep}
			code={code}
			token={token}
			setCode={setCode}
			handleNext={handleNext}
			t={t}
		/>
);

/**
 * @returns {*}
 */
const EmailValidation = ({
	step_loading = false,
	individual = [],
	token = "",
	activeStep = 0,
	proceedEmail = false,
	stepErrorText = "",
	handleNext = () => {
	},
	t = (key: string) => key,
}): JSX.Element => {

	const [email, setEmail] = useState(""),
		[password, setPassword] = useState(""),
		[confirmPassword, setConfirmPassword] = useState(""),
		[internal, setInternal] = useState(0),
		[code, setCode] = useState(""),
		[title, setTitle] = useState(""),
		[titleOption] = useState([
			{title: 'Mr'},
			{title: 'Ms'},
			{title: 'Miss'},
			{title: 'Mrs'},
			{title: 'Sir'},
			{title: 'Dr'},
			{title: 'Lady'},
			{title: 'Lord'},
		]);

	useEffect(() => {
		if (proceedEmail) {
			setInternal(1);
		}
	}, [proceedEmail]);

	if (step_loading) {
		return (
			<Loading absolute/>
		);
	}

	return renderAccordinglyWithInternalStep(
		internal,
		individual,
		token,
		title,
		titleOption,
		email,
		password,
		confirmPassword,
		code,
		activeStep,
		stepErrorText,
		setTitle,
		setEmail,
		setPassword,
		setConfirmPassword,
		setInternal,
		setCode,
		handleNext,
		t
	);
}

/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => {
	const {
		Steps
	} = state;

	return {
		...Steps,
	};
};

/**
 * @param dispatch
 * @returns {{updateIndividualData: (function(*=, *=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	updateIndividualData: (data = false, token = false) => dispatch(updateIndividualData(data, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailValidation);