import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AddressWhite from "../images/side-menu/address-white.svg";
import AddressBlue from "../images/side-menu/beneficiaries-blue.svg";
import PersonalWhite from "../images/side-menu/personal-white.svg";
import PersonalBlue from "../images/side-menu/personal-blue.svg";
import LoginWhite from "../images/side-menu/login-details-white.svg";
import LoginBlue from "../images/side-menu/login-details-blue.svg";
import BeneficiariesWhite from "../images/side-menu/beneficiaries-white.svg";
import BeneficiariesBlue from "../images/side-menu/beneficiaries-blue.svg";
import TaxReliefWhite from "../images/side-menu/tax-relief-white.svg";
import TaxReliefBlue from "../images/side-menu/tax-relief-blue.svg";

import React from "react";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const MyAccountLeftMenu = (
	{
		history = () => {
		},
		currentPage = "",
		myHeight = window.innerHeight - 75,
	}
): JSX.Element => (
		<Grid
			xl={2.5}
			lg={2.5}
			item
			direction={"column"}
			container
			className={"side-menu"}
			minHeight={myHeight}
		>
			<Grid
				marginTop={6}
				paddingLeft={5}
				justifyContent={"left"}
				item
				container
				borderRight={currentPage === "Personal" ? "3px solid #0060FD" : ""}
				onClick={() => history("/my-account/personal-information")}
				maxWidth={"100%"}
			>
				<img
					src={currentPage === "Personal" ? PersonalBlue : PersonalWhite}
					alt={"personal Icon"}
				/>
				<Typography
					className={currentPage !== "Personal" ? "side-text-disabled" : "side-text"}
				>
					Personal
				</Typography>
			</Grid>
			<Grid
				marginTop={5}
				paddingLeft={5}
				justifyContent={"left"}
				item
				container
				borderRight={currentPage === "Address" ? "3px solid #0060FD" : ""}
				onClick={() => history("/my-account/address")}
			>
				<img
					src={currentPage === "Address" ? AddressBlue : AddressWhite}
					alt={"address Icon"}
				/>
				<Typography
					className={currentPage !== "Address" ? "side-text-disabled" : "side-text"}
				>
					Address
				</Typography>
			</Grid>
			<Grid
				marginTop={5}
				paddingLeft={5}
				justifyContent={"left"}
				item
				container
				borderRight={currentPage === "LoginDetails" ? "3px solid #0060FD" : ""}
				onClick={() => history("/my-account/login-details")}
			>
				<img
					src={currentPage === "LoginDetails" ? LoginBlue : LoginWhite}
					alt={"login Icon"}
				/>
				<Typography
					className={currentPage !== "LoginDetails" ? "side-text-disabled" : "side-text"}
				>
					Login details
				</Typography>
			</Grid>
			<Grid
				marginTop={5}
				paddingLeft={5}
				justifyContent={"left"}
				item
				container
				borderRight={currentPage === "Beneficiaries" ? "3px solid #0060FD" : ""}
				onClick={() => history("/my-account/beneficiaries")}
			>
				<img
					src={currentPage === "Beneficiaries" ? BeneficiariesBlue : BeneficiariesWhite}
					alt={"Beneficiaries Icon"}
				/>
				<Typography
					className={currentPage !== "Beneficiaries" ? "side-text-disabled" : "side-text"}
				>
					Beneficiaries
				</Typography>
			</Grid>
			<Grid
				marginTop={5}
				paddingLeft={5}
				justifyContent={"left"}
				item
				container
				borderRight={currentPage === "TaxRelief" ? "3px solid #0060FD" : ""}
				onClick={() => history("/my-account/tax-relief")}
			>
				<img
					src={currentPage === "TaxRelief" ? TaxReliefBlue : TaxReliefWhite}
					alt={"Tax relief Icon"}
				/>
				<Typography
					className={currentPage !== "TaxRelief" ? "side-text-disabled" : "side-text"}
				>
					Tax relief
				</Typography>
			</Grid>
		</Grid>
	);

export default MyAccountLeftMenu;