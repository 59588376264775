// material ui components.
import Grid from '@mui/material/Grid';
import PropTypes from "prop-types";
import BackIcons from "../LoggedHeader/BackIcons";
import Title from "../LoggedHeader/Title";
import CustomIcon from "../LoggedHeader/CustomIcon";


/**
 * @param props
 * @returns {JSX.Element}
 */
const Header = (props = {
	history: {
		push: () => {
		},
	},
	renderCustomIcon: false,
	customIcon: false,
	showLeftArrow: false,
	fullWidthImg: false,
	customAction: false,
	isCustomIconIsSVG: false,
	customActionParams: {},
	title: "",
	id: "header-your-retirement",
	customIconAction: () => {},
	customIconActionParams: {},
	titleMargin: "",
}): JSX.Element => (

	<Grid
		id={props?.id ?? "header-your-retirement"}
		xs={12}
		md={12}
		lg={12}
		sm={12}
		container
		item
	>
		<BackIcons
			showLeftArrow={props?.showLeftArrow ?? false}
			history={props?.history ?? function () {
			}}
			backLink={props?.backLink ?? ""}
			customActionParams={props?.customActionParams ?? false}
			customAction={props?.customAction ?? false}
		/>
		<Title
			title={props?.title ?? ""}
			renderCustomIcon={props?.renderCustomIcon ?? false}
			customIcon={props?.customIcon ?? false}
			personalInformationTitle={props?.personalInformationTitle}
			titleMargin={props?.titleMargin}
		/>
		<CustomIcon
			fullWidthImg={props?.fullWidthImg ?? false}
			customIcon={props?.customIcon ?? false}
			renderCustomIcon={props?.renderCustomIcon ?? false}
			title={props?.title ?? ""}
			isCustomIconIsSVG={props?.isCustomIconIsSVG ?? false}
			customIconActionParams={props?.customIconActionParams ?? {}}
			customIconAction={props?.customIconAction ?? function() {}}
			iconMargin={props?.titleMargin}
		/>
	</Grid>
);

Header.propTypes = {
	title: PropTypes.string.isRequired,
	backLink: PropTypes.string.isRequired,
	renderCustomIcon: PropTypes.bool,
	showLeftArrow: PropTypes.bool,
	fullWidthImg: PropTypes.bool,
	customIcon: PropTypes.element,
	customAction: PropTypes.func,
	customIconAction: PropTypes.func,
	customActionParams: PropTypes.object,
	customIconActionParams: PropTypes.any,
	isCustomIconIsSVG: PropTypes.bool,
};

export default Header;