import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from "react";
import PlusContributionIcon from "../../images/plusContrubutionIcon.svg";
import HandleContributionIcon from "../../images/handContributionIcon.svg";
import MinusContributionIcon from "../../images/minusContributionIcon.svg";

const returnAlignRow = (
	columnName1: string = "",
	columnName2: string = "",
) => {
	return (
		<Grid
			item
			container
			direction={"row"}
			spacing={1}
			justifyContent={"center"}
			alignItems={"center"}
		>
			<Grid
				item
				textAlign={"center"}
			>
				<Typography
					className={"typography-for-column-name"}
				>
					{columnName1}
				</Typography>
			</Grid>
			<Grid
				item
				textAlign={"center"}
			>
				<Typography
					className={"typography-for-column-data"}
				>
					{columnName2}
				</Typography>
			</Grid>
		</Grid>
	)
};

/**
 * @param tableColumnDate
 * @param tableColumnTotal
 * @param tableColumnFee
 * @param expandedList
 * @param index
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const ContributionsTableList = (
	{
	tableColumnDate = "",
	tableColumnTotal = "",
	tableColumnFee = "",
	expandedList = [],
	index = 0,
		t=key=>key,
}
): JSX.Element => {
	return (
		<Grid
			item
			container
			minHeight={"100px"}
		>
			<Grid
				item
				container
				direction={"row"}
				justifyContent={"center"}
				alignItems={"center"}
				className={"contribution-history-column-style"}
			>
				<Grid
					item
					lg={1.5}
					xs={1.5}
					md={1.5}
					sm={1.5}
					textAlign={"center"}
				>
					<img
						src={HandleContributionIcon}
						alt={""}
					/>
				</Grid>
				<Grid
					item
					lg={3}
					xs={3}
					md={3}
					sm={3}
					textAlign={"center"}
				>
					{returnAlignRow(t("individual.contributions_history.month"), tableColumnDate)}
				</Grid>
				<Grid
					item
					lg={3}
					xs={3}
					md={3}
					sm={3}
					textAlign={"center"}
				>
					{returnAlignRow(t("individual.contributions_history.total"), tableColumnTotal)}
				</Grid>
				<Grid
					item
					lg={3}
					xs={3}
					md={3}
					sm={3}
					textAlign={"center"}
				>
					{returnAlignRow(t("individual.contributions_history.fees"), tableColumnFee)}
				</Grid>
				{
					expandedList.some(item => index === item) ?
						<Grid
							item
							lg={1.5}
							xs={1.5}
							md={1.5}
							sm={1.5}
							textAlign={"center"}
						>
							<img
								src={MinusContributionIcon}
								alt={""}
							/>
						</Grid>
					:
						<Grid
							item
							lg={1.5}
							xs={1.5}
							md={1.5}
							sm={1.5}
							textAlign={"center"}
						>
							<img
								src={PlusContributionIcon}
								alt={""}
							/>
						</Grid>
				}
			</Grid>
		</Grid>
	)
}

export default ContributionsTableList;