import {TOGGLE_INCOME_BUTTON} from "./Type";

/**
 * @param toggle
 * @returns {function(...[*]=)}
 */
export const toggleIncomePot = (toggle = false) => dispatch => {
	dispatch({
		type: TOGGLE_INCOME_BUTTON,
		potChecked: !toggle,
	});
};