import React from 'react';
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import {connect} from "react-redux";
import CloseMenuIcon from "../../images/main-menu/close-menu.svg";
import GlobalIcon from "../../images/global-icon-white.svg";
import ContributionsIcon from "../../images/contribution-icon-white.svg";
import HomeIcon from "../../images/home-icon-white.svg";
import PensionIcon from "../../images/main-menu/my-dashboard.svg";
import {capitalizeFirstLetter} from "../../utils/Helpers";

/**
 * @param accessible
 * @param handleAccessible
 * @param history
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const AcessibleMenu = (
	{
		accessible = false,
		handleAccessible = () => {
		},
		history = {
			push: () => {
			}
		},
		t= key => key,
	}
): JSX.Element => (
	<Drawer
		id={"accessible-menu"}
		anchor={"bottom"}
		open={accessible}
		onClose={() => handleAccessible()}
	>
		<Grid
			xs={12}
			md={12}
			sm={12}
			xl={12}
			item
			container
		>
			<Grid
				xs={12}
				md={12}
				sm={12}
				xl={12}
				item
				container
				className={"first-grid"}
				justifyContent={"end"}
			>
				<img
					src={CloseMenuIcon}
					alt={"Close Icon"}
					className={"icon-close"}
					onClick={() => handleAccessible()}
				/>
			</Grid>
			<Grid
				xs={12}
				md={12}
				sm={12}
				xl={12}
				item
				container
				className={"collegia-account"}
				alignItems={"center"}
			>
				<Typography
					className={"text"}
					onClick={() => history("/main-page")}
				>
					<img
						src={HomeIcon}
						alt={"Home Icon"}
						className={"icon"}
					/>
					{t("individual.collegia_account.title")}
				</Typography>
			</Grid>
			<Grid
				xs={12}
				md={12}
				sm={12}
				xl={12}
				item
				container
				className={"pension-pot"}
				alignItems={"center"}
			>
				<Typography
					className={"text"}
					onClick={() => history("/collegia-contribution")}
				>
					<img
						src={PensionIcon}
						alt={"Pension Icon"}
						className={"icon"}
					/>
					{t("individual.accessible_button.pension_pot")}
				</Typography>
			</Grid>
			<Grid
				xs={12}
				md={12}
				sm={12}
				xl={12}
				item
				container
				className={"contributions"}
				alignItems={"center"}
			>
				<Typography
					className={"text"}
					onClick={() => history("/extra-contributions")}
				>
					<img
						src={ContributionsIcon}
						alt={"Pension Icon"}
						className={"icon"}
					/>
					{t("individual.collegia_account.inner_menu.contributions")}
				</Typography>
			</Grid>
			<Grid
				xs={12}
				md={12}
				sm={12}
				xl={12}
				item
				container
				className={"investment-approach"}
				alignItems={"center"}
			>
				<Typography
					className={"text"}
					onClick={() => history("/investment-approach")}
				>
					<img
						src={GlobalIcon}
						alt={"Pension Icon"}
						className={"icon"}
					/>
					{t("individual.investment_approaches.list.title")}
				</Typography>
			</Grid>
			<Grid
				xs={12}
				md={12}
				sm={12}
				xl={12}
				item
				container
				className={"chat"}
				alignItems={"center"}
			>
				<Typography
					className={"text"}
					onClick={() => {
						window.$crisp.push(['do', 'chat:open']);
					}}
				>
					<img
						src={GlobalIcon}
						alt={"Pension Icon"}
						className={"icon"}
					/>
					{t("individual.chat_with_us")}
				</Typography>
			</Grid>
			<Grid
				xs={12}
				md={12}
				sm={12}
				xl={12}
				item
				container
				className={"bottom-menu"}
				alignItems={"center"}
				justifyContent={"center"}
			>
				<Typography
					paddingBottom={5}
					onClick={() => history("/main-page")}
				>
					{t("individual.right_navigation_menu.my_dashboard")}
				</Typography>
				<Typography
					paddingBottom={5}
					onClick={() => history("/my-account")}
				>
					{t("individual.right_navigation_menu.my_account")}
				</Typography>
				<Typography
					paddingBottom={5}
					onClick={() => history("/refer-employer")}
				>
					{t("individual.ae.refer_employer_button")}
				</Typography>
				<Typography
					paddingBottom={5}
					onClick={() => history("/legal")}
				>
					{t("individual.right_navigation_menu.legal")}
				</Typography>
				<Typography
					paddingBottom={5}
					onClick={() => history("/software-connections")}
				>
					{capitalizeFirstLetter(t("individual.data_sharing_permissions"))}
				</Typography>
				<Typography
					onClick={() => history("/logout")}
				>
					{t("individual.right_navigation_menu.log_out")}
				</Typography>
			</Grid>
		</Grid>
	</Drawer>
);

const mapStateToProps = (state = {
	Profile: {
		profile_loading: false,
		slider_loading: false,
		profile_data: {
			projection: {
				active: true,
				annuity_income: "0",
				badge: "",
				formatted_annuity_income: "00.00",
				formatted_nominal_value_of_future_pot: "00.00",
				formatted_real_total_return_rate_month_over_month: "0.21",
				formatted_total_ae_monthly_contribution: "00.00",
				formatted_total_ae_net_of_contribution_charge: "0.00",
				formatted_total_extra_net_of_contribution_charge: "0.00",
				formatted_total_value_of_future_pot: "00.00",
				formatted_total_yearly_income: "00.00",
				fund_charge_amc_month_over_month: "0.00",
				fund_charge_amc_year_over_year: "0.00",
				id: null,
				individual_extra_contribution_id: null,
				main_page_container: "minimum-container",
				nominal_value_of_future_pot: "",
				progress_level_classname: "progress-minimum pot-minimum",
				real_total_return_rate_month_over_month: "0.21",
				ruler_progress_classname: "progress-minimum",
				total_ae_monthly_contribution: "0",
				total_ae_net_of_contribution_charge: "0",
				total_extra_net_of_contribution_charge: "0.00",
				total_value_of_future_pot: "0",
				total_yearly_income: "0",
				transaction_fee_id: null,
			},
			portfolio: {
				created_at: "",
				fund_price_id: null,
				id: null,
				individual_id: null,
				investment_return: "",
				portfolio_date: "",
				total_units: "",
				total_value_invested: "",
				updated_at: null,
			},
			comfort_ruler_projection_parameters: {
				comfortable: 43100,
				lower_bound: 0,
				minimum: 14400,
				moderate: 31300,
				upper_limit: 64000,
			},
			individual_employers: [],
			extra_contributions: [],
			other_pensions: [],
			third_party_software_connections: [],
			ifa_connections: [],
			policy_number: "",
			my_ip: "",
			id: null,
		},
		walkthrough_complete: false,
		login_text: "",
		employers: [],
		my_projections: [],
		options_value: [],
		request_change_email: false,
		request_change_password: false,
		operation: "",
		request_change_email_code_confirmed: false,
		request_change_password_code_confirmed: false,
		exist_nin: false,
		token_loading: false,
		token: false,
		state_pension_loading: false,
		first_login: false,
		token_active: false,
		individual_id: null,
		code_confirmed: null,
		message_error: "",
		language: "en",
	},
}) => state.Profile;

export default connect(mapStateToProps, null)(AcessibleMenu);