import React, {useEffect, useState} from "react";

// default material ui imports
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid";

// custom components import.
import Header from './Header';
import RightNavigationMenu from "../Header/RightNavigationMenu";
import Footer from "./Footer";
import {connect} from "react-redux";
import Loading from "../../components/Loading";
import Fab from "@mui/material/Fab";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import AcessibleMenu from "../Header/AcessibleMenu";
import {setCountry} from "../../actions/Languages";
import {useTranslation} from "react-i18next";

/**
 * @param history
 * @param notifications
 * @param hideFooter
 * @param profile_data
 * @param available_countries
 * @param profile_loading
 * @param selected_country
 * @param children
 * @param textHeader
 * @param t
 * @param setCountry
 * @returns {JSX.Element}
 * @constructor
 */
const Wrapper = (
	{
		history = {},
		notifications = [],
		hideFooter = false,
		profile_data = {
			id: null,
		},
		available_countries= [],
		profile_loading = false,
		selected_country = "GB",
		children = () => {},
		textHeader = "",
		setCountry = (newCountry = "GB") => newCountry,
		removeRightMenu = false,
	}
): JSX.Element => {
	// eslint-disable-next-line no-unused-vars
	const [notificationAnchor, setNotificationAnchor] = useState(null);

	const [drawer, setDrawer] = useState(false),
		[accessible, setAccessible] = useState(false);

	const {t} = useTranslation();

	const handleDrawer = () => {
		setDrawer(!drawer);
	};

	const handleAccessible = () => {
		if (!accessible) {
			window.$crisp.push(['do', 'chat:show'])
		} else {
			window.$crisp.push(['do', 'chat:hide'])
		}
		setAccessible(!accessible);
	}

	useEffect(() => {
		if (profile_data?.id) {
			setCountry(profile_data.country_code, null, false);
		}
	}, [
		profile_loading,
		profile_data,
		setCountry
	]);

	if (profile_loading) {
		return <Loading absolute/>
	}

	return (
		<Container
			className={"no-padding logged-background-color"}
			fixed
		>
			<Grid
				spacing={0}
				direction={"column"}
				alignItems={"center"}
				alignContent={"center"}
				className={"main-page-container-wrapper"}
				id={"menu-logged-wrapper"}
				container
				item
				zIndex={1000}
			>
				<Header
					notifications={notifications}
					drawer={drawer}
					handleNotificationsOpen={setNotificationAnchor}
					handleDrawerOpen={handleDrawer}
					history={history}
					profile_data={profile_data}
					textHeader={textHeader}
					selected_country={selected_country}
					available_countries={available_countries}
					setCountry={setCountry}
					t={t}
					removeRightMenu={removeRightMenu}
				/>
			</Grid>
			{children}
			<div className={"dark-bg-container"}>
				<Grid
					spacing={0}
					direction={"column"}
					alignItems={"center"}
					alignContent={"center"}
					className={"main-page-container-wrapper"}
					container
					item
				>
					{hideFooter ? "" : <Footer/>}
				</Grid>
			</div>
			{/*<NotificationMenu*/}
			{/*	notificationAnchor={notificationAnchor}*/}
			{/*	setNotificationAnchor={setNotificationAnchor}*/}
			{/*/>*/}
			<RightNavigationMenu
				drawer={drawer}
				handleDrawer={handleDrawer}
				history={history}
				t={t}
			/>
			<Fab
				sx={{
					position: 'absolute',
					bottom: (theme) => theme.spacing(2),
					left: (theme) => theme.spacing(2),
					backgroundColor: '#0160FD',
					color: '#FFFFFF'
				}}
				onClick={() => handleAccessible()}
				className={"display-accessible"}
			>
				<MenuOpenIcon/>
			</Fab>
			<Grid>
				<AcessibleMenu
					accessible={accessible}
					handleAccessible={handleAccessible}
					history={history}
					t={t}
				/>
			</Grid>
		</Container>
	);
}


const mapStateToProps = state => {
	const {
		Profile,
		Languages = {},
	} = state;

	return {
		...Profile,
		...Languages,
	};
};

const mapDispatchToProps = dispatch => ({
	setCountry: (newCountry = "GB", id = null, loading) =>
		dispatch(setCountry(newCountry, id, loading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper);
