// importing default actions.
import {GET_BY_TOKEN} from "../../actions/ActivationToken/types";
import {resolveReduxState} from "../../utils/Helpers";

/**
 * @type {{token_data: boolean, token_loading: boolean}}
 */
const initial_state = {
	token_loading: false,
	token_data: {
		active: false,
		deleted_at: null,
		created_at: "",
		token: "",
		updated_at: "",
		individual: {
			active: false,
			eligible_for_tax_relief: false,
			register_completed: false,
			state_pension_included: true,
			avatar: null,
			country_code: null,
			days_to_be_retired: null,
			middle_name: null,
			mobile: null,
			birthdate: "",
			country: "",
			created_at: "",
			email: "",
			expected_retirement_date: "",
			forename: "",
			gender: "",
			surname: "",
			title: "",
			updated_at: "",
			expected_retirement_age: 0,
			years_to_be_retired: 0,
			id: 0,
			months_to_be_retired: 0,
			status: 0,
			national_insurance_number: "",
			papdis: [{
				is_individual_married: false,
				active: true,
				ae_opt_out_date: null,
				ae_opt_out_reference: null,
				cyclical_reenrolment_date: null,
				enrolment_communications_issued_date: null,
				exit_date: null,
				exit_reason_code: null,
				forename2: null,
				gross_annual_pensionable_salary: null,
				gross_annual_salary: null,
				gross_annual_salary_effective_date: null,
				group_number: null,
				is_individual_already_member_of_qps: null,
				is_member_an_overseas_national_awaiting_an_nin_number: null,
				job_title: null,
				member_plan_number: null,
				message_function_code: null,
				opt_out_window_end_date: null,
				pay_reference_end_date: null,
				pay_reference_start_date: null,
				payment_due_date: null,
				place_of_work_post_code: null,
				reason_for_partial_or_non_payment: null,
				reason_for_refund: null,
				reenrolment_indicator: null,
				secondary_email_address: null,
				software_product_id: null,
				staging_date: null,
				statutory_letter_code: null,
				stop_employee_contribution_date: null,
				total_gross_qualifying_earnings_amount: null,
				worker_exclusion_code: null,
				retirement_age: null,
				assessment_code: 0,
				employer_id: 0,
				event_code: 0,
				individual_id: 0,
				intermediary_id: 0,
				id: 0,
				tax_period: 0,
				contribution_deduction_date: "",
				additional_voluntary_contributions_amount: "",
				additional_voluntary_contributions_percent: "",
				salary_sacrifice_indicator: 0,
				country: "",
				created_at: "",
				current_gender: "",
				deferreal_date: "",
				document_id: "",
				employee_contributions_amount: "",
				employer_contributions_amount: "",
				employer_contributions_percent: "",
				employee_contributions_percent: "",
				employment_start_date: "",
				event_date: "",
				forename1: "",
				frequency_code: "",
				group: "",
				nationality: "",
				payroll_end_date: "",
				payroll_start_date: "",
				pension_provider_id: "",
				pensionable_earnings_amount: "",
				subgroup: "",
				title: "",
				updated_at: "",
				user_intermediary_id: "",
				version: "",
				surname: "",
				employer: {
					code: "",
					company_number: "",
					title: "",
					nickname: null,
				},
			}],
			steps: [{
				completed: false,
				completed_at: null,
				created_at: "",
				started_at: null,
				updated_at: "",
				step_id: 0,
			}],
			projection: {
				active: true,
				annuity_income: "",
				created_at: "",
				formatted_annuity_income: "",
				formatted_nominal_value_of_future_pot: "",
				formatted_real_total_return_rate_month_over_month: "",
				formatted_total_ae_monthly_contribution: "",
				formatted_total_ae_net_of_contribution_charge: "",
				formatted_total_extra_net_of_contribution_charge: "",
				formatted_total_value_of_future_pot: "",
				formatted_total_yearly_income: "",
				fund_charge_amc_month_over_month: "",
				fund_charge_amc_year_over_year: "",
				nominal_value_of_future_pot: "",
				real_total_return_rate_month_over_month: "",
				total_ae_monthly_contribution: "",
				total_ae_net_of_contribution_charge: "",
				total_extra_net_of_contribution_charge: "",
				total_value_of_future_pot: "",
				total_yearly_income: "",
				updated_at: "",
			},
		},
	},
};

/**
 * @param state
 * @param action
 * @returns {{valueOf, (): boolean}|boolean|{token_data: boolean, token_loading: boolean}}
 */
const ActivationTokenStore = (state = initial_state, action = false) => {

	if (action) {
		const {type = false} = action;

		if (type === GET_BY_TOKEN) return resolveReduxState(state, action);
	}

	return state;
}

export default ActivationTokenStore;