import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

/**
 * @returns {JSX.Element}
 * @constructor
 */
const HeaderInfo = (): JSX.Element => (
	<Paper id={"investment-approach-container-header-info"}>
		<Typography paragraph>
			For each contribution that you made you need to inform to Collegia where's from the source of your funds.
		</Typography>
		<Typography
			className={"last-paragraph"}
			paragraph
		>
			As we an FCA regulated company, we need to you provide this information to keep our work in the most highly
			quality and reach all the standards of regulatory.

			Don't worry, it's very quick to fill your information above.
		</Typography>
	</Paper>
);

export default HeaderInfo;