import PropTypes from "prop-types";
import {FormControl} from "@mui/material";

/**
 * @param errors
 * @returns {JSX.Element|*[]}
 * @constructor
 */
const DefaultFormErrorsRender = (
	{
		errors = [] || {},
	}
) => {
	const view = [];

	if (Object.keys(errors).length > 0 || errors.length > 0) {
		Object.keys(errors).forEach(key => {
			if (errors[key].length > 0) {
				errors[key].forEach(error => {
					view.push(
						<li key={key + error} className="form-error">
							{error}
						</li>
					);
				});
			}
		});

		return (
			<FormControl
				className="form-errors"
				fullWidth
			>
				<ul>
					{view}
				</ul>
			</FormControl>
		)
	}

	return [];
};

DefaultFormErrorsRender.propTypes = {
	errors: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
	]).isRequired,
};

export default DefaultFormErrorsRender;