import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const GeneralInformation = (): JSX.Element=> (
	<Grid
		xs={12}
		md={12}
		sm={12}
		xl={12}
		item
	>
		<Grid
			xs={12}
			md={12}
			sm={12}
			xl={12}
			item
		>
			<h3>
				Overview
			</h3>
		</Grid>
		<Grid
			xs={12}
			md={12}
			sm={12}
			xl={12}
			item
		>
			<Typography>
				Check if a pension scheme member is a resident in Scotland or Wales for tax purposes.
				Pension scheme administrators need this for relief at source claims.
			</Typography>
		</Grid>
		<Grid
			xs={12}
			md={12}
			sm={12}
			xl={12}
			item
		>
			<h3>
				Before you start
			</h3>
		</Grid>
		<Grid
			xs={12}
			md={12}
			sm={12}
			xl={12}
			item
		>
			<Typography>
				You will need the pension scheme member’s:
				<br />
			</Typography>
			<ul>
				<li>
					Name.
				</li>
				<li>
					Date of birth.
				</li>
				<li>
					National Insurance Number.
				</li>
			</ul>
		</Grid>
	</Grid>
);

export default GeneralInformation;