import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from "react";
import moment from "moment";
import {capitalizeFirstLetter} from "../../utils/Helpers";

/**
 * @param typeOfContribution
 * @param contribution
 * @param img
 * @param titleCollegiaFee
 * @param collegiaFee
 * @param date
 * @param negative
 * @returns {JSX.Element}
 */
const renderContributions = (
	typeOfContribution,
	contribution,
	img,
	titleCollegiaFee,
	collegiaFee,
	date,
	negative = false,
): JSX.Element => {
	return (
		<Grid
			direction={"row"}
			justifyContent={"space-between"}
			item
			container
			xs={12}
			sm={12}
			lg={12}
			md={12}
			alignContent={"center"}
			alignItems={"center"}
			spacing={2}
		>
			<Grid
				textAlign={"center"}
				item
				xs={1}
				marginBottom={3.5}
			>
				<img
					className={"contributions-image-style"}
					src={img}
					alt={""}
				/>
			</Grid>

			<Grid
				item
				xs={11}
			>
				<Grid
					direction={"row"}
					justifyContent={"space-between"}
					item
					container
					xs={12}
					sm={12}
					lg={12}
					md={12}
					marginBottom={3}
				>
					<Grid
						item
						xs={6}
						sm={6}
						lg={6}
						md={6}
						marginBottom={1}
					>
						<Typography
							textAlign={"start"}
							className={"typography-title-contributions-history"}
							fontSize={"3vw"}
						>
							{typeOfContribution}
						</Typography>

					</Grid>

					<Grid
						item
						xs={6}
						sm={6}
						lg={6}
						md={6}
					>
						<Typography
							textAlign={"center"}
							className={
								negative ?
									"typography-contributions-amount-history-negative"
									:
									"typography-contributions-amount-history"
							}
						>
							{contribution}
						</Typography>
					</Grid>
					{
						collegiaFee !== "- £ 0" &&
						<Grid
							justifyContent={"space-between"}
							item
							container
							xs={12}
							sm={12}
							lg={12}
							md={12}
							direction={"row"}
							alignItems={"center"}
							alignContent={"center"}
						>
							<Grid
								item
								container
								direction={"row"}
								xs={6}
								sm={6}
								lg={6}
								md={6}
								spacing={1}
							>
								<Grid
									item
								>
									<Typography
										className={"typography-title-contributions-history"}
										textAlign={"center"}
									>
										{titleCollegiaFee}
									</Typography>
								</Grid>
								<Grid
									item
								>
									<Typography
										className={"typography-contributions-one-off-history-and-fee"}
									>
										{date}
									</Typography>
								</Grid>
							</Grid>

							<Grid
								item
								xs={6}
								sm={6}
								lg={6}
								md={6}
							>
								<Typography
									textAlign={"center"}
									className={"typography-contributions-one-off-history-and-fee"}
								>
									{collegiaFee}
								</Typography>
							</Grid>
						</Grid>
					}
				</Grid>

			</Grid>
		</Grid>
	)
}

/**
 * @param contributions
 * @param employer_contribution
 * @param contributions_collegia_fee
 * @param one_off_date
 * @param custom_transaction_code
 * @param contributions_government
 * @param government_collegia_fee
 * @param employer_fee_value
 * @param individual_fee_value
 * @param transaction_status
 * @param government_contributions_image
 * @param contributions_image
 * @param employer_title
 * @param pay_period_start_date
 * @param pay_period_end_date
 * @param transaction
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const ContributionsExpandedList = (
	{
		contributions = 0,
		employer_contribution = 0,
		contributions_collegia_fee = 0,
		one_off_date = 0,
		contributions_government = 0,
		government_collegia_fee = 0,
		employer_fee_value = 0,
		individual_fee_value = 0,
		transaction_status = 0,
		government_contributions_image = null,
		contributions_image = null,
		employer_title = "",
		pay_period_start_date = "",
		pay_period_end_date = "",
		transaction = [],
		t = key => key,
	}
): JSX.Element => {

	let dateOfPayment, payPeriodStartDate, payPeriodEndDate;
	if (one_off_date) {
		dateOfPayment = moment(one_off_date).format("DD/MM/YYYY");
	}
	if (pay_period_start_date && pay_period_end_date) {
		payPeriodStartDate = moment(pay_period_start_date)?.format("DD/MM/YYYY")
		payPeriodEndDate = moment(pay_period_end_date)?.format("DD/MM/YYYY")
	}
	let typeOfContribution = "";
	let contribution = "";

	let collegiaFee = "";

	switch (transaction?.transaction_type) {
		case "Monthly":
			typeOfContribution = capitalizeFirstLetter(t("individual.monthly"));
			contribution = contributions;
			collegiaFee = individual_fee_value;
			break;
		case "SOFTWARE":
		case "PAPDIS":
			typeOfContribution = t("individual.ae.title");
			contribution = employer_contribution;
			collegiaFee = employer_fee_value;
			break;
		case "Refund Fees":
			typeOfContribution = t("individual.refund_fees");
			contribution = contributions;
			break;
		case "Flat Fee":
			typeOfContribution = t("individual.flat_fee");
			contribution = -contributions;
			break;
		case "One-off":
			typeOfContribution = t("individual.one_off_capital_first_letters");
			contribution = contributions;
			collegiaFee = contributions_collegia_fee;
			break;
		case "Pot-Transfer":
			typeOfContribution = t("individual.pot_transfer");
			contribution = contributions;
			collegiaFee = contributions_collegia_fee;
			break;
		default:
			typeOfContribution = "";
			break;
	}

	if (transaction?.is_redemption) {
		typeOfContribution = t("individual.contributions_history.redemption");
		contribution = -contributions;
		collegiaFee = contributions_collegia_fee;
	}

	return (
		<Grid
			xs={12}
			sm={12}
			lg={12}
			md={12}
			item
			container
			paddingLeft={2}
			paddingRight={3}
		>
			{
				(collegiaFee >= 0 && !transaction?.is_redemption && !transaction?.is_flat_fee && !transaction?.is_pot_transfer && !transaction?.is_refund) ?
					renderContributions(
						typeOfContribution === t("individual.ae.title") ?
							employer_title : typeOfContribution,
						"+ £ " + contribution,
						contributions_image,
						t("individual.contributions_history.collegia_fee"),
						"- £ " + collegiaFee,
						typeOfContribution + " - " + dateOfPayment,
						payPeriodStartDate && payPeriodEndDate ?
							t("individual.contributions_history.pay_period", {
								payPeriod: payPeriodStartDate + " - " + payPeriodEndDate,
							})
							:
							""
					)
					:
					""
			}
			{
				typeOfContribution === t("individual.ae.title") && contributions > 0 ?
					renderContributions(
						t("individual.your_contributions"),
						"+ £ " + contributions,
						contributions_image,
						t("individual.contributions_history.collegia_fee"),
						"- £ " + individual_fee_value,
						t("individual.contributions_history.your_contribution", {
							dateOfPayment
						}),
					)
					:
					""
			}
			{
				typeOfContribution === t("individual.pot_transfer") &&
					renderContributions(
						t("individual.pot_transfer"),
						"+ £ " + contributions,
						contributions_image,
						t("individual.contributions_history.collegia_fee"),
						"- £ 0",
						t("individual.contributions_history.date_of_transfer", {
							dateOfPayment
						}),
					)
			}
			{
				typeOfContribution === t("individual.refund_fees") &&
					renderContributions(
						"Refund Fees",
						"+ £ " + transaction.total_contribution,
						contributions_image,
						t("individual.contributions_history.collegia_fee"),
						"- £ 0",
						t("individual.contributions_history.date_of_transfer", {
							dateOfPayment
						}),
					)
			}
			{
				typeOfContribution === t("individual.flat_fee") &&
					renderContributions(
						"Annual Flat Fee",
						contribution > 0 ? "+ £ " + contribution : "- £ " + Math.abs(contribution),
						contributions_image,
						t("individual.contributions_history.collegia_fee"),
						"- £ 0",
						t("individual.contributions_history.date_of_transfer", {
							dateOfPayment
						}),
						true
					)
			}
			{
				typeOfContribution === t("individual.contributions_history.redemption") &&
					renderContributions(
						t("individual.contributions_history.redemption"),
						"- £ " + contributions,
						contributions_image,
						t("individual.contributions_history.collegia_fee"),
						"- £ " + individual_fee_value,
						t("individual.contributions_history.date_of_transfer", {
							dateOfPayment
						}),
						true
					)
			}
			{
				contributions_government > 0 &&
					renderContributions(
						t("individual.contributions_history.uk_gov_top_up"),
						"+ £ " + contributions_government,
						transaction_status === 0 ? government_contributions_image : contributions_image,
						t("individual.contributions_history.collegia_fee"),
						"- £ " + government_collegia_fee,
						transaction_status === 0 ?
							t("individual.contributions_history.waiting_gov_top_up_collection")
							:
							"",
					)
			}
		</Grid>
	)
};

export default ContributionsExpandedList;