import React, {useEffect} from "react";

import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import Wrapper from "../layout/Logged/Wrapper";
import Withdraw from "../components/Options/Withdraw";
import TaxFreeWithdraw from "../components/Options/TaxFreeWithdraw";
import MonthlyIncome from "../components/Options/MonthlyIncome";
import {connect} from "react-redux";
import {returnUser, retrieveOptionsValue} from "../actions/Profile";
import {PrimaryHeader} from "../components/Headers/PrimaryHeader";
import Loading from "../components/Loading";
import {useTranslation} from "react-i18next";

/**
 * @param id
 * @param t
 * @returns {string|boolean}
 */
const renderTitle = (id: number = 0, t = () => false) => {
	switch (parseInt(id)) {
		case 1:
			return t("individual.main_page.tabs.card_options.withdraw_everything");

		case 2:
			return t("individual.options.tax_free_witthdraw_with_slash");

		case 3:
			return t("individual.options.only_monthly_income_title");

		default:
			return "";
	}
}

/**
 * @param id
 * @param options_value
 * @param t
 * @returns {JSX.Element}
 */
const renderContent = (id: number = 0, options_value: any[] = false, t = () => false) => {
	switch (parseInt(id)) {
		case 1:
			return (
				<Withdraw
					options_value={options_value ?? false}
					t={t}
				/>
			);

		case 2:
			return (
				<TaxFreeWithdraw
					options_value={options_value ?? false}
					t={t}
				/>
			);

		case 3:
			return (
				<MonthlyIncome
					options_value={options_value ?? false}
					t={t}
				/>
			);

		default:
			return (
				<Withdraw
					options_value={options_value ?? false}
					t={t}
				/>
			);
	}
}

const Options = (
	{
		options_value = [],
		retrieveOptionsValue = () => {
		},
		match = {
			params: {
				token: ""
			}
		},
		history = () => {
		},
		loading = false,
	}
) => {
	const {t} = useTranslation();

	const id = match.params && match.params.id ?
		match.params.id
		:
		false;

	useEffect(() => {
		retrieveOptionsValue({
			options: {
				option: `option${id}`,
			}
		});
	}, [
		retrieveOptionsValue,
		id
	]);

	if (Object.keys(options_value).length === 0 || loading) {
		return (
			<Loading absolute/>
		)
	}

	return (
		<Wrapper hideFooter={true} t={t}>
			<Slide in={true} direction={"left"}>
				<div>
					<Grid
						direction={"column"}
						alignItems={"center"}
						alignContent={"center"}
						id={"options-wrapper"}
						container
						item
					>
						<PrimaryHeader
							history={history}
							backPage={"/main-page"}
							t={t}
							backIcon
						>
							{renderTitle(id, t)}
						</PrimaryHeader>
						{renderContent(id, options_value, t)}
					</Grid>
				</div>
			</Slide>
		</Wrapper>
	);
}


const mapStateToProps = state => state.Profile;

/**
 * @param dispatch
 * @returns {{retrieveOptionsValue: (function(*[]=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	retrieveOptionsValue: (data: any[] = false) => dispatch(retrieveOptionsValue(data ?? false)),
	returnUser: dispatch(returnUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Options);