import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/**
 * @param section
 * @param expanded
 * @param setExpanded
 * @private
 */
const _changeAccordion = (
	section: string = "",
	expanded: any = [],
	setExpanded: Function = () => {
	}
) => {
	const sectionFilter = expanded.filter(expanded => expanded.section === section);
	if (sectionFilter.length === 0) {
		setExpanded(expanded => [...expanded,
			{
				section: section,
				read: true,
			}
		]);
	}
}

/**
 * @param noProceedButtonText
 * @param expanded
 * @param sectionTitle
 * @param trialPeriod
 * @param proceed
 * @param noProceed
 * @param setExpanded
 * @param setSectionTitle
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const GenericTermsAndConditions = ({
	noProceedButtonText = "No, I want to opt out",
	expanded = [],
	sectionTitle = "",
	trialPeriod = false,
	proceed = () => {
	},
	noProceed = () => {
	},
	setExpanded = () => {
	},
	setSectionTitle = () => {
	},
	t = key => key,
}): JSX.Element => {
	return (
		<Grid
			xl={12}
			lg={12}
			md={12}
			sm={12}
			xs={12}
			alignItems={"center"}
			alignContent={"center"}
			direction={"column"}
			item
			container
		>
			<Grid
				className={"dialog-without-padding dialog-without-border-top"}
				item
			>
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					direction={"row"}
					className={"big-picture-container"}
					container
					item
				>
					<Accordion
						expanded={
							sectionTitle === 'Is my money safe?'
						}
						onClick={() => {
							sectionTitle === 'Is my money safe?' ?
									setSectionTitle("")
								:
									setSectionTitle('Is my money safe?')
							_changeAccordion(
								'Is my money safe?',
								expanded,
								setExpanded
							);
						}}
						className={"expansion-panel"}
					>
						<AccordionSummary
							expandIcon={
								<ExpandMoreIcon/>
							}
							aria-controls={"panel1bh-content"}
							id={"panel1bh-header"}
						>
							<Typography
								className={"expansion-panel-title"}
							>
								{t("individual.generic_terms_and_conditions.the_big_picture.money_safe.title")}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography
								className={"expansion-subtitle"}
								dangerouslySetInnerHTML={{
									__html: t("individual.generic_terms_and_conditions.the_big_picture.money_safe.information")
								}}
							>
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={
							sectionTitle === 'How is my money invested?'
						}
						onChange={() => {
							sectionTitle === 'How is my money invested?' ?
								setSectionTitle("")
								:
								setSectionTitle('How is my money invested?')
							_changeAccordion(
								'How is my money invested?',
								expanded,
								setExpanded
							);
						}}
						className={"expansion-panel"}
					>
						<AccordionSummary
							expandIcon={
								<ExpandMoreIcon/>
							}
							aria-controls="panel2bh-content"
							id="panel2bh-header"
						>
							<Typography
								className={"expansion-panel-title"}
							>
								{t("individual.generic_terms_and_conditions.the_big_picture.how_money_invested.title")}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography
								className={"expansion-subtitle"}
								dangerouslySetInnerHTML={{
									__html: t("individual.generic_terms_and_conditions.the_big_picture.how_money_invested.information"),
								}}
							/>
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={
							sectionTitle === 'Collegia does not provide advice'
						}
						onClick={() => {
							sectionTitle === 'Collegia does not provide advice' ?
								setSectionTitle("")
								:
								setSectionTitle('Collegia does not provide advice')
							_changeAccordion(
								'Collegia does not provide advice',
								expanded,
								setExpanded
							);
						}}
						className={"expansion-panel"}
					>
						<AccordionSummary
							expandIcon={
								<ExpandMoreIcon/>
							}
							aria-controls="panel3bh-content"
							id="panel3bh-header"
						>
							<Typography
								className={"expansion-panel-title"}
							>
								{t("individual.generic_terms_and_conditions.the_big_picture.collegia_not_provide_advice.title")}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography
								className={"expansion-subtitle"}
							>
								{t("individual.generic_terms_and_conditions.the_big_picture.collegia_not_provide_advice.information")}
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={
							sectionTitle === 'How much does it cost?'
						}
						onClick={() => {
							sectionTitle === 'How much does it cost?' ?
								setSectionTitle("")
								:
								setSectionTitle('How much does it cost?')
							_changeAccordion(
								'How much does it cost?',
								expanded,
								setExpanded
							);
						}}
						className={"expansion-panel"}
					>
						<AccordionSummary
							expandIcon={
								<ExpandMoreIcon/>
							}
							aria-controls="panel4bh-content"
							id="panel4bh-header"
						>
							<Typography
								className={"expansion-panel-title"}
							>
								{t("individual.generic_terms_and_conditions.the_big_picture.how_much_cost.title")}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography
								className={"expansion-subtitle"}
								dangerouslySetInnerHTML={{
									__html: t("individual.generic_terms_and_conditions.the_big_picture.how_much_cost.information")
								}}
							/>
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={
							sectionTitle === 'Why should I trust Collegia?'
						}
						onClick={() => {
							sectionTitle === 'Why should I trust Collegia?' ?
								setSectionTitle("")
								:
								setSectionTitle('Why should I trust Collegia?')
							_changeAccordion(
								'Why should I trust Collegia?',
								expanded,
								setExpanded
							);
						}}
						className={"expansion-panel"}
					>
						<AccordionSummary
							expandIcon={
								<ExpandMoreIcon/>
							}
							aria-controls="panel5bh-content"
							id="panel5bh-header"
						>
							<Typography
								className={"expansion-panel-title"}
							>
								{t("individual.generic_terms_and_conditions.the_big_picture.should_i_trust_collegia.title")}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography
								className={"expansion-subtitle"}
								dangerouslySetInnerHTML={{
									__html: t("individual.generic_terms_and_conditions.the_big_picture.should_i_trust_collegia.information")
								}}
							/>
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={
							sectionTitle === 'Accessing, transferring and cancelling your pension'
						}
						onClick={() => {
							sectionTitle === 'Accessing, transferring and cancelling your pension' ?
								setSectionTitle("")
								:
								setSectionTitle('Accessing, transferring and cancelling your pension')
							_changeAccordion
							(
								'Accessing, transferring and cancelling your pension',
								expanded,
								setExpanded
							);
						}}
						className={"expansion-panel"}
					>
						<AccordionSummary
							expandIcon={
								<ExpandMoreIcon/>
							}
							aria-controls="panel6bh-content"
							id="panel6bh-header"
						>
							<Typography
								className={"expansion-panel-title"}
							>
								{t("individual.generic_terms_and_conditions.the_big_picture.access_transfer_cancel_pension.title")}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography
								className={"expansion-subtitle"}
								dangerouslySetInnerHTML={{
									__html: t("individual.generic_terms_and_conditions.the_big_picture.access_transfer_cancel_pension.information")
								}}
							/>
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={
							sectionTitle === 'The 25% pension top up'
						}
						onClick={() => {
							sectionTitle === 'The 25% pension top up' ?
								setSectionTitle("")
								:
								setSectionTitle('The 25% pension top up')
							_changeAccordion
							(
								'The 25% pension top up',
								expanded,
								setExpanded
							);
						}}
						className={"expansion-panel"}
					>
						<AccordionSummary
							expandIcon={
								<ExpandMoreIcon/>
							}
							aria-controls="panel7bh-content"
							id="panel7bh-header"
						>
							<Typography
								className={"expansion-panel-title"}
							>
								{t("individual.generic_terms_and_conditions.the_big_picture.pension_top_up.title")}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography
								className={"expansion-subtitle"}
								dangerouslySetInnerHTML={{
									__html: t("individual.generic_terms_and_conditions.the_big_picture.pension_top_up.information")
								}}
							/>
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={
							sectionTitle === 'How much can I invest?'
						}
						onClick={() => {
							sectionTitle === 'How much can I invest?' ?
								setSectionTitle("")
								:
								setSectionTitle('How much can I invest?')
							_changeAccordion
							(
								'How much can I invest?',
								expanded,
								setExpanded
							);
						}}
						className={"expansion-panel"}
					>
						<AccordionSummary
							expandIcon={
								<ExpandMoreIcon/>
							}
							aria-controls="panel7bh-content"
							id="panel7bh-header"
						>
							<Typography
								className={"expansion-panel-title extra"}
							>
								{t("individual.generic_terms_and_conditions.the_big_picture.how_much_can_i_invest.title")}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography
								className={"expansion-subtitle"}
								dangerouslySetInnerHTML={{
									__html: t("individual.generic_terms_and_conditions.the_big_picture.how_much_can_i_invest.information")
								}}
							/>
						</AccordionDetails>
					</Accordion>
				</Grid>
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					alignContent={"center"}
					alignItems={"center"}
					direction={"row"}
					justifyContent={"space-between"}
					className={"grid-b1"}
					item
					container
				>
					<Grid
						xl={5.75}
						lg={5.75}
						md={5.75}
						sm={5.75}
						xs={12}
						item
						container
					>
						<Button
							className={'want-to-opt-out-button'}
							fullWidth
							onClick={() => proceed()}
						>
							{t("individual.terms_and_conditions.i_read_and_accept_it_button")}
						</Button>
					</Grid>
					<Grid
						xl={5.75}
						lg={5.75}
						md={5.75}
						sm={5.75}
						xs={12}
						item
						container
					>
						{
							trialPeriod ?
								<Button
									className={'want-to-stay-button'}
									fullWidth
									onClick={() => noProceed()}
								>
									{noProceedButtonText ?? t("individual.no_i_want_to_opt_out")}
								</Button>
								:
								""
						}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default GenericTermsAndConditions;