import {
	NOTIFICATIONS_CLEAR_ALL,
	NOTIFICATIONS_GET,
} from "./types";
import * as SnackBar from "../SnackBar";
import Notification from "../../services/Notification";


/**
 * @param data
 * @returns {(function(*): void)|*}
 */
export const setVisualized = (data) => dispatch => {
	if (data) {
		new Notification()
			.setVisualized(data ?? false)
			.then(response =>
				dispatch({
					type: NOTIFICATIONS_GET,
					notifications: response?.data?.data ?? [],
				})
			)
			.catch(error => console.error(error));
	}
}

/**
 * Custom pusher notification.
 *
 * @param notification
 * @returns {function(*): void}
 */
export const notificationsSnackBar = (notification) => dispatch => {

	let payload = [];

	if (notification?.data?.length > 0 || notification?.data !== "") {
		payload["data"] = notification?.data;
		if (notification?.message) {
			payload["message"] = notification?.message;
		}
	}

	if (notification?.code === 200) {
		dispatch(SnackBar?.info(payload));
	} else if (notification?.success) {
		dispatch(SnackBar?.success(payload));
	} else if (notification?.error) {
		dispatch(SnackBar?.error(payload));
	}
};

/**
 * @param data
 * @returns {(function(*): void)|*}
 */
export const clearAllNotification = (data) => dispatch => {
	if (data) {
		new Notification()
			.setVisualized(data ?? false)
			.then(response => {
				switch (response?.data?.code) {
					case 200:
						dispatch({type: NOTIFICATIONS_CLEAR_ALL});
						break;

					default:
						dispatch(SnackBar.error("Something went wrong."));
						break;
				}
			})
			.catch(error => console.error(error));
	}
}

/**
 * @returns {(function(*): void)|*}
 */
export const getNotification = () => dispatch => {
	new Notification()
		.getNotification()
		.then(response =>
			dispatch({
				type: NOTIFICATIONS_GET,
				notifications: response?.data?.data ?? [],
			})
		)
		.catch(error => console.error(error));
}