export const FETCH_PROFILE_DATA = "PROFILE.fetch_profile_data";
export const COMPLETE_WALKTHROUGH = "PROFILE.complete_walkthrough";
export const LOGIN = "PROFILE.LOGIN";
export const UPDATE_RETIREMENT_AGE = "PROFILE.UPDATE_RETIREMENT_AGE";
export const UPDATE_MY_STATE_PENSION = "PROFILE.UPDATE_MY_STATE_PENSION";
export const FETCH_EMPLOYERS_DATA = "PROFILE.FETCH_EMPLOYERS_DATA";
export const MY_PROJECTIONS = "PROFILE.MY_PROJECTIONS";
export const UPDATE_PROFILE_PICTURE = "PROFILE.UPDATE_PROFILE_PICTURE";
export const REQUEST_CHANGE_EMAIL = "PROFILE.REQUEST_CHANGE_EMAIL";
export const CONFIRM_REQUEST_CHANGE_PROFILE_CODE = "PROFILE.CONFIRM_REQUEST_CHANGE_PROFILE_CODE";
export const PROFILE_CHANGE_CODE_CHECKED = "PROFILE.PROFILE_CHANGE_CODE_CHECKED";
export const RETRIEVE_NEW_PASSWORD = "PROFILE.RETRIEVE_NEW_PASSWORD";
export const PASSWORD_CHANGE_TOKEN_REQUEST = "PROFILE.PASSWORD_CHANGE_TOKEN_REQUEST";
export const RETRIEVE_OPTIONS_VALUES = "PROFILE.RETRIEVE_OPTIONS_VALUES";
export const UPDATE_PROFILE = "PROFILE.UPDATE";
export const CHECK_NIN_DUPLICATE = "PROFILE.CHECK_NIN";
export const PROFILE_RETRIEVE_BANK_ACCOUNTS = "PROFILE.RETRIEVE_BANK_ACCOUNTS";
export const GET_ACTIVATION_TOKEN_STARTED = "GET_ACTIVATION_TOKEN_STARTED";
export const GET_ACTIVATION_TOKEN_SUCCESS = "GET_ACTIVATION_TOKEN_SUCCESS";
export const GET_ACTIVATION_TOKEN_ERROR = "GET_ACTIVATION_TOKEN_ERROR";
export const GET_ACTIVATION_TOKEN_CLEAR = "GET_ACTIVATION_TOKEN_CLEAR";
export const IFA_ACCESS_SUCCESS = "IFA_ACCESS_SUCCESS";
export const IFA_ACCESS_ERROR = "IFA_ACCESS_ERROR";
export const IFA_ACCESS = "IFA_ACCESS";
export const GENERATE_HMRC_ACCESS_TOKEN_STATE = "GENERATE_HMRC_ACCESS_TOKEN_STATE";
export const PROCESS_PAST_EMPLOYMENTS = "PROCESS_PAST_EMPLOYMENTS";
export const PROCESS_RESIDENCY_STATUS = "PROCESS_RESIDENCY_STATUS";
export const CREATE_FIRST_PASSWORD_STARTED = "CREATE_FIRST_PASSWORD_STARTED";
export const CREATE_FIRST_PASSWORD_SUCCESS = "CREATE_FIRST_PASSWORD_SUCCESS";
export const CREATE_FIRST_PASSWORD_ERROR = "CREATE_FIRST_PASSWORD_ERROR";
export const RETURN_MONTHLY_PROJECTION = "PROFILE.RETURN_MONTHLY_PROJECTION";