import AbstractServiceRequestsClass from "../../classes/AbstractServiceRequestsClass";

import Request from '../../request';
import {AxiosResponse} from "axios";

export default class AssetsLocationService extends AbstractServiceRequestsClass {
	constructor() {
		super(Request.AssetsLocation);
	}

	/**
	 * @returns {null|Promise<AxiosResponse<any>>}
	 * @param id
	 */
	getAssetsLocation(id: number = 0): null | Promise<AxiosResponse<any>> {
		if (id !== "" && this.request) {
			return this.request.GetAssetsLocation(id);
		}

		return null;
	}
}