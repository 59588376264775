import React from "react";

import Grid from "@mui/material/Grid";

import collegiaLogo from "../../images/logo-icon.svg";

const _renderUrl = (token = "", url = "") => {
	if (url) {
		return url;
	}

	if (token) {
		return `/activation/${token}`;
	}
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Header = (props = {
	token: "",
	url: "",
}): JSX.Element => {

	return (
		<Grid
			id={"activation-page-header"}
			alignContent={"center"}
			alignItems={"center"}
			direction={"column"}
			item
			container
		>
			<img
				src={collegiaLogo}
				alt={"Back to initial page."}
				onClick={() => props.history(_renderUrl(props?.token, props?.url ?? false))}
			/>
		</Grid>
	)
}

export default Header;