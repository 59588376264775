import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CheckIcon from "../images/main-menu/check-icon.svg";
import Close from "../images/main-menu/close-black.svg";
import {useNavigate} from "react-router-dom";


/**
 * @param open
 * @param setOpen
 * @returns {JSX.Element}
 * @constructor
 */
const ReferEmployerModal = (
	{
		open = false,
		setOpen = () => {},
	}
): JSX.Element => {

	const push = useNavigate();

	return (
		<Grid
		item
		xl={12}
		lg={12}
		container
		>
			<Dialog
				open={open}
				id={"main-page-modal"}
			>
				<Grid
					xs={12}
					sm={12}
					md={12}
					lg={12}
					direction={"row"}
					justifyContent={"center"}
					container
					item
					paddingBottom={4}
					paddingTop={2}
				>
					<Grid
						item
						lg={12}
						xl={12}
						sm={12}
						md={12}
						textAlign={"end"}
						marginBottom={2}
						marginRight={2}
					>
						<img
							alt={""}
							src={Close}
							onClick={() => {
								setOpen(false);
								push("/main-page");
							}}
						/>
					</Grid>
					<Grid
						item
						lg={12}
						xl={12}
						sm={12}
						md={12}
						textAlign={"center"}
						marginTop={3}
						marginBottom={2}
					>
						<img
							alt={""}
							src={CheckIcon}
						/>
					</Grid>
					<Grid
						lg={12}
						xl={12}
						sm={12}
						md={12}
						item
						textAlign={"-webkit-center"}
						justifyContent={"center"}
						container
					>
						<Typography
							align={"center"}
							className={"modal-title"}
						>
							Thank you!
						</Typography>
						<Typography
							align={"center"}
							className={"modal-subtitle"}
						>
							We will get in touch with your employer and see what can be done.
						</Typography>
					</Grid>
				</Grid>
			</Dialog>
	</Grid>
	)
};

export default ReferEmployerModal;