import ActivationToken from "../../services/ActivationToken";
import {GET_BY_TOKEN} from "./types";
import {MOBILE_CODE_SENT, NEXT_STEP} from "../Steps/types";
import {CONFIRM_CODE} from "../ActivationCode/types";
import {FETCH_CONNECTION_CODE} from "../Connections/types";

/**
 * @param token
 * @param modalLoading
 * @param shouldRedirect
 * @returns {function(...[*]=)}
 */
export const retrieveByToken = (
	token = false,
	modalLoading = false,
	shouldRedirect = true,
) => dispatch => {
	if (token) {
		if (modalLoading) {
			dispatch({
				type: NEXT_STEP,
				step_loading: true,
			});
			dispatch({
				type: CONFIRM_CODE,
				activation_code_loading: true,
				activationCodeData: {
					canProceed: false,
				}
			});
		} else {
			dispatch({
				type: GET_BY_TOKEN,
				token_loading: true,
			});
		}

		new ActivationToken()
			.retrieveByToken(token)
			.then(response => {

				/**
				 * Case the user's data is null
				 * it's means the user doesn't exist or
				 * the user is already activated on Collegia.
				 * So we'll going to push him back to the login screen.
				 */
				if (shouldRedirect) {
					if (response.data.data?.active === false) {
						window.location = "/login";
					}
				}
				dispatch({
					type: NEXT_STEP,
					step_loading: false,
				});
				dispatch({
					type: CONFIRM_CODE,
					activation_code_loading: false,
				});

				dispatch({
					type: GET_BY_TOKEN,
					token_loading: false,
					token_data: {
						...response?.data?.data,
					},
				});

			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: GET_BY_TOKEN,
					token_loading: false,
				});
			});
	}
};

/**
 * @param token
 * @param data
 * @returns {(function(*): void)|*}
 */
export const sentMobileCode = (token: string = "", data: any = {}) => dispatch => {
	dispatch({
		type: MOBILE_CODE_SENT,
		proceedMobile: false,
		step_loading: true,
		stepErrorText: "",
	});
	new ActivationToken()
		.sentMobileCode(token, data)
		.then(response => {
			if (response?.data?.data?.error) {
				dispatch({
					type: MOBILE_CODE_SENT,
					token_loading: false,
					stepErrorText: response?.data?.data?.message,
					step_loading: false,
				});
			} else {
				dispatch({
					type: MOBILE_CODE_SENT,
					proceedMobile: response.data.data,
					step_loading: false,
				});
			}
		})
		.catch(error => {
			let message = "Please verify your phone number.";
			if (error.response) {
				message = error.response.data.message;
			}

			dispatch({
				type: MOBILE_CODE_SENT,
				token_loading: false,
				stepErrorText: message,
				step_loading: false,
			});
		});
}

/**
 * @param data
 * @param token
 * @param setInternal
 * @returns {(function(*): void)|*}
 */
export const sentEmailCode = (data = {}, token: string = "") => dispatch => {
	dispatch({
		type: NEXT_STEP,
		step_loading: true,
		canProceedStepTree: false,
	});

	new ActivationToken()
		.sentEmailCode(token, data)
		.then(response => {
			if (response?.data?.data?.error) {
				dispatch({
					type: NEXT_STEP,
					token_loading: false,
					stepErrorText: response?.data?.data?.message,
					step_loading: false,
					proceedEmail: false,
				});
			} else {
				dispatch({
					type: NEXT_STEP,
					proceedEmail: response.data.data,
					step_loading: false,
				});
			}
		})
		.catch(error => {
			// Error 😨
			let message = "Please verify your phone number.";
			if (error.response) {
				/*
				 * The request was made and the server responded with a
				 * status code that falls out of the range of 2xx
				 */
				message = error.response.data.message;
			}

			dispatch({
				type: NEXT_STEP,
				token_loading: false,
				stepErrorText: message,
				step_loading: false,
			});
		});
}

/**
 * @param token
 * @param code
 * @param action
 * @returns {(function(*): void)|*}
 */
export const updateSoftwareConnection = (token: string = "", code: string = "", action: string = "") => dispatch => {
	if (code && action) {
		dispatch({
			type: FETCH_CONNECTION_CODE,
			loading: true,
		});

		if (action === "authorize") {
			new ActivationToken()
				.authorize(code ?? false, token ?? false)
				.then(() => dispatch(retrieveByToken(token ?? "", false)))
				.catch(error => {
					dispatch({
						type: FETCH_CONNECTION_CODE,
						loading: false,
					});
					console.error(error);
				});

		} else if (action === "deny") {
			new ActivationToken()
				.deny(code ?? false, token ?? false)
				.then(() => dispatch(retrieveByToken(token ?? "", false)))
				.catch(error => {
					dispatch({
						type: FETCH_CONNECTION_CODE,
						loading: false,
					});
					console.error(error);
				});
		} else {
			dispatch({
				type: FETCH_CONNECTION_CODE,
				loading: false,
			});
		}
	}
};