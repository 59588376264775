import {resolveReduxState} from "../../utils/Helpers";
import {FETCH_SOURCE_OF_FUNDS} from "../../actions/SourceOfFunds/types";

const initial_state = {
	sourceOfFunds: [],
};

/**
 * @param state
 * @param action
 * @returns {{valueOf: (function(): boolean)}|boolean|{contributions: boolean}}
 * @constructor
 */
const SourceOfFundsStore = (state = initial_state, action = false) => {

	if (action) {
		const {type = false} = action;

		if (type) {
			if (type === FETCH_SOURCE_OF_FUNDS) return resolveReduxState(state, action);
		}
	}


	return state;
};

export default SourceOfFundsStore;