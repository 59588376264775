import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";

/**
 * @param props
 * @returns {JSX.Element|*[]}
 * @constructor
 */
const CustomIcon = (props = {
	fullWidthImg: false,
	customIcon: false,
	renderCustomIcon: false,
	isCustomIconIsSVG: false,
	title: "",
	customIconAction: () => {},
	customIconActionParams: {},
	iconMargin: "",
}): JSX.Element | *[] => (
	props.renderCustomIcon && props.customIcon ?
		<Grid
			xs={1}
			md={1}
			lg={1}
			sm={1}
			item
			className={"header-custom-icon"}
			onClick={() => props?.customIconAction(props?.customIconActionParams ?? {}) ?? false}
			style={props.iconMargin ? {marginTop: props.iconMargin} : {marginTop: 0}}
		>
			{
				props.isCustomIconIsSVG ?
					props.customIcon
					:
					<img
						className={props.fullWidthImg ? "full-img-size" : "default-img-size"}
						src={props.customIcon}
						alt={props.title}
					/>
			}
		</Grid>
		:
		[]
);


CustomIcon.propTypes = {
	fullWidthImg: PropTypes.bool.isRequired,
	customIcon: PropTypes.any.isRequired,
	renderCustomIcon: PropTypes.any.isRequired,
	title: PropTypes.any.isRequired,
	isCustomIconIsSVG: PropTypes.any.isRequired,
	customIconAction: PropTypes.func.isRequired,
	customIconActionParams: PropTypes.any.isRequired,
};

export default CustomIcon;