import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param code
 * @returns {Promise<AxiosResponse<any>>|Promise<never>}
 * @constructor
 */
const RejectInvitationRequest = (code:string = ""): Promise<AxiosResponse<any>>|Promise<never> =>
	Request?.put(`/individual/invitations/reject/${code}`)
	??
	Promise.reject(new Error("Request is not defined"));

export default RejectInvitationRequest;