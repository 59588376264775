import AbstractServiceRequestClass from "../../classes/AbstractServiceRequestsClass";
import Request from '../../request';
import {AxiosResponse} from "axios";


export default class InvitationsService extends AbstractServiceRequestClass {
	constructor() {
		super(Request.Invitations);
	}

	/**
	 * @param code
	 * @returns {Promise<AxiosResponse<*>>|Promise<never>|Promise<never>}
	 */
	findByCode = (code:string = ""): Promise<AxiosResponse<*>>|Promise<never>|Promise<never> =>
		this?.request?.FindByCode(code) ??
		Promise.reject(new Error("InvitationsService.findByCode: request is not defined"));

	/**
	 * @param code
	 * @returns {Promise<AxiosResponse<*>>|Promise<never>|Promise<never>}
	 */
	rejectInvitation = (code:string = ""): Promise<AxiosResponse<*>>|Promise<never>|Promise<never> =>
		this?.request?.RejectInvitation(code) ??
		Promise.reject(new Error("InvitationsService.findByCode: request is not defined"));

	/**
	 * @param code
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|Promise<never>|Promise<AxiosResponse<*>>}
	 */
	acceptInvitation = (code:string = "", data = {}): Promise<AxiosResponse<*>>|Promise<never>|Promise<never> =>
		this?.request?.AcceptInvitation(code, data) ??
		Promise.reject(new Error("InvitationsService.findByCode: request is not defined"));

	referEmployerManually = (data = {}): Promise<AxiosResponse<*>>|Promise<never>|Promise<never> =>
		this?.request?.ReferEmployerManually(data) ??
		Promise.reject(new Error("InvitationsService.referEmployerManually: request is not defined"));
}