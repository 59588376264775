import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import StillNotThere from "../../images/newStillNotThere.svg";
import Minimum from "../../images/newMinimumTree.svg";
import Moderate from "../../images/newModerateTree.svg";
import Comfortable from "../../images/newComfortableTree.svg";
import CircularProgress from "@mui/material/CircularProgress";
// import ArrowIcon from "../../images/Arrow.svg";
import {capitalizeFirstLetter} from "../../utils/Helpers";

/**
 * @param my_projections
 * @param t
 * @returns {JSX.Element}
 * @private
 */
function _switchBadges(my_projections,t =key=>key): JSX.Element {
	if (my_projections.badge) {
		switch (my_projections.badge) {
			default:
				return (
					<img
						className={"icon-image"}
						src={StillNotThere}
						alt={"Still not there"}
					/>
				);
			case t("individual.lifestyle_ruler.minimum"):
				return (
					<img
						className={"icon-image"}
						src={Minimum}
						alt={capitalizeFirstLetter(t("individual.lifestyle_ruler.minimum"))}
					/>
				);
			case t("individual.lifestyle_ruler.moderate"):
				return (
					<img
						className={"icon-image"}
						src={Moderate}
						alt={capitalizeFirstLetter(t("individual.lifestyle_ruler.moderate"))}
					/>
				);
			case t("individual.lifestyle_ruler.comfortable"):
				return (
					<img
						className={"icon-image"}
						src={Comfortable}
						alt={capitalizeFirstLetter(t("individual.lifestyle_ruler.comfortable"))}
					/>
				);
		}
	}
}

/**
 * @param my_projections
 * @param loading
 * @param history
 * @param t
 * @param xl
 * @param lg
 * @param md
 * @param sm
 * @param xs
 * @returns {JSX.Element}
 * @constructor
 */
const ExpectedRetirementLifestyleCard = (
	{
		my_projections = [],
		loading = false,
		history = {
			push: () => {
			}
		},
		t = key => key,
		xl = 12,
		lg=12,
		md=12,
		sm=12,
		xs=12
	}
): JSX.Element => {
	const [text, setText] = useState("");

	useEffect(() => {
		if (my_projections?.badge) {
			switch (my_projections.badge) {
				default:
					setText(t("individual.main_page.expected_retirment_styles.still_not_there.text"));
					break;
				case t("individual.lifestyle_ruler.minimum"):
					setText(t("individual.main_page.expected_retirment_styles.minimum.text"));
					break;
				case t("individual.lifestyle_ruler.moderate"):
					setText(t("individual.main_page.expected_retirment_styles.moderate.text"));
					break;
				case t("individual.lifestyle_ruler.comfortable"):
					setText(t("individual.main_page.expected_retirment_styles.comfortable.text"));
					break;
			}
		}
	}, [
		setText,
		my_projections.badge,
		t,
	]);

	return (
		<Grid
			container
			item
			direction={"column"}
			justifyContent={"space-between"}
			xs={xs}
			sm={sm}
			md={md}
			lg={lg}
			xl={xl}
			id={"expected-retirement-lifestyle"}
		>
			{
				loading ?
					<Grid className={'loading-retire'}>
						<CircularProgress sx={{minWidth: "20rem", minHeight: "20rem"}}/>
					</Grid>
					:
					<Grid
						direction={"column"}
						container
					>
						<Typography
							align={"center"}
							className={"retirement-age-title"}
						>
							{t("individual.main_page.expected_retirment_styles.title")}
						</Typography>
						<Grid
							item
							textAlign={"center"}
						>
							{_switchBadges(my_projections,t)}
						</Grid>
						<Typography
							align={"center"}
							className={"retirement-age-yearly-income"}
						>
							{"£ " + my_projections?.total_yearly_income} <span
							className={"year"}> / {t("individual.year")} </span>
						</Typography>
						<Typography
							align={"center"}
							variant={"p"}
							display={"block"}
							className={"retirement-age-lifestyle"}
							margin={"0 auto"}
						>
							{my_projections?.badge !== t("individual.lifestyle_ruler.still_not_there") ?
								t("individual.main_page.expected_retirment_styles.current_life_style", {
									style: my_projections.badge,
								})
								:
								my_projections?.badge
							}
						</Typography>
						<Typography
							align={"center"}
							variant={"p"}
							display={"block"}
							className={"retirement-age-subtitle"}
							margin={"0 auto"}
						>
							{text}
						</Typography>
						{/*<Typography*/}
						{/*	className={"cta"}*/}
						{/*	align={"center"}*/}
						{/*	onClick={() => history("/understand-lifestyles")}*/}
						{/*>*/}
						{/*	{t("individual.learn_more")}*/}
						{/*	<img*/}
						{/*		className={"icon-arrow"}*/}
						{/*		src={ArrowIcon}*/}
						{/*		alt={t("individual.learn_more")}*/}
						{/*	/>*/}
						{/*</Typography>*/}
					</Grid>

			}
		</Grid>
	)
};

export default ExpectedRetirementLifestyleCard;