import AbstractServiceRequestClass from "../../classes/AbstractServiceRequestsClass";

import Request from '../../request';

export default class UpcomingTransactions extends AbstractServiceRequestClass {
	constructor() {
		super(Request.UpcomingTransactions);
	}

	/**
	 * @returns {Promise<never>|*}
	 */
	all(): Promise<never> | * {
		if (this.request) {
			return this.request.All();
		}

		return Promise.reject("No request handle informed.");
	}
}