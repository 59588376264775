import React, {useEffect} from "react";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import Wrapper from "../layout/Logged/Wrapper";
import {returnUser} from "../actions/Profile";
import {connect} from "react-redux";
import HeaderInfo from "../components/InformYourSourceOfFunds/HeaderInfo";
import Loading from "../components/Loading";
import {get, updateSourceOfFunds} from "../actions/ExtraContributions";
import {get as getSourceOfFunds} from '../actions/SourceOfFunds';
import ExtraContributionsCardList from "../components/NewContributions/ExtraContributionsCardList";
import {PrimaryHeader} from "../components/Headers/PrimaryHeader";
import {useTranslation} from "react-i18next";

/**
 * @param loading
 * @param contributions
 * @param sourceOfFunds
 * @param returnUser
 * @param get
 * @param getSourceOfFunds
 * @param updateSourceOfFunds
 * @param history
 * @returns {JSX.Element}
 * @constructor
 */
const InformYourSourceOfFunds = (
	{
		loading = false,
		contributions = [],
		sourceOfFunds = [],
		returnUser = () => {
		},
		get = () => {
		},
		getSourceOfFunds = () => {
		},
		updateSourceOfFunds = () => {
		},
		history = () => {
		}
	}
): JSX.Element => {
	const {t} = useTranslation();
	useEffect(() => {
		returnUser();
		get();
		getSourceOfFunds();
	}, [returnUser, get, getSourceOfFunds]);

	if (loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<Wrapper hideFooter={true}>
			<Slide in={true} direction={"left"}>
				<div>
					<Grid
						spacing={0}
						direction={"column"}
						alignItems={"center"}
						alignContent={"center"}
						id={"source-of-funds-wrapper"}
						container
						item
					>
						<PrimaryHeader
							history={history}
							backIcon
							backPage={"/main-page"}
						>
							Source of funds
						</PrimaryHeader>
						<HeaderInfo/>
						{
							contributions.length > 0 && contributions.filter(c => c.source_of_fund_id === null).map(c => (
								<ExtraContributionsCardList
									key={c.id}
									contribution={c}
									sourceOfFunds={sourceOfFunds}
									history={history}
									empty={contributions.filter(c => c.source_of_fund_id === null).length === 0}
									updateSourceOfFunds={updateSourceOfFunds}
									t={t}
									informSourceOfFunds
								/>
							))
						}
					</Grid>
				</div>
			</Slide>
		</Wrapper>
	);
}


const mapStateToProps = state => {
	const {
		Profile = [],
		ExtraContributions = [],
		SourceOfFunds = [],
	} = state;

	return {
		...Profile,
		...ExtraContributions,
		...SourceOfFunds,
	};
};

/**
 * @param dispatch
 * @returns {{get: (function(): *)}}
 */
const mapDispatchToProps = dispatch => ({
	returnUser: () => dispatch(returnUser()),
	get: () => dispatch(get()),
	getSourceOfFunds: () => dispatch(getSourceOfFunds()),
	updateSourceOfFunds: (id, data) => dispatch(updateSourceOfFunds(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InformYourSourceOfFunds);