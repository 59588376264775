import React, {useState, useEffect} from "react";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import {connect} from "react-redux";
import Wrapper from "../layout/Logged/Wrapper";
import {checkDuplicateNin, isValidNationalInsuranceNumber, returnUser, update} from "../actions/Profile";
import {
	setDefaults,
	fromAddress,
} from "react-geocode";
import Loading from "../components/Loading";
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {capitalizeFirstLetter} from "../utils/Helpers";
import {CountryRegionData} from "react-country-region-selector";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

/**
 * @param event
 * @param addressComponents
 * @param setAddress1
 * @param setAddress2
 * @param setCountry
 * @param setAddressComponents
 * @private
 */
const onChange = (
	event: any = [],
	addressComponents = [],
	setAddress1 = () => {
	},
	setAddress2 = () => {
	},
	setCountry = () => {
	},
	setAddressComponents = () => {
	},
) => {
	if (event.target.value === "") {
		setAddress1("");
		setAddress2("");
		setCountry("GB");
		setAddressComponents([]);
	}
	postCodeChange(event, addressComponents, setAddressComponents);
}

/**
 * @param event
 * @param addressComponents
 * @param setAddressComponents
 */
const postCodeChange = (
	event: any = false,
	addressComponents: any = [],
	setAddressComponents = () => {
	}
) => {
	if (event && event.target && event.target.value) {
		setDefaults({
			key: process.env.REACT_APP_GEOCODE_API_KEY, // Your API key here.
			language: "en", // Default language for responses.
			region: "uk", // Default region for responses.
		});
		fromAddress(event.target.value)
			.then((response) => {
					const {
						status = "OK"
					} = response;
					if (status === "OK") {
						setAddressComponents([{
							address: response?.results[0]?.formatted_address,
							components: response?.results[0]?.address_components,
						}]);
					}
				}
			)
			.catch(error => console.error(error));
	}
};

/**
 * @param value
 * @param setPostalCode
 * @param setAddress1
 * @param setAddress2
 * @param setCountry
 * @private
 */
const optionSelected = (
	value = [],
	setPostalCode = () => {
	},
	setAddress1 = () => {
	},
	setAddress2 = () => {
	},
	setCountry = () => {
	}
) => {
	const postal_code = value?.components?.map(v => v.types.filter(t => t === "postal_code").length > 0 ? v?.long_name : false).filter(v => v)[0],
		country = value?.components?.map(v => v.types.filter(t => t === "country").length > 0 ? v?.short_name : false).filter(v => v)[0];

	let address1 = value?.components?.map(v => v.types.filter(t => t === "route").length > 0 ? v?.long_name : false).filter(v => v)[0];
	let address2 = value?.components?.map(v => v.types.filter(t => t === "postal_town").length > 0 ? v?.long_name : false).filter(v => v)[0];

	value?.components?.forEach((v, index) => {
		if (v) {
			if (v.types.filter(t => t === "country").length > 0) {
				delete value.components[index];
			}
		}
	});

	setPostalCode(postal_code);
	setAddress1(address1);
	setAddress2(address2);
	setCountry(country);
};

/**
 * @param profile_data
 * @param valid_nin
 * @param exist_nin
 * @param update
 * @param checkDuplicateNin
 * @param isValidNationalInsuranceNumber
 * @param activation_code_loading
 * @returns {JSX.Element}
 * @constructor
 */
const PensionNinAndAddress = (
	{
		profile_data = {
			national_insurance_number: "",
		},
		valid_nin = false,
		exist_nin = false,
		update = () => {},
		checkDuplicateNin = () => {},
		isValidNationalInsuranceNumber = () => {},
		activation_code_loading = false,
	}
) => {
	const [nin, setNin] = useState(""),
		[country, setCountry] = useState("GB"),
		[address1, setAddress1] = useState(""),
		[address2, setAddress2] = useState(""),
		[address3, setAddress3] = useState(""),
		[address4, setAddress4] = useState(""),
		[addressComponents, setAddressComponents] = useState([]),
		[postalCode, setPostalCode] = useState(""),
		[dontHaveNin, setDontHaveNin] = useState(false),
		{t} = useTranslation(),
		history = useNavigate();

	//use effect for delay after type the nin
	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if (nin.length > 0) {
				checkDuplicateNin({
					individuals: {
						national_insurance_number: nin
					}
				});

				isValidNationalInsuranceNumber({
					profiles: {
						national_insurance_number: nin,
					}
				});
			}
		}, 1000)

		return () => clearTimeout(delayDebounceFn)
	}, [nin, checkDuplicateNin, isValidNationalInsuranceNumber]);

	if (activation_code_loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<Wrapper
			history={history}
			hideFooter={true}
			t={t}
		>
			<Slide
				in={true}
				direction={"up"}
			>
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					container
					direction={"row"}
					justifyContent={"center"}
					item
				>
					<Grid
						xl={8}
						lg={8}
						md={8}
						sm={8}
						xs={12}
						container
						direction={"row"}
						justifyContent={"space-around"}
						id={"activate-nin"}
						item
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							container
							className={"grid-text"}
							justifyContent={"center"}
							item
						>
							<Typography
								className={"title"}
							>
								{t("individual.my_account.login_details.verify_account.title")}
							</Typography>
						</Grid>
						<Grid
							xl={11.5}
							lg={11.5}
							md={11.5}
							sm={11.5}
							xs={12}
							className={"grid-text"}
							item
						>
							<Typography
								className={"label"}
							>
								{t("individual.activate_your_pension.nin_address.type_your_nin_input")}
							</Typography>
							<TextField
								className={"textfield-pension"}
								value={nin}
								onChange={(e) => setNin(e.target.value)}
								fullWidth
								disabled={dontHaveNin}
							/>

							<Grid container>
								{
									valid_nin &&
									<small
										className={"red-text"}
										key={t}
									>
										{t("individual.wrong_nin_number")}
									</small>
								}

							</Grid>
							<Grid container>
								{
									exist_nin &&
									<small
										className={"red-text"}
										key={t}
									>
										{t("individual.nin_already_exists")}
									</small>
								}

							</Grid>
							{
								(profile_data.national_insurance_number === "" ||
								profile_data.national_insurance_number === null) &&
								<FormControlLabel
									control={
										<Checkbox
											onChange={(e) => {
												setDontHaveNin(e.target.checked);
												setNin(null);
											}}
										/>
									}
									label={t("individual.i_do_not_have_a_national_insurance_number_yet")}
								/>
							}
						</Grid>
						<Grid
							xl={11.5}
							lg={11.5}
							md={11.5}
							sm={11.5}
							xs={12}
							className={"grid-text"}
							item
						>
							<Typography
								className={"label"}
							>
								{t("individual.my_account.address.search_your_address", "Type your postal code to search your address:")}
							</Typography>
							<FormControl
								fullWidth
							>
								<Autocomplete
									getOptionLabel={addressComponents => addressComponents.address ?? ""}
									options={addressComponents}
									onChange={(event, value) =>
										optionSelected(
											value,
											setPostalCode,
											setAddress1,
											setAddress2,
											setAddress3,
											setAddress4,
											setCountry
										)
									}
									renderInput={(params) => (
										<TextField
											{...params}
											margin={"normal"}
											variant={"outlined"}
											className={"address-pension"}
											onChange={event =>
												onChange(
													event,
													addressComponents,
													setAddress1,
													setAddress2,
													setCountry,
													setAddressComponents
												)
											}
										/>
									)}
									freeSolo
									disableClearable
								/>
							</FormControl>
						</Grid>

						<Grid
							xl={5.5}
							lg={5.5}
							md={5.5}
							sm={5.5}
							xs={12}
							className={"grid-text"}
							container
							item
						>
							<Typography
								className={"label"}
							>
								{`${t("individual.my_account.address.address_line_1_input")} *`}
							</Typography>
							<TextField
								id={"gender"}
								className={"textfield-pension"}
								value={address1}
								fullWidth
								onChange={event => setAddress1(event.target.value)}
								error={!address1}
							/>
						</Grid>

						<Grid
							xl={5.5}
							lg={5.5}
							md={5.5}
							sm={5.5}
							xs={12}
							className={"grid-text"}
							item
						>
							<Typography
								className={"label"}
							>
								{`${t("individual.my_account.address.postal_code_input")} *`}
							</Typography>
							<TextField
								className={"textfield-pension"}
								value={postalCode}
								onChange={(e) => setPostalCode(e.target.value)}
								fullWidth
								error={!postalCode}
							/>
						</Grid>

						<Grid
							xl={5.5}
							lg={5.5}
							md={5.5}
							sm={5.5}
							xs={12}
							className={"grid-text"}
							item
						>
							<Typography
								className={"label"}
							>
								{t("individual.my_account.address.house_flat_number_input")}
							</Typography>
							<TextField
								className={"textfield-pension"}
								value={address4}
								onChange={event => setAddress4(event.target.value)}
								fullWidth
							/>
						</Grid>
						<Grid
							xl={5.5}
							lg={5.5}
							md={5.5}
							sm={5.5}
							xs={12}
							className={"grid-text"}
							item
						>
							<Typography
								className={"label"}
							>
								{t("individual.my_account.address.town_city_input")}
							</Typography>
							<TextField
								className={"textfield-pension"}
								value={address2}
								onChange={event => setAddress2(event.target.value)}
								fullWidth
							/>
						</Grid>
						<Grid
							xl={5.5}
							lg={5.5}
							md={5.5}
							sm={5.5}
							xs={12}
							className={"grid-text"}
							item
						>
							<Typography
								className={"label"}
							>
								{t("individual.my_account.address.address_line_3")}
							</Typography>
							<TextField
								className={"textfield-pension"}
								value={address3}
								onChange={event => setAddress3(event.target.value)}
								fullWidth
							/>
						</Grid>
						<Grid
							xl={5.5}
							lg={5.5}
							md={5.5}
							sm={5.5}
							xs={12}
							className={"grid-text"}
							item
						>
							<Typography
								className={"label"}
							>
								{capitalizeFirstLetter(t("individual.my_account.beneficiaries.country"))}
							</Typography>
							<TextField
								className={"textfield-pension"}
								value={country}
								onChange={event => setCountry(event.target.value)}
								disabled
								select
								fullWidth
							>
								{
									CountryRegionData.map((option, index) => (
										<MenuItem key={index} value={option[1]}>
											{option[0]}
										</MenuItem>
									))
								}
							</TextField>
						</Grid>
						<Grid
							xl={7}
							lg={7}
							md={7}
							sm={7}
							xs={12}
							className={"grid-text"}
							item
						>
							<Button
								textAlign={"center"}
								onClick={() => {
									update(profile_data?.id, {
										profiles: {
											national_insurance_number: nin,
											first_login: false,
											address: {
												postal_code: postalCode,
												address1: address1,
												address2: address2,
												address3: address3,
												address4: address4,
												country: country,
											},
										}
									})
								}}
								disabled={
									!postalCode ||
									!address1 ||
									!country ||
									exist_nin ||
									valid_nin
								}
								fullWidth
							>
								{t("individual.login.continue_button")}
							</Button>
						</Grid>
					</Grid>
				</Grid>

			</Slide>
		</Wrapper>
	);
}
/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => state.Profile;

/**
 * @param dispatch
 * @returns {{checkDuplicateNin: (function(*[]=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	checkDuplicateNin: (data: any[] = false) => dispatch(checkDuplicateNin(data ?? false)),
	update: (id: any[] = false, data: any[] = false) => dispatch(update(id, data)),
	returnUser: dispatch(returnUser()),
	isValidNationalInsuranceNumber: (data: {} = {
		national_insurance_number: "",
	}) => dispatch(isValidNationalInsuranceNumber(data)),
});

export default connect(mapStateToProps ?? {}, mapDispatchToProps ?? {})(PensionNinAndAddress);