import React from "react";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const DifferentKindOfPension = (): JSX.Element => (
	<div
		id="Section-3"
		className="section-3 wf-section"
	>
		<div className="second-cta">
			<h1 className="heading-3">
				Because we are a different kind of pension
			</h1>
			<p className="paragraph-3">
				<strong>We avoid unnecessary jargon and help you plan for the future. </strong>Collegia
				translates your pension into the likely lifestyle you will afford in retirement: groceries, holidays,
				car and etc.
			</p>
		</div>
	</div>
);

export default DifferentKindOfPension;