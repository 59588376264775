import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

/**
 * @param value
 * @param history
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const CollegiaAccountCard = (
	{
		profile_data = {
			portfolio: {
				investment_return: 0,
				totalCollegiaAccount: 0,
				portfolio: 0,
			}
		},
		history = () => {
		},
		t = key => key,
	}
): JSX.Element => {

	let accountValue = parseFloat(profile_data?.portfolio?.investment_return ?? 0) +
		parseFloat(profile_data?.totalCollegiaAccount?.replace(',','') ?? 0) +
		parseFloat(profile_data?.total_collegia_account_to_be_collected?.replace(',','') ?? 0);

	return (
		<Grid
			xl={2.4}
			lg={2.8}
			md={6}
			sm={6}
			item
			justifyContent={'center'}
			className={"collegia-account-card"}
			onClick={() => history("/collegia-contribution")}
		>
			<Grid
				lg={12}
				xl={12}
				sm={12}
				xs={12}
				md={12}
				item
			>
				<Typography
					className={"title"}
					align={"center"}
				>
					{t("individual.collegia_account.title")}
				</Typography>
			</Grid>
			<Grid
				lg={12}
				xl={12}
				sm={12}
				xs={12}
				md={12}
				item
			>
				<Typography
					className={"account-value"}
					align={"center"}
				>
					{"£ " + accountValue.toLocaleString({
						style: 'currency',
						currency: 'GBP',
					})}
				</Typography>
			</Grid>
			<Grid
				lg={12}
				xl={12}
				sm={12}
				xs={12}
				md={12}
				item
				textAlign={"center"}
				paddingTop={9}
			>
				<Button
					className={"access-account"}
				>
					{t("individual.access_your_collegia_account")}
				</Button>
			</Grid>
		</Grid>
	);
};

export default CollegiaAccountCard;