import {CardActions, Menu, MenuItem, TextField} from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import React, {useState} from "react";

/**
 * @param isOther
 * @param other
 * @param otherChange
 * @returns {JSX.Element|*[]}
 */
const renderOtherTextInput = (
	isOther = false,
	other = "",
	otherChange = () => {
	}
): JSX.Element | *[] => {
	if (isOther) {
		return (
			<Grid
				className={"source-of-funds-other-text-holder"}
				xs={12}
				sm={12}
				lg={12}
				md={12}
				item
				container
			>
				<TextField
					variant={"outlined"}
					placeholder="Please inform you source of fund."
					value={other}
					onChange={(event) => otherChange(event.target.value)}
					fullWidth
				/>
			</Grid>
		);
	}

	return [];
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ExtraContributionsCardActions = (props = {
	sourceOfFunds: [],
	updateSourceOfFunds: () => {
	},
	t: key => key,
}): JSX.Element => {
	const [open, handleOpen] = useState(false);
	const [other, otherChange] = useState("");
	const [selectedSourceOfFund, handleChange] = useState(null);
	return (
		<CardActions className={"card-actions"}>
			<Grid
				className={"source-of-funds-selection"}
				xs={12}
				sm={12}
				lg={12}
				md={12}
				item
				container
			>
				<Grid
					className={"select-source-of-fund-button-holder"}
					xs={12}
					sm={12}
					lg={12}
					md={12}
					item
					container
				>
					<Button
						variant={"outlined"}
						onClick={() => handleOpen(!open)}
						fullWidth
					>
						{
							selectedSourceOfFund ?
								props?.sourceOfFunds?.filter(
									s => selectedSourceOfFund === s.id
								)[0]?.name
								:
								props.t("individual.extra_contributions.new.select_source_of_funds_select_default_input")
						}
					</Button>
				</Grid>
				{renderOtherTextInput(
					props?.sourceOfFunds?.filter(
						s => selectedSourceOfFund === s?.id
					)[0]?.name?.toLowerCase() === "other",
					other,
					otherChange,
				)}
				<Grid
					className={"source-of-funds-buttons-holder"}
					xs={12}
					sm={12}
					lg={12}
					md={12}
					item
					container
				>
					<Button
						variant={"outlined"}
						disabled={
							!selectedSourceOfFund ||
							(
								props?.sourceOfFunds?.filter(
									s => selectedSourceOfFund === s?.id
								)[0]?.name?.toLowerCase() === "other" &&
								other === ""
							)
						}
						onClick={() => props.updateSourceOfFunds(props.contribution.id, {
							contributions: {
								is_other: props?.sourceOfFunds?.filter(
									s => selectedSourceOfFund === s?.id
								)[0]?.name?.toLowerCase() === "other",
								name: other,
								source_of_fund_id: selectedSourceOfFund,
							},
						})}
						fullWidth
					>
						Update my source of funds.
					</Button>
				</Grid>
			</Grid>
			<Menu
				open={open}
			>
				{
					props.sourceOfFunds.map(s => (
						<MenuItem
							key={s.id}
							selected={selectedSourceOfFund === s.id}
							onClick={() => {
								handleOpen(!open)
								handleChange(s.id);
							}}
						>
							{s.name}
						</MenuItem>
					))
				}
			</Menu>
		</CardActions>
	)
}

export default ExtraContributionsCardActions;