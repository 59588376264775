import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const GeneralInformation = (): JSX.Element=> (
	<Grid
		xs={12}
		md={12}
		sm={12}
		xl={12}
		item
	>
		<Grid
			xs={12}
			md={12}
			sm={12}
			xl={12}
			item
		>
			<h3>
				Overview
			</h3>
		</Grid>
		<Grid
			xs={12}
			md={12}
			sm={12}
			xl={12}
			item
		>
			<Typography>
				Provides information about your history of employment for a given tax year,
				as reported to HMRC by their employer(s) through the PAYE process.
				The history of employment mainly contains details like Employer Paye Reference, Employer's
				name and Off Payroll Workflag (if available).

				<br />
				<br />
				The primary intended use for this information is to pre-populate an individual's
				Self Assessment tax return.
			</Typography>
		</Grid>
		<Grid
			xs={12}
			md={12}
			sm={12}
			xl={12}
			item
		>
			<h3>
				Data availability
			</h3>
		</Grid>
		<Grid
			xs={12}
			md={12}
			sm={12}
			xl={12}
			item
		>
			<Typography>
				Data is only available for taxpayers who are registered for Self Assessment and had any
				employments in the given tax year.
				<br />
				Also, data is only available once PAYE reconciliation has completed.
				This is the process that determines whether the individual has paid the right amount of
				tax and, for PAYE-only taxpayers,
				results in a P800 in the event of {" "}
				<a href={"https://www.gov.uk/tax-overpayments-and-underpayments"}>
					overpayment or underpayment
					.</a>
				<br />
				<br />
				Reconciliation starts on or around 6th June and runs for around one million individuals
				per working night. It has usually completed by the end of November.

				<br />
				<br />
				If HMRC expecting a P11D, reconciliation will not start for that individual until HMRC
				have received and processed the P11D. If we receive a P11D unexpectedly,
				HMRC will re-run the reconciliation.

				<br />
				<br />
				Data is no longer available once HMRC have received and processed the
				corresponding Self Assessment return.
			</Typography>
		</Grid>
	</Grid>
);

export default GeneralInformation;