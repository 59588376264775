import React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {calculateNextOnDate, formatMoney} from "../../utils/Helpers";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AddIcon from '@mui/icons-material/Add';
import moment from "moment";
import DateRangeIcon from '@mui/icons-material/DateRange';
import ExtraContributionsCardActions from "./ExtraContributions/ExtraContributionsCardActions";

/**
 * @param props
 * @returns {JSX.Element|string}
 * @private
 */
function _renderTitle(props = {
	contribution: {
		contribution_date: "",
	},
	type: "",
}): JSX.Element | string {
	if (props) {
		const date = moment(props?.contribution?.contribution_date);
		switch (props?.type) {
			case "one-off":
				return (
					<Grid
						xs={12}
						sm={12}
						md={12}
						lg={12}
						container
						item
						className={"header-title-info-holder"}
					>
                        <span className={"header-icon"}>
                            <AddIcon/>
                        </span>
						<span className={"date-info"}>
                            {date.format("DD")} {date.format("MMMM").toUpperCase()}
                        </span>
					</Grid>
				);

			default:
				return "";
		}
	}

	return "";
}

/**
 * @param status
 * @returns {string}
 * @private
 */
function _renderPaymentStatus(status: string = ""): string {
	status = status.toLowerCase();

	if (status === "processing") {
		return "processing-payment";
	} else if (
		status !== "cancelled" &&
		status !== "failed" &&
		status !== "declined" &&
		status !== "settlement_rejected" &&
		status !== "timeout" &&
		status !== "consent_timeout" &&
		status !== "unexpected_error" &&
		status !== "unknown" &&
		status !== "rejected"
	) {
		return "success-payment";
	}

	return "failed-payment";
}

/**
 * @param props
 * @returns {JSX.Element|string}
 * @private
 */
function _renderSubHeader(props = {
	contribution: {
		contribution_date: "",
		created_at: "",
		provider_status: "",
		next_contribution_date: "",
	},
	type: "",
	t: key => key,
}): JSX.Element | string {

	if (props) {

		let cardHeader;

		if (props?.type === "Monthly" && props?.contribution?.provider_status !== "CANCELLED") {
			cardHeader = (
				<Grid
					xs={12}
					md={12}
					lg={12}
					sm={12}
					item
					container
					className={"next-contribution-date"}
				>
					<Grid
						xs={1}
						md={1}
						sm={1}
						lg={1}
						className={"custom-flex-grid-base"}
						item
					>
						<DateRangeIcon/>
					</Grid>
					<Grid
						xs={11}
						md={11}
						sm={11}
						lg={11}
						item
					>
                            <span className={"next-contribution-date"}>
                                <span className={"next-on-label"}>
                                    {props.t("individual.extra_contributions.monthly.next_on")}
                                </span>
	                            {" "}
	                            <span className={'date'}>
                                    {calculateNextOnDate(props?.contribution?.contribution_date).format("DD MMM").toUpperCase()}
                                </span>
                            </span>
					</Grid>
				</Grid>
			);
		} else if (props?.contribution?.provider_status !== "CANCELLED") {
			cardHeader = (
				<Grid
					xs={12}
					md={12}
					lg={12}
					sm={12}
					item
					container
					className={"next-contribution-date"}
				>
					<Grid
						xs={1}
						md={1}
						sm={1}
						lg={1}
						className={"custom-flex-grid-base"}
						item
					>
						<AddIcon/>
					</Grid>
					<Grid
						xs={11}
						md={11}
						sm={11}
						lg={11}
						item
					>
                            <span className={"one-off-contribution-date"}>
                                <span className={'date'}>
                                    {moment(props?.contribution?.created_at).format('DD MMM')}
                                </span>
                            </span>
					</Grid>
				</Grid>
			);
		}
		return (
			<Grid
				xs={12}
				md={12}
				lg={12}
				sm={12}
				item
				container
			>
				{cardHeader}
				<Grid
					xs={12}
					md={12}
					lg={12}
					sm={12}
					item
					container
					className={"bank-name"}
				>
					{props?.contribution?.bank_provider ?? ""}
				</Grid>
				<Grid
					xs={12}
					md={12}
					lg={12}
					sm={12}
					item
					container
					className={_renderPaymentStatus(props?.contribution?.provider_status ?? "")}
				>
					{props?.contribution?.provider_status ?? ""}
				</Grid>
			</Grid>
		);
	}
}

/**
 * @param contribution
 * @param informSourceOfFunds
 * @param sourceOfFunds
 * @param updateSourceOfFunds
 * @param t
 * @returns {JSX.Element|*[]}
 * @private
 */
const _renderCardActions = (
	contribution,
	informSourceOfFunds = false,
	sourceOfFunds = [],
	updateSourceOfFunds = () => {
	},
	t = key => key,
): JSX.Element|*[] => {
	if (informSourceOfFunds) {
		return (
			<ExtraContributionsCardActions
				contribution={contribution}
				informSourceOfFunds={informSourceOfFunds}
				sourceOfFunds={sourceOfFunds}
				updateSourceOfFunds={updateSourceOfFunds}
				t={t}
			/>
		);
	}

	return [];
}

/**
 * @param props
 * @returns {JSX.Element}
 * @private
 */
function _renderContent(props: any = {
	history: {
		push: () => false,
	},
	contribution: {
		order_id: null,
		contribution_value: "",
	},
	type: "",
	informSourceOfFunds: false,
	sourceOfFunds: [],
	updateSourceOfFunds: () => {
	},
	t: key => key,
}): JSX.Element {
	if (props && !props.empty) {
		return (
			<Card
				className={"default-card-list"}
				onClick={() => {
					if (!props?.informSourceOfFunds) {
						props?.history(`/contributions/details/${props?.contribution?.order_id}/${props?.type}`)
					}
				}}
			>
				<CardHeader
					title={_renderTitle(props)}
					subheader={_renderSubHeader(props)}
					t={props.t}
				/>
				<CardContent
					onClick={() => {
						if (props?.informSourceOfFunds) {
							props?.history(`/contributions/details/${props?.contribution?.order_id}/${props?.type}`)
						}
					}}
				>
					<Grid
						xs={12}
						md={12}
						sm={12}
						lg={12}
						container
						item
					>
						<Grid
							xs={6}
							md={6}
							sm={6}
							lg={6}
							container
							item
							direction={"column"}
							alignItems={"flex-start"}
							alignContent={"flex-start"}
						>
							<Grid
								xs={12}
								md={12}
								sm={12}
								lg={12}
								item
							>
                        <span>
                            <LockOutlinedIcon className={"lock-icon"}/>
                        </span>
								<span className={"money-value"}>
                            £ {formatMoney(props?.contribution?.contribution_value)}
                        </span>

							</Grid>
						</Grid>
						<Grid
							xs={6}
							md={6}
							sm={6}
							lg={6}
							container
							item
							direction={"column"}
							alignItems={"flex-end"}
							alignContent={"flex-end"}
						>
							<Grid
								xs={12}
								md={12}
								sm={12}
								lg={12}
								item
							>
								<IconButton
									disableFocusRipple
									disableTouchRipple
									disableRipple
								>
									<KeyboardArrowRightIcon/>
								</IconButton>
							</Grid>
						</Grid>
					</Grid>
				</CardContent>
				{_renderCardActions(
					props.contribution,
					props.informSourceOfFunds,
					props.sourceOfFunds,
					props.updateSourceOfFunds,
					props.t,
				)}
			</Card>
		);
	}

	return (
		<Card
			className={"default-card-list"}
			onClick={() => {
				if (props?.type || !props?.contribution?.order_id) {
					return false;
				}

				return props.history(`/contributions/details/${props?.contribution?.order_id}/${props?.type}`);
			}}
		>
			<CardContent>
				<Grid
					xs={12}
					md={12}
					sm={12}
					lg={12}
					container
					item
					className={"no-contributions"}
				>
					<Typography
						paragraph
					>
						No {props?.type ?? "contributions"} contributions yet
					</Typography>
				</Grid>
			</CardContent>
		</Card>
	);
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ExtraContributionsCardList = (props = {
	history: {
		push: () => false,
	},
	contribution: {
		order_id: null,
		contribution_value: "",
		contribution_date: "",
		created_at: "",
	},
	type: "",
	updateSourceOfFunds: () => {
	},
}): JSX.Element => _renderContent(props);

export default ExtraContributionsCardList;