import Activation from "./Activation";
import Home from "./Home";
import AccountActivated from "./AccountActivated";
import AccountDeleted from "./AccountDeleted";

import ActivationPage from './ActivationPage';
import OptOut from "./OptOut";

import Login from './Login';
import ForgotPassword from "./ForgotPassword";
import PasswordRequestSent from "./PasswordRequestSent";
import ResetPassword from "./ResetPassword";
import SignUp from './SignUp';
import Page_403 from './403';

// logged in pages
import MainPage from "./MainPage";
import AccountContributions from "./Contributions";
import ContributionsHistory from "./ContributionsHistory";
import YourPension from "./YourPension";
import YourPensionConfigs from './YourPension/index';
import UnderstandLifestyles from "./UnderstandLifestyles";
import AutoEnrolment from "./AutoEnrolment";
import OptOutEmployer from "./AutoEnrolment/OptOutEmployer";
import InvestmentApproach from './InvestmentApproach';
import OtherPensions from './OtherPensions';
import ExtraContributions from "./ExtraContributions/index";
import Profile from './Profile';
import Options from './Options';
import TermsAndConditions from "./TermsAndConditions";
import CloseAccount from './CloseAccount';
import ReferEmployerComplete from "./ReferEmployerComplete";

import Test from './Test';
import LogOut from "./LogOut";
import ReferEmployer from "./ReferEmployer";
import PensionNinAndAddress from "./PensionNinAndAddress";
import PensionActivated from "./PensionActivated";
import Connections from './Connections';
import ConfirmYourEmail from "./ConfirmYourEmail";
import ConfirmYourMobile from "./ConfirmYourMobile";
import SoftwareConnections from "./SoftwareConnections";
import InformYourSourceOfFunds from "./InformYourSourceOfFunds";
import ConfirmEmploymentStatus from "./ConfirmEmploymentStatus"
import UpcomingTransactions from "./UpcomingTransactions"
import Portfolio from "./Portfolio";
import EnrolledByEmployer from "./SignUp/EnrolledByEmployer";
import Invitation from "./Invite";
import SelectService from "./SelectService";
import IFAConnections from "./IFAConnections";
import AllowHMRCPastEmployersInfo from './AllowHMRCPastEmployersInfo';
import AllowHMRCResidencyStatusInfo from "./AllowHMRCResidencyStatusInfo";
import ResidencyStatusAccepted from "./ResidencyStatusAccepted";
import MandateCreated from "./MandateCreated";

const Pages = {
	Page_403,
	Activation,
	ActivationPage,
	Home,
	AccountActivated,
	AccountDeleted,
	OptOut,
	Login,
	ForgotPassword,
	PasswordRequestSent,
	ResetPassword,
	SignUp,
	EnrolledByEmployer,
	SelectService,
	Invitation,
	MandateCreated,

	// logged in pages
	MainPage,
	AccountContributions,
	ContributionsHistory,
	Portfolio,
	UpcomingTransactions,
	YourPension,
	YourPensionConfigs,
	UnderstandLifestyles,
	AutoEnrolment,
	OptOutEmployer,
	InvestmentApproach,
	OtherPensions,
	ExtraContributions,
	Profile,
	Test,
	Options,
	TermsAndConditions,
	CloseAccount,
	LogOut,
	ReferEmployer,
	ReferEmployerComplete,
	PensionNinAndAddress,
	PensionActivated,
	Connections,
	ConfirmYourEmail,
	ConfirmYourMobile,
	SoftwareConnections,
	InformYourSourceOfFunds,
	ConfirmEmploymentStatus,
	IFAConnections,
	AllowHMRCPastEmployersInfo,
	AllowHMRCResidencyStatusInfo,
	ResidencyStatusAccepted,
};

export default Pages;