import React from 'react';

import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';

/**
 * @param index
 * @returns {{"aria-controls": string, style: {background: string}, id: string}}
 */
function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
		style: {
			background: "transparent"
		}
	};
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const NewContributionsTab = (props): JSX.Element => (
	<Grid
		className={"tab-class-holder"}
		spacing={0}
		direction={"column"}
		alignItems={"center"}
		alignContent={"center"}
		container
		item
	>
		<Grid
			spacing={0}
			direction={"column"}
			alignItems={"center"}
			alignContent={"center"}
			container
			item
			className={"tab-container"}
		>
			<div
				style={{
					width: "100%"
				}}
			>
				<AppBar position="static">
					<Tabs
						value={props.tabIndex}
						onChange={props.handleChange}
						aria-label="New contribution tabs choices."
						variant={"fullWidth"}
						TabIndicatorProps={
							{
								style: {
									background: 'white',
								}
							}
						}
					>
						>
						<Tab
							fullWidth
							selected={props.tabIndex === 0}
							label="MONTHLY"
							{...a11yProps(0)}
						/>
						<Tab
							fullWidth
							selected={props.tabIndex === 1}
							label="ONE-OFF"
							{...a11yProps(1)}
						/>
					</Tabs>
				</AppBar>
			</div>
		</Grid>
	</Grid>
);

export default NewContributionsTab;