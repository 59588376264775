import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 *
 * @param token
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const GenerateAutoSignUpTokenPromise = (data = {}): Promise<AxiosResponse<any>> =>
	Request.post(`individual/activation/generate-auto-sign-up-token`, data);

export default GenerateAutoSignUpTokenPromise;