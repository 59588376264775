import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";

/**
 * @param beneficiary_type_id
 * @param id
 * @returns {string}
 * @private
 */
const _getCorrectClassName = (beneficiary_type_id, id): string => (
	beneficiary_type_id === id ?
		"pBlue"
		:
		"pWhite"
);

/**
 * @param beneficiary_types
 * @param beneficiary_type_id
 * @param setBeneficiaryTypeId
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const RenderTypesButtons = (
	{
		beneficiary_types = [],
		beneficiary_type_id = 1,
		setBeneficiaryTypeId = () => {
		},
		t = key => key,
	}
): JSX.Element => (
	<FormControl
		className={"form-beneficiaries-details"}
		fullWidth
	>
		<Grid
			alignItems={"center"}
			alignContent={"center"}
			direction={"row"}
			container
		>
			{beneficiary_types?.map((beneficiary = {
				name: "",
				id: null,
			}) => (
				<Grid
					key={beneficiary?.id}
					lg={12 / beneficiary_types.length}
					md={12 / beneficiary_types.length}
					xs={12 / beneficiary_types.length}
					sm={12 / beneficiary_types.length}
					className={"tab"}
					onClick={() => setBeneficiaryTypeId(beneficiary?.id)}
					item
					textAlign={"center"}
				>
					<p
						className={`${_getCorrectClassName(beneficiary_type_id, beneficiary?.id)} pointer`}
					>
						{beneficiary?.name?.toUpperCase()}
					</p>
				</Grid>
			))}
		</Grid>
	</FormControl>
);

RenderTypesButtons.propTypes = {
	beneficiary_types: PropTypes.array.isRequired,
	beneficiary_type_id: PropTypes.number.isRequired,
};

export default RenderTypesButtons;