import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param data
 * @returns {Promise<AxiosResponse<*>>}
 * @constructor
 */
const CreateRequest = (data = {}): Promise<AxiosResponse<any>> =>
	Request.post(`/individual/beneficiaries/`, data);

export default CreateRequest;