import {resolveReduxState} from "../../utils/Helpers";
import {GET_REAL_PROJECTION_PARAMETERS} from "../../actions/RealProjectionParameters/types";

const initial_state = {
	realProjectionParameters: [],
};

/**
 * @param state
 * @param action
 * @returns {{realProjectionParameters: []}|{valueOf, (): boolean}|boolean}
 */
const RealProjectionParametersStore = (state = initial_state, action = false) => {
	if (action && action.type) {
		const {
			type = false
		} = action;

		if (type === GET_REAL_PROJECTION_PARAMETERS) return resolveReduxState(state, action);
	}


	return state;
};

export default RealProjectionParametersStore;