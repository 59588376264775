import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import ArrowIcon from "../../images/Arrow.svg";


const SecondRowCard = ({
	pageTitle = "",
	title = "",
	subTitle = "",
	cta = "",
	pageLink = "",
	history = () => {},
	icon = "",
}) => {

	return (
		<Grid
			xl={2.4}
			lg={2.8}
			md={3}
			sm={4.5}
			xs={9}
			item
			container
			justifyContent={'center'}
			className={"second-row-cards"}
		>
			<Grid
				lg={12}
				xl={12}
				sm={12}
				xs={12}
				md={12}
				item
				textAlign={"left"}
			>
				<img
					className={"icon"}
					src={icon}
					alt={""}
				/>
			</Grid>
			<Grid
				lg={12}
				xl={12}
				sm={12}
				xs={12}
				md={12}
				item
			>
				<Typography
					className={"card-title"}
					align={"left"}
				>
					{pageTitle}
				</Typography>
			</Grid>
			<Grid
				lg={12}
				xl={12}
				sm={12}
				xs={12}
				md={12}
				item
				textAlign={"center"}
			>
				<Typography
					className={"title"}
					align={"left"}
				>
					{title}
				</Typography>
			</Grid>
			<Grid
				lg={12}
				xl={12}
				sm={12}
				xs={12}
				md={12}
				item
				textAlign={"center"}
			>
				<Typography
					className={"sub-title"}
					align={"left"}
				>
					{subTitle}
				</Typography>
			</Grid>
			<Grid
				lg={12}
				xl={12}
				sm={12}
				xs={12}
				md={12}
				item
				container
				alignItems={"center"}
			>
				<Typography
					className={"cta"}
					align={"left"}
					onClick={() => history(pageLink)}
				>
					{cta}
					<img
						className={"icon-arrow"}
						src={ArrowIcon}
						alt={""}
					/>
				</Typography>
			</Grid>
		</Grid>
	);
};

export default SecondRowCard;