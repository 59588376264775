import React, {useEffect} from "react";

import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import Header from "../components/YourPension/Header";
import Wrapper from "../layout/Logged/Wrapper";
import {returnUser, modifyIfaAccess} from "../actions/Profile";
import {connect} from "react-redux";
import DataSharingConnectionsCardList from "../components/IFAConnections/DataSharingConnectionsCardList";
import HeaderInfo from "../components/IFAConnections/HeaderInfo";
import Loading from "../components/Loading";
import {useTranslation} from "react-i18next";

/**
 * @param loading
 * @param profile_data
 * @param profile_loading
 * @param returnUser
 * @param modifyIfaAccess
 * @param history
 * @returns {JSX.Element}
 * @constructor
 */
const IFAConnections = (
	{
		loading = false,
		profile_data = [],
		profile_loading = false,
		returnUser = () => {
		},
		modifyIfaAccess = () => {
		},
		history = () => {
		}
	}
): JSX.Element => {
	const {t} = useTranslation();

	useEffect(() => {
		returnUser()
	}, [returnUser]);

	if (loading || profile_loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<Wrapper t={t}>
			<Slide in={true} direction={"left"}>
				<div>
					<Grid
						direction={"column"}
						alignItems={"center"}
						alignContent={"center"}
						id={"investment-approach-wrapper"}
						container
						item
					>
						<Header
							history={history}
							title={t("individual.ifa_connections")}
							backLink={"/main-page"}
							showLeftArrow
						/>
						<HeaderInfo t={t}/>
						<DataSharingConnectionsCardList
							ifa_connections={profile_data?.ifa_connections ?? []}
							modifyIfaAccess={modifyIfaAccess}
							t={t}
						/>
					</Grid>
				</div>
			</Slide>
		</Wrapper>
	);
}


const mapStateToProps = state => {
	const {
		Profile = [],
		Connections = [],
	} = state;

	return {
		...Profile,
		...Connections,
	};
};

/**
 * @param dispatch
 * @returns {{get: (function(): *)}}
 */
const mapDispatchToProps = dispatch => ({
	returnUser: () => dispatch(returnUser()),
	modifyIfaAccess: (data: any = []) => dispatch(modifyIfaAccess(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IFAConnections);