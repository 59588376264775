import AbstractServiceRequestsClass from "../../classes/AbstractServiceRequestsClass";

import Request from "./../../request";
import {AxiosResponse} from "axios";

export default class StepService extends AbstractServiceRequestsClass {
	constructor() {
		super(Request.Step);
	}

	/**
	 * @param data
	 * @returns {Promise<never>|Promise<AxiosResponse<*>>|*}
	 */
	proceed(data = false): Promise<never> | Promise<AxiosResponse<*>> | * {
		if (this.request && data) {
			return this.request.Proceed(data);
		}

		return Promise.reject("No request and data provided.");
	}

	/**
	 * @param data
	 * @returns {Promise<never>|Promise<AxiosResponse<*>>|*}
	 */
	updatePersonalInfo(data = false): Promise<never> | Promise<AxiosResponse<*>> | * {
		if (this.request && data) {
			return this.request.UpdatePersonalInfo(data);
		}

		return Promise.reject("No request and data provided.");
	}

	/**
	 * @param data
	 * @returns {Promise<never>|Promise<AxiosResponse<*>>|*}
	 */
	retrieveTermsRead(data = false): Promise<never> | Promise<AxiosResponse<*>> | * {
		if (this.request && data) {
			return this.request.ReadTermsAndConditions(data);
		}

		return Promise.reject("No request and data provided.");
	}

	/**
	 * @param data
	 * @returns {Promise<never>|Promise<AxiosResponse<T>>|*}
	 */
	agreeTermsAndConditions(data = false): Promise<never> | Promise<AxiosResponse<T>> | * {
		if (this.request && data) {
			return this.request.AgreeTermsAndConditions(data);
		}

		return Promise.reject("No request and data provided.");
	}

	/**
	 * @param token
	 * @param data
	 * @returns {Promise<never>|Promise<AxiosResponse<*>>|*}
	 */
	confirmMobileCode(token: string = "", data: any = {}): Promise<never> | Promise<AxiosResponse<*>> | * {
		if (this.request && token && data) {
			return this.request.ConfirmMobileCode(token, data);
		}

		return Promise.reject("No request, token and data provided.");
	}
}