import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param token
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const ConfirmMobileCodePromise = (token: string = "", data = false): Promise<AxiosResponse<any>> =>
	Request.post(`/individual/steps/${token}/confirm-mobile-code`, data);

export default ConfirmMobileCodePromise;