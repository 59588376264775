import React from 'react';
import Slide from "@mui/material/Slide";

/**
 * @param props
 * @returns {string|*}
 */
function returnEmployerName(props = {
	papdis: [],
}): string {

	if (props) {
		const {
			individual = {
				papdis: [],
			}
		} = props;

		if (individual) {
			const {papdis = []} = individual;

			if (papdis.length > 0) {
				const {
					employer = false
				} = papdis[0];
				if (employer) {
					return employer.title;
				}
			}
		}
	}

	return "";
}

/**
 * @param props
 * @returns {string}
 */
function returnIndividualName(props = {
	individual: {
		forename: "",
	}
}): string {

	if (props) {
		const {
			individual = false
		} = props;

		if (individual) {
			return ` ${individual.forename}!`;
		}
	}

	return "!";
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const HomeHeader = (props): JSX.Element => (
	<Slide
		direction="up"
		in={true}
		mountOnEnter
		unmountOnExit
	>
		<div id="Section-1" className="section-1 wf-section">
			<div className="first-cta">
				<h1 className="heading">Welcome to Collegia, {returnIndividualName(props)}!</h1>
				<p className="paragraph"><strong>{returnEmployerName(props)}</strong> has enrolled you into the
					Collegia pension. First you have to activate your account.</p>
				<button
					onClick={() => props.history(`/activation/setup/${props.token ?? ""}`)}
					className="button w-button"
				>
					LET&#x27;S START
				</button>
			</div>
		</div>
	</Slide>
);

export default HomeHeader;