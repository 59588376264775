import AbstractServiceRequestsClass from "../../classes/AbstractServiceRequestsClass";

import Request from '../../request';
import {AxiosResponse} from "axios";

export default class EmployersService extends AbstractServiceRequestsClass {
	constructor() {
		super(Request.Employers);
	}

	/**
	 * @param code
	 * @returns {null|Promise<AxiosResponse<any>>}
	 */
	employerInfo(code: string = ""): null | Promise<AxiosResponse<any>> {
		if (code !== "" && this.request) {
			return this.request.GetEmployerInfo(code);
		}

		return null;
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|Promise<AxiosResponse<T>>|null}
	 */
	optOut(data: any = false): Promise<AxiosResponse<*>> | Promise<AxiosResponse<T>> | null {
		if (this.request && data) {
			return this.request.OptOut(data ?? false);
		}

		return null;
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	createLead(data: any = false): Promise<AxiosResponse<*>> | null {
		if (this.request && data) {
			return this.request.CreateLead(data ?? false);
		}

		return null;
	}
}