// importing the actions.
import {resolveReduxState} from "../../utils/Helpers";
import {GET_EMPLOYMENT_STATUS} from "../../actions/EmploymentStatus/types";


const initial_state = {
	employment_status_loading: false,
	employment_status: [],
}

const EmploymentStatusStore = (
	state = initial_state,
	action = {
		type: "",
	}
) => {
	switch (action?.type) {

		case GET_EMPLOYMENT_STATUS: return resolveReduxState(state, action);

		default: return state;
	}
};

export default EmploymentStatusStore;