import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ShariahIcon from "../../images/investment-approaches/shariah-icon.svg";
import StickerIcon from "../../images/investment-approaches/sticker-strategy.svg";

import React from "react";

/**
 * @param currentStrategy
 * @param name
 * @param updateInvestmentApproach
 * @param investment
 * @param hasIfa
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const ShariahDetails = (
	{
		currentStrategy = "",
		name = "",
		updateInvestmentApproach = () => {
		},
		investment = "",
		hasIfa = false,
		t = key => key,
	}
): JSX.Element => (
	<Grid
		container
		item
		justifyContent={"center"}
		id={"shariah-details"}
	>
		<Grid
			id={'upper-component'}
			xl={9.3}
			lg={9.3}
			md={10}
			sm={11}
			xs={11}
			item
		>
			<Grid>
				<img
					src={ShariahIcon}
					alt={"ShariahIcon"}
					className={"icon"}
				/>
				{
					currentStrategy === name && (
						<img
							src={StickerIcon}
							alt={"StickerIcon"}
							className={"sticker"}
						/>
					)
				}
			</Grid>
			<Grid>
				<Typography
					className={"title"}
				>
					{t("individual.investment_approaches.details.seeks_to", {
						strategy: name,
					})}
				</Typography>
			</Grid>
			<Grid
				xl={7}
				lg={9}
				md={10}
				sm={10}
				xs={10}
				item
			>
				<Typography
					className={"sub-title"}
				>
					{t("individual.individual.investment_approaches.details.islamic_finance.information")}
				</Typography>
			</Grid>
			{
				(currentStrategy !== name && hasIfa === false) && (
					<Grid
						item
						xl={3.5}
						lg={3.5}
						md={5}
						sm={6}
						xs={12}
						textAlign={"center"}
						onClick={() => updateInvestmentApproach({
							individuals: {
								investment_approach_id: investment.id,
							}
						})}
					>
						<Typography
							className={"button"}
						>
							{t("individual.investment_approaches.details.select_this_strategy")}
						</Typography>
					</Grid>
				)
			}
		</Grid>
		<Grid
			id={'body'}
			xl={9.3}
			lg={9.3}
			md={10}
			sm={11}
			xs={11}
			item
			container
			justifyContent={"center"}
		>
			<Grid
				xl={10}
				lg={10}
				md={10}
				sm={11}
				xs={11}
				item
			>
				<Grid>
					<Typography
						className={"title"}
					>
						{t("individual.investment_approaches.details.islamic_finance.sharia_philosophy.title")}
					</Typography>
				</Grid>
				<Grid
					xl={10}
					lg={10}
					md={12}
					sm={12}
					xs={12}
					item
				>
					<Typography
						className={"sub-title"}
					>
						{t("individual.investment_approaches.details.islamic_finance.sharia_philosophy.information")}
					</Typography>
				</Grid>
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
					container
				>
					<Grid
						xl={6}
						lg={6}
						md={6}
						sm={12}
						xs={12}
						item
					>
						<Typography
							className={"icons"}
							textAlign={"left"}
						>
							{t("individual.investment_approaches.details.islamic_finance.sharia_philosophy.riba")}
							<Typography
								className={"sub details"}
								textAlign={"left"}
							>
								{t("individual.investment_approaches.details.islamic_finance.sharia_philosophy.riba_description")}
							</Typography>
						</Typography>

					</Grid>
					<Grid
						xl={6}
						lg={6}
						md={6}
						sm={12}
						xs={12}
						item
					>
						<Typography
							className={"icons"}
							textAlign={"left"}
						>
							{t("individual.investment_approaches.details.islamic_finance.sharia_philosophy.maisir")}
							<Typography
								className={"sub details"}
								textAlign={"left"}
							>
								{t("individual.investment_approaches.details.islamic_finance.sharia_philosophy.maisir_description")}
							</Typography>
						</Typography>
					</Grid>
					<Grid
						xl={6}
						lg={6}
						md={6}
						sm={12}
						xs={12}
						item
					>
						<Typography
							className={"icons"}
							textAlign={"left"}
						>
							{t("individual.investment_approaches.details.islamic_finance.sharia_philosophy.gharar_title")}
							<Typography
								className={"sub details"}
								textAlign={"left"}
							>
								{t("individual.investment_approaches.details.islamic_finance.sharia_philosophy.gharar_description")}
							</Typography>
						</Typography>
					</Grid>
					<Grid
						xl={6}
						lg={6}
						md={6}
						sm={12}
						xs={12}
						item
					>
						<Typography
							className={"icons"}
							textAlign={"left"}
						>
							{t("individual.investment_approaches.details.islamic_finance.sharia_philosophy.ethical_title")}
							<Typography
								className={"sub details"}
								textAlign={"left"}
							>
								{t("individual.investment_approaches.details.islamic_finance.sharia_philosophy.ethical_description")}
							</Typography>
						</Typography>
					</Grid>
					<Grid
						xl={6}
						lg={6}
						md={6}
						sm={12}
						xs={12}
						item
					>
						<Typography
							className={"icons no-padding-bottom"}
							textAlign={"left"}
							dangerouslySetInnerHTML={{
								__html: `${t("individual.investment_approaches.details.islamic_finance.sharia_philosophy.enhance.title")}`
							}}
						/>
						<Typography
							className={"sub details"}
							textAlign={"left"}
						>
							{t("individual.investment_approaches.details.islamic_finance.sharia_philosophy.enhance.description")}
						</Typography>
					</Grid>
					<Grid
						xl={6}
						lg={6}
						md={6}
						sm={12}
						xs={12}
						item
					>
						<Typography
							className={"icons"}
							textAlign={"left"}
						>
							{t("individual.investment_approaches.details.islamic_finance.sharia_philosophy.asset.title")}
							<Typography
								className={"sub details"}
								textAlign={"left"}
							>
								{t("individual.investment_approaches.details.islamic_finance.sharia_philosophy.asset.description")}
							</Typography>
						</Typography>
					</Grid>
				</Grid>
				{
					(currentStrategy !== name && hasIfa === false) && (
						<Grid
							container
							item
							justifyContent={"center"}
						>
							<Grid
								item
								xl={3.5}
								lg={3.5}
								md={5}
								sm={6}
								xs={12}
								textAlign={"center"}
								className={'button-grid'}
								onClick={() => updateInvestmentApproach({
									individuals: {
										investment_approach_id: investment.id,
									}
								})}
							>
								<Typography
									className={"button"}
								>
									{t("individual.investment_approaches.details.select_this_strategy")}
								</Typography>
							</Grid>
						</Grid>
					)
				}
			</Grid>
		</Grid>
	</Grid>
);

export default ShariahDetails;