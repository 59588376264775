import {connect} from "react-redux";
import Wrapper from "../layout/Wrapper";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import Logo from "../images/logo-icon.svg";
import MinimalFooter from "../layout/MinimalFooter";
import EmployerCard from "./Services/EmployerCard";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import AdvisorsCard from "./Services/AdvisorsCard";
import {useNavigate} from "react-router-dom";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const SelectService = (): JSX.Element => {
	const history = useNavigate();

	return (
		<Wrapper showSandwich={false} history={history}>
			<Slide direction="up" in={true} mountOnEnter unmountOnExit>
				<div id={"select-service-wrapper-no-logged"}>
					<Grid
						xs={12}
						lg={12}
						md={12}
						sm={12}
						alignItems={"center"}
						alignContent={"center"}
						direction={"column"}
						id={"header"}
						container
						item
						onClick={() => history("/login")}
					>
						<img
							src={Logo}
							alt={"Welcome Collegia"}
						/>
					</Grid>

					<Grid
						id={"select-service-container-no-logged"}
						xs={12}
						lg={12}
						md={12}
						alignContent={"center"}
						alignItems={"center"}
						direction={"row"}
						item
						container
					>
						<Grid
							xs={12}
							md={12}
							lg={12}
							item
							className={"first-title"}
						>
							<Typography align={"center"}>
								Collegia for companies
							</Typography>
						</Grid>

						<Grid
							xs={12}
							md={12}
							lg={12}
							item
							className={"login-form-control-2 second-title"}
						>
							<Typography align={"center"}>
								Select the service that's right for your company
							</Typography>
						</Grid>

						<Grid
							sm={12}
							xs={12}
							md={12}
							lg={12}
							direction={"row"}
							alignItems={"center"}
							alignContent={"center"}
							id={"select-service-wrapper"}
							item
							container
							spacing={1}
						>
							<Grid
								sm={12}
								xs={12}
								md={6}
								lg={6}
								direction={"column"}
								alignItems={"center"}
								alignContent={"center"}
								item
								container
							>
								<EmployerCard
									history={history}
								/>
							</Grid>
							<Grid
								sm={12}
								xs={12}
								md={6}
								lg={6}
								direction={"column"}
								alignItems={"center"}
								alignContent={"center"}
								item
								container
							>
								<AdvisorsCard
									history={history}
								/>
							</Grid>
						</Grid>

						<Grid
							xs={12}
							md={12}
							lg={12}
							item
							className={"login-form-control-2 about"}
						>
							<Typography align={"center"}>
								Want to know more about Collegia for companies?
							</Typography>
						</Grid>

						<Grid
							xs={12}
							md={12}
							lg={12}
							item
							className={"login-form-control-2 second-title"}
						>

							<FormControl
								className={"login-form-control-2 more-details"}
								fullWidth
							>
                                <span
	                                onClick={() => history('/forgot-password')}
                                >
                                    See more details
                                </span>
							</FormControl>
						</Grid>
					</Grid>
					<MinimalFooter/>
				</div>
			</Slide>
		</Wrapper>
	);
}

/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => {
	const {
		selectservice,
	} = state;

	return {
		...selectservice,
	}
};

/**
 * @param dispatch
 * @returns {{checkEmailAddress: (function(*[]=): *)}}
 */
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps ?? {}, mapDispatchToProps ?? {})(SelectService);