import React from "react";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";


/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const EmailForm = ({
	code = "",
	errorMessage = "",
	profile_data = {
		id: null,
		email: "",
	},
	setCode = () => {
	},
	setRequestMailChange = () => {
	},
	setFlagToBack = () => {
	},
	resendCode = () => {
	},
	confirmCode = () => {
	},
	t = key => key,
}): JSX.Element => (
	<Grid
		xl={12}
		lg={12}
		md={12}
		container
		item
		direction={"row"}
		justifyContent={"center"}
		id={"change-email-wrapper"}
	>
		<Grid
			xl={9.5}
			lg={9.5}
			md={12}
			sm={12}
			xs={12}
			item
			direction={"row"}
			container
			justifyContent={"center"}
			className={"grid"}
		>
			<Grid
				xl={6.55}
				lg={7.2}
				md={8}
				sm={12}
				xs={12}
				item
				container
				direction={"row"}
				display={"inline-block"}
				textAlign={"-webkit-center"}
			>
				<Typography
					className={"title"}
				>
					{t("individual.my_account.login_details.verify_account.title")}
				</Typography>
				<Typography
					className={"subtitle"}
				>
					{t("individual.my_account.login_details.verify_account.security_reasons_message")}
				</Typography>
				<Grid
					xl={8.2}
					lg={8.2}
					md={8.2}
					sm={6}
					xs={12}
					item
					container
				>
					<Typography
						className={"label-code"}
					>
						{t("individual.code_sent_by_email")}
					</Typography>
					<TextField
						id={"code"}
						className={"textfield2"}
						value={code ?? ""}
						onChange={(e) => setCode(e.target.value)}
						fullWidth
					/>
					<Fade in={errorMessage !== "" ?? false}>
						<small className={'collegia-text-danger default-error'}>
							{errorMessage}
						</small>
					</Fade>
					<Typography
						className={"label-send-again"}
					>
						{t("individual.did_not_receive_the_code")}
						<br/>
						<span
							onClick={() =>
								resendCode({
									activation_codes: {
										individual_id: profile_data?.id,
									}
								})
							}
						>
							{t("individual.send_again")}
							&nbsp;
						</span>
						Or
						<span
							onClick={() => {
								setRequestMailChange(true);
								setFlagToBack(false);
							}}
						>
							&nbsp;
							{t("individual.change_email")}
						</span>
					</Typography>
					<Button
						fullWidth
						onClick={() =>
							confirmCode({
								activation_codes: {
									code: code,
									autoSignUp: true,
								}
							})
						}
						disabled={!code}
					>
						{t("individual.login.continue_button")}
					</Button>
				</Grid>
			</Grid>
		</Grid>
	</Grid>
);

export default EmailForm;