import Request from "../../utils/Request";
import PropTypes from "prop-types";

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
const FindBeneficiariesPromise = (id, relationship = []) => {
	if (!id) {
		return Promise.reject("No id provided");
	}
	let promise = Request.get(`/individual/beneficiaries/${id}`);

	if (relationship) {
		promise = Request.get(`/individual/beneficiaries/${id}?${Request.parseParams(relationship)}`);
	}

	return promise;
}

FindBeneficiariesPromise.propTypes = {
	id: PropTypes.any.isRequired,
};


export default FindBeneficiariesPromise;