import {useEffect, useState} from 'react';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import 'react-phone-input-2/lib/material.css';
import {confirmMobileCode, proceed, update} from "../../../actions/Steps";
import {connect} from 'react-redux';
import MobileInput from "./MobileNumber/MobileInput";
import {sentMobileCode} from "../../../actions/ActivationToken";
import Loading from "../../Loading";
import React from "react";
import {useTranslation} from "react-i18next";
import TextField from "@mui/material/TextField";
import Fade from "@mui/material/Fade";
import {isValidPhoneNumber} from "libphonenumber-js";

/**
 * @param value
 * @param code
 * @param setCode
 * @returns {JSX.Element|*[]}
 * @private
 */
const onlyNumber = (
	value: string = "",
	code: string = "",
	setCode: Function = () => {
	}
) => {
	if (!isNaN(value)) {
		setCode(value);
	} else {
		setCode(code);
	}
}

/**
 * @param internal
 * @param individual
 * @param mobileNumber
 * @param regions
 * @param stepErrorText
 * @param code
 * @param setMobileNumber
 * @param setStepErrorText
 * @param setCode
 * @param setInternal
 * @param t
 * @param token
 * @param sentMobileCode
 * @param confirmMobileCode
 * @returns {JSX.Element}
 * @private
 */
const _renderInternal = (
	internal: number = 0,
	individual = {
		country_code: "GB",
	},
	mobileNumber = "",
	regions = [],
	stepErrorText = "",
	code = "",
	setMobileNumber = () => {
	},
	setStepErrorText = () => {
	},
	setCode = () => {
	},
	setInternal = () => {
	},
	t=key=>key,
	token = "",
	sentMobileCode = () => {},
	confirmMobileCode = () => {},
): JSX.Element => (
	internal !== 0 ?
		<Grid
			xl={12}
			lg={12}
			md={12}
			container
			item
			direction={"row"}
			justifyContent={"center"}
			id={"change-email-wrapper"}
		>
			<Grid
				xl={9.5}
				lg={9.5}
				md={12}
				sm={12}
				xs={12}
				item
				direction={"row"}
				container
				justifyContent={"center"}
				className={"grid"}
			>
				<Grid
					xl={6.55}
					lg={7.2}
					md={8}
					sm={12}
					xs={12}
					item
					container
					direction={"row"}
					display={"inline-block"}
					textAlign={"-webkit-center"}
				>
					<Typography
						className={"title"}
					>
						{t("individual.my_account.login_details.verify_account.title")}
					</Typography>
					<Typography
						className={"subtitle"}
						dangerouslySetInnerHTML={{
							__html: t("individual.confirm_your_mobile.form.security_reasons", {
								complement_message: `<small>${t("individual.mobile_number")}: 
									${mobileNumber ?? ""}</small>`
							})
						}}
					/>
					<Grid
						xl={8.2}
						lg={8.2}
						md={8.2}
						sm={6}
						xs={12}
						item
						container
					>
						<Typography
							className={"label-code"}
						>
							{t("individual.code_sent_by_sms")}
						</Typography>
						<TextField
							id={"code"}
							placeholder={"code-sent-by-sms"}
							className={"textfield2 color-extra"}
							onChange={event => onlyNumber(event.target.value, code, setCode)}
							value={code}
							fullWidth
						/>
						<Fade in={stepErrorText !== "" ?? false}>
							<small className={'collegia-text-danger default-error'}>
								{stepErrorText}
							</small>
						</Fade>
						<Typography
							className={"label-send-again"}
						>
							{t("individual.did_not_receive_the_code")}
							<br/>
							<span
								onClick={() =>
									setInternal(0)
								}
							>
								{t("individual.change_my_mobile_number")}
							</span>
						</Typography>
						<Button
							fullWidth
							onClick={() =>
								confirmMobileCode(token, {
									steps: {
										code,
									}
								})
							}
							disabled={!code || _isSubmitEnabled(internal, mobileNumber, code)}
						>
							{t("individual.login.continue_button")}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
		:
		<Grid
			xl={12}
			lg={12}
			md={12}
			container
			item
			direction={"row"}
			justifyContent={"center"}
			id={"change-email-wrapper"}
		>
			<Grid
				xl={9.5}
				lg={9.5}
				md={12}
				sm={12}
				xs={12}
				item
				direction={"row"}
				container
				justifyContent={"center"}
				className={"grid"}
			>
				<Grid
					xl={6.55}
					lg={7.2}
					md={8}
					sm={12}
					xs={12}
					item
					container
					direction={"row"}
					display={"inline-block"}
					textAlign={"-webkit-center"}
				>
					<Typography
						className={"title"}
					>
						{t("individual.my_account.login_details.verify_account.title")}
					</Typography>
					<Typography
						className={"subtitle"}
						dangerouslySetInnerHTML={{
							__html: t("individual.confirm_your_mobile.form.title", {
								complement_message:
									(mobileNumber.slice(0, 3) === "+44" || mobileNumber === "") ?
										`<br/>${t("individual.confirm_your_mobile.form.uk_warning")}` : ""
							})
						}}
					/>
					<Grid
						xl={8.2}
						lg={8.2}
						md={8.2}
						sm={6}
						xs={12}
						item
						container
					>
						<Typography
							className={"label-code"}
						>
							{t("individual.mobile_number")}
						</Typography>
						<MobileInput
							individual={individual}
							mobileNumber={mobileNumber}
							stepErrorText={stepErrorText}
							regions={regions ?? ['europe', "asia"]}
							setMobileNumber={setMobileNumber}
							setStepErrorText={setStepErrorText}
							t={t}
							color={true}
						/>
						<Fade in={stepErrorText !== "" ?? false}>
							<small className={'collegia-text-danger default-error'}>
								{stepErrorText}
							</small>
						</Fade>
						<Button
							fullWidth
							onClick={() =>
								_proceedNextStepMobile(
									token,
									mobileNumber,
									setStepErrorText,
									sentMobileCode,
								)
							}
							disabled={!isValidPhoneNumber(mobileNumber) || _isSubmitEnabled(internal, mobileNumber, code)}
							sx={{
								background: "#000000",
							}}
						>
							{t("individual.login.continue_button")}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
);

/**
 * @param token
 * @param mobileNumber
 * @param setStepErrorText
 * @param sentMobileCode
 * @private
 */
const _proceedNextStepMobile = (
	token: string = "",
	mobileNumber: string = "",
	setStepErrorText: Function = () => {
	},
	sentMobileCode: Function = () => {
	},
) => {
	if (mobileNumber && token) {
		setStepErrorText("");

		const activation_tokens = {
			mobile_number: mobileNumber,
		};

		sentMobileCode(token, {activation_tokens});
	}
}

/**
 * @param internal
 * @param mobileNumber
 * @param code
 * @private
 */
const _isSubmitEnabled = (internal: number = 0, mobileNumber: string = "", code: string = "") =>
	internal === 0 ?
		!mobileNumber
		:
		!code


/**
 * @param step_loading
 * @param individual
 * @param token
 * @param stepErrorText
 * @param proceedMobile
 * @param sentMobileCode
 * @param confirmMobileCode
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const MobileNumber = (
	{
		step_loading = false,
		individual = [],
		token = "",
		stepErrorText = "",
		proceedMobile = false,
		sentMobileCode = () => {
		},
		confirmMobileCode = () => {
		},
		language = "GB",
	}
): JSX.Element => {
	const [mobileNumber, setMobileNumber] = useState(""),
		[ErrorText, setErrorText] = useState(""),
		[internal, setInternal] = useState(0),
		[code, setCode] = useState(""),
		[regions, setRegions] = useState([]),
		{t, i18n} = useTranslation();

	useEffect(() => {
		i18n.changeLanguage(language);
	}, [
		language,
		i18n,
	]);

	useEffect(() => {
		if (process.env.REACT_APP_ENV === "local" || process.env.REACT_APP_ENV === "development") {
			setRegions(["europe", "south-america", "asia"]);
		} else {
			setRegions(["europe", "asia"]);
		}
		if (stepErrorText) {
			setErrorText(stepErrorText);
		}
		if (proceedMobile) {
			setInternal(1);
		}
	}, [stepErrorText, proceedMobile]);

	if (step_loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		_renderInternal(
			internal,
			individual,
			mobileNumber,
			regions,
			ErrorText,
			code,
			setMobileNumber,
			setErrorText,
			setCode,
			setInternal,
			t,
			token,
			sentMobileCode,
			confirmMobileCode,

		)
	);
}


/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => {
	const {
		Steps,
	} = state;

	return {
		...Steps
	};
};

/**
 * @param dispatch
 * @returns {{proceed: (function(*=): *), update: (function(*=, *=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	proceed: (data = false) => dispatch(proceed(data)),
	update: (id = false, data = false) => dispatch(update(id, data)),
	sentMobileCode: (token: string = "", data: any = {}) => dispatch(sentMobileCode(token, data)),
	confirmMobileCode: (token: string = "", data: any = {}) => dispatch(confirmMobileCode(token, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileNumber);