import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Card from '@mui/material/Card';

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ExpectedRetirementAge = (props = {
	t: key => key,
}): JSX.Element => (
	<Card
		id={"expected-retirement-age-card"}
		onClick={() => props.history("/adjust-retirement-age")}
	>
		<CardHeader
			action={
				<IconButton
					aria-label="settings"
					disableTouchRipple
					disableFocusRipple
					disableRipple
				>
					<KeyboardArrowRightIcon/>
				</IconButton>
			}
			title={props.t("individual.your_pension.expected_retirement_age")}
			subheader={<strong>{props.profile_data.expected_retirement_age}</strong>}
		/>
	</Card>
);

export default ExpectedRetirementAge;