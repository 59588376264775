import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const UpdatePersonalInfoPromise = (data = false): Promise<AxiosResponse<any>> => (
	Request.post(`/individual/activation/update-personal-info`, data)
);

export default UpdatePersonalInfoPromise;