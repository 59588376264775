import {NEXT_STEP_MODAL, READ_TERMS_AND_CONDITIONS} from "./types";
import StepService from "../../services/StepService";
import TermsAndConditionsService from "../../services/TermsAndConditions";

/**
 * @param nextStep
 * @returns {function(...[*]=)}
 */
export const proceedNextSteep = (nextStep: number = 0) => dispatch => {
	if (!isNaN(nextStep)) {
		dispatch({
			type: NEXT_STEP_MODAL,
			activeStep: nextStep + 1,
		});
	}
};

/**
 * @param data
 * @returns {function(...[*]=)}
 */
export const retrieveReadTerms = (data = false) => dispatch => {
	if (data) {

		dispatch({
			type: READ_TERMS_AND_CONDITIONS,
			step_loading: true,
		});

		new StepService()
			.retrieveTermsRead(data)
			.then(response => {
				dispatch({
					type: READ_TERMS_AND_CONDITIONS,
					step_loading: false,
					readTerms: response.data.data
				});
			})
			.catch(error => {
				console.error(error);

				dispatch({
					type: READ_TERMS_AND_CONDITIONS,
					step_loading: true,
				});
			});
	}
};

/**
 * @param data
 * @returns {function(...[*]=)}
 */
export const addReadTermsAndConditions = (data = false) => dispatch => {
	if (data) {
		new TermsAndConditionsService()
			.insert(data)
			.then(() => new StepService()
				.retrieveTermsRead({
					terms_and_conditions: {
						individual_id: data.terms_and_conditions.individual_id
					}
				})
				.then(response =>
					dispatch({
						type: READ_TERMS_AND_CONDITIONS,
						step_loading: false,
						readTerms: response.data.data
					})
				)
				.catch(error => console.error(error))
			)
			.catch(error => console.error(error));
	}
};