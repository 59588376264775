import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";

/**
 * @param search_results
 * @param search_loading
 * @param setSearchCompany
 * @param setReferManually
 * @param setEmployerTrigger
 * @param setSelectedEmployer
 * @param renderCompanyNumber
 * @param renderCompanyName
 * @param renderIncorporatedInfo
 * @param renderAddressSnippet
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const CompaniesList = (
	{
		search_results = [],
		search_loading = false,
		setSearchCompany = () => {
		},
		setReferManually = () => {
		},
		setEmployerTrigger = () => {
		},
		setSelectedEmployer = () => {
		},
		renderCompanyNumber = () => {
		},
		renderCompanyName = () => {
		},
		renderIncorporatedInfo = () => {
		},
		renderAddressSnippet = () => {
		},
		t = key => key,
	}
): JSX.Element => (
	<Autocomplete
		fullWidth
		options={search_results ?? []}
		getOptionLabel={(option) => option.title}
		key={search_results?.company_number}
		onChange={(event, value) => {
			setEmployerTrigger(true);
			setSelectedEmployer(value);
		}}
		className={"auto-complete-style"}
		renderOption={(props, option) => (
			<Card {...props} id={"company-style"}>
				<CardContent>
					<Typography className={"company-title"}>
						{renderCompanyName(option, t)}
					</Typography>
					<Typography className={"company-subtitle"}>
						{renderCompanyNumber(option, t) + " - " + renderIncorporatedInfo(option, t)}
					</Typography>
					<Typography className={"company-subtitle"}>
						{renderAddressSnippet(option)}
					</Typography>
				</CardContent>
			</Card>
		)}
		renderInput={(params) =>
			<TextField
				{...params}
				className={"text-field-style"}
				placeholder={t("individual.refer_employer.search_company_input")}
				variant={"outlined"}
				value={search_results?.title}
				onChange={(event) => setSearchCompany(event.target.value)}
				onFocus={() => setReferManually(false)}
				InputProps={{
					...params.InputProps,
					startAdornment: (
						<InputAdornment position={"start"}>
							<SearchIcon/>
						</InputAdornment>
					),
					endAdornment: (
						<InputAdornment position={"end"}>
							{search_loading && <CircularProgress/>}
						</InputAdornment>
					)
				}}
			/>
		}
		disableClearable
	/>
);

export default CompaniesList;