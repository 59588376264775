import React from "react";

import {connect} from "react-redux";

import {returnUser, update} from "../../actions/Profile";

import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import Typography from "@mui/material/Typography";
import TaxReliefEligibleInformation from "../../components/TaxReliefEligibleInformation";
import Wrapper from "../../layout/Logged/Wrapper";
import Loading from "../../components/Loading";
import {useTranslation} from "react-i18next";
import {capitalizeFirstLetter} from "../../utils/Helpers";
import MyAccountLeftMenu from "../../components/MyAccountLeftMenu";
import {useNavigate} from "react-router-dom";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const TaxRelief = (props) => {
	const {
			profile_data = [],
			update = () => {
			},
			loading = false,
		} = props,
		{t} = useTranslation(),
		history = useNavigate();

	if (loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<Wrapper
			hideFooter={true}
			history={history}
			textHeader={t("individual.my_account.menu.tax_relief")}
			t={t}
		>
			<Slide in={true} direction={"left"}>
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
					direction={"row"}
					container
					className={"grid"}
				>
					<MyAccountLeftMenu
						history={history}
						currentPage={"TaxRelief"}
						t={t}
						capitalizeFirstLetter={capitalizeFirstLetter}
						myHeight={window.innerHeight}
					/>
					<Grid
						xl={9.5}
						lg={9.5}
						md={12}
						sm={12}
						xs={12}
						direction={"row"}
						justifyContent={"center"}
						maxHeight={"750px"}
						id={"tax-relief-container"}
						container
						item
					>
						<Grid
							xl={9.5}
							lg={9.5}
							md={12}
							sm={12}
							xs={12}
							direction={"column"}
							justifyContent={"center"}
							alignSelf={"center"}
							alignContent={"center"}
							item
							container
						>
							<Typography className={"title"} variant={"h2"}>
								{t("individual.my_account.tax_relief.to_be_eligible")}
							</Typography>
							<Typography variant={"p"}>
								When you make personal contributions to your Collegia pension, we’ll add your 25% tax top up automatically. Please note that we’re only able to apply for the standard basic rate tax relief. If you’re a higher-rate tax payer, you’ll need to claim any additional tax relief yourself through your self-assessment tax return.
							</Typography>
						</Grid>
						<Grid
							xl={9}
							lg={9}
							md={9}
							sm={9}
							xs={11}
							item
							justifyContent={"center"}
							direction={"row"}
							container
						>
							<TaxReliefEligibleInformation
								t={t}
								profile_data={profile_data}
								update={update}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Slide>
		</Wrapper>
	);
};

const mapStateToProps = state => state.Profile;

const mapDispatchToProps = dispatch => ({
	update: (id, data) => dispatch(update(id, data, false)),
	returnUser: dispatch(returnUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaxRelief);