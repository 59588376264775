import AppBar from "./Header/AppBar";

/**
 * @stateless
 * @param props
 * @returns {*}
 */
const Header = props => (
	<AppBar {...props} />
);

export default Header;