import Request from "../../utils/Request";
import PropTypes from "prop-types";

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
const UpdateBeneficiariesPromise = (id, data) => {
	if (!id || !data) {
		return Promise.reject("No id or data provided");
	}

	return Request.put(`/individual/beneficiaries/${id}` ,data);
}

UpdateBeneficiariesPromise.propTypes = {
	id: PropTypes.any.isRequired,
	data: PropTypes.any.isRequired,
};


export default UpdateBeneficiariesPromise;