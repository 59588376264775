import React from "react";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import UpcomingContributionModalList from "./UpcomingContributionModalList";
import UkTopUpContributionImage from "../../images/UkTopUpContributionHistory.svg";
import Divider from "@mui/material/Divider";


/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const UpcomingContributionModal = (props = {
	history: () => {
	},
	handleModal: () => {
	},
	upcomingPayments: {}
}): JSX.Element => {
	return (
		<Modal
			open={props.open ?? false}
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			className={"contribution-history-modal"}
			closeAfterTransition
			onBackdropClick={ () => props?.handleModal() }
		>
			<Grid
				item
				container
				xs={12}
				sm={12}
				lg={12}
				md={12}
				className={"grid-modal-with-borders"}
				minWidth={350}
			>
				<Grid
					item
					container
					xs={12}
					sm={12}
					lg={12}
					md={12}
					className={"style-grid-modal"}
					minWidth={300}
				>
					<Grid
						direction={"row"}
						justifyContent={"space-between"}
						item
						container
						xs={12}
						sm={12}
						lg={12}
						md={12}
					>
						<Grid
							xs={3}
							item
							textAlign={"center"}
							padding={3}
						>
							<Typography
								minWidth={"90px"}
								className={"typography-for-column-data"}
							>
								{ props?.upcomingPayments?.month }
							</Typography>
						</Grid>
						<Grid
							xs={3}
							item
							textAlign={"end"}
							marginBottom={2}
						>
							<Button
								onClick={ ()=> props.handleModal() }
							>
								X
							</Button>
						</Grid>
						{
							props?.upcomingPayments?.upcoming_transaction?.map((d, index = {
								employer_contribution: 0,
								employer_fee_value: 0,
								government_top_up_contribution: 0,
								government_top_up_fee_value: 0,
								individual_contribution: 0,
								individual_fee_value: 0,
								payroll_end_date: "",
								payroll_start_date: "",
								title: ""
							}) => (
								<Grid
									justifyContent={"space-between"}
									item
									container
									xs={12}
									sm={12}
									lg={12}
									md={12}
									key={index}
								>
									<UpcomingContributionModalList
										employerContribution={d?.employer_contribution}
										employerFeeValue={d?.employer_fee_value}
										governmentTopUpContribution={d?.government_top_up_contribution}
										governmentTopUpFeeValue={d?.government_top_up_fee_value}
										individualContribution={d?.individual_contribution}
										individualFeeValue={d?.individual_fee_value}
										payrollStartDate={d?.payroll_start_date}
										payrollEndDate={d?.payroll_end_date}
										employerTitle={d?.title}
										contributionClockImage={UkTopUpContributionImage}
									/>
									{
										index === props?.upcomingPayments?.upcoming_transaction.length - 1 ?
											""
											:
											<Divider
												className={"divider-style"}
											/>
									}
								</Grid>
							))
						}
					</Grid>
				</Grid>
			</Grid>

		</Modal>
	);
};

export default UpcomingContributionModal;