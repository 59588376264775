import React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";


/**
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const Footer = (
	{
		t = key => key,
	}
): JSX.Element => (
	<Grid
		id={"activation-page-footer"}
		alignItems={"center"}
		alignContent={"center"}
		direction={"column"}
		item
		container
	>
		<Grid
			xs={12}
			md={12}
			lg={12}
			item
		>
			<Typography
				component={"p"}
				align={"center"}
			>
				<strong>
					<a href={"https://www.collegia.co.uk"}
					   target={"_blank"}
					   rel="noopener noreferrer"
					>
						© {new Date().getFullYear()} Collegia Partners Limited. All rights reserved.
					</a>
				</strong>
			</Typography>
		</Grid>
		<Grid
			xs={12}
			md={12}
			lg={12}
			item
		>
			<Typography
				align={"center"}
				component={"p"}
			>
				{t("individual.login.all_rights_reserved_footer")}
			</Typography>
		</Grid>
	</Grid>
);

export default Footer;