import StepService from "../../services/StepService";
import {MOBILE_CODE_SENT, NEXT_STEP, UPDATE_INDIVIDUAL_DATA, UPDATE_STEP} from "./types";
import {retrieveByToken} from "../ActivationToken";
import {retrieveCollegiaAccessToken} from "../../utils/Helpers";

/**
 * @param data
 * @param token
 * @param step
 * @returns {(function(*): void)|*}
 */
export const proceed = (
	data = false,
	token = "",
	step = "canProceedStepOne"
) => dispatch => {
	if (data) {
		dispatch({
			type: NEXT_STEP,
			step_loading: true,
			[step]: false,
		});

		new StepService()
			.proceed(data)
			.then(response => {
				dispatch({
					type: NEXT_STEP,
					step_loading: false,
					[step]: response?.data?.data?.canProceed
				});

				if (response.data.data.canProceed) {
					dispatch({
						type: NEXT_STEP,
						step_loading: false,
						[step]: false,
					});

					if (token) {
						dispatch(retrieveByToken(token, true));
					}
				}
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: NEXT_STEP,
					step_loading: false,
					[step]: false,
				});
			});
	}
};

/**
 * @param id
 * @param data
 * @returns {function(...[*]=)}
 */
export const update = (id = false, data = false) => dispatch => {
	if (id && data) {

		dispatch({
			type: UPDATE_STEP,
			step_loading: true,
		});

		new StepService()
			.update(id, data)
			.then(response => {

				dispatch({
					type: UPDATE_STEP,
					step_loading: false,
					...response.data.data
				});
			})
			.catch(error => {

				console.error(error);
				dispatch({
					type: UPDATE_STEP,
					step_loading: false,
				});
			});
	}

};

/**
 * @param data
 * @returns {function(...[*]=)}
 */
export const updateIndividualData = (data = false, token = false) => dispatch => {

	if (data) {
		dispatch({
			type: UPDATE_INDIVIDUAL_DATA,
			step_loading: true,
			canProceedStepTree: false,
		});

		new StepService()
			.updatePersonalInfo(data)
			.then(response => {

				dispatch({
					type: UPDATE_INDIVIDUAL_DATA,
					step_loading: false,
					canProceedStepTree: response.data.data.canProceed,
				});

				if (token) {
					dispatch(retrieveByToken(token, true));
				}

			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: UPDATE_INDIVIDUAL_DATA,
					step_loading: false,
					canProceedStepTree: false,
				});
			});
	}
};

/**
 * @param data
 * @returns {function(...[*]=)}
 */
export const agreedTermsAndConditions = (data = false, token = "") => dispatch => {
	if (data) {
		dispatch({
			type: UPDATE_INDIVIDUAL_DATA,
			step_loading: true,
		});

		new StepService()
			.agreeTermsAndConditions(data)
			.then(response => {

				dispatch({
					type: UPDATE_INDIVIDUAL_DATA,
					step_loading: false,
					canProceedTermsAndCondition: response.data.data.canProceed,
					token: response.data.data.token,
				});

				if (response.data.data.canProceed) {
					dispatch({
						type: UPDATE_INDIVIDUAL_DATA,
						step_loading: false,
						canProceedTermsAndCondition: true,
						token: response.data.data.token,
					});
					if (response.data.data.token) {
						if (retrieveCollegiaAccessToken()) {
							localStorage.clear();
							sessionStorage.clear();
						}
						localStorage.setItem("collegiaToken", response.data.data.token);
						sessionStorage.setItem("collegiaToken", response.data.data.token);
						window.location = (`/account-activated/${token}`);
					} else {
						window.location = '/login';
					}
				}

			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: UPDATE_INDIVIDUAL_DATA,
					step_loading: false,
				});
			});
	}
};

/**
 * @param token
 * @param data
 * @param customRedirect
 * @returns {(function(*): void)|*}
 */
export const confirmMobileCode = (token: string = "", data: any = {}, customRedirect = "") => dispatch => {
	dispatch({
		type: MOBILE_CODE_SENT,
		step_loading: true,
		errorMessage: "",
		proceedMobile: false,
	});
	new StepService()
		.confirmMobileCode(token, data)
		.then(response => {
			if (response?.data?.data?.error) {
				dispatch({
					type: MOBILE_CODE_SENT,
					step_loading: false,
					errorMessage: response?.data?.data?.message ?? response?.data?.data,
					proceedMobile: false,
				});
			} else {
				if (customRedirect !== "") {
					window.location.href = customRedirect;
				}

				dispatch({
					type: NEXT_STEP,
					step_loading: false,
					errorMessage: "",
				});
				dispatch(retrieveByToken(token, true));
			}
		})
		.catch(error => {
			console.error(error);
			dispatch({
				type: MOBILE_CODE_SENT,
				step_loading: false,
				errorMessage: error?.response?.data?.data ?? "Something went wrong.",
				proceedMobile: false,
			});

		});
}