import GetEmployerInfo from "./GetEmployerInfo";
import OptOut from "./OptOut";
import Search from "./Search";
import CreateLead from "./CreateLead";

const EmployersRequests = {
	GetEmployerInfo,
	OptOut,
	Search,
	CreateLead,
};

export default EmployersRequests;