import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import React from "react";

/**
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const Footer = (
	{
		t = (key) => key
	}
): JSX.Element => (
	<Grid
		xs={12}
		sm={12}
		lg={12}
		md={12}
		container
		item
		className={"contact-information"}
	>
		<Typography paragraph>
			{t("individual.options.footer_information")}, {" "}
			<a href={"mailto:hello@collegia.co.uk"}>{t("individual.please_contact_us")}!</a>
		</Typography>
	</Grid>
);

export default Footer;