import React, {useState} from 'react';
import Wrapper from "../layout/Logged/Wrapper";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import BodyText from "../components/Home/SetUpPage/TermsAndConditions/BodyText";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {PrimaryHeader} from "../components/Headers/PrimaryHeader";

/**
 * @returns {*}
 */
const TermsAndConditions = ({
	                            history = () => {
	                            }
                            }) => {
	const [expanded, setExpanded] = useState("");

	return (
		<Wrapper hideFooter={true}>
			<Slide in={true} direction={"up"}>
				<div>
					<Grid
						direction={"column"}
						alignItems={"center"}
						alignContent={"center"}
						id={"terms-and-conditions-container"}
						container
						item
					>
						<PrimaryHeader
							history={history}
							backPage={"/main-page"}
							backIcon
						>
							Legal
						</PrimaryHeader>
						<Grid
							alignItems={"center"}
							alignContent={"center"}
							direction={"column"}
							className={"modal-container"}
							item
							container
						>
							<BodyText/>
						</Grid>
						<Grid
							alignItems={"center"}
							alignContent={"center"}
							direction={"column"}
							item
							container
						>
							<Grid
								className={"dialog-without-padding dialog-without-border-top"}
								item
							>
								<Grid
									lg={12}
									direction={"column"}
									alignItems={"center"}
									justifyContent="center"
									className={"big-picture-title-container"}
									container
									item
								>
									<Typography
										variant={"subtitle2"}
										component={"p"}
									>
										The big picture:
									</Typography>
								</Grid>
								<Grid
									lg={12}
									direction={"row"}
									className={"big-picture-container"}
									container
									item
								>
									<Accordion
										expanded={expanded === 'Is my money safe?'}
										onChange={() =>
											expanded === 'Is my money safe?' ?
												setExpanded("")
												:
												setExpanded('Is my money safe?')
										}
										className={"expansion-panel"}
									>
										<AccordionSummary
											expandIcon={
												<ExpandMoreIcon/>
											}
											aria-controls="panel1bh-content"
											id="panel1bh-header"
										>
											<Typography>Is my money safe?</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Typography
												component={"p"}
												variant={"body1"}
											>
												First, we are authorised and regulated by the Financial Conduct
												Authority (FCA).
												Second, through Collegia, your pension investments are structured as
												long-term
												insurance contracts. This means that, in the very unlikely case of
												something
												happening to the money manager we work with, 100% of your Collegia
												account is
												protected by the Financial Services Compensation Scheme (FSCS) -
												without any
												upper limit.
												<br/>
												<br/>
												In short, while when investing your money is always at risk
												(due to investment performance), your retirement savings
												are protected in Collegia.
											</Typography>
										</AccordionDetails>
									</Accordion>
									<Accordion
										expanded={expanded === 'How is my money invested?'}
										onChange={() =>
											expanded === 'How is my money invested?' ?
												setExpanded("")
												:
												setExpanded('How is my money invested?')
										}
										className={"expansion-panel"}
									>
										<AccordionSummary
											expandIcon={
												<ExpandMoreIcon/>
											}
											aria-controls="panel2bh-content"
											id="panel2bh-header"
										>
											<Typography>
												How is my money invested?
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Typography
												component={"p"}
												variant={"body1"}
											>
												We partner with the money manager AllianceBernstein (AB). They are
												one
												of the world’s biggest money managers (with over $500 billion under
												management)
												and, most importantly, have an extensive expertise in investment
												management
												and pensions (in fact, since 1967).
												<br/>
												<br/>
												AB are pioneers in target date funds (TDFs). These are financial
												instruments
												in which different people with the same expected retirement date
												combine
												their money together to gain the advantages of being part of a large
												group.
												In TDFs, your investments are more adventurous at the beginning of
												your retirement journey. As you approach retirement, they become
												more cautious.
											</Typography>
										</AccordionDetails>
									</Accordion>
									<Accordion
										expanded={expanded === 'Collegia does not provide advice'}
										onChange={() =>
											expanded === 'Collegia does not provide advice' ?
												setExpanded("")
												:
												setExpanded('Collegia does not provide advice')
										}
										className={"expansion-panel"}
									>
										<AccordionSummary
											expandIcon={
												<ExpandMoreIcon/>
											}
											aria-controls="panel3bh-content"
											id="panel3bh-header"
										>
											<Typography>
												Collegia does not provide advice
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Typography
												component={"p"}
												variant={"body1"}
											>
												We do not provide financial advice. The Collegia app helps you
												understand your
												likely retirement living standard and what you can do now to improve
												it.
												It does so based on the information you provide us and recognised
												industry
												standards – such as those published by the Pensions and
												Lifetime Savings Association (PLSA).
											</Typography>
										</AccordionDetails>
									</Accordion>
									<Accordion
										expanded={expanded === 'How much does it cost?'}
										onChange={() =>
											expanded === 'How much does it cost?' ?
												setExpanded("")
												:
												setExpanded('How much does it cost?')
										}
										className={"expansion-panel"}
									>
										<AccordionSummary
											expandIcon={
												<ExpandMoreIcon/>
											}
											aria-controls="panel4bh-content"
											id="panel4bh-header"
										>
											<Typography>
												How much does it cost?
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Typography
												component={"p"}
												variant={"body1"}
											>
												We charge 0.3% annually on the value of your Collegia Pension. We
												also charge
												1.7% of new contributions. We believe this charging model is the
												most
												appropriate for retirement investing, as it minimises the total
												value of fees
												over long periods of time. The graph below compares our total fees
												to those of
												our major competitors. This calculation assumes 30 years of
												investing with an
												average yearly return of 5%.
											</Typography>
										</AccordionDetails>
									</Accordion>
									<Accordion
										expanded={expanded === 'Why should I trust Collegia?'}
										onChange={() =>
											expanded === 'Why should I trust Collegia?' ?
												setExpanded("")
												:
												setExpanded('Why should I trust Collegia?')
										}
										className={"expansion-panel"}
									>
										<AccordionSummary
											expandIcon={
												<ExpandMoreIcon/>
											}
											aria-controls={"panel5bh-content"}
											id={"panel5bh-header"}
										>
											<Typography>
												Why should I trust Collegia?
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Typography
												component={"p"}
												variant={"body1"}
											>
												We created Collegia to help everyone save for retirement. However,
												as you probably heard it before, in pensions – like all investments
												– your
												capital is at risk. To ensure that we are fulfilling our mission,
												<a
													href={"https://www.ptluk.com/about-us"}
													target={"_blank"}
													rel="noopener noreferrer"
												> we have instructed PTL</a>, a specialist in monitoring
												pension schemes, to set up a completely independent committee to
												assess the
												quality of the Collegia Pension. This committee is called the
												Collegia
												Governance Advisory Arrangement (GAA). On an ongoing basis, they
												judge how
												well we are serving you and publish their opinion on an annual
												report.
												We are legally obliged to act on their recommendations.
											</Typography>
										</AccordionDetails>
									</Accordion>
									<Accordion
										expanded={expanded === 'Can I transfer or cancel?'}
										onChange={() =>
											expanded === 'Can I transfer or cancel?' ?
												setExpanded("")
												:
												setExpanded('Can I transfer or cancel?')
										}
										className={"expansion-panel"}
									>
										<AccordionSummary
											expandIcon={
												<ExpandMoreIcon/>
											}
											aria-controls="panel6bh-content"
											id={"panel6bh-header"}
										>
											<Typography>
												Can I transfer or cancel?
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Typography
												component={"p"}
												variant={"body1"}
											>
												You can only access your pension when you’re 55 or older. However,
												you do have a period during which you can cancel your Collegia
												Pension.
												This period lasts 30 calendar days, starting from either when you
												accept these
												Terms and Conditions or when your employer signs you up to fulfil
												its Auto
												Enrolment duties (the earliest of these two events). You can also
												ask us to
												transfer your pension to another provider. We charge no exit fees.
											</Typography>
										</AccordionDetails>
									</Accordion>
									<Accordion
										expanded={expanded === 'The UK Government Top Up'}
										onChange={() =>
											expanded === 'The UK Government Top Up' ?
												setExpanded("")
												:
												setExpanded('The UK Government Top Up')
										}
										className={"expansion-panel"}
									>
										<AccordionSummary
											expandIcon={
												<ExpandMoreIcon/>
											}
											aria-controls="panel7bh-content"
											id={"panel7bh-header"}
										>
											<Typography>
												The UK Government Top Up
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Typography
												component={"p"}
												variant={"body1"}
											>
												As a personal pension scheme registered with HMRC, Collegia collects
												a
												government top up of £0.25 for each £1 invested in your pension.
												Officially,
												this mechanism is called relief at source tax relief. You qualify
												for this
												benefit even if you do not pay income tax. As long as we identify
												that you are
												a UK resident (or has been over the last 5 years), Collegia will
												claim this
												top up automatically. We will also claim the top up if you have
												earnings
												subject to England & N. Ireland, Scottish or Welsh income tax.
											</Typography>
										</AccordionDetails>
									</Accordion>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</div>
			</Slide>
		</Wrapper>
	);
}

export default TermsAndConditions;