import React from "react";

import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import orderConfirmed from "../../images/undraw_order_confirmed_aaw7 2.svg";

/**
 * @stateless
 * @param props
 * @returns {JSX.Element}
 */
const Activated = ({
	history = () => {
	}
}): JSX.Element => (
	<Grid
		id={"pension-activated-container"}
		lg={12}
		xs={12}
		md={12}
		sm={12}
		alignItems={"center"}
		alignContent={"center"}
		direction={"column"}
		spacing={0}
		container
		item
	>
		<img id="img-title" src={orderConfirmed} alt={"Pension activated"}/>
		<form
			noValidate
			autoComplete="off"
		>
			<FormControl
				className={"pension-activated-form-control-2 first-title"}
				fullWidth
			>
				<Typography align={"center"} component={"p"}>
					<strong>Pension activated!</strong>
				</Typography>
			</FormControl>

			<FormControl
				className={"pension-activated-form-control-2 second-title"}
				fullWidth
			>
				<Typography align={"center"} component={"p"}>
					You can start your retirement story, try making you first contribution or refering
					your employer to make auto enrolment contributions to your Collegia account.
				</Typography>

			</FormControl>

			<FormControl
				className={"pension-activated-form-control"}
				fullWidth
			>
				<Button
					variant="contained"
					color="primary"
					onClick={() => history("/extra-contributions")}
				>
					Add First Contribution
				</Button>
			</FormControl>

			<FormControl
				className={"pension-activated-form-control"}
				fullWidth
			>
				<Button
					id="mui-disabled"
					variant="contained"
					color="primary"
					onClick={() => history("/main-page")}
				>
					Take me to my Pension
				</Button>
			</FormControl>
		</form>
	</Grid>
);

export default Activated;