import React from "react";
import {connect} from "react-redux";
import Grid from '@mui/material/Grid';
import Wrapper from "../../layout/Logged/Wrapper";
import {returnUser} from "../../actions/Profile";
import Loading from "../../components/Loading";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

/**
 * @param profile_data
 * @param profile_loading
 * @param history
 * @returns {JSX.Element}
 * @constructor
 */
const YourDocuments = (
	{
		profile_loading = false,
	}
): JSX.Element => {
	const {t} = useTranslation();

	let menu = [
		{
			text: t("individual.mobile.your_documents.individual_terms_and_conditions"),
			link: "https://s3.eu-west-2.amazonaws.com/public.production.collegia/Collegia+Legal+Docs/Terms_and_Conditions_Collegia_individual.pdf",
		},
		{
			text: t("individual.mobile.your_documents.collegia_fees"),
			link: "https://s3.eu-west-2.amazonaws.com/public.collegia/Website+documentation/Collegia+Our+Fees.pdf",
		},
		{
			text: t("individual.mobile.your_documents.collegia_key_features"),
			link: "https://s3.eu-west-2.amazonaws.com/public.collegia/Website+documentation/Collegia+Key+Features.pdf",
		},
		{
			text: "G.A.A. Contact Details",
			link: "https://s3.eu-west-2.amazonaws.com/public.collegia/Website+documentation/GAA-contact-details.pdf"
		},
		{
			text: "Collegia Transfer Out Form",
			link: "https://imagesdevcollegia.blob.core.windows.net/imagesdevcollegia/dev/Collegia.Transfer.Out.Form.pdf"
		}
	];
	const history = useNavigate();


	if (profile_loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<Wrapper
			history={history}
			hideFooter={true}
			t={t}
		>
			<Typography
				textAlign={"center"}
				marginTop={10}
				fontSize={25}
				variant={"h4"}
				color={"blue"}
			>
				{t("individual.your_documents.title")}
			</Typography>
			<Grid
				container
				justifyContent={"center"}
				id={"your-documents"}
				padding={15}
				direction={"column"}
				alignContent={"center"}
			>
				{
					menu.map((options, index) => (
						<Grid
							container
							justifyContent={"center"}
							alignContent={"center"}
							key={index}
						>
							<Typography
								className={"text-properties"}
								onClick={() => window.open(options.link, '_blank').focus()}
							>
								{options.text}
							</Typography>
						</Grid>
					))
				}
			</Grid>
		</Wrapper>
	);
}

const mapStateToProps = state => state.Profile;

/**
 * @param dispatch
 * @returns {{me: (function(): *), updateProfilePicture: (function(*[]=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	returnUser: dispatch(returnUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(YourDocuments)