import React, {useEffect} from "react";

// default material ui components.
import Wrapper from "../layout/Logged/Wrapper";

import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';

// helper functions.
import {retrieveCollegiaAccessToken} from "../utils/Helpers";

import YourPensionBadgesContainer from "../components/YourPension/YourPensionBadgesContainer";
import LifeStyleCard from "../components/YourPension/LifeStyleCard";

// redux import.
import {connect} from "react-redux";
import {returnUser, myProjections} from "../actions/Profile";
import {retrieveGenericProjectionParameters} from "../actions/GenericProjectionParemeters";
import {toggleIncomePot} from "../actions/PotToggle";
import {get} from "../actions/InvestmentApproach";
import Loading from "../components/Loading";
import {PrimaryHeader} from "../components/Headers/PrimaryHeader";
import {useTranslation} from "react-i18next";

/**
 * @param profile_data
 * @param potChecked
 * @param genericProjectionParameters
 * @param investment_approaches
 * @param individual_approach
 * @param toggleIncomePot
 * @param returnUser
 * @param retrieveGenericProjectionParameters
 * @param getInvestmentApproach
 * @param history
 * @returns {JSX.Element}
 * @constructor
 */
const YourPension = (
	{
		profile_data = {
			ifa_connections: [],
		},
		potChecked = false,
		genericProjectionParameters = [],
		investment_approaches = [],
		individual_approach = {},
		toggleIncomePot = () => {
		},
		returnUser = () => {
		},
		retrieveGenericProjectionParameters = () => {
		},
		getInvestmentApproach = () => {
		},
		history = () => {
		}
	}
): JSX.Element => {
	let progress_level_classname = false,
		badge = false;
	const {t} = useTranslation();

	if (profile_data !== undefined) {
		const {
			projection = false
		} = profile_data;
		if (projection) {
			progress_level_classname = projection?.progress_level_classname;
			badge = projection.badge;
		}
	}

	useEffect(() => {
		returnUser();
		retrieveGenericProjectionParameters();
		getInvestmentApproach(true);
	}, [returnUser, retrieveGenericProjectionParameters, getInvestmentApproach]);

	if (!profile_data) {
		return (
			<Loading
				absolute
			/>
		);
	}

	if (!retrieveCollegiaAccessToken()) {
		window.location = "/login";
	}

	localStorage.setItem("potCheck", potChecked);

	return (
		<Wrapper t={t}>
			<Slide
				in={true}
				direction={"up"}
			>
				<div>
					<Grid
						direction={"column"}
						alignItems={"center"}
						alignContent={"center"}
						id={"your-pension-wrapper"}
						container
						item
					>
						<PrimaryHeader
							history={history}
							backPage={"/main-page"}
							backIcon
						>
							{t("individual.your_pension.title")}
						</PrimaryHeader>
						<LifeStyleCard
							t={t}
							totalYearlyIncome={profile_data.totalYearlyIncome ?? 0}
							progress={profile_data.progress ?? 0}
							currentProgress={profile_data.progress ?? 0}
							projection={profile_data.projection ?? false}
							history={history}
							potChecked={potChecked}
							toggleIncomePot={toggleIncomePot}
							progressLevelClassName={progress_level_classname ?? false}
							badge={badge ?? false}
							showAllSteps
							noLegend
						/>
						<YourPensionBadgesContainer
							profile_data={profile_data}
							history={history}
							genericProjectionParameters={genericProjectionParameters}
							investment_approaches={investment_approaches}
							individual_approach={individual_approach}
							t={t}
						/>
					</Grid>
				</div>
			</Slide>
		</Wrapper>
	);
};

const mapStateToProps = state => {
	const {
		Profile = [],
		GenericProjectionParameters = [],
		ComfortRulerProjectionParameters = [],
		PotToggle = [],
		InvestmentApproach = [],
		ExtraContributions = [],
	} = state;

	return {
		...Profile,
		...GenericProjectionParameters,
		...ComfortRulerProjectionParameters,
		...PotToggle,
		...InvestmentApproach,
		...ExtraContributions,
	};
};

/**
 * @param dispatch
 * @returns {{returnUser: (function(): *)}}
 */
const mapDispatchToProps = dispatch => ({
	returnUser: () => dispatch(returnUser()),
	retrieveGenericProjectionParameters: () => dispatch(retrieveGenericProjectionParameters(false)),
	toggleIncomePot: (toggle = true) => dispatch(toggleIncomePot(toggle)),
	getInvestmentApproach: (scrollUp: boolean = false) => dispatch(get(scrollUp)),
	myProjections: () => dispatch(myProjections()),
});

export default connect(mapStateToProps, mapDispatchToProps)(YourPension);