// default material ui components.
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import CountUp from "react-countup";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

// importing the default images.
import AutoEnrolmentIcon from '../../../images/AutoEnrollActive.svg';
import InactiveAutoEnrollIcon from '../../../images/AutoEnrollInactive.svg';
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";

/**
 * @param props
 * @returns {*}
 * @private
 */
function _renderAutoEnrollIcons(props) {

	if (props.profile_data.individual_employers) {
		if (props.profile_data.individual_employers.length > 0) {
			return AutoEnrolmentIcon;
		}
	}

	return InactiveAutoEnrollIcon;
}

/**
 * @param props
 * @returns {string}
 */
function renderSubHeaderText(props = {
	t: key => key,
}): string {

	if (props.profile_data.individual_employers) {
		if (props.profile_data.individual_employers.filter(employer => employer.active === true).length > 0) {
			let title =
				props.profile_data.individual_employers.filter(employer => employer.active === true).length > 1 ?
					props.t("individual.employers") : props.t("individual.employer");
			return `${props.profile_data.individual_employers.filter(employer => employer.active === true).length} ${title} ${props.t("individual.active")}`;
		}
	}

	return props.t("individual.no_connection");
}

/**
 * @param props
 * @returns {JSX.Element}
 */
function renderEmployerContributions(props): JSX.Element {

	const {
		profile_data = {
			individual_employers: [],
		}
	} = props;
	let individual_employers = [], total = 0;

	if (profile_data) {
		individual_employers = profile_data.individual_employers ?? [];

		if (individual_employers && individual_employers.length > 0) {

			individual_employers = individual_employers.filter(employer => employer.active === true);

			if (individual_employers.length === 0) {
				total = 0;
			} else {
				individual_employers.forEach((d = {
					employer: {
						papdis: [],
					}
				}) => {
					const {
						employer = {
							papdis: [],
						},
					} = d;

					if (employer) {
						const {
							papdis = []
						} = employer;

						if (papdis.length > 0) {
							total += (papdis[0]?.transaction?.total_contribution) ?? 0;
						}
					}
				});

				return (
					<Grid
						className={"line-padding"}
						xs={12}
						sm={12}
						md={12}
						lg={12}
						item
						container
					>
						<Grid
							xs={1}
							sm={1}
							md={1}
							lg={1}
							item
						>
							<LockOutlinedIcon className={"lock-icon"}/>
						</Grid>
						<Grid
							xs={10}
							sm={10}
							md={10}
							lg={10}
							item
						>
							<CountUp
								className={"count-number"}
								start={0}
								end={parseFloat(total)}
								separator=","
								decimals={2}
								decimal="."
								duration={1}
								prefix={`£ `}
								suffix={" "}
							/>
						</Grid>
					</Grid>
				);
			}
		}
	}


	return (
		<Grid
			className={"line-padding"}
			xs={12}
			sm={12}
			md={12}
			lg={12}
			item
			container
		>
			<Grid
				xs={1}
				sm={1}
				md={1}
				lg={1}
				item
			>
				<ScheduleOutlinedIcon
					className={`lock-icon ${individual_employers.length > 0 ? "active" : "inactive"}`}/>
			</Grid>
			<Grid
				xs={10}
				sm={10}
				md={10}
				lg={10}
				item
			>
                <span
	                className={individual_employers.length > 0 ? "active" : "inactive"}
	                style={{
		                paddingLeft: "5px",
	                }}
                >
                    N/A
                </span>
			</Grid>
		</Grid>
	);
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const AutoEnrolmentContributions = (props = {
	t: key => key,
}): JSX.Element => (
	<Card
		id={"auto-enrolment-contributions-card"}
		onClick={() => props.history("/auto-enrolment")}
	>
		<CardHeader
			action={
				<IconButton
					aria-label="settings"
					disableTouchRipple
					disableFocusRipple
					disableRipple
				>
					<KeyboardArrowRightIcon/>
				</IconButton>
			}
			title={props.t("individual.auto_enrolment_contributions")}
			subheader={renderSubHeaderText(props)}
		/>
		<CardContent>
			<Grid
				xs={12}
				sm={12}
				md={12}
				lg={12}
				item
				container
			>
				<Grid
					xs={8}
					sm={8}
					md={8}
					lg={8}
					item
					container
				>
					{renderEmployerContributions(props)}
				</Grid>
				<Grid
					xs={4}
					sm={4}
					md={4}
					lg={4}
					alignItems={"flex-end"}
					alignContent={"flex-end"}
					direction={"column"}
					item
					container
				>
					<img
						src={_renderAutoEnrollIcons(props)}
						alt={props.t("individual.auto_enrolment_contributions")}
					/>
				</Grid>
			</Grid>
		</CardContent>
	</Card>
);

export default AutoEnrolmentContributions;