import AbstractServiceRequestsClass from "../../classes/AbstractServiceRequestsClass";

import Request from '../../request';
import {AxiosResponse} from "axios";

export default class ExtraContributionsService extends AbstractServiceRequestsClass {
	constructor() {
		super(Request.ExtraContributions);
	}

	/**
	 * @param orderId
	 * @param type
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	retrievePaymentInfo(orderId: string = "", type: string = "") {
		if (orderId !== "" && type !== "" && this.request) {
			return this.request.RetrievePaymentInfo(orderId ?? "", type ?? "");
		}

		return null;
	}

	/**
	 * @param orderId
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	details(orderId: string = "") {
		if (orderId !== "" && this.request) {
			return this.request.Details(orderId ?? "");
		}

		return null;
	}

	/**
	 *
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	bankList(): Promise<AxiosResponse<*>> | null {
		if (this.request) {
			return this.request.BankList();
		}

		return null;
	}

	/**
	 * @param data
	 * @returns {null|Promise<AxiosResponse<*>>|*}
	 */
	confirmDirectDebit(data: any = false): null | Promise<AxiosResponse<*>> | * {
		if (this.request && data) {
			return this.request.ConfirmDirectDebit(data ?? false);
		}

		return null;
	}

	/**
	 * @returns {null|Promise<AxiosResponse<*>>|*}
	 */
	getEndToEnd(): null | Promise<AxiosResponse<*>> | * {
		if (this.request) {
			return this.request.GetEndToEnd();
		}

		return null;
	}

	/**
	 * @param endToEnd
	 * @returns {Promise<never>|Promise<AxiosResponse<*>>|*}
	 */
	cancelDirectDebitSigning(endToEnd: string = ""): Promise<never> | Promise<AxiosResponse<*>> | * {
		if (this.request && endToEnd) {
			return this.request.CancelDirectDebitSigning(endToEnd ?? "");
		}

		return Promise.reject("No end to end verification and request handle provided.");
	}

	/**
	 * @param endToEnd
	 * @returns {Promise<never>|Promise<AxiosResponse<*>>}
	 */
	cancelDDI(endToEnd: string = ""): Promise<never> | Promise<AxiosResponse<*>> {
		if (this.request && endToEnd) {
			return this.request.CancelDDI(endToEnd ?? "");
		}

		return Promise.reject("No end to end verification and request handle provided.");
	}

	updateSourceOfFunds(id, data) {
		if (this.request && id && data) {
			return this.request.UpdateSourceOfFunds(id, data);
		}

		return Promise.reject("Wrong data provided.");
	}
}