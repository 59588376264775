import Grid from "@mui/material/Grid";
import Logo from "../images/logo-icon.svg";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Wrapper from "../layout/Wrapper";
import React, {useState} from "react";
import Slide from '@mui/material/Slide';
import Button from "@mui/material/Button";
import {connect} from "react-redux";
import PasswordForm from "../components/403/PasswordForm";
import {submitEnvPassword} from "../actions/403";
import Loading from "../components/Loading";

/**
 * @param profile_data
 * @param submitEnvPassword
 * @param error_messages
 * @param loading
 * @returns {JSX.Element}
 * @constructor
 */
const Page_403 = (
	{
		profile_data = {
			my_ip: "",
		},
		submitEnvPassword = (data) => data,
		error_messages = {},
		loading = false,
	}
): JSX.Element => {
	const [enterPassword, setEnterPassword] = useState(false);
	const [password, setPassword] = useState("");
	const [user, setUser] = useState("");

	if (loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<Wrapper showSandwich={false}>
			<Slide
				direction="up"
				in={true}
				mountOnEnter
				unmountOnExit
			>
				<div id={"login-wrapper"}>
					<Grid
						xs={12}
						lg={12}
						md={12}
						sm={12}
						alignItems={"center"}
						alignContent={"center"}
						direction={"column"}
						id={"header"}
						container
						item
					>
						<img
							src={Logo}
							alt={"Welcome Collegia"}
						/>
					</Grid>
					<Grid
						id={"login-container"}
						lg={12}
						xs={12}
						md={12}
						sm={12}
						alignItems={"center"}
						alignContent={"center"}
						direction={"column"}
						spacing={0}
						container
						item
					>
						<form
							noValidate
							autoComplete="off"
						>
							<FormControl
								className={"login-form-control-2 first-title"}
								fullWidth
							>
								<Typography align={"center"}>
									You're not allowed to see this page.
								</Typography>
							</FormControl>
							<FormControl
								className={"login-form-control-2 second-title"}
								fullWidth
							>
								<Typography align={"center"}>
									Please enter in touch in support.
								</Typography>
							</FormControl>
							<FormControl
								className={"login-form-control-2 second-title"}
								fullWidth
							>
								<Button
									disabled={enterPassword}
									onClick={() => setEnterPassword(!enterPassword)}
								>
									I have a environment password
								</Button>
							</FormControl>
						</form>

						{
							enterPassword && (
								<PasswordForm
									password={password}
									setPassword={setPassword}
									user={user}
									setUser={setUser}
									profile_data={profile_data}
									submitEnvPassword={submitEnvPassword}
									error_messages={error_messages}
								/>
							)
						}
					</Grid>
				</div>
			</Slide>
		</Wrapper>
	);
};

const mapStateToProps = state => {

	return {
		...state.Profile,
		...state.Forbidden,
	};
}

const mapDispatchToProps = dispatch => ({
	submitEnvPassword: (data = {}) => dispatch(submitEnvPassword(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Page_403);