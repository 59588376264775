import {AxiosResponse} from "axios";
import Request from "../../utils/Request";

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const CheckDuplicateNinPromise = (data: any[] = false): Promise<AxiosResponse<any>> => (
	Request.post(`/individual/check-duplicate-nin`, data)
);

export default CheckDuplicateNinPromise;