import {
	ACCEPT_INVITATION,
	REJECT_INVITATION,
	RETRIEVE_INVITATION_DATA,
	REFER_EMPLOYER_MANUALLY
} from "./types";
import InvitationsService from "../../services/Invitations";
import {resolveRequestResponse} from "../../utils/Helpers";

export const referEmployerManually = (data = {}) => dispatch => {
	if (data) {
		dispatch({
			type: REFER_EMPLOYER_MANUALLY,
			invitation_loading: true
		});
		new InvitationsService()
			.referEmployerManually(data)
			.then(() => dispatch({
				type: REFER_EMPLOYER_MANUALLY,
				invitation_loading: false,
				refer_employer_manually_success: true,
			}))
			.catch(() => dispatch({
				type: REFER_EMPLOYER_MANUALLY,
				invitation_loading: false,
				refer_employer_manually_success: false,
			}));
	}
};

/**
 * @param code
 * @returns {(function(*): void)|*}
 */
export const getByCode = (code: string = "") => dispatch => {
	if (code) {
		dispatch({
			type: RETRIEVE_INVITATION_DATA,
			invitation_loading: true,
			invite: {
				name: "",
				email: "",
				accepted: null,
			},
		});

		new InvitationsService()
			.findByCode(code)
			.then(response => dispatch({
				type: RETRIEVE_INVITATION_DATA,
				invitation_loading: false,
				invite: response?.data?.data ?? {
					name: "",
					email: "",
					accepted: null,
				},
			}))
			.catch(error => {

				if (error.response) {
					if (error.response.status === 401) {
						localStorage.clear();
						window.location = "/login";
					}
				}
				dispatch({
					type: RETRIEVE_INVITATION_DATA,
					invitation_loading: false,
					invite: {
						name: "",
						email: "",
						accepted: null,
					},
				});

				console.error(error);
			});

	}
};

/**
 * @param code
 * @returns {(function(*): void)|*}
 */
export const rejectInvitation = (code = "") => dispatch => {
	if (code) {
		dispatch({
			type: REJECT_INVITATION,
			invitation_loading: true,
			invite: {
				name: "",
				email: "",
				accepted: null,
			},
		});

		new InvitationsService()
			.rejectInvitation(code)
			.then(() => window.location.href = "/login")
			.catch(error => {

				if (error.response) {
					if (error.response.status === 401) {
						localStorage.clear();
						window.location = "/login";
					}
				}

				resolveRequestResponse(
					dispatch,
					{
						invitation_loading: false,
						invite: {
							name: "",
							email: "",
							accepted: null,
						},
						error_data: error?.response?.data ?? {},
					},
					REJECT_INVITATION
				);

				console.error(error);
			});
	}
};

/**
 * @param code
 * @param data
 * @returns {(function(*): void)|*}
 */
export const acceptInvitation = (code: string = "", data = {}) => dispatch => {
	if (code && data) {
		dispatch({
			type: ACCEPT_INVITATION,
			invitation_loading: true,
			error_data: {},
		});

		new InvitationsService()
			.acceptInvitation(code, data)
			.then(response => {
				const {
					data = false
				} = response;

				if (data.data) {
					const {
						token = ""
					} = data.data;

					if (token) {
						localStorage.clear();
						localStorage.setItem("collegiaToken", token);
						window.location.href = "/main-page";
					}
				}
				dispatch({
					type: ACCEPT_INVITATION,
					invitation_loading: false,
					error_data: {},
				});
			})
			.catch(error => {

				if (error.response) {
					if (error.response.status === 401) {
						localStorage.clear();
						window.location = "/login";
					}
				}

				resolveRequestResponse(
					dispatch,
					{
						error_data: error?.response?.data?.data ?? {},
						invitation_loading: false,
					},
					error?.response,
					ACCEPT_INVITATION,
				);

				console.error(error);
			});
	}
};