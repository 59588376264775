import Request from "../../utils/Request";
import PropTypes from "prop-types";

/**
 * @param id
 * @returns {Promise<never>|Promise<AxiosResponse<any>>}
 * @constructor
 */
const DeleteBeneficiariesPromise = (id) => {
	if (id) {
		return Request.delete(`/individual/beneficiaries/${id}`);
	}

	return Promise.reject("No id provided");
}

DeleteBeneficiariesPromise.propTypes = {
	id: PropTypes.number.isRequired,
}


export default DeleteBeneficiariesPromise;