import Request from "../../utils/Request";

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>|Promise<never>}
 * @constructor
 */
const ProcessPastEmployments = (data) =>
	Request.post(`/profile/process-past-employments`, data)
	??
	Promise.reject("It's not possible to process past employments");

export default ProcessPastEmployments;