import React from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import {getYearlyIncomeFormattedValue} from "../../../utils/Helpers";

/**
 * @param completed
 * @param yearlySetUp
 * @param props
 * @returns {*}
 */
function changeClassName(completed = 0, yearlySetUp = true, props = false): string {
	let className;

	if (props.progressLevelClassName) {
		return props.progressLevelClassName;
	}

	if (props.rulerProgressClassName) {
		return props.rulerProgressClassName;
	}

	if (yearlySetUp && props.totalYearlyIncome) {
		let value = 0;

		if (typeof props.totalYearlyIncome.replace === "function") {
			value = getYearlyIncomeFormattedValue(props ?? false);
		} else {
			value = parseInt(props.totalYearlyIncome);
		}

		if (value >= 0 && value <= 10900) {
			className = "progress-not-there pot-critical";
		} else if (value > 10901 && value <= 20800) {
			className = "progress-minimum";
		} else if (value >= 20801 && value < 33600) {
			className = "progress-moderate";
		} else if (value >= 33601) {
			className = "progress-comfortable";
		}
	} else {

		if (completed >= 0 && completed <= 23.61) {
			className = "progress-not-there";
		} else if (completed >= 23.62 && completed < 46.76) {
			className = "progress-minimum";
		} else if (completed >= 46.76 && completed < 76) {
			className = "progress-moderate";
		} else {
			className = "progress-comfortable";
		}
	}

	return className;
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function PensionPotProgress(props = {
	progress: 0,
	projection: {
		total_yearly_income: 0,
	},
	retirementAge: 67,
	genericProjectionParameters: {
		full_yearly_state_pension: 0,
	}
}): JSX.Element {
	const [progress, setProgress] = React.useState(props.progress ?? 0);
	let totalIncome = 0,
		counterProgress = 0;

	if (props.progress) {
		counterProgress = props.progress > 100 ? 100 : props.progress;
	}

	if (props.projection) {
		totalIncome = parseFloat(props.projection.total_yearly_income);
	}

	if (props.retirementAge && props.retirementAge < 67) {
		if (props.genericProjectionParameters) {
			totalIncome -= parseFloat(props.genericProjectionParameters.full_yearly_state_pension);
		}
	}

	if (totalIncome <= -1) {
		totalIncome = Math.abs(totalIncome);
	}

	React.useEffect(() => {
		const timer = setTimeout(() => {
			for (let i = 0; i <= counterProgress; i++) {
				setProgress(i);
			}

		}, 1000);

		return () => {
			clearInterval(timer);
		}
	}, [
		totalIncome,
		props.progress,
		counterProgress,
	]);

	return (
		<div style={{width: "100%"}}>
			<LinearProgress
				variant="determinate"
				value={progress}
				className={changeClassName(progress, true, props)}
			/>
		</div>
	);
}
