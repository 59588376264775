import React from "react";

// importing the material ui cards
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardContent from "@mui/material/CardContent";

/**
 * @param progress
 * @param yearlyIncome
 * @param props
 * @returns {string}
 */
function setTextInfo(progress = 0, yearlyIncome = false, props): string {

	if (yearlyIncome && props.totalYearlyIncome) {
		let value = 0;

		if (typeof props.totalYearlyIncome.replace === "function") {
			value = parseInt(props.totalYearlyIncome.replace(",", ""));
		} else {
			value = parseInt(props.totalYearlyIncome);
		}
		if (value >= 0 && value < 14400) {
			return props.t("individual.your_pensions.retirment_info.still_not_there");
		} else if (value >= 14400 && value < 31300) {
			return props.t("individual.your_pensions.retirment_info.minimum");
		} else if (value >= 31300 && value < 43100) {
			return props.t("individual.your_pensions.retirment_info.moderate");
		} else if (value >= 43100) {
			return props.t("individual.your_pensions.retirment_info.comfortable");
		}
	} else {
		if (progress >= 23.62 && progress < 46.76) {
			return props.t("individual.your_pensions.retirment_info.minimum");
		} else if (progress >= 46.76 && progress < 76.39) {
			return props.t("individual.your_pensions.retirment_info.moderate");
		} else if (progress >= 76.39) {
			return props.t("individual.your_pensions.retirment_info.comfortable");
		}
	}

	return props.t("individual.your_pensions.retirment_info.still_not_there");
}

/**
 * @param props
 * @returns {*}
 */
const RetirementInfo = (props = {
	t: key => key,
}) => (

	<Grid
		xs={12}
		sm={12}
		md={12}
		lg={12}
		item
	>
		<CardContent>
			<Typography
				variant={"body1"}
				className={"your-pension-card-body"}
			>
				{setTextInfo(props.progress ?? 0, true, props ?? false)}
			</Typography>
		</CardContent>
	</Grid>
);

export default RetirementInfo;