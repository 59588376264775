// importing the default actions.
import {NEXT_STEP_MODAL, READ_TERMS_AND_CONDITIONS} from "../../actions/SetUpModal/types";
import {resolveReduxState} from "../../utils/Helpers";

/**
 * @type {{readTerms: [], activeStep: number}}
 */
const initial_state = {
	activeStep: 0,
	readTerms: [],
};

/**
 * @param state
 * @param action
 * @returns {{readTerms: *[], activeStep: number}|{valueOf, (): boolean}|boolean}
 */
const SetupModalStore = (state = initial_state, action = false) => {

	if (action) {
		const {type = false} = action;

		if (type === NEXT_STEP_MODAL) return resolveReduxState(state, action);
		else if (type === READ_TERMS_AND_CONDITIONS) return resolveReduxState(state, action);
	}

	return state;
};

export default SetupModalStore;