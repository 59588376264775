import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {createAutoCorrectedDatePipe, validBirthday} from "../../utils/Helpers";
import MaskedInput from "react-text-mask";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

/**
 * @param name
 * @param middleName
 * @param surname
 * @param birthday
 * @param title
 * @param titleOption
 * @param setName
 * @param setMiddleName
 * @param setSurname
 * @param setBirthday
 * @param setTitle
 * @param proceedStep
 * @param history
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const PersonalDetailsForm = (
	{
	name = "",
	middleName = "",
	surname = "",
	birthday = "",
	title  = "",
	titleOption = [],
	setName = () => {
	},
	setMiddleName = () => {
	},
	setSurname = () => {
	},
	setBirthday = () => {
	},
	setTitle = () => {
	},
	proceedStep = () => {
	},
	history = () => {
	},
	t = key => key,
}
): JSX.Element => (
	<Grid
		id={"signup-container"}
		xl={3.75}
		lg={4.5}
		md={5.5}
		sm={7}
		xs={11}
		container
		item
		justifyContent={"center"}
	>
		<form
			noValidate
			autoComplete={"off"}
		>
			<FormControl
				fullWidth
			>
				<Typography
					align={"center"}
					className={"page-title"}
				>
					{t("individual.sign_up.create_your_account.title")}
				</Typography>
			</FormControl>
			<FormControl
				fullWidth
			>
				<Typography
					align={"center"}
					className={"page-subtitle"}
				>
					{t("individual.sign_up.create_your_account.subtitle")}
				</Typography>
			</FormControl>
			<FormControl
				className={"signup-form-control"}
				fullWidth
			>
				<Autocomplete
					id={"title"}
					options={titleOption ?? []}
					getOptionLabel={(option) => option.title}
					onChange={(event, value= {
						title: "",
					}) => setTitle(value?.title ?? "")}
					renderInput={(params) =>
						<TextField
							{...params}
							label={`${t("individual.sign_up.create_your_account.salutation_input")} *`}
							variant={"outlined"}
							name={"title"}
							id={"title"}
							value={title}
						/>
					}
				/>
			</FormControl>
			<FormControl
				className={"signup-form-control"}
				fullWidth
			>
				<TextField
					type={"text"}
					onChange={event => setName(event.target.value)}
					label={`${t("individual.my_account.beneficiaries.name")} *`}
					variant={"outlined"}
					name={"name"}
					id={"name"}
					value={name}
				/>
			</FormControl>
			<FormControl
				className={"signup-form-control"}
				fullWidth
			>
				<TextField
					type={"text"}
					onChange={event => setMiddleName(event.target.value)}
					label={`${t("individual.sign_up.create_your_account.middle_name_input")}`}
					variant="outlined"
					name={"middle_name"}
					id={"middle_name"}
					value={middleName}
				/>
			</FormControl>
			<FormControl
				className={"signup-form-control"}
				fullWidth
			>
				<TextField
					type={"text"}
					onChange={event => setSurname(event.target.value)}
					label={`${t("individual.my_account.personal_information.surname")} *`}
					variant="outlined"
					name={"surname"}
					id={"surname"}
					value={surname}
				/>
			</FormControl>
			<FormControl
				className={"signup-form-control"}
				fullWidth
			>
				<MaskedInput
					mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
					placeholderChar={'\u2000'}
					placeholder={`${t("individual.birthdate")} *`}
					className={"signup-form-control"}
					label={`${t("individual.birthdate")} *`}
					variant="outlined"
					name={"birthday"}
					id={"birthday"}
					value={birthday}
					onChange={event => setBirthday(event.target.value)}
					pipe={createAutoCorrectedDatePipe("dd mm yyyy", {
						minYear: 1900,
						maxYear: new Date().getFullYear() - 16
					})}
				/>

			</FormControl>
			<FormControl
				className={"signup-form-control-2 forgot-password"}
				fullWidth
			>
				<small className={"red-text"}>
					{
						name === "" ||
						surname === "" ||
						birthday === "" ? t("individual.required_fields_span") : ""
					}
				</small>
			</FormControl>
			<FormControl
				fullWidth
			>
				<Button
					variant={"contained"}
					className={"button"}
					onClick={() => proceedStep()}
					disabled={
						!name ||
						!surname ||
						!validBirthday(birthday ?? "") ||
						!title
					}
				>
					{t("individual.login.continue_button")}
				</Button>
			</FormControl>
		</form>
	</Grid>
);

export default PersonalDetailsForm;