// importing material ui components.
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Slider from '@mui/material/Slider';

/**
 * @param retirementAge
 * @returns {string}
 */
function renderClassNames(retirementAge = 67): string {

	if (retirementAge < 67) {
		return "pot-minimum";
	}

	return "pot-comfortable";
}

/**
 * @param profile_data
 * @param retirementAge
 * @param setRetirementAge
 * @param setSimulation
 * @returns {JSX.Element}
 * @constructor
 */
const RetirementAgeSlider = (
	{
		profile_data = [],
		retirementAge = 67,
		setRetirementAge = () => {
		},
		setSimulation = () => {
		}
	}
): JSX.Element => {

	return (
		<div
			id={"retirement-age-slider-card-container"}
		>
			<Card
				className={"card-container"}
			>
				<CardContent>
					<Slider
						className={renderClassNames(retirementAge)}
						value={retirementAge}
						min={57}
						max={75}
						onChange={(event, newValue) => {
							setRetirementAge(newValue);
							setSimulation(true);
						}}
					/>
				</CardContent>
			</Card>
		</div>
	);
}

export default RetirementAgeSlider;