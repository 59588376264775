import OtherPensionsService from "../../services/OtherPensions";
import {ADD_OTHER_PENSIONS, DELETE_OTHER_PENSIONS, FETCH_OTHER_PENSIONS} from "./types";
import {myProjections} from "../Profile";

/**
 * @returns {function(...[*]=)}
 */
export const get = () => dispatch => {
	new OtherPensionsService()
		.active()
		.then(response => dispatch({
			type: FETCH_OTHER_PENSIONS,
			other_pensions: response.data.data,
			code: 0,
		}))
		.catch(error => {
			console.error(error);
			if (error.response) {
				if (error.response.status === 401) {
					localStorage.clear();
					window.location = "/login";
				}
			}
		});
};

/**
 * @stateless
 * @param data
 * @returns {function(...[*]=)}
 */
export const create = (data: any = false) => dispatch => {
	if (data) {
		new OtherPensionsService()
			.insert(data)
			.then(response => {
				dispatch({
					type: ADD_OTHER_PENSIONS,
				});
				if (response.data.data) {
					window.location = "/other-pensions";
				}
			})
			.catch(error => {
				console.error(error);
				if (error.response) {
					if (error.response.status === 401) {
						localStorage.clear();
						window.location = "/login";
					}
				}
			});
	}
}

/**
 * @param id
 * @returns {function(...[*]=)}
 */
export const erase = (id: any = false) => dispatch => {
	if (id) {
		new OtherPensionsService()
			.delete(id)
			.then(() => {
				dispatch({
					type: DELETE_OTHER_PENSIONS,
				});
				dispatch(get());
				dispatch(myProjections());
			})
			.catch(error => {
				console.error(error);
				if (error.response) {
					if (error.response.status === 401) {
						localStorage.clear();
						window.location = "/login";
					}
				}
			});
	}
};