import React from 'react';
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {connect} from "react-redux";
import {confirmCode, resendCode} from "../../../../actions/ActivationCode";
import Loading from "../../../Loading";
import TextField from "@mui/material/TextField";

/**
 * @returns {*}
 */
const ConfirmCode = ({
	activation_code_loading = false,
	step_loading = false,
	activeStep = false,
	code = "",
	token = "",
	errorMessage = "",
	individual = [],
	setCode = () => {
	},
	resendCode = () => {
	},
	confirmCode = () => {
	},
	t = () => {}
}): JSX.Element => {
	if (activation_code_loading || step_loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<Grid
			xl={12}
			lg={12}
			md={12}
			container
			item
			direction={"row"}
			justifyContent={"center"}
			id={"change-email-wrapper"}
		>
			<Grid
				xl={9.5}
				lg={9.5}
				md={12}
				sm={12}
				xs={12}
				item
				direction={"row"}
				container
				justifyContent={"center"}
				className={"grid"}
			>
				<Grid
					xl={6.55}
					lg={7.2}
					md={8}
					sm={12}
					xs={12}
					item
					container
					direction={"row"}
					display={"inline-block"}
					textAlign={"-webkit-center"}
				>
					<Typography
						className={"title"}
					>
						{t("individual.my_account.login_details.verify_account.title")}
					</Typography>
					<Typography
						className={"subtitle"}
					>
						{t("individual.my_account.login_details.verify_account.security_reasons_message")}
					</Typography>
					<Grid
						xl={8.2}
						lg={8.2}
						md={8.2}
						sm={6}
						xs={12}
						item
						container
					>
						<Typography
							className={"label-code"}
						>
							{t("individual.code_sent_by_email")}
						</Typography>
						<TextField
							id={"code"}
							className={"textfield2 color-extra"}
							value={code ?? ""}
							onChange={(e) => setCode(e.target.value)}
							fullWidth
						/>
						<Fade in={errorMessage !== "" ?? false}>
							<small className={'collegia-text-danger default-error'}>
								{errorMessage}
							</small>
						</Fade>
						<Typography
							className={"label-send-again"}
						>
							{t("individual.did_not_receive_the_code")}
							<br/>
							<span
								onClick={() =>
									resendCode({
										activation_codes: {
											individual_id: individual.id,
										}
									})
								}
							>
							{t("individual.send_again")}
								&nbsp;
						</span>
							Or
							<span
								onClick={() => window.location.reload()}
							>
							&nbsp;
								{t("individual.change_email")}
						</span>
						</Typography>
						<Button
							fullWidth
							onClick={() =>
								confirmCode({
									activation_codes: {
										code,
										step: activeStep
									}
								}, token)
							}
							disabled={!code}
						>
							{t("individual.login.continue_button")}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		ActivationCode = [],
		Steps,
	} = state;

	return {
		...ActivationCode,
		...Steps,
	};
};

/**
 * @param dispatch
 * @returns {{confirmCode: (function(*=): *), resendCode: (function(*=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	resendCode: (data = false) => dispatch(resendCode(data)),
	confirmCode: (data = false, token = "") => dispatch(confirmCode(data, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmCode);