import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";

/**
 * @param accepted_at
 * @param deny_at
 * @param active
 * @private
 */
const _renderAuthorizationInformation = (
	accepted_at: "",
	deny_at: "",
	active: false,
) => {
	let message = "";

	if (!active) {
		if (deny_at) {
			message = `You denied the software connection at ${deny_at}`;
		}
	} else {
		message = `You accepted the software connection at ${accepted_at}`;
	}

	return (
		<Grid
			xs={12}
			sm={12}
			md={4}
			lg={4}
			direction={"column"}
			alignContent={"center"}
			alignItems={"center"}
			className={"action-card-container"}
			item
			container
		>
			<small className={"acceptance-information"}>
				{message}
			</small>
		</Grid>
	);
};

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CardList = (props = {
	id: null,
	software: {
		name: "",
		email: "",
	},
	accepted_at: "",
	deny_at: "",
	intermediary_token: "",
	token: "",
	active: false,
	buttonActions: (token: string = props?.token, code: string = "", action: string = "") => ([code, action, token]),
	retrieveByToken: (token: string = props?.token) => ([token]),
}): JSX.Element => (
	<Card
		className={"software-connection-card-list"}
	>
		<CardHeader
			title={props?.software?.name}
			subheader={
				<a href={`mailto:${props?.software?.email}`}>
					{props?.software?.email}
				</a>
			}
		/>
		<CardContent
			className={"card-body-container"}
		>
			<Grid
				id={"body"}
				xs={12}
				sm={12}
				lg={12}
				md={12}
				item
			>
				<p className={"subtitle"}>

					What the software connection will able to see if you allow it:
				</p>
				<ul>
					<li>
						Access your Collegia account projections.
					</li>
					<li>
						Contributions history.
					</li>
					<li>
						How many years you have until get retired.
					</li>
				</ul>
			</Grid>
		</CardContent>
		<CardActions>
			<Grid
				xs={12}
				sm={12}
				md={12}
				lg={12}
				container
				item
			>
				<Grid
					xs={12}
					sm={12}
					md={4}
					lg={4}
					alignContent={"flex-start"}
					direction={"column"}
					className={"action-card-container"}
					item
					container
				>
					<Button
						variant={"outlined"}
						className={"MuiCardHeader-button accept-button"}
						onClick={() => props?.buttonActions(
							props?.token ?? false,
							props?.intermediary_token ?? false,
							"authorize"
						)}
						fullWidth
					>
						Ok, I accept the connection.
					</Button>
				</Grid>
				<Grid
					xs={12}
					sm={12}
					md={4}
					lg={4}
					alignContent={"flex-start"}
					direction={"column"}
					className={"action-card-container"}
					item
					container
				>
					<Button
						variant={"outlined"}
						className={"MuiCardHeader-button deny-button"}
						onClick={() => props?.buttonActions(
							props?.token ?? "",
							props?.intermediary_token ?? false,
							"deny"
						)}
						fullWidth
					>
						No, I don't accept the connection.
					</Button>
				</Grid>
				{_renderAuthorizationInformation(
					props?.accepted_at,
					props?.deny_at,
					props?.active
				)}
			</Grid>
		</CardActions>
	</Card>
);

export default CardList;