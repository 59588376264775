import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const OtherPensionsCardList = (props): JSX.Element => (
	<Card
		className={"default-card-list"}
	>
		<CardHeader
			action={
				<IconButton
					aria-label="settings"
					disableFocusRipple
					disableRipple
					disableTouchRipple
					onClick={() => props.erase(props.pension.id)}
				>
					<DeleteOutlineIcon
						className={"trash-icon"}
					/>
				</IconButton>
			}
			title={props.pension.pension_provider}
			subheader={props.t("individual.in_projections")}
		/>
		<CardContent>
			<div>
                <span>
                    <LockOutlinedIcon className={"lock-icon"}/>
                </span>
				<span className={"money-value"}>
                    £ {props.pension.formatted_total}
                </span>
			</div>
		</CardContent>
	</Card>
);


export default OtherPensionsCardList;