import ExtraContributionsService from "../../services/ExtraContributions";
import {
	ADD_NEW_EXTRA_CONTRIBUTIONS,
	CANCEL_PREPARE_EMANDATE, CREATE_INDIVIDUAL_SCHEDULE,
	FETCH_EXTRA_CONTRIBUTIONS,
	FETCH_ORDER_ID_DETAILS,
	PREPARE_EMANDATE,
	RETRIEVE_BANK_LIST, VERIFY_EMANDATE
} from "./types";
import {forceScrollUp} from "../../utils/Helpers";
import {PROFILE_RETRIEVE_BANK_ACCOUNTS} from "../Profile/types";
import * as SnackBar from "../SnackBar";
import Request from "../../utils/Request";

/**
 * @action
 * @param data
 * @returns {function(...[*]=)}
 */
export const insert = (data: any[] = false) => dispatch => {
	if (data) {
		dispatch({
			type: ADD_NEW_EXTRA_CONTRIBUTIONS,
			new_payment_object: false,
			payment_loading: true
		});
		new ExtraContributionsService()
			.insert(data)
			.then(response => dispatch({
					type: ADD_NEW_EXTRA_CONTRIBUTIONS,
					new_payment_object: response.data.data,
					payment_loading: false
				})
			)
			.catch(error => {
				console.error(error);
				dispatch({
					type: ADD_NEW_EXTRA_CONTRIBUTIONS,
					payment_loading: false,
				});
				// if (error.response) {
				//     if (error.response.status === 401) {
				//         localStorage.clear();
				//         window.location = "/login";
				//     }
				// }
			});
	}
};

/**
 * @action
 * @param orderId
 * @param type
 * @returns {function(...[*]=)}
 */
export const retrievePaymentInfo = (orderId: string = "", type: string = "") => dispatch => {
	if (orderId !== "") {
		new ExtraContributionsService()
			.retrievePaymentInfo(orderId ?? "", type ?? "")
			.catch(error => {
				console.error(error);
				if (error.response) {
					if (error.response.status === 401) {
						localStorage.clear();
						window.location = "/login";
					}
				}
			});
	}
}

/**
 * @action
 * @returns {function(...[*]=)}
 */
export const clearPaymentObject = () => dispatch => {
	dispatch({
		type: ADD_NEW_EXTRA_CONTRIBUTIONS,
		new_payment_object: false,
	});
}

/**
 * @action
 * @param scrollUp
 * @param oldPage
 * @param pageNumber
 * @param abortController
 * @param first
 * @returns {function(...[*]=)}
 */
export const get = (scrollUp: boolean = false, oldPage: number = 1, pageNumber: number = 1, abortController = false, first = false) => dispatch => {
	if(oldPage !== pageNumber) {
		dispatch({
			type: FETCH_EXTRA_CONTRIBUTIONS,
			contributions: [],
			contributionsLoading: true
		});
	}
	if(first) {
		dispatch({
			type: FETCH_EXTRA_CONTRIBUTIONS,
			contributionsLoading: true
		});
	}
	new ExtraContributionsService()
		.active(pageNumber, abortController)
		.then(response => {
			if(!abortController?.signal?.aborted) {
				dispatch({
					type: FETCH_EXTRA_CONTRIBUTIONS,
					contributions: response.data.data,
					contributionsLoading: false
				});

				if (scrollUp) {
					forceScrollUp();
				}
			}
		})
		.catch(error => console.error(error));
};

/**
 * @action
 * @param orderId
 * @param scrollUp
 * @returns {function(...[*]=)}
 */
export const details = (orderId: string = "", scrollUp: boolean = false) => dispatch => {
	dispatch({
		type: FETCH_ORDER_ID_DETAILS,
		contribution: false,
		payment_loading: true,
	});
	if (orderId !== "") {
		new ExtraContributionsService()
			.details(orderId ?? "")
			.then((response = {
				code: 200,
				completed_at: "",
				message: "",
				data: null,
				error: false,
				success: true,
			}) => {
				dispatch({
					type: FETCH_ORDER_ID_DETAILS,
					contribution: response?.data?.data,
					payment_loading: false,
				});

				if (response?.data?.code === 404) {
					window.location.href = "/extra-contributions";
				}

				if (scrollUp) {
					forceScrollUp();
				}
			})
			.catch(error => {
				console.error(error);
				if (error.response) {
					if (error.response.status === 401) {
						localStorage.clear();
						window.location = "/login";
					}
				}

				dispatch({
					type: FETCH_ORDER_ID_DETAILS,
					contribution: false,
					payment_loading: false,
				});
			});
	}
}

/**
 * @action
 * @returns {function(...[*]=)}
 */
export const retrieveBankList = () => dispatch => {
	dispatch({
		type: RETRIEVE_BANK_LIST,
		bank_list: [],
		payment_loading: true
	});
	dispatch({
		type: PROFILE_RETRIEVE_BANK_ACCOUNTS,
		bank_accounts: [],
	});
	new ExtraContributionsService()
		.bankList()
		.then(response => response.data.data ? dispatch({
			type: RETRIEVE_BANK_LIST,
			bank_list: response.data.data,
			payment_loading: false
		}) : dispatch({
			type: RETRIEVE_BANK_LIST,
			bank_list: [],
			payment_loading: false
		}))
		.catch(error => console.error(error));
}

/**
 * @param data
 * @returns {(function(*): void)|*}
 */
export const confirmDirectDebit = (data: any = false) => dispatch => {
	if (data) {
		dispatch({
			type: ADD_NEW_EXTRA_CONTRIBUTIONS,
			new_payment_object: false,
			confirm_mandate_loading: true
		});

		new ExtraContributionsService()
			.confirmDirectDebit(data ?? false)
			.then(response => {
				dispatch({
					type: ADD_NEW_EXTRA_CONTRIBUTIONS,
					new_payment_object: false,
					confirm_mandate_loading: false
				});
				if (response?.data?.data) {
					window.location.href = "/extra-contributions";
				}
			})
			.catch(error => {
				if (error?.response?.status === 400) {
					dispatch(SnackBar.error("Something went wrong. Please contact support."), 5000);
				}
				dispatch(details(data?.endToEnd));

				console.error(error);
				dispatch({
					type: FETCH_ORDER_ID_DETAILS,
					contribution: false,
					confirm_mandate_loading: false,
				});
				if (error?.response?.status === 401) {
					localStorage.clear();
					window.location = "/login";
				}
			});
	}
}

/**
 * @returns {function(*): Promise<*>}
 */
export const getEndToEnd = () => dispatch => {

	new ExtraContributionsService()
		.getEndToEnd()
		.then(response => dispatch({
			type: RETRIEVE_BANK_LIST,
			endToEnd: response.data.data,
			payment_loading: false,
		}))
		.catch(error => {

			console.error(error);
			if (error.response) {
				if (error.response.status === 401) {
					localStorage.clear();
					window.location = "/login";
				}
			}

			dispatch({
				type: FETCH_ORDER_ID_DETAILS,
				contribution: false,
				payment_loading: false,
			});
		});
};

/**
 * @param endToEnd
 * @returns {(function(*): void)|*}
 */
export const cancelDirectDebitSigning = (endToEnd: string = "") => dispatch => {
	if (endToEnd) {

		dispatch({
			type: ADD_NEW_EXTRA_CONTRIBUTIONS,
			new_payment_object: false,
			cancel_mandate_loading: true
		});
		new ExtraContributionsService()
			.cancelDirectDebitSigning(endToEnd ?? "")
			.then(response => {
				dispatch({
					type: ADD_NEW_EXTRA_CONTRIBUTIONS,
					new_payment_object: false,
					cancel_mandate_loading: false
				});

				if (response?.data?.data) {
					window.location.href = "/extra-contributions";
				}

				if (typeof response?.data?.data === "number") {
					if (response?.data?.data === 0) {
						window.location.href = "/extra-contributions";
					}
				}
			})
			.catch(error => {
				dispatch({
					type: ADD_NEW_EXTRA_CONTRIBUTIONS,
					new_payment_object: false,
					cancel_mandate_loading: false
				});
				// Error 😨
				let message = "Something went wrong.";
				if (error?.response) {
					/*
					 * The request was made and the server responded with a
					 * status code that falls out of the range of 2xx
					 */
					const {
						response = {
							data: {
								code: 400,
								completed_at: "2021-05-25 10:05:20",
								data: "Something went wrong.",
								error: true,
								message: "",
								success: false,
							},
						},
					} = error;

					if (response?.data) {
						const {
							data = {
								code: 400,
								completed_at: "2021-05-25 10:05:20",
								data: "Something went wrong.",
								error: true,
								message: "",
								success: false,
							}
						} = response?.data;

						if (typeof data === "string") {
							message = data;
						}
					}
				}

				dispatch({
					type: FETCH_ORDER_ID_DETAILS,
					contribution: false,
					payment_loading: false,
					message,
				});
			});
	}
};

/**
 * @param endToEnd
 * @returns {(function(*): void)|*}
 */
export const cancelDDI = (endToEnd: string = "") => dispatch => {
	dispatch({
		type: FETCH_ORDER_ID_DETAILS,
		contribution: false,
		payment_loading: true,
	});
	new ExtraContributionsService()
		.cancelDDI(endToEnd ?? "")
		.then(response => {
			if (response.data.data) {
				window.location.href = "/extra-contributions";
			}
			dispatch({
				type: FETCH_ORDER_ID_DETAILS,
				contribution: false,
				payment_loading: false,
			});
		})
		.catch(error => {
			// Error 😨
			let message = "Please verify your phone number.";
			if (error?.response) {
				/*
				 * The request was made and the server responded with a
				 * status code that falls out of the range of 2xx
				 */
				const {
					response = {
						data: {
							code: 400,
							completed_at: "2021-05-25 10:05:20",
							data: "Incorrect code.",
							error: true,
							message: "",
							success: false,
						},
					},
				} = error;

				if (response?.data) {
					const {
						data = {
							code: 400,
							completed_at: "2021-05-25 10:05:20",
							data: "Incorrect code.",
							error: true,
							message: "",
							success: false,
						}
					} = response?.data;

					if (typeof data === "string") {
						message = data;
					}
				}
			}

			dispatch({
				type: FETCH_ORDER_ID_DETAILS,
				contribution: false,
				payment_loading: false,
				message,
			});
		});
};

/**
 * @param id
 * @param data
 * @returns {(function(*): void)|*}
 */
export const updateSourceOfFunds = (id, data) => dispatch => {
	if (id && data) {
		new ExtraContributionsService()
			.updateSourceOfFunds(id, data)
			.then(() => dispatch(get()))
			.catch(error => console.error(error));
	}
}

export const prepareEmandate = (data) => dispatch => {
	dispatch({
		type: PREPARE_EMANDATE,
		prepare_emandate_loading: true,
		token: "",
	});
	if (data) {
		Request.post("/individual/contributions/prepare-emandate", data)
			.then(response => {
				if (response?.data?.data?.success) {
					dispatch({
						type: PREPARE_EMANDATE,
						prepare_emandate_loading: false,
						token: response?.data?.data?.token
					});
					let link = "https://sandbox.nuapay.com/tpp-ui/app/emandate-ui/aisp?flow=WEB_REDIRECT&token=" + response?.data?.data?.token;
					if (process.env.REACT_APP_ENV === "production") {
						link = "https://api.nuapay.com/tpp-ui/app/emandate-ui/aisp?flow=WEB_REDIRECT&token=" + response?.data?.data?.token;
					}
					setTimeout(function() {
						window.open(link, "_blank");
						window.location.href = "/confirm-monthly-contribution";
					}, 5000);
				} else {
					dispatch({
						type: PREPARE_EMANDATE,
						prepare_emandate_loading: false,
						token: "",
					});
					SnackBar.error(response?.data?.message);
				}
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: PREPARE_EMANDATE,
					prepare_emandate_loading: false,
					token: "",
				});
			});
	}
};

export const cancelPrepareEmandate = (id) => dispatch => {
	dispatch({
		type: CANCEL_PREPARE_EMANDATE,
		prepare_emandate_loading: true,
		token: "",
	});
	if (id) {
		Request.delete(`/individual/contributions/cancel-preparate-emandate/${id}`)
			.then(response => {
				if (response?.data?.success) {
					dispatch({
						type: CANCEL_PREPARE_EMANDATE,
						prepare_emandate_loading: false,
						token: "",
					});
					window.location.href = "/";
				} else {
					dispatch({
						type: CANCEL_PREPARE_EMANDATE,
						prepare_emandate_loading: false,
					});
				}
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: CANCEL_PREPARE_EMANDATE,
					prepare_emandate_loading: false,
					token: "",
				});
			});
	}
};

export const verifyIfTheMandateFinish = (id: number = 0) => dispatch => {
	dispatch({
		type: VERIFY_EMANDATE,
		verify_emandate_loading: true
	});
	if (id) {
		Request.get(`/individual/contributions/verify-if-the-emandate-finish/${id}`)
			.then(response => {
				if (response?.data?.data?.success) {
					dispatch({
						type: VERIFY_EMANDATE,
						verify_emandate_loading: false
					});
					window.location.reload();
				} else {
					dispatch({
						type: VERIFY_EMANDATE,
						verify_emandate_loading: true
					});
				}
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: VERIFY_EMANDATE,
					verify_emandate_loading: false
				});
			});
	}
};

export const createIndividualSchedule = () => dispatch => {
	dispatch({
		type: CREATE_INDIVIDUAL_SCHEDULE,
		verify_emandate_loading: true
	});
	Request.get(`/individual/contributions/create-individual-schedule`)
		.then(response => {
			if (response?.data?.data?.success) {
				dispatch({
					type: CREATE_INDIVIDUAL_SCHEDULE,
					verify_emandate_loading: false
				});
				window.location.href = "/";
			} else {
				dispatch({
					type: CREATE_INDIVIDUAL_SCHEDULE,
					verify_emandate_loading: true
				});
			}
		})
		.catch(error => {
			console.error(error);
			dispatch({
				type: CREATE_INDIVIDUAL_SCHEDULE,
				verify_emandate_loading: false
			});
		});
};