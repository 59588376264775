import ActivationToken from "../../services/ActivationToken";
import * as SnackBar from "../SnackBar";
import {GET_BY_TOKEN} from "../ActivationToken/types";

/**
 * @param data
 * @returns {function(...[*]=)}
 */
export const optOut = (data = false) => dispatch => {
	if (data) {
		dispatch({
			type: GET_BY_TOKEN,
			token_loading: true,
		});
		new ActivationToken()
			.optOut(data)
			.then(() => {
				window.location = "/account-deleted";
				dispatch({
					type: GET_BY_TOKEN,
					token_loading: false,
				});
			})
			.catch(error => {
				dispatch({
					type: GET_BY_TOKEN,
					token_loading: false,
				});
				dispatch(SnackBar.error("An unexpected error occurred. Please contact support and inform code OPT-11."), 9000);
				console.error(error);
			});
	}
};