import Request from "../../utils/Request";

/**
 * @param id
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
const UpdateSourceOfFundsPromise = (id ,data) => Request.put(`/individual/contributions/update-source-of-funds/${id}`, data);

export default UpdateSourceOfFundsPromise;