import AssetsLocationService from "../../services/AssetsLocation";
import {FETCH_ASSETS_LOCATION} from "./types";

/**
 * @returns {function(...[*]=)}
 */
export const getAssetsLocation = (id: number = 0) => dispatch => {
	dispatch({
		type: FETCH_ASSETS_LOCATION,
		assets_location_loading: true,
	});
	new AssetsLocationService()
		.getAssetsLocation(id)
		.then(response => {
			dispatch({
				type: FETCH_ASSETS_LOCATION,
				assets_location: response?.data?.data ?? {},
				assets_location_loading: false,
			});
		})
		.catch(error => {
			console.error(error);
			dispatch({
				type: FETCH_ASSETS_LOCATION,
				assets_location_loading: false,
			});
			if (error.response) {
				if (error.response.status === 401) {
					localStorage.clear();
					window.location = "/login";
				}
			}
		});
};