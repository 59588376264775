import React from "react";

import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

// importing the icons
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';

/**
 * @param props
 * @returns {string|*}
 * @private
 */
function _renderHeaderClassName(props: any = {
	ifa_connections: [],
	t: key => key,
}): string {
	if (props) {
		if (props.ifa_connections) {
			return props.ifa_connections.length > 0 ? "active" : "inactive";
		}
	}

	return props.t("individual.ae.cards.inactive_label");
}

/**
 * @param props
 * @returns {string}
 * @private
 */
function _renderSubHeaderInfo(props: any[] = {
	ifa_connections: [],
	t: key => key,
}): string {
	if (props) {
		if (props.ifa_connections && props.ifa_connections.length > 0) {
			return `${props.ifa_connections.length} ${props.t("individual.connection")}`;
		}
	}

	return props.t("individual.no_connection_dot");
}

/**
 * @param props
 * @returns {*}
 * @private
 */
function _renderPensionValues(props: any[] = {
	ifa_connections: [{
		active: false,
	}],
	t: key => key,
}): string {
	let notResolvedCount = props.ifa_connections?.filter(ifa => ifa.active === null )?.length ?? 0;

	if (notResolvedCount > 0) {
		return props.t("individual.your_pensions.you_have_a_quantity_new_ifa_connection_connection", {
			quantity: notResolvedCount
		});
	}

	return props.t("individual.your_pensions.you_have_a_quantity_new_ifa_connection_connections", {
		quantity: props.ifa_connections.length,
	});
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const IFAConnections = (
	props: any[] = {
	ifa_connections: [],
	history: {
		push: () => {
		},
	},
	t: key => key,
}
): JSX.Element => (
	<Card
		id={"extra-contributions-card"}
		onClick={() => props.history("/ifa-connections")}
	>
		<CardHeader
			className={_renderHeaderClassName(props)}
			action={
				<IconButton
					aria-label="settings"
					disableTouchRipple
					disableFocusRipple
					disableRipple
				>
					<KeyboardArrowRightIcon/>
				</IconButton>
			}
			title={props.t("individual.ifa_connections")}
			subheader={_renderSubHeaderInfo(props)}
		/>
		<CardContent>
			<Grid
				xs={12}
				sm={12}
				md={12}
				lg={12}
				item
				container
			>
				<Grid
					className={`${_renderHeaderClassName(props)} line-padding`}
					xs={6}
					sm={6}
					md={6}
					lg={6}
					item
					container
				>
					<Grid
						xs={1}
						sm={1}
						md={1}
						lg={1}
						item
					>
						<ImportantDevicesIcon className={`lock-icon ${_renderHeaderClassName(props)}`}/>
					</Grid>
					<Grid
						xs={10}
						sm={10}
						md={10}
						lg={10}
						item
					>
                        <span
	                        className={_renderHeaderClassName(props)}
	                        style={{
		                        paddingLeft: "5px",
	                        }}
                        >
                            {_renderPensionValues(props)}
                        </span>
					</Grid>
				</Grid>
				<Grid
					xs={6}
					sm={6}
					md={6}
					lg={6}
					alignItems={"flex-end"}
					alignContent={"flex-end"}
					direction={"column"}
					item
					container
				>
					<ImportantDevicesIcon className={`${_renderHeaderClassName(props)}`}/>
				</Grid>
			</Grid>
		</CardContent>
	</Card>
);

export default IFAConnections;