import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param endToEnd
 * @returns {Promise<AxiosResponse<any>>}
 */
const CancelDDIPromise = (endToEnd: string = ""): Promise<AxiosResponse<any>> =>
	Request.post(`/individual/contributions/cancel-ddi/${endToEnd}`);

export default CancelDDIPromise;