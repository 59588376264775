import { FETCH_ASSETS_LOCATION } from "../../actions/AssetsLocation/types";
import { resolveReduxState } from "../../utils/Helpers";

/**
 * @type {{investment_approaches: [], investment_approach_loading: boolean}}
 */
const initial_state = {
	assets_location_loading: false,
	assets_location: [],
}

/**
 * @param state
 * @param action
 * @returns {{investment_approaches: [], investment_approach_loading: boolean}|{valueOf, (): boolean}|boolean}
 */
const AssetsLocationStore = (state = initial_state, action: any = false) => {
	if (action) {
		const {
			type = false
		} = action;
		if (type) {
			if (type === FETCH_ASSETS_LOCATION) return resolveReduxState(state, action);
		}
	}

	return state;
};

export default AssetsLocationStore;