import React from "react";
import Wrapper from "../../layout/Logged/Wrapper";
import Grid from '@mui/material/Grid';
import {get, updateInvestmentApproach} from "../../actions/InvestmentApproach";
import {connect} from "react-redux";
import SustainableDetails from "../../components/InvestmentApproach/SustainableDetails";
import LowGrowthDetails from "../../components/InvestmentApproach/LowGrowthDetails";
import HighGrowthDetails from "../../components/InvestmentApproach/HighGrowthDetails";
import StandardDetails from "../../components/InvestmentApproach/StandardDetails";
import ShariahDetails from "../../components/InvestmentApproach/ShariahDetails";
import Loading from "../../components/Loading";
import {useTranslation, withTranslation} from "react-i18next";
import {returnUser} from "../../actions/Profile";
import {useNavigate, useParams} from "react-router-dom";

/**
 * @param investment_approaches
 * @param name
 * @param currentStrategy
 * @param updateInvestmentApproach
 * @param hasIfa
 * @param t
 * @returns {JSX.Element}
 * @private
 */
function _renderDetails(
	investment_approaches,
	name,
	currentStrategy,
	updateInvestmentApproach,
	hasIfa,
	t = key => key,
): JSX.Element {
	const currentInvestment = investment_approaches.filter(i => name === i.name);

	if (currentInvestment.length > 0) {
		const investment = currentInvestment[0];

		switch (investment.name) {
			case t("system.investment_approaches.sustainable"):
				return (
					<SustainableDetails
						currentStrategy={currentStrategy}
						name={name}
						investment={investment}
						updateInvestmentApproach={updateInvestmentApproach}
						hasIfa={hasIfa}
						t={t}
					/>
				);
			case t("system.investment_approaches.standard"):
				return (
					<StandardDetails
						currentStrategy={currentStrategy}
						name={name}
						investment={investment}
						updateInvestmentApproach={updateInvestmentApproach}
						hasIfa={hasIfa}
						t={t}
					/>
				)
			case t("system.investment_approaches.high_growth"):
				return (
					<HighGrowthDetails
						currentStrategy={currentStrategy}
						name={name}
						investment={investment}
						updateInvestmentApproach={updateInvestmentApproach}
						hasIfa={hasIfa}
						t={t}
					/>
				);
			case t("system.investment_approaches.low_growth"):
				return (
					<LowGrowthDetails
						currentStrategy={currentStrategy}
						name={name}
						investment={investment}
						updateInvestmentApproach={updateInvestmentApproach}
						hasIfa={hasIfa}
						t={t}
					/>
				);
			case t("system.investment_approaches.islamic_finance"):
				return (
					<ShariahDetails
						currentStrategy={currentStrategy}
						name={name}
						investment={investment}
						updateInvestmentApproach={updateInvestmentApproach}
						hasIfa={hasIfa}
						t={t}
					/>
				);
			default:
				break;
		}
	}
}

/**
 * @param investment_approaches
 * @param match
 * @param updateInvestmentApproach
 * @param profile_data
 * @param investment_approach_loading
 * @param loading
 * @returns {JSX.Element}
 * @constructor
 */
const InvestmentApproachDetails = (
	{
		investment_approaches = [],
		match = {
			params: {
				token: ""
			}
		},
		updateInvestmentApproach = () => {
		},
		profile_data = [],
		investment_approach_loading = false,
		loading = false,
	}
): JSX.Element => {
	let hasIfa = false;

	const currentStrategy = profile_data?.individual_investment_approach?.investment_approach?.name;
	const name = useParams().name;
	const {t} = useTranslation(),
		history = useNavigate();

	if (investment_approach_loading || loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<Wrapper
			history={history}
			hideFooter={true}
			t={t}
		>
			<Grid
				container
				item
				id={"investment-approach-details-container"}
				justifyContent={"center"}
			>
				{_renderDetails(investment_approaches, name, currentStrategy, updateInvestmentApproach, hasIfa, t)}
			</Grid>
		</Wrapper>
	);
}

const mapStateToProps = state => {
	const {
		InvestmentApproach = [],
		Profile = [],
	} = state;

	return {
		...InvestmentApproach,
		...Profile,
	};
};

/**
 * @param dispatch
 * @returns {{get: (function(): *)}}
 */
const mapDispatchToProps = dispatch => ({
	get: dispatch(get()),
	updateInvestmentApproach: (data: any = false) => dispatch(updateInvestmentApproach(data)),
	returnUser: dispatch(returnUser()),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(InvestmentApproachDetails));