import React from "react";

import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

// importing the icons
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';


/**
 * @param props
 * @returns {string}
 * @private
 */
function _renderHeaderClassName(props: any = {
	third_party_software_connections: [],
}): string {
	if (props) {
		if (props.third_party_software_connections) {
			return props.third_party_software_connections.length > 0 ? "active" : "inactive";
		}
	}

	return "inactive";
}

/**
 * @param props
 * @returns {string}
 * @private
 */
function _renderSubHeaderInfo(props: any[] = {
	third_party_software_connections: [],
}) {
	if (props) {
		if (props.third_party_software_connections && props.third_party_software_connections.length > 0) {
			return `${props.third_party_software_connections.length} connection`;
		}
	}

	return "No connections.";
}

/**
 * @param props
 * @returns {string}
 * @private
 */
function _renderPensionValues(props: any[] = {
	third_party_software_connections: [{
		active: false,
		deny_at: null,
		accepted_at: null,
	}],
	t: key => key,
}) {
	let notResolvedCount = 0;

	notResolvedCount = props.third_party_software_connections.filter(s => !s.active && !s.deny_at).length ?? 0;

	if (notResolvedCount > 0) {
		return props.t("individual.software_quantity_pending_approval", {
			quantity: notResolvedCount
		});
	}

	return props.t("individual.you_have_quantity_data_sharing_permissions", {
		quantity: props.third_party_software_connections.length
	});
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SoftwareConnections = (
	props: any[] = {
		third_party_software_connections: [{
			active: false,
			deny_at: null,
			accepted_at: null,
		}],
		history: {
			push: () => {
			},
		},
		t: key => key,
	}
): JSX.Element => (
	<Card
		id={"extra-contributions-card"}
		onClick={() => props.history("/software-connections")}
	>
		<CardHeader
			className={_renderHeaderClassName(props)}
			action={
				<IconButton
					aria-label="settings"
					disableTouchRipple
					disableFocusRipple
					disableRipple
				>
					<KeyboardArrowRightIcon/>
				</IconButton>
			}
			title={props.t("individual.data_sharing_permissions")}
			subheader={_renderSubHeaderInfo(props)}
		/>
		<CardContent>
			<Grid
				xs={12}
				sm={12}
				md={12}
				lg={12}
				item
				container
			>
				<Grid
					className={`${_renderHeaderClassName(props)} line-padding`}
					xs={6}
					sm={6}
					md={6}
					lg={6}
					item
					container
				>
					<Grid
						xs={1}
						sm={1}
						md={1}
						lg={1}
						item
					>
						<ImportantDevicesIcon className={`lock-icon ${_renderHeaderClassName(props)}`}/>
					</Grid>
					<Grid
						xs={10}
						sm={10}
						md={10}
						lg={10}
						item
					>
                        <span
	                        className={_renderHeaderClassName(props)}
	                        style={{
		                        paddingLeft: "5px",
	                        }}
                        >
                            {_renderPensionValues(props)}
                        </span>
					</Grid>
				</Grid>
				<Grid
					xs={6}
					sm={6}
					md={6}
					lg={6}
					alignItems={"flex-end"}
					alignContent={"flex-end"}
					direction={"column"}
					item
					container
				>
					<ImportantDevicesIcon className={`${_renderHeaderClassName(props)}`}/>
				</Grid>
			</Grid>
		</CardContent>
	</Card>
);

export default SoftwareConnections;