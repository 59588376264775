import React from "react";

import Wrapper from "../../layout/Logged/Wrapper";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import {retrieveCollegiaAccessToken} from "../../utils/Helpers";
import {getEndToEnd, insert, retrieveBankList} from "../../actions/ExtraContributions";
import {connect} from "react-redux";
import BankListCard from "../../components/NewContributions/BankListCard";
import Loading from "../../components/Loading";
import UploadDdiFile from "../../components/UploadDdiFile/UploadDdiFile";
import {returnUser} from "../../actions/Profile";
import {withTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import {withRouter} from "../../utils/withRouter";


class SelectBanks extends React.Component {
	/**
	 * @param props
	 */
	constructor(props = {
		location: {
			state: {},
		}
	}) {
		super(props);
		this.state = {
			contributionValue: 0,
			selectedDay: "",
			bankName: "",
			accountNumber: "",
			sortCode: "",
			step: "Monthly",
			accepted: false,
			disableClick: false,
			is_other: false,
			name: "",
			source_of_fund_id: null,
			open: false,
		};
		if (!retrieveCollegiaAccessToken()) {
			window.location = "/login";
		}
	}

	componentDidMount() {
		this.setState({
			...this.props.location?.state,
		});
	}

	/**
	 * @param selectedBankId
	 * @param bankName
	 * @private
	 */
	#_selectMyBank = (selectedBankId: string = "", bankName: string = "") => {
		const {
			contributionValue = 10,
			selectedDay = 1,
			step = "",
			accountNumber = "",
			sortCode = "",
		} = this.state;
		this.#_submitContribution(
			contributionValue,
			selectedDay,
			step,
			bankName,
			accountNumber,
			sortCode,
			selectedBankId,
		);
	}

	/**
	 * @param contributionValue
	 * @param selectedDay
	 * @param step
	 * @param bankName
	 * @param accountNumber
	 * @param sortCode
	 * @param selectedBankId
	 * @param accepted
	 * @private
	 */
	#_submitContribution = (
		contributionValue = 10,
		selectedDay = 1,
		step = "",
		bankName = "",
		accountNumber = "",
		sortCode = "",
		selectedBankId,
		accepted,
	) => {
		let data = {};
		const endToEnd = this.props.endToEnd;
		const customer_ip = this.props.profile_data.my_ip;
		const {
			name,
			source_of_fund_id,
			is_other,
		} = this.state;

		if (step === "Monthly") {
			if (selectedDay > 0 && selectedDay) {
				const redirect_uri = `${process.env.REACT_APP_TRUE_LAYER_PAYMENT}/payment/${endToEnd}`;
				data = {
					"contributions": {
						"amount": contributionValue,
						"day_of_month": selectedDay,
						'bankId': selectedBankId,
						step,
						bankName,
						accountNumber,
						sortCode,
						accepted,
						redirect_uri,
						endToEnd,
						name,
						source_of_fund_id,
						is_other,
						customer_ip,
					}
				}
			}
		} else {
			const redirect_uri = `${process.env.REACT_APP_TRUE_LAYER_PAYMENT}/payment/one-off/${endToEnd}`;
			data = {
				contributions: {
					"amount": contributionValue,
					bankId: selectedBankId,
					step,
					bankName,
					endToEnd,
					redirect_uri,
					name,
					source_of_fund_id,
					is_other,
					customer_ip,
				}
			};
		}

		this.props.insert(data);
	}

	/**
	 * @returns {unknown[]|*[]}
	 * @private
	 */
	#_renderContentAccordinglyWithStep = () => {

		if (this.props.bank_list.length > 0) {
			let banks = [];
			this.props.bank_list.forEach((b = {
				supportedCurrencies: [],
			}) => {
				if (b.supportedCurrencies.length > 0) {
					b.supportedCurrencies.forEach(c => {
						if (c === "GBP") {
							banks.push(b);
							return true;
						}
					});
				}
			});
			if (banks.length > 0) {
				return banks.map(bank =>
					<BankListCard
						key={bank.id}
						selectMyBank={this.#_selectMyBank}
						disableClick={this.state.disableClick}
						component={this}
						{...bank}
					/>
				);
			}
		}

		return [];
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		const {
				new_payment_object = false,
				payment_loading = false,
			} = this.props,
			{
				source_of_fund_id = null,
				contributionValue = 0,
				selectedDay = "",
			} = this.state;

		if (new_payment_object) {
			let fullUrl = new_payment_object.fullUrl ?? false;

			if (!fullUrl) {
				fullUrl = new_payment_object.aspspAuthUrl ?? false;
			}

			if (fullUrl) {
				window.location.href = fullUrl;
			}
		}

		const handleOpen = () => this?.setState({open: true});
		const handleClose = () => this?.setState({open: false});

		if (payment_loading) {
			return (
				<Loading absolute/>
			);
		}

		return (
			<Wrapper
				history={this.props.navigate}
				t={this.props.t}
				hideFooter={true}
			>
				<Slide in={true} direction={"left"}>
					<Grid
						xs={12}
						sm={12}
						md={12}
						lg={12}
						item
						container
						id={"new-contributions-wrapper"}
						justifyContent={"center"}
					>
						<Typography
							className={'upper-grid-title'}
						>
							{this.props.t("individual.add_contribution.select_you_bank.title")}
						</Typography>
						<Grid
							xl={11}
							lg={11.2}
							md={11.2}
							sm={11}
							xs={12}
							id={"digit-your-bank-container"}
							item
							container
							justifyContent={"flex-start"}
							rowGap={5}
							columnGap={5.7}
						>
							{this.#_renderContentAccordinglyWithStep()}
						</Grid>
						<Grid
							className={"ddi-pdf-download"}
							justifyContent={"center"}
							container
						>
							{
								this?.props?.location?.state?.step === "Monthly" ?
									<Grid>
										<div>
											<h5
												className={"ddi-pdf-download-header"}
											>
												{this.props.t("individual.did_not_find_your_bank_question")}
											</h5>
											<span
												className={"span-style-download"}
												onClick={() => handleOpen()}
											>
												{this.props.t("individual.click_here")}
											</span>
										</div>
										<UploadDdiFile
											history={this?.props?.navigate}
											handleOpen={handleOpen}
											handleClose={handleClose}
											open={this?.state?.open}
											source_of_fund_id={source_of_fund_id}
											contributionValue={contributionValue}
											selectedDay={selectedDay}
										/>
									</Grid>
									:
									""
							}
						</Grid>
					</Grid>
				</Slide>
			</Wrapper>
		);
	}
}

const _mapStateToProps = state => {
	const {
		Profile = [],
		ExtraContributions = [],
	} = state;

	return {
		...Profile,
		...ExtraContributions,
	};
};

/**
 * @param dispatch
 * @returns {{retrieveBankList: (function(): *), insert: (function(*[]=): *)}}
 * @private
 */
const _mapDispatchToProps = dispatch => ({
	insert: (data: any[] = false) => dispatch(insert(data)),
	retrieveBankList: dispatch(retrieveBankList()),
	getEndToEnd: dispatch(getEndToEnd()),
	returnUser: dispatch(returnUser()),
})

export default withRouter(
	withTranslation()(
		connect(
			_mapStateToProps,
			_mapDispatchToProps
		)(
			SelectBanks
		)
	)
);