import React, {useState, useEffect} from 'react';
import Wrapper from "../layout/Logged/Wrapper";
import Slide from '@mui/material/Slide';
import {connect} from "react-redux";
import {returnUser, update} from "../actions/Profile";
import Grid from "@mui/material/Grid";
import MobileForm from "../components/ActiveYourPension/MobileForm";
import {generateAutoSignUpToken, resendCode} from "../actions/ActivationCode";
import Loading from "../components/Loading";
import {confirmMobileCode} from "../actions/Steps";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

/**
 * @param profile_data
 * @param mobileNumber
 * @param code
 * @param stepErrorText
 * @param token
 * @param errorMessage
 * @param setMobileNumber
 * @param setCode
 * @param setStepErrorText
 * @param setToken
 * @param update
 * @param generateAutoSignUpToken
 * @param confirmMobileCode
 * @param resendCode
 * @param history
 * @param t
 * @param canProceed
 * @returns {JSX.Element}
 */
const renderContent = (
	profile_data: any = [],
	mobileNumber: string = "",
	code: string = "",
	stepErrorText: string = "",
	token: string = "",
	errorMessage: string = "",
	setMobileNumber = () => {},
	setCode = () => {},
	setStepErrorText = () => {},
	setToken = () => {},
	update = () => {},
	generateAutoSignUpToken = () => {},
	confirmMobileCode = () => {},
	resendCode = () => {},
	history = () => {},
	t = key => key,
	canProceed = false,
): JSX.Element => (
	<MobileForm
		profile_data={profile_data}
		mobileNumber={mobileNumber}
		code={code}
		stepErrorText={stepErrorText}
		token={token}
		errorMessage={errorMessage}
		setMobileNumber={setMobileNumber}
		setCode={setCode}
		setStepErrorText={setStepErrorText}
		setToken={setToken}
		update={update}
		generateAutoSignUpToken={generateAutoSignUpToken}
		resendCode={resendCode}
		confirmMobileCode={confirmMobileCode}
		history={history}
		t={t}
		canProceed={canProceed}
	/>
);

/**
 * @param profile_data
 * @param profile_loading
 * @param activation_code_loading
 * @param step_loading
 * @param canProceed
 * @param errorMessage
 * @param activationCodeData
 * @param update
 * @param generateAutoSignUpToken
 * @param confirmMobileCode
 * @param resendCode
 * @returns {JSX.Element}
 * @constructor
 */
const ActiveYourPension = (
	{
		profile_data = [],
		profile_loading = false,
		activation_code_loading = false,
		step_loading = false,
		canProceed = false,
		errorMessage = "",
		activationCodeData = [],
		update = () => {},
		generateAutoSignUpToken = () => {},
		confirmMobileCode = () => {},
		resendCode = () => {},
	}
) => {
	const [mobileNumber, setMobileNumber] = useState(""),
		[code, setCode] = useState(""),
		[stepErrorText, setStepErrorText] = useState(""),
		[token, setToken] = useState(""),
		{t} = useTranslation(),
		history = useNavigate();
	
	useEffect(() => {
		if (profile_data?.mobile_confirmation !== undefined && profile_data?.mobile_confirmation === true) {
			history("/main-page");
		}

		if (profile_data?.mobile) {
			setMobileNumber(profile_data?.mobile);
		}

		if (profile_data?.activation_token?.token) {
			setToken(profile_data?.activation_token?.token);
		}

	}, [
		activationCodeData?.canProceedStepTwo,
		history,
		profile_data?.activation_token?.token,
		profile_data?.country_code,
		profile_data?.id,
		profile_data?.mobile,
		profile_data?.mobile_confirmation
	]);

	if (activation_code_loading || step_loading || profile_loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<Wrapper
			hideFooter={true}
			history={history}
			t={t}
		>
			<Slide direction={"up"} in={true}>
				<Grid
					xs={12}
					md={12}
					lg={12}
					sm={12}
					xl={12}
					item
					container
				>
					{
						renderContent(
							profile_data,
							mobileNumber,
							code,
							stepErrorText,
							token,
							errorMessage,
							setMobileNumber,
							setCode,
							setStepErrorText,
							setToken,
							update,
							generateAutoSignUpToken,
							confirmMobileCode,
							resendCode,
							history,
							t,
							canProceed
						)
					}
				</Grid>
			</Slide>
		</Wrapper>
	);
}

/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => {
	const {
		Profile = [],
		ActivationCode = [],
		Steps = [],
	} = state;

	return {
		...Profile,
		...ActivationCode,
		...Steps,
	}
};

/**
 * @param dispatch
 * @returns {{returnUser: (function(): *)}}
 */
const mapDispatchToProps = dispatch => ({
	returnUser: dispatch(returnUser()),
	update: (id: number = 0, data: {} = {}) => dispatch(update(id, data, false)),
	generateAutoSignUpToken: (data: {} = {}) => dispatch(generateAutoSignUpToken(data)),
	confirmMobileCode: (token: string = "", data: any = {}, customRedirect = "") => dispatch(
		confirmMobileCode(
			token,
			data,
			customRedirect
		)
	),
	resendCode: (data = false) => dispatch(resendCode(data)),
});


export default connect(mapStateToProps, mapDispatchToProps)(ActiveYourPension);