import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import {useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import React from "react";

/**
 * @param ifa_connections
 * @param modifyIfaAccess
 * @param setAdvisorId
 * @param setIndividualId
 * @param setActive
 * @param setId
 * @param setOpenWarning
 * @returns {*[]|*}
 */
function renderCards(ifa_connections, modifyIfaAccess, setAdvisorId, setIndividualId, setActive, setId, setOpenWarning) {
	if (ifa_connections) {
		if (ifa_connections.length > 0) {
			return ifa_connections.sort().map(ifa => ifa.active !== false && (
					<Grid
						key={ifa.id}
						xs={12}
						lg={12}
						sm={12}
						md={12}
						item
						container
						className={"investment-approach-card-container-list"}
					>
						<Card
							key={ifa.id}
							className={"card-holder"}
						>
							<CardHeader
								className={"card-header-holder"}
								action={
									<Grid
										id={"actions-container"}
										xs={12}
										sm={12}
										lg={12}
										md={12}
										direction={"row"}
										alignContent={"center"}
										alignItems={"center"}
										item
										container
									>
										<Grid
											xs={12}
											sm={12}
											md={6}
											lg={6}
											direction={"column"}
											alignContent={"flex-start"}
											alignItems={"flex-start"}
											className={"actions-holder connection-status"}
											item
											container
										>
										<span>
											{
												ifa.active ? "Connection active" : "Connection not active"
											}
										</span>
										</Grid>
										<Grid
											xs={12}
											sm={12}
											md={6}
											lg={6}
											direction={"column"}
											alignContent={"flex-end"}
											alignItems={"flex-end"}
											className={"actions-holder"}
											item
											container
										>
											<Button
												key={ifa.id}
												className={ifa.active ? "deny-button" : "accept-button"}
												onClick={() => {
													if (ifa.active) {
														setActive(!ifa.active);
														setAdvisorId(ifa.advisor_id);
														setIndividualId(ifa.individual_id);
														setId(ifa.id);
														setOpenWarning(true);
													} else {
														modifyIfaAccess({
															access: {
																id: ifa.id,
																active: !ifa.active,
																advisor_id: ifa.advisor_id,
																individual_id: ifa.individual_id
															}
														});
													}
												}}
												fullWidth
											>
												{
													ifa.active ? "Revoke connection" : "Allow connection"
												}
											</Button>
										</Grid>
									</Grid>
								}
								title={ifa.advisor.title}
								subheader={
									<>
										<a href={`mailto:${ifa.advisor.email}`}>
											{ifa.advisor.email}
										</a>
										<p>Annual fee charged: {ifa.ifa_fee * 100}%</p>
									</>

								}
							/>
							<CardContent
								className={"card-body-container"}
							>
								<Grid
									id={"body"}
									xs={12}
									sm={12}
									lg={12}
									md={12}
									item
								>
									<p
										className={"subtitle"}
									>
										Data being shared:
									</p>
									<ul>
										<li>
											Your Collegia account balance.
										</li>
										<li>
											Your Collegia account projections.
										</li>
										<li>
											Your name.
										</li>
										<li>
											How many years you have until retirement.
										</li>
									</ul>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				)
			);
		}
	}

	return [];
}

/**
 * @param ifa_connections
 * @param modifyIfaAccess
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const DataSharingConnectionsCardList = (
	{
		ifa_connections = [],
		modifyIfaAccess = () => {
		},
		t=key=>key,
	}
): JSX.Element => {
	const [openWarning, setOpenWarning] = useState(false);
	const [advisorId, setAdvisorId] = useState(0);
	const [individualId, setIndividualId] = useState(0);
	const [active, setActive] = useState(null);
	const [id, setId] = useState(null);

	return (
		<Grid
			xs={12}
			lg={12}
			sm={12}
			md={12}
			container
			id={"investment-approach-card-container-list"}
			item
		>
			{renderCards(ifa_connections, modifyIfaAccess, setAdvisorId, setIndividualId, setActive, setId, setOpenWarning)}
			<Dialog
				open={openWarning}
			>
				<DialogTitle>
					<Grid
						xs={12}
						md={12}
						sm={12}
						lg={12}
						alignContent={"center"}
						alignItems={"center"}
						direction={"column"}
						item
						container
					>
						{t("individual.ifa_connections.page.are_you_sure_want_to_revoke.title")}
					</Grid>
				</DialogTitle>
				<DialogContent>
					<DialogContentText component={"p"} align={"center"}>
						{t("individual.ifa_connections.page.are_you_sure_want_to_revoke.subtitle")}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Grid>
						<Button
							variant={"contained"}
							onClick={() => {
								setOpenWarning(false);
							}}
							fullWidth
						>
							{t("individual.ifa_connections.page.are_you_sure_want_to_revoke.no_i_want_to_keep")}
						</Button>
					</Grid>
					<Grid>
						<Button
							variant={"contained"}
							onClick={() => {
								setOpenWarning(false);
								modifyIfaAccess({
									access: {
										id: id,
										active: active,
										advisor_id: advisorId,
										individual_id: individualId
									}
								})
							}}
							fullWidth
						>
							{t("individual.ifa_connections.page.are_you_sure_want_to_revoke.yes_i_want_to_disconnect")}
						</Button>
					</Grid>
				</DialogActions>
			</Dialog>
		</Grid>
	)
};

export default DataSharingConnectionsCardList;