import React from "react";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const CollegiaIsYours = (): JSX.Element => (
	<div
		id="Section-4"
		className="section-4 wf-section"
	>
		<div className="second-cta">
			<h1 className="heading-3-black">Collegia is yours</h1>
			<p className="paragraph-3-black"><strong>You can check your account at any time,</strong> increase or reduce
				your personal contributions and add an extra ethical screening to your pension – like avoid investing in
				arms manufacturers.</p>
		</div>
	</div>
);

export default CollegiaIsYours;