import Active from "./Active";
import Create from "./Create";
import Delete from "./Delete";

const OtherPensionsRequests = {
	Active,
	Create,
	Delete,
};

export default OtherPensionsRequests;