import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const VerifyEmailPromise = (data: any[] = {}): Promise<AxiosResponse<any>> =>
	Request.post("/individual/verify-email", data);

export default VerifyEmailPromise;