import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";


/**
 * @param props
 * @returns {{paddingRight: string}}
 * @private
 */
const _renderCustomStyle = (props = {
	customIcon: false,
	titleMargin: "",
}) => {
	if (props) {
		if (props.customIcon) {
			return {
				paddingRight: "50px",
				marginTop: props.titleMargin
			};
		}
	}
};

const Title = (props = {
	renderCustomIcon: false,
	customIcon: false,
	title: "",
}) => (
	<Grid
		xs={props.renderCustomIcon && props.customIcon ? 10 : 11}
		md={props.renderCustomIcon && props.customIcon ? 10 : 11}
		lg={props.renderCustomIcon && props.customIcon ? 10 : 11}
		sm={props.renderCustomIcon && props.customIcon ? 10 : 11}
		item
	>
		<Grid
			xs={12}
			md={12}
			lg={12}
			sm={12}
			alignItems={"center"}
			alignContent={"center"}
			direction={"column"}
			justifyContent={"center"}
			container
			item
		>
			<Grid
				item
				style={props?.personalInformationTitle ? {marginRight: "7.5%"} : {marginRight: "0"}}
			>
				<Typography
					align={"center"}
					component={"p"}
					variant={"caption"}
					style={_renderCustomStyle(props)}
				>
					{props.title}
				</Typography>
			</Grid>
		</Grid>
	</Grid>
);

Title.propTypes = {
	title: PropTypes.string.isRequired,
	renderCustomIcon: PropTypes.bool.isRequired,
	customIcon: PropTypes.any.isRequired,
}

export default Title;