import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @request
 * @param token
 * @returns {Promise<AxiosResponse<any>>}
 */
const CheckForgotPasswordTokenPromise = (token: string = ""): Promise<AxiosResponse<any>> => (
	Request.get(`/individual/forgot-password/check-token/${token}`)
);

export default CheckForgotPasswordTokenPromise;