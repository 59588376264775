import Token from "./Token";
import ResendCode from "./ResendCode";
import ConfirmCode from "./ConfirmCode";
import OptOut from "./OptOut";
import SentMobileCode from "./SentMobileCode";
import SentEmailCode from "./SentEmailCode";
import GenerateAutoSignUpToken from "./GenerateAutoSignUpToken";
import Authorize from "./Authorize";
import Deny from "./Deny";

const ActivationRequests = {
	Token,
	ResendCode,
	ConfirmCode,
	OptOut,
	SentMobileCode,
	SentEmailCode,
	GenerateAutoSignUpToken,
	Authorize,
	Deny,
};

export default ActivationRequests;