import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const PaginatePromise = (data = {}): Promise<AxiosResponse<any>> => (
	Request.get(`/profile/transactions/upcoming-contributions?${Request.parseParams(data)}`)
);

export default PaginatePromise;