import React from 'react';
import Wrapper from "../layout/Logged/Wrapper";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {retrieveCollegiaAccessToken} from "../utils/Helpers";
import {returnUser, myEmployers} from "../actions/Profile";
import {connect} from "react-redux";
import EmployersCard from "../components/AutoEnrolment/EmployersCard";
import Loading from "../components/Loading";
import UpperInstructionCard from "../components/UpperInstructionCard";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

/**
 * @param profile_data
 * @param profile_loading
 * @param employers
 * @returns {JSX.Element}
 * @constructor
 */
const AutoEnrolment = (
	{
		profile_data = {},
		profile_loading = false,
		employers = []
	}
): JSX.Element => {
	const {t} = useTranslation(),
		history = useNavigate();

	if (!retrieveCollegiaAccessToken()) {
		window.location = "/login";
	}


	if (profile_loading) {
		return <Loading absolute/>
	}

	return (
		<Wrapper
			history={history}
			hideFooter={true}
			t={t}
		>
			<Grid
				xs={12}
				sm={12}
				md={12}
				lg={12}
				xl={12}
				item
				container
				justifyContent={"center"}
				id={"auto-enrolment-container"}
			>
				<UpperInstructionCard
					title={"Auto Enrolment connections"}
					subtitle1={t("individual.refer_employer.body")}
				/>
				<Grid
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
					item
					textAlign={"center"}
					className={"grid-connection"}
				>
					<Typography
						className={"container-title"}
					>
						{t("individual.ae.contributions_span")}
					</Typography>
				</Grid>
				<Grid
					xs={11}
					sm={10}
					md={8}
					lg={7.5}
					xl={7.5}
					item
					textAlign={"center"}
				>
					<EmployersCard
						employers={employers}
						history={history}
						profile_loading={profile_loading}
						profile_data={profile_data ?? []}
						t={t}
					/>
				</Grid>
			</Grid>
		</Wrapper>
	);
};

const mapStateToProps = state => {
	const {
		Profile = [],
		Transactions = [],
		PotToggle = [],
	} = state;

	return {
		...Profile,
		...Transactions,
		...PotToggle,
	};
};

/**
 * @param dispatch
 * @returns {{me: (function(): *)}}
 */
const mapDispatchToProps = dispatch => ({
	returnUser: dispatch(returnUser()),
	myEmployers: dispatch(myEmployers(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoEnrolment);
