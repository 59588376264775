import React, {useState} from "react";
import PropTypes from "prop-types";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import UpcomingContributionModal from "./UpcomingContributionModal";
import ContributionsTableList from "../ContributionsHistory/ContributionsTableList";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const UpcomingContributionCard = (props = {
	data: [{
		payment_date: "",
		employer_contribution: 0,
		individual_contribution: 0,
		government_top_up_contribution: 0,
		payroll_start_date: "",
		payroll_end_date: ""
	}],
}): JSX.Element => {
	const [open, setOpen] = useState(false);
	const handleModal = () => setOpen(!open);
	const [upcomingPayments, setUpcomingPayments] = useState(null);

	return (
		<Grid
			spacing={0}
			marginBottom={8}
			direction={"column"}
			alignItems="center"
			alignContent={"center"}
			id={"tax-relief-container"}
			container
			item
		>
			{
				props?.data?.map((d, index = {
					fee_total: "",
					month: 0,
					total: 0,
					upcoming_transaction: {},
				}) => (
					<Card
						className={"min-height-card-list"}
						key={index}
						onClick={() => {
							setUpcomingPayments(d);
							handleModal();
						}}
					>
						<CardContent>
							<ContributionsTableList
								tableColumnName_1={"Date"}
								tableColumnName_2={"Total"}
								tableColumnName_3={"Fees"}
							/>
							<img src={props?.contribution_icon} alt={""}/>
							<ContributionsTableList
								tableColumnName_1={d?.month}
								tableColumnName_2={"£ " + (d?.total).toFixed(2)}
								tableColumnName_3={"£ " + (d?.fee_total).toFixed(2)}
								classNameForData={true}
							/>
						</CardContent>
					</Card>
				))
			}
			<UpcomingContributionModal
				handleModal={handleModal}
				open={open}
				upcomingPayments={upcomingPayments}
			/>
		</Grid>
	);
}

UpcomingContributionCard.propTypes = {
	data: PropTypes.arrayOf(
		PropTypes.shape({
			total: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number,
			]),
			fee_total: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number,
			]),
			month: PropTypes.oneOfType([
				PropTypes.string,
			]),
			upcoming_transaction: PropTypes.arrayOf(PropTypes.shape({})),
		})
	).isRequired,
};

export default UpcomingContributionCard;