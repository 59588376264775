import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import React from "react";
import Switch from "@mui/material/Switch";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import arrow from "../../images/arrow-down-culture.svg";
import AccordionDetails from "@mui/material/AccordionDetails";

/**
 * @param props
 * @returns {*[]}
 */
function renderCards(props = {
	third_party_software_connections: [{
		id: null,
		software: {
			name: "",
			email: "",
		},
		accepted_at: "",
		deny_at: "",
		intermediary_token: "",
		token: "",
		active: false,
	}],
	buttonActions: (code: string = "", action: string = "") => {
	},
}) {
	if (props) {
		if (props.third_party_software_connections.length > 0) {
			return props.third_party_software_connections.sort().map((data = {
					id: null,
					software: {
						name: "",
						email: "",
					},
					accepted_at: "",
					deny_at: "",
					intermediary_token: "",
					token: "",
					active: false,
				}) =>

					<Grid
						key={data.id}
						xl={5.5}
						lg={5.5}
						md={5.5}
						sm={12}
						xs={12}
						item
						container
						className={"data-card"}
					>
						<Grid
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
							item
							container
							justifyContent={"space-between"}
						>
							<Typography
								className={"title"}
							>
								{data?.software?.name}
							</Typography>
							<Switch
								onClick={() => props.buttonActions(
									data.intermediary_token ?? "",
									data.active ? "deny" : "authorize",
								)}
								checked={data?.active}
							/>
						</Grid>
						<Grid
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
							item
						>
							<Typography
								className={"mail"}
							>
								{data?.software?.email}
							</Typography>
						</Grid>
						<Grid
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
							item
							textAlign={"start"}
							display={"inline-flex"}
							paddingTop={"10px"}
						>
							<Accordion>
								<AccordionSummary
									expandIcon={
										<img
											className={'img-mobile-arrow'}
											src={arrow}
											alt={"Arrow Icon"}
										/>
									}
									className={"acordion-summary"}
								>
									<Typography
										className={"details"}
									>
										Details
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Grid
										id={"body"}
										xs={12}
										sm={12}
										lg={12}
										md={12}
										item
									>
										<p
											className={"subtitle"}
										>
											{props.t("individual.software_connections.cards.list.title")}
										</p>
										<ul>
											<li>
												{props.t("individual.software_connections.cards.list.collegia_balance")}
											</li>
											<li>
												{props.t("individual.software_connections.cards.list.account_projections")}
											</li>
											<li>
												{props.t("individual.software_connections.cards.list.contributions_history")}
											</li>
											<li>
												{props.t("individual.software_connections.cards.list.years_until_retirement")}
											</li>
										</ul>
									</Grid>
								</AccordionDetails>
							</Accordion>
						</Grid>
					</Grid>
			);
		}
	}

	return [];
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const DataSharingConnectionsCardList = (props = {
	third_party_software_connections: [{
		id: null,
		software: {
			name: "",
			email: "",
		},
		accepted_at: "",
		deny_at: "",
		intermediary_token: "",
		token: "",
		active: false,
	}],
	buttonActions: (code: string = "", action: string = "") => {
	},
	t: key => key,
}): JSX.Element => (
	<Grid
		xl={12}
		lg={12}
		md={12}
		sm={12}
		xs={12}
		id={"data-sharing-permission"}
		container
		item
		justifyContent={"center"}
	>
		{renderCards(props)}
	</Grid>
);

export default DataSharingConnectionsCardList;