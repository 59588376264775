import React, {useState} from "react";
import Grid from "@mui/material/Grid";
import EmployersSearchResultsCard from "../AutoEnrolment/EmployersSearchResultsCard";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import ConfirmEmailRefer from "./ConfirmEmailRefer";
import CircularProgress from "@mui/material/CircularProgress";
import ReferEmployerManually from "./ReferEmployerManually";

/**
 * @param search_results
 * @param companyName
 * @param searchTrigger
 * @param setSelectedEmployer
 * @param setEmployerTrigger
 * @param renderCompanyName
 * @param renderCompanyNumber
 * @param renderIncorporatedInfo
 * @param renderAddressSnippet
 * @param t
 * @returns {JSX.Element|*[]}
 */
const searchResults = (
	search_results: any[] = false,
	companyName: string = "",
	searchTrigger: boolean = false,
	setSelectedEmployer = () => {
	},
	setEmployerTrigger = () => {
	},
	renderCompanyName = () => {
	},
	renderCompanyNumber = () => {
	},
	renderIncorporatedInfo = () => {
	},
	renderAddressSnippet = () => {
	},
	t=key=>key,
): JSX.Element|*[] => {
	if (searchTrigger) {

		const results = [];
		let label = `RESULTS FOR ${companyName}`,
			index = 0;

		if (search_results.length === 0) {
			label = `NO RESULTS FOR ${companyName}`;
		} else {
			search_results.filter(companies => companies?.company_status === "active").forEach(companies => {
				results.push(
					<Grid
						key={companies.company_number}
						xs={12}
						md={12}
						lg={12}
						sm={12}
						item
						container
					>
						<EmployersSearchResultsCard
							companies={companies}
							setSelectedEmployer={setSelectedEmployer}
							setEmployerTrigger={setEmployerTrigger}
							renderCompanyName={renderCompanyName}
							renderCompanyNumber={renderCompanyNumber}
							renderIncorporatedInfo={renderIncorporatedInfo}
							renderAddressSnippet={renderAddressSnippet}
							t={t}
						/>
					</Grid>
				);
				index++;

				if (index === 2) {
					index = 0;
				}
			});
		}

		return (
			<Grid
				xs={12}
				md={12}
				lg={12}
				sm={12}
				item
				container
				marginTop={2}
			>
				<Typography className={"no-results-style"}>
					{label}
				</Typography>
				{results}
			</Grid>
		);
	}

	return [];
};


/**
 * @param search_results
 * @param searchCompany
 * @param employerTrigger
 * @param searchTrigger
 * @param email
 * @param selectedEmployer
 * @param search_loading
 * @param setEmail
 * @param setSelectedEmployer
 * @param setEmployerTrigger
 * @param setSearchCompany
 * @param renderCompanyNumber
 * @param renderCompanyName
 * @param renderIncorporatedInfo
 * @param renderAddressSnippet
 * @param createLead
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const ReferEmployerMobile = (
	{
		search_results = [],
		searchCompany = "",
		employerTrigger = false,
		searchTrigger = false,
		email = "",
		selectedEmployer = {},
		search_loading = false,
		setEmail = () => {
		},
		setSelectedEmployer = () => {
		},
		setEmployerTrigger = () => {
		},
		setSearchCompany = () => {
		},
		renderCompanyNumber = () => {
		},
		renderCompanyName = () => {
		},
		renderIncorporatedInfo = () => {
		},
		renderAddressSnippet = () => {
		},
		createLead = () => {
		},
		t = key => key,
	}
): JSX.Element => {
	const [referManually, setReferManually] = useState(false);

	return (
		<Grid
			id={"refer-your-employer-mobile"}
		>
			{
				employerTrigger ?
					<ConfirmEmailRefer
						email={email}
						selectedEmployer={selectedEmployer}
						setEmail={setEmail}
						renderCompanyName={renderCompanyName}
						renderCompanyNumber={renderCompanyNumber}
						renderIncorporatedInfo={renderIncorporatedInfo}
						renderAddressSnippet={renderAddressSnippet}
						createLead={createLead}
						t={t}
					/>
					:
					<Grid
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
						item
						container
						padding={2}
					>
						<TextField
							fullWidth
							className={"text-field-style"}
							placeholder={t("individual.refer_employer.search_company_input")}
							variant={"outlined"}
							value={searchCompany}
							onChange={(event) => setSearchCompany(event.target.value)}
							onFocus={() => setReferManually(false)}
							InputProps={{
								startAdornment: (
									<InputAdornment position={"start"}>
										<SearchIcon/>
									</InputAdornment>
								),
								endAdornment: (
									<InputAdornment position={"end"}>
										{search_loading && <CircularProgress/>}
									</InputAdornment>
								)
							}}
						/>
						<Grid
							container
							direction={"column"}
						>
							<Grid>
								<Typography className={"refer-company-typography-style"}>
									{t("individual.refer_employer.did_not_find_employer_question")}
									<span
										className={"refer-company-span-style"}
										onClick={() => setReferManually(true)}
									>
										{" "} {t("individual.refer_employer.refer_manually_link")}
									</span>
								</Typography>
							</Grid>

							{
								referManually &&
								<ReferEmployerManually/>
							}

							<Grid>
								{
									searchResults(
										search_results,
										searchCompany,
										searchTrigger,
										setSelectedEmployer,
										setEmployerTrigger,
										renderCompanyName,
										renderCompanyNumber,
										renderIncorporatedInfo,
										renderAddressSnippet,
										t,
									)
								}
							</Grid>
						</Grid>

					</Grid>
			}
		</Grid>
	);
};

export default ReferEmployerMobile;