import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import CardActions from '@mui/material/CardActions';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import CountUp from "react-countup";
import {pensionIncomeNominalValues, setExpectPensionPotClassName} from "../../utils/Helpers";
import LifeStyleCardHeader from "./LifeStyleCard/LifeStyleCardHeader";
import LifeStyleDrawRuler from "../LifeStyleDrawRuler";
import RetirementInfo from "./RetirementInfo";


/**
 * @param progress
 * @param yearlySetUp
 * @param props
 * @returns {*}
 */
function setSubHeaderActionLabel(progress = 0, yearlySetUp = false, props = false): string {

	if (yearlySetUp && props.totalYearlyIncome) {
		let value = 0;

		if (typeof props.totalYearlyIncome.replace === "function") {
			value = parseInt(props.totalYearlyIncome.replace(",", ""));
		} else {
			value = parseInt(props.totalYearlyIncome);
		}

		if (value >= 0 && value < 14400) {
			return props.t("individual.still_not_there");
		} else if (value >= 14400 && value < 31300) {
			return props.t("individual.minimum_lifestyle");
		} else if (value >= 31300 && value < 43100) {
			return props.t('individual.moderate_lifestyle');
		} else if (value >= 43100) {
			return props.t('individual.comfortable_lifestyle');
		}
	} else {
		if (progress >= 71) {
			return props.t('individual.comfortable_lifestyle');
		} else if (progress >= 31) {
			return props.t('individual.moderate_lifestyle');
		} else if (progress >= 23 && progress <= 30) {
			return props.t("individual.minimum_lifestyle");
		}
	}

	return props.t("individual.still_not_there");
}

/**
 * @param props
 * @param perMonth
 * @returns {*}
 * @private
 */
const _yearlyIncome = (props = false, perMonth = false) => (
	<div>
		<CountUp
			start={0}
			end={pensionIncomeNominalValues(props, perMonth)}
			separator=","
			decimals={2}
			decimal="."
			duration={1}
			prefix={`£ `}
		/>
		{
			perMonth ? <span className={"suffix"}>
                    / {props.t("individual.lower_case_year")}
                </span> :
				[]
		}
	</div>
);

/**
 * @param checkedDB
 * @param t
 * @returns {string}
 * @private
 */
const _cardHeaderTitle = (checkedDB = true, t = () => false): string => {
	if (checkedDB) {
		return t("individual.main_page.cards.pension_pot.expected_pension_income");
	}

	return t("individual.main_page.cards.pension_pot.expected_pension_pot");
};

/**
 * @param props
 * @returns {*}
 */
const LifeStyleCard = (props = {
	t: key => key,
}) => {

	let currentProgress = 0;

	if (props.progress) {
		currentProgress = props.progress;
	}

	return (
		<Paper
			id={"lifestyle-paper-container"}
		>
			<Card
				id={'lifestyle-card-container'}
			>
				<CardHeader
					action={
						<LifeStyleCardHeader
							checked={props.potChecked}
							onChange={props.toggleIncomePot}
							t={props.t}
						/>
					}
					className={setExpectPensionPotClassName(currentProgress, false, true, props)}
					title={_cardHeaderTitle(props.potChecked, props.t)}
					subheader={_yearlyIncome(props, props.potChecked, props.t)}
				/>
				<CardContent
					className={"comfortable-card-container"}
				>
					<LifeStyleDrawRuler
						{...props}
						currentProgress={currentProgress}
					/>
				</CardContent>
				<CardActions
					id={"sub-action"}
					onClick={() => props.history("/understand-lifestyles")}
				>
					<Grid
						container
					>
						<Grid

							xs={12}
							sm={12}
							md={12}
							lg={12}
							item
						>
							<CardHeader
								action={
									<IconButton
										aria-label="settings"
										disableTouchRipple
										disableFocusRipple
										disableRipple
									>
										<KeyboardArrowRightIcon/>
									</IconButton>
								}
								className={setExpectPensionPotClassName(currentProgress, false, true, props)}
								title={setSubHeaderActionLabel(currentProgress, true, props)}
							/>
						</Grid>
						<RetirementInfo
							currentProgress={currentProgress}
							totalYearlyIncome={props.totalYearlyIncome}
							t={props.t}
						/>
					</Grid>
				</CardActions>
			</Card>
		</Paper>
	);
}

export default LifeStyleCard;
