import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param code
 * @param token
 * @returns {Promise<AxiosResponse<*>>}
 * @constructor
 */
const AcceptRequest = (code: string = "", token: string = ""): Promise<AxiosResponse<any>> =>
	Request.post(`/individual/activation/accept/${code}/${token}`);

export default AcceptRequest;