import React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";

/**
 * @param t
 * @param profile_data
 * @param update
 * @returns {JSX.Element}
 * @constructor
 */
const TaxReliefEligibleInformation = (
	{
		t = key => key,
		profile_data = {
			eligible_for_tax_relief: false,
		},
		update = data => data,
	}
): JSX.Element => (
	<Grid
		id={"eligible-information-list"}
		xs={12}
		sm={12}
		lg={12}
		md={12}
		container
		item
	>
		<Grid
			xs={12}
			sm={12}
			lg={12}
			md={12}
			item
		>
			<ul>
				<li>
					{t("individual.my_account.tax_relief.list.some_point")}
				</li>
				<li>
					{t("individual.my_account.tax_relief.list.have_earning")}
				</li>
				<li>
					{t("individual.my_account.tax_relief.list.be_resident")}
				</li>
			</ul>
			<hr/>
			<Grid
				className={"ant-switch"}
				xl={12}
				md={12}
				sm={12}
				lg={12}
				component="label"
				alignItems="center"
				item
				container
			>
				<Grid
					item
				>
					<Typography component="div">
						<Switch
							checked={profile_data?.eligible_for_tax_relief ?? false}
							onChange={() => update(
								profile_data?.id ?? null,
								{
									profiles: {
										eligible_for_tax_relief: !profile_data?.eligible_for_tax_relief,
									}
								}
							)}
							name="checkedC"
						/>
					</Typography>
				</Grid>
				<Grid item>
					{t("individual.my_account.tax_relief.i_confirm_i_am_eligible_for_tax_relief_radio_button")}
				</Grid>
			</Grid>
		</Grid>
	</Grid>
);

export default TaxReliefEligibleInformation;