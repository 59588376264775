import React from "react";
import Wrapper from "../layout/Logged/Wrapper";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import CompleteReferIcon from '../images/CompleteReferIcon.svg';
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {PrimaryHeader} from "../components/Headers/PrimaryHeader";
import {useTranslation} from "react-i18next";

/**
 * @param history
 * @returns {JSX.Element}
 * @constructor
 */
const ReferEmployerComplete = (
	{
		history = () => {
		},
	}
) => {
	const {t} = useTranslation();

	return (

		<Wrapper t={t}>
			<Slide in={true} direction={"up"}>
				<div>
					<Grid
						direction={"column"}
						alignItems={"center"}
						alignContent={"center"}
						id={"refer-employer-complete-container"}
						container
						item
					>
						<PrimaryHeader
							history={history}
							title={t("individual.refer_employer.title")}
							backPage={"/refer-employer"}
							backIcon
						>
							{t("individual.refer_employer.title")}
						</PrimaryHeader>
						<Paper
							className={"paper-holder"}
						>
							<Grid
								direction={"column"}
								alignContent={"center"}
								xs={12}
								sm={12}
								md={12}
								lg={12}
								item
								container
							>
								<img src={CompleteReferIcon}
								     alt={"Refer employer complete"}
								/>
							</Grid>

							<Grid
								direction={"column"}
								alignContent={"center"}
								xs={12}
								sm={12}
								md={12}
								lg={12}
								item
								container
							>
								<Typography component={"h1"} align={"center"}>
									{t("individual.done")}!
								</Typography>
								<Typography
									component={"p"} align={"center"}
									dangerouslySetInnerHTML={{
										__html: t("individual.refer_employer_complete.now_just_wait")
									}}
								/>
							</Grid>
							<Grid
								className={"button-holder"}
								direction={"column"}
								alignContent={"center"}
								xs={12}
								lg={12}
								sm={12}
								md={12}
								item
								container
							>
								<Button
									variant={"contained"}
									onClick={() => history('/')}
									fullWidth
								>
									Back to the dashboard
								</Button>
							</Grid>
						</Paper>
					</Grid>
				</div>
			</Slide>
		</Wrapper>
	);
}

export default ReferEmployerComplete;