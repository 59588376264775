import AbstractServiceRequestsClass from "../../classes/AbstractServiceRequestsClass";

import Request from '../../request/index';
import {AxiosResponse} from "axios";

export default class ActivationToken extends AbstractServiceRequestsClass {
	constructor() {
		super(Request.Activation);
	}

	/**
	 *
	 * @param token
	 * @returns {Promise<never>|*}
	 */
	retrieveByToken(token = false): Promise<never> | * {
		if (this.request && token) {
			return this.request.Token(token);
		}

		return Promise.reject("Request or token wasn't set up");
	}

	/**
	 *
	 * @param data
	 * @returns {Promise<never>|Promise<AxiosResponse<*>>|*}
	 */
	resendCode(data = false): Promise<never> | Promise<AxiosResponse<*>> | * {
		if (data && this.request) {
			return this.request.ResendCode(data);
		}

		return Promise.reject("Request or data wasn't set up");
	}

	/**
	 * @param data
	 * @returns {Promise<never>|Promise<AxiosResponse<T>>|*}
	 */
	confirmCode(data = false): Promise<never> | Promise<AxiosResponse<T>> | * {
		if (this.request && data) {
			return this.request.ConfirmCode(data);
		}

		return Promise.reject("Request or data wasn't set up");
	}

	/**
	 * @param data
	 * @returns {Promise<never>|Promise<AxiosResponse<*>>|*|Promise<AxiosResponse<T>>}
	 */
	optOut(data = false): Promise<never> | Promise<AxiosResponse<*>> | * | Promise<AxiosResponse<T>> {
		if (data && this.request) {
			return this.request.OptOut(data);
		}

		return Promise.reject("Request, token or data wasn't set up");
	}

	/**
	 * @param token
	 * @param data
	 * @returns {Promise<never>|Promise<AxiosResponse<*>>|*}
	 */
	sentMobileCode(token: string = "", data: any = {}): Promise<never> | Promise<AxiosResponse<*>> | * {
		if (token && data && this.request) {
			return this.request.SentMobileCode(token, data);
		}

		return Promise.reject("Request, token or data wasn't set up");
	}

	/**
	 * @param token
	 * @param data
	 * @returns {Promise<never>|Promise<AxiosResponse<*>>|*}
	 */
	sentEmailCode(token: string = "", data: any = {}): Promise<never> | Promise<AxiosResponse<*>> | * {
		if (token && data && this.request) {
			return this.request.SentEmailCode(token, data);
		}

		return Promise.reject("Request, token or data wasn't set up");
	}

	/**
	 * @param data
	 * @returns {Promise<never>|Promise<AxiosResponse<*>>|*}
	 */
	generateAutoSignUpToken(data: {} = {}): Promise<never> | Promise<AxiosResponse<*>> | * {
		if (data && this.request) {
			return this.request.GenerateAutoSignUpToken(data ?? false);
		}

		return Promise.reject("Request, data wasn't set up");
	}

	/**
	 * @param code
	 * @param token
	 * @returns {Promise<never>|Promise<AxiosResponse<*>>|*}
	 */
	authorize(code: string = "", token: string = ""): Promise<AxiosResponse<*>> | * {
		if (this.request && code && token) {
			return this.request.Authorize(code ?? false, token ?? false);
		}

		return Promise.reject("No request found");
	}

	/**\
	 * @param code
	 * @param token
	 * @returns {Promise<never>|Promise<AxiosResponse<*>>|*}
	 */
	deny(code: string = "", token: string = ""): Promise<AxiosResponse<*>> | * {
		if (this.request && code && token) {
			return this.request.Deny(code ?? false, token ?? false);
		}

		return Promise.reject("No request found");
	}
}