import Request from "../../utils/Request";

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>|Promise<never>}
 * @constructor
 */
const ProcessResidencyStatus = (data) =>
	Request.post(`/profile/process-residency-status`, data)
	??
	Promise.reject("It's not possible to process residency status.");

export default ProcessResidencyStatus;