import React, {useEffect} from "react";

import Wrapper from "../../layout/Logged/Wrapper";

import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import {connect} from "react-redux";
import {get} from "../../actions/InvestmentApproach";
import {returnUser} from "../../actions/Profile";
import Typography from "@mui/material/Typography";
import InvestmentApproach from "../../components/InvestmentApproach/InvestmentApproach";
import sustainable from "../../images/investment-approaches/sustainable.svg";
import standard from "../../images/investment-approaches/standard.svg";
import islamic from "../../images/investment-approaches/islamic.svg";
import high_growth from "../../images/investment-approaches/high-growth.svg";
import low_growth from "../../images/investment-approaches/low-growth.svg";
import UpperInstructionCard from "../../components/UpperInstructionCard";
import {useTranslation} from "react-i18next";
import Loading from "../../components/Loading";
import {useNavigate} from "react-router-dom";

/**
 * @param investment_approaches
 * @param individual_approach
 * @param get
 * @param myProjections
 * @param history
 * @returns {JSX.Element}
 * @constructor
 */
const InvestmentApproachList = (
	{
		investment_approaches = [],
		individual_approach = [],
		investment_approach_loading = false,
		get = () => {},
	}
): JSX.Element => {
	useEffect(() => {
		get();
	}, [
		get,
	]);
	const {t} = useTranslation(),
		history = useNavigate();

	const investment_approaches_array = {
		[t("system.investment_approaches.sustainable")]: {
			icon: sustainable,
		},
		"Standard": {
			icon: standard,
		},
		[t("system.investment_approaches.islamic_finance")]: {
			icon: islamic,
		},
		[t("system.investment_approaches.high_growth")]: {
			icon: high_growth,
		},
		[t("system.investment_approaches.low_growth")]: {
			icon: low_growth,
		},
	};

	if (investment_approach_loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<Wrapper
			hideFooter={true}
			textHeader={"Investment Approach"}
			history={history}
			t={t}
		>
			<Slide
				in={true}
				direction={"left"}
			>
				<Grid
					id={"investment-approaches-container"}
				>
					<UpperInstructionCard
						title={t("individual.investment_approaches.list.title")}
						subtitle1={t("individual.investment_approaches.list.carousel.message_1")}
					/>
					<Typography
						className={"title"}
						align={"center"}
					>
						{t('individual.investment_approaches.list.title')}
					</Typography>

					<Typography
						className={"sub-title"}
						align={"center"}
						display={"block"}
					>
						{t("individual.investment_approaches.list.carousel.message_1")}
					</Typography>

					<Grid
						container
						justifyContent={"center"}
						align={"center"}
						className={"investments-cards-grid"}
					>

						{investment_approaches.filter(
							(e) => e.name !== t("system.investment_approaches.ifa_managed")
						).map((approach) => {
							return (
								<Grid
									key={approach.id}
									item
									xs={6}
									sm={6}
									md={3.5}
									lg={1.5}
									xl={1.5}
									className={"investment-approaches-cards"}
								>
									<InvestmentApproach
										icon={investment_approaches_array[approach.name]?.icon}
										name={approach?.name}
										yearly_charge={approach?.yearly_charge}
										active={approach?.id === individual_approach?.investment_approach_id}
										link={"/investment-approach/details/" + approach?.name}
										history={history}
										t={t}
									/>
								</Grid>
							);
						})}
					</Grid>
				</Grid>
			</Slide>
		</Wrapper>
	);
};


const mapStateToProps = state => {
	const {
		InvestmentApproach = [],
		Profile = [],
	} = state;

	return {
		...InvestmentApproach,
		...Profile,
	};
};

/**
 * @param dispatch
 * @returns {{get: (function(): *)}}
 */
const mapDispatchToProps = dispatch => ({
	get: () => dispatch(get()),
	returnUser: () => dispatch(returnUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvestmentApproachList);