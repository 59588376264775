import React from "react";

import arrowDowImg from "../../images/1-arrow.png";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const WhyCollegiaSection = (): JSX.Element => (
	<div className="section-2 wf-section">
		<div className="scroll-cta">
			<p className="paragraph-2">
				<a
					href="#Section-3"
					className="link"
				>
					Why Collegia?
				</a>
			</p>
			<a
				href="#Section-3"
				className="w-inline-block"
			>
				<img
					src={arrowDowImg}
					width="17"
					alt=""
				/>
			</a>
		</div>
	</div>
);

export default WhyCollegiaSection;