import {SIGNUP_VERIFY_EMAIL_ADDRESS} from "../../actions/SignUp/type";
import {resolveReduxState} from "../../utils/Helpers";

const initial_state = {
	error: false,
	verify_email: false,
}

/**F
 * @param state
 * @param action
 * @returns {initial_state}
 */
const SignUpStore = (state: initial_state = initial_state, action: any[] = false) => {
	if (action) {
		const {
			type = false
		} = action;
		if (type) {
			if (type === SIGNUP_VERIFY_EMAIL_ADDRESS) return resolveReduxState(state, action);
		}
	}

	return state;
}

export default SignUpStore;