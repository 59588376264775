import Slide from "@mui/material/Slide";
import {removeDefaultLoggedLayout} from "../../utils/Helpers";
import MinimalFooter from "../../layout/MinimalFooter";
import Wrapper from "../../layout/Wrapper";
import React, {useEffect, useState} from "react";
import DefaultHeader from "../../layout/Header/DefaultHeader";
import {useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {clearActivationToken, getActivationToken} from "../../actions/Profile";
import Loading from "../../components/Loading";
import WereYouEnrolledByEmployer from "../../components/SignUp/WereYouEnrolledByEmployer";
import EnrolledByEmployerForm from "../../components/SignUp/EnrolledByEmployerForm";
import EnrolledByEmployerError from "../../components/SignUp/EnrolledByEmployerError";
import {useTranslation} from "react-i18next";
import {setCountry} from "../../actions/Languages";

/**
 * @param token
 * @param token_active
 * @param token_loading
 * @param first_login
 * @param available_countries
 * @param selected_country
 * @param language
 * @param getActivationToken
 * @param clearActivationToken
 * @param setCountry
 * @returns {JSX.Element}
 * @constructor
 */
const EnrolledByEmployer = (
	{
		token = "",
		token_active = true,
		token_loading = false,
		first_login = false,
		available_countries = [],
		selected_country = "GB",
		language = "en",
		getActivationToken = () => {
		},
		clearActivationToken = () => {
		},
		setCountry = (newCountry = "GB") => newCountry,
	}
): JSX.Element => {
	let history = useNavigate();
	const {t, i18n} = useTranslation();

	const [step, setStep] = useState(0);

	useEffect(() => {
		removeDefaultLoggedLayout();
		i18n.changeLanguage(language);
		const menu = document.getElementById("menu");

		if (menu) {
			menu.style.display = "none";
		}

		if (!token_active && first_login) {
			history("/first-password");
		} else if (token === null) {
			setStep(2)
		} else if (token !== false) {
			history("/activation/" + token)
		}
	}, [
		setStep,
		history,
		token,
		token_active,
		first_login,
		language,
		i18n,
	]);

	const _renderContent = (step = 0, t) => {
		switch (step) {
			case 0:
				return (
					<WereYouEnrolledByEmployer
						setStep={setStep}
						history={history}
						t={t}
					/>
				);

			case 1:
				return (
					<EnrolledByEmployerForm
						setStep={setStep}
						getActivationToken={getActivationToken}
						t={t}
					/>
				);

			case 2:
				return (
					<EnrolledByEmployerError
						history={history}
						setStep={setStep}
						clearActivationToken={clearActivationToken}
					/>
				);
			default:
				return [];
		}
	};

	return (
		<Wrapper t={t}>
			<Slide direction="up" in={true} mountOnEnter unmountOnExit>
				<div id={"login-wrapper"}>
					<DefaultHeader
						navigate={history}
						language={language}
						setCountry={setCountry}
						available_countries={available_countries}
						selected_country={selected_country}
					/>
					{token_loading ? <Loading absolute/> : _renderContent(step, t)}
					<MinimalFooter t={t}/>
				</div>
			</Slide>
		</Wrapper>
	);
};

const mapStateToProps = state => {
	return {
		...state.Profile,
		...state.Languages,
	};
}

const mapDispatchToProps = dispatch => ({
	getActivationToken: (data) => dispatch(getActivationToken(data)),
	clearActivationToken: () => dispatch(clearActivationToken()),
	setCountry: (newCountry = "GB") => dispatch(setCountry(newCountry, null, false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EnrolledByEmployer);