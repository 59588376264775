import Activation from "./Activation";
import Step from './Step';
import TermsAndConditions from "./TermsAndConditions";
import Profile from './Profile';
import Transactions from "./Transactions";
import GenericProjectionParameters from './GenericProjectionParemeter';
import RealProjectionParameters from './RealProjectionParemeter';
import ComfortRulerProjectionParameters from './ComfortRulerProjectionParemeter';
import Employers from './Employers';
import InvestmentApproach from './InvestmentApproach';
import OtherPensions from './OtherPensions';
import ExtraContributions from "./ExtraContributions";
import Projections from './Projections';
import SignUp from "./SignUp";
import Connections from "./Connections";
import Beneficiaries from "./Beneficiaries";
import SourceOfFunds from "./SourceOfFunds";
import EmploymentStatus from "./EmploymentStatus";
import UpcomingTransactions from "./UpcomingTransactions";
import Notification from "./Notification";
import AssetsLocation from "./AssetsLocation";
import Invitations from "./Invitations";
import Forbidden from "./403";

const Requests = {
	Activation,
	Step,
	TermsAndConditions,
	Profile,
	Transactions,
	UpcomingTransactions,
	GenericProjectionParameters,
	RealProjectionParameters,
	ComfortRulerProjectionParameters,
	Employers,
	InvestmentApproach,
	OtherPensions,
	ExtraContributions,
	Projections,
	SignUp,
	Connections,
	Beneficiaries,
	SourceOfFunds,
	EmploymentStatus,
	Notification,
	AssetsLocation,
	Invitations,
	Forbidden,
};

export default Requests;