import GetByCode from "./GetByCode";
import Authorize from './Authorize';
import Deny from "./Deny";

const ConnectionsRequest = {
	GetByCode,
	Authorize,
	Deny,
};

export default ConnectionsRequest;