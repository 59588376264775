import GetContributionByDate from './GetContributionByDate';
import All from './All';
import GetNonSignedMandates from "./GetNonSignedMandates";
import GetContributionsHistoryYears from "./GetContributionsHistoryYears"

const TransactionsRequests = {
	GetContributionByDate,
	All,
	GetNonSignedMandates,
	GetContributionsHistoryYears,
};

export default TransactionsRequests;