import AbstractServiceRequestsClass from "../../classes/AbstractServiceRequestsClass";

import Request from "../../request";
import {AxiosResponse} from "axios";

export default class ProjectionsServiceClass extends AbstractServiceRequestsClass {
	constructor() {
		super(Request.Projections);
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	simulations(data: any[] = false): Promise<AxiosResponse<*>> | null {
		if (this.request && data) {
			return this.request.Simulation(data ?? false);
		}

		return null;
	}
}