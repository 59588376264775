import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
const SearchPromise = (params: any[] = false): Promise<AxiosResponse<any>> => (
	Request.get(`/individual/employers/search?${Request.parseParams(params)}`)
);

export default SearchPromise;