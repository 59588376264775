import React from "react";

// importing default material ui components.
import Paper from "@mui/material/Paper";

// importing collegia custom components.
import FullStatePensionCard from "./PensionBadges/FullStatePensionCard";
import ExpectedRetirementAge from "./PensionBadges/ExpectedRetirementAge";
import AutoEnrolmentContributions from "./PensionBadges/AutoEnrolmentContributions";
import ExtraContributionsCard from "./PensionBadges/ExtraContributionsCard";
import InvestmentApproachCard from "./PensionBadges/InvestmentApproachCard";
import OtherPensionsCard from "./PensionBadges/OtherPensionsCard";
import SoftwareConnections from "./PensionBadges/SoftwareConnections";
import IFAConnections from "./PensionBadges/IFAConnections";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const YourPensionBadgesContainer = (props = {
	t: key => key,
}) =>
	<Paper
		id={'your-pension-badges-container'}
	>
		<ExpectedRetirementAge
			profile_data={props.profile_data}
			history={props.history}
			t={props.t}
		/>
		<FullStatePensionCard
			profile_data={props.profile_data}
			history={props.history}
			genericProjectionParameters={props.genericProjectionParameters}
			t={props.t}
		/>
		<AutoEnrolmentContributions
			profile_data={props.profile_data}
			history={props.history}
			t={props.t}
		/>
		<ExtraContributionsCard
			history={props.history}
			extra_contributions={props.profile_data.extra_contributions ?? []}
			t={props.t}
		/>
		<InvestmentApproachCard
			individual_approach={props.individual_approach}
			investment_approaches={props.investment_approaches}
			profile_data={props.profile_data}
			history={props.history}
			t={props.t}
		/>
		<OtherPensionsCard
			other_pensions={props.profile_data.other_pensions ?? []}
			history={props.history}
			t={props.t}
		/>
		<SoftwareConnections
			third_party_software_connections={props.profile_data.third_party_software_connections ?? []}
			history={props.history}
			t={props.t}
		/>
		<IFAConnections
			ifa_connections={props.profile_data.ifa_connections}
			history={props.history}
			t={props.t}
		/>
	</Paper>;

export default YourPensionBadgesContainer;