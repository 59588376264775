import {AxiosResponse} from "axios";

export default class AbstractServiceRequestClass {
	/**
	 * @type {null}
	 * @private
	 */
	#_request = null;

	/**
	 * @param request
	 */
	constructor(request) {
		if (request) this.request = request;
	}

	/**
	 * @returns {null}
	 */
	get request() {
		return this.#_request;
	}

	/**
	 * @param value
	 */
	set request(value) {
		this.#_request = value;
	}

	/**
	 * @returns {null|*}
	 */
	get(relationships = []) {
		if (this.request) {
			return this.request.Get(relationships);
		}

		return null;
	}

	/**
	 * @param id
	 * @param relationship
	 * @returns {null|*}
	 */
	find(id = false, relationship = []) {
		if (this.request && id) {
			return this.request.Find(id, relationship);
		}

		return null;
	}

	/**
	 * @param data
	 * @returns {null|*}
	 */
	insert(data = false) {
		if (this.request && data) {
			return this.request.Create(data);
		}

		return null;
	}

	/**
	 * @param id
	 * @param data
	 * @returns {Promise<AxiosResponse<T>>|null}
	 */
	update(id = false, data = false) {
		if (this.request && id && data) {
			return this.request.Update(id, data);
		}

		return null;
	}

	/**
	 * @param id
	 * @returns {null|*}
	 */
	delete(id = false) {
		if (this.request && id) {
			return this.request.Delete(id);
		}

		return null;
	}

	/**
	 * @param queryString
	 * @returns {null|*}
	 */
	search(queryString = false) {
		if (queryString) {
			return this.request.Search(queryString);
		}

		return null;
	}

	/**
	 * @returns {null|*}
	 */
	active(pageNumber = 1, abortController) {
		if (this.request) {
			return this.request.Active(pageNumber, abortController);
		}

		return null;
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<T>>|null}
	 */
	login(data = false) {
		if (data && this.request) {
			return this.request.Login(data);
		}

		return null;
	}

	/**
	 * @param page
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	paginate(page = 1): Promise<AxiosResponse<*>> | null {
		if (page && this.request) {
			return this.request.Paginate(page);
		}

		return null;
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	verifyEmail(data: any[] = false): Promise<AxiosResponse<*>> | null {
		if (this.request && data) {
			return this.request.VerifyEmail(data ?? false);
		}

		return null;
	}
}