import React from "react";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import OptOutContentImage1 from "../../images/background-16.svg";
import {connect} from "react-redux";
import {optOut} from "../../actions/OptOut";
import {withTranslation} from "react-i18next";

class ConfirmOptOut extends React.Component {

	/**
	 * @returns {void}
	 * @private
	 */
	_confirmOptOut = () => {
		this.props.closeAccount();
	}

	/**
	 * @returns {string|*}
	 * @private
	 */
	_getEmployerName = (): string | * => {
		const {
			profile_data = false
		} = this.props;

		if (profile_data) {
			const {
				individual_employers = []
			} = profile_data;


			if (individual_employers.length > 0) {

				return individual_employers.map(employers => (
					<Typography
						key={employers.employer.id}
						variant={"caption"}
						component={"p"}
						align={"center"}
					>
						{employers.employer.title}
					</Typography>
				));
			}
		}

		return "";
	};

	/**
	 * @param profile_data
	 * @returns {number}
	 */
	calculateCurrentCollegiaAccountValue = (profile_data = {
		totalCollegiaAccountWithoutFee: 0
	}): number => profile_data?.totalCollegiaAccountWithoutFee ?? 0.00;

	/**
	 * @param profile_data
	 * @returns {number}
	 * @private
	 */
	_renderIndividualProjections = (profile_data: any = false): number => {
		let total = 0;

		if (profile_data) {
			const {
				projection = {
					total_value_of_future_pot: "0.00",
				}
			} = profile_data;

			if (projection) {
				return `${projection.total_value_of_future_pot}`;
			}
		}

		return total;
	};

	/**
	 * @returns {*}
	 */
	render(): * {
		const {
			profile_data = false,
		} = this.props;

		return (
			<React.Fragment>
				<Grid
					xs={12}
					md={12}
					lg={12}
					alignContent={"center"}
					alignItems={"center"}
					direction={"column"}
					className={'no-padding-content'}
					item
					container
				>
					<Grid
						item
					>
						<img
							alt={"Opt out and cancellation"}
							src={OptOutContentImage1}
							id={"OptOutContentImage1"}
						/>
					</Grid>
				</Grid>
				<Grid
					xs={12}
					lg={12}
					md={12}
					alignContent={"center"}
					alignItems={"center"}
					direction={"row"}
					className={"money-info-container"}
					justifyContent={"center"}
					spacing={2}
					item
					container
				>
					<Grid
						xs={6}
						lg={6}
						md={6}
						item
					>
						<Grid
							xs={12}
							md={12}
							lg={12}
							item
						>
							<Typography
								variant={"caption"}
								component={"p"}
								align={"right"}
								className={'font-size-11'}
							>
								YOU ARE GIVING UP TODAY
							</Typography>
						</Grid>
						<Grid
							xs={12}
							md={12}
							lg={12}
							item
						>
							<Typography
								variant={"caption"}
								component={"p"}
								align={"right"}
								className={"current-contribution-amount"}
							>
								£ {this.calculateCurrentCollegiaAccountValue(profile_data)}
							</Typography>
						</Grid>
					</Grid>
					<Grid
						xs={6}
						lg={6}
						md={6}
						item
					>
						<Grid
							xs={12}
							md={12}
							lg={12}
							item
						>
							<Typography
								variant={"caption"}
								component={"p"}
								align={"left"}
								className={'font-size-11'}
							>
								THE FUTURE YOU IS GIVING UP
							</Typography>
						</Grid>
						<Grid
							xs={12}
							md={12}
							lg={12}
							item
						>
							<Typography
								variant={"caption"}
								component={"p"}
								align={"left"}
								className={"future-contribution-amount"}
							>
								£ {this._renderIndividualProjections(profile_data)}
							</Typography>
						</Grid>
					</Grid>
				</Grid>

				<Grid
					xs={12}
					md={12}
					lg={12}
					item
				>
					<Typography
						variant={"caption"}
						align={"center"}
						component={"p"}
						className={"projection"}
					>
						*This projection assumes that: (1) you will retire at {profile_data?.expected_retirement_age ?? 67};
						(2) the current pension contributions will be maintained;
						and (3) your pension will have an average yearly investment return of 5%.
					</Typography>
				</Grid>

				<Grid
					xs={12}
					md={12}
					lg={12}
					className={"contributions-refund-container"}
					item
				>
					<Typography
						variant={"caption"}
						component={"p"}
						align={"center"}
						className={"current-contribution-amount force-normal-font force-text-center"}
					>
						Contributions will be refunded to:
					</Typography>
				</Grid>

				<Grid
					xs={12}
					md={12}
					lg={12}
					className={"employer-container-identifier"}
					item
				>
					{this._getEmployerName()}
				</Grid>

				<Grid
					xs={12}
					md={12}
					lg={12}
					item
				>
					<Typography
						variant={"caption"}
						component={"p"}
						align={"center"}
						className={"sad-to-say"}
					>
						{this.props.t("individual.opt_out.employer.its_sad_to_say")}
					</Typography>
					<Typography
						variant={"caption"}
						component={"p"}
						align={"center"}
						className={"opting-out"}
					>
						Opting out now will delete your Collegia account.
					</Typography>
				</Grid>

				<Grid
					xs={12}
					lg={12}
					md={12}
					id={"SecondStepOptOutContainerNoMargin"}
					item
				>
					<Grid
						xs={12}
						md={12}
						lg={12}
						className={"dialog-contained-button"}
						item
					>
						<Button
							className={"want-to-opt-out-button"}
							type={'button'}
							variant="contained"
							color="primary"
							onClick={() => this._confirmOptOut()}
						>
							I WANT TO STOP CONTRIBUTIONS
						</Button>
					</Grid>
					<Grid
						xs={12}
						md={12}
						lg={12}
						className={'dialog-contained-button'}
						item
					>
						<Button
							className={"want-to-stay-button"}
							variant={"outlined"}
							type={'button'}
							onClick={() => this.props.history(`/my-account`)}
						>
							NO, I WANT TO STAY
						</Button>
					</Grid>
				</Grid>
			</React.Fragment>
		)
	}
}

/**
 * @param dispatch
 * @returns {{optOut: (function(*=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	optOut: (data = false) => dispatch(optOut(data))
});

/**
 * @param state
 * @returns {{}}
 */
const mapStateToProps = (state = {}) => {
	const {
		Profile = []
	} = state;

	return {
		...Profile,
	};
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ConfirmOptOut));