import React from 'react';
import {connect} from "react-redux";
import {cancelDDI, details} from "../../actions/ExtraContributions";
import Wrapper from "../../layout/Logged/Wrapper";
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Header from "../../components/YourPension/Header";
import {formatMoney, removeDefaultLoggedLayout} from "../../utils/Helpers";
import Button from "@mui/material/Button";
import Loading from "../../components/Loading";
import {withTranslation} from "react-i18next";
import {withRouter} from "../../utils/withRouter";


class ExtraContributionsDetails extends React.Component {
	/**
	 * @type {null}
	 * @private
	 */
	_updateInterval = null;

	/**
	 * @param props
	 */
	constructor(props) {
		super(props);
		this.state = {
			id: props.match.params && props.match.params.id ?
				props.match.params.id :
				false,
			type: props.match.params && props.match.params.type ?
				props.match.params.type :
				false,
		};

		this.props.details(this.state.id ?? "", true);
		this._updateInterval = setInterval(() => this.props.details(this.state.id ?? ""), 15000);
	}

	componentDidMount = () => removeDefaultLoggedLayout();

	componentWillUnmount() {
		clearInterval(this._updateInterval);
		delete this._updateInterval;
	}

	/**
	 * @param prevProps
	 * @param prevState
	 * @param snapshot
	 */
	componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
		if (this.props !== prevProps) {
			removeDefaultLoggedLayout();
		}
	}

	/**
	 * @param status
	 * @returns {string}
	 * @private
	 */
	_renderPaymentStatus = (status: string = ""): string => {
		if (
			status.toLowerCase() !== "cancelled" &&
			status.toLowerCase() !== "failed" &&
			status.toLowerCase() !== "rejected"
		) {
			return "success-payment";
		}

		return "failed-payment";
	}

	/**
	 * @param contribution
	 * @returns {JSX.Element|*[]}
	 */
	#renderCancelButton = (contribution = {
		active: false,
		contribution_type: "Monthly",
		provider_status: "ACTIVE",
	}): JSX.Element | *[] => {
		if (contribution?.contribution_type !== "One-off" &&
			(contribution?.provider_status === "ACTIVE" || contribution?.provider_status === "EXPORTED")
		) {
			return (
				<Grid
					xs={12}
					md={12}
					lg={12}
					sm={12}
					spacing={2}
					id={"cancel-ddi-container"}
					direction={"column"}
					alignContent={"center"}
					container
					item
				>

					<Grid
						xs={12}
						md={12}
						lg={12}
						sm={12}
						item
						container
					>
						<Button
							className={"cancel-ddi-button"}
							onClick={() => this.props.navigate(`/confirm-direct-debit-cancellation/${this.state.id}`)}
							fullWidth
						>
							{this.props.t("individual.i_want_to_stop_it")}
						</Button>
					</Grid>
				</Grid>
			);
		}

		return [];
	}

	render() {
		const {
				contribution = {
					active: false,
					contribution_type: "Monthly",
					provider_status: "CANCELLED",
					bank_provider: "",
					source_of_fund: {
						name: "",
					}
				},
				cancellation_loading = false,
			} = this.props,
			{
				type = ""
			} = this.state;

		if (cancellation_loading) {
			return (
				<Loading absolute/>
			);
		}

		return (
			<Wrapper
				t={this.props.t}
				history={this.props.navigate}
			>
				<Slide in={true} direction={"up"}>
					<div>
						<Grid
							spacing={0}
							direction={"column"}
							alignItems={"center"}
							alignContent={"center"}
							id={"extra-contribution-container"}
							container
							item
						>
							<Header
								history={this.props.navigate}
								title={this.props.t("individual.contributions.details.title")}
								backLink={"/extra-contributions"}
								showLeftArrow
							/>
							<Paper
								id={"details-container"}
							>
								<Grid
									xs={12}
									sm={12}
									lg={12}
									md={12}
									alignContent={"flex-start"}
									alignItems={"flex-start"}
									direction={"column"}
									item
									container
								>
									<Typography
										className={"section-title"}
										paragraph
									>
										{this.props.t("individual.contributions.details.bank")}
									</Typography>
									<Grid
										xs={12}
										md={12}
										lg={12}
										sm={12}
										item
										className={"detail-info"}
									>

										<Typography
											className={"section-info"}
											paragraph
										>
											{contribution.bank_provider ?? "Loading..."}
										</Typography>
									</Grid>
									<Typography
										className={"section-title"}
										paragraph
									>
										{this.props.t("individual.contributions.details.contribution_type")}
									</Typography>
									<Grid
										xs={12}
										md={12}
										lg={12}
										sm={12}
										item
										className={"detail-info"}
									>
										<Typography
											className={"section-info"}
											paragraph
										>
											{type ?? contribution.contribution_type ?? "Loading..."}
										</Typography>
									</Grid>
									<Typography
										className={"section-title"}
										paragraph
									>

										{this.props.t("individual.contributions.details.status")}
									</Typography>
									<Grid
										xs={12}
										md={12}
										lg={12}
										sm={12}
										item
										className={"detail-info"}
									>
										<Typography
											className={`section-info ${this._renderPaymentStatus(contribution.provider_status ?? "")}`}
											paragraph
										>
											{contribution.provider_status ?? "Loading..."}
										</Typography>
									</Grid>
									<Typography
										className={"section-title"}
										paragraph
									>
										{this.props.t("individual.contributions.details.amount")}
									</Typography>
									<Grid
										xs={12}
										md={12}
										lg={12}
										sm={12}
										item
										className={"detail-info"}
									>
										<Typography
											className={"section-info"}
											paragraph
										>
											{`£ ${formatMoney(contribution.contribution_value ?? 0, 2, ".", ",")}` ?? "Loading..."}
										</Typography>
									</Grid>
									<Typography
										className={"section-title"}
										paragraph
									>
										{this.props.t("individual.contributions.details.source_of_funds")}
									</Typography>
									<Grid
										xs={12}
										md={12}
										lg={12}
										sm={12}
										item
										className={"detail-info"}
									>
										<Typography
											className={"section-info"}
											paragraph
										>
											{contribution?.source_of_fund?.name ?? "Loading..."}
										</Typography>
									</Grid>
								</Grid>
								{this.#renderCancelButton(contribution)}
							</Paper>
						</Grid>
					</div>
				</Slide>
			</Wrapper>
		);
	}
}

const mapStateToProps = state => state.ExtraContributions;

/**
 * @param dispatch
 * @returns {{details: (function(string=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	details: (orderId: string = "", scrollUp: boolean = false) => dispatch(details(orderId ?? "", scrollUp)),
	cancelDDI: (orderId: string = "") => dispatch(cancelDDI(orderId ?? "")),
});

export default withRouter(
	withTranslation()(
		connect(
			mapStateToProps,
			mapDispatchToProps
		)(
			ExtraContributionsDetails
		)
	)
);