import React from "react";

import {TOGGLE_SNACK_BAR} from "../actions/SnackBar/types";
import moment from "moment";

import standardStrategy from "../images/investment-approaches/strategies/standard.svg";
import sustainableStrategy from "../images/investment-approaches/strategies/sustainable.svg";
import lowGrowthStrategy from "../images/investment-approaches/strategies/low-growth.svg";
import islamicStrategy from "../images/investment-approaches/strategies/islamic.svg";
import highGrowthStrategy from "../images/investment-approaches/strategies/high-growth.svg";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";

import StillNotThereIcon from "../images/stillNotThereIllustration.svg";
import ModerateIcon from "../images/ModerateIllustration.svg";
import ComfortableIcon from "../images/ComfortableIllustration.svg";
import MinimumIcon from "../images/MinimumIllustration.svg";
import Transactions from "../services/Transactions";
import FormControl from "@mui/material/FormControl";
import MaskedInput from "react-text-mask";

/**
 * @type {number[]}
 */
const maxValueMonth = [31, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

/**
 * @type {string[]}
 */
const formatOrder = ['yyyy', 'yy', 'mm', 'dd', 'HH', 'MM', 'SS'];

/**
 * @param event
 * @param component
 * @param onlyNumbers
 * @param customValue
 */
export function onChange(
	event = null,
	component = null,
	onlyNumbers: boolean = false,
	customValue: string = "",
) {
	let value = "";

	if (customValue !== "") {
		value = customValue;
	}

	if (event && component) {
		if (event.target) {

			if (value === "") {
				value = event.target.value;
			}

			if (onlyNumbers) {
				if (value !== undefined) {
					if (typeof value.replace === "function") {
						value = value.replace(/[^0-9]/gi, '');
						value = value.replace(/^0+/, '')
					}
				}
			}
			component.setState({[event.target.name]: value});
		}
	}
}

/**
 * @param state
 * @param action
 * @returns {{valueOf(): boolean}|boolean}
 */
export function resolveReduxState(state = false, action = false) {
	if (action && state) {

		return {
			...state,
			...action,
		}
	}

	return state;
}

/**
 * @param component
 * @param input
 */
export function defaultOnChangeInput(component = false, input = false) {
	if (component && input) {
		component.setState({[input.target.name]: input.target.value});
	}
}

/**
 * @param min
 * @param max
 * @returns {*}
 */
export function getRandomInt(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * @param dispatch
 * @param success
 * @param error
 * @param info
 * @param message
 * @param custom_data
 * @param open
 */
export function toggleSnackBar(
	dispatch,
	success = false,
	error = false,
	info = false,
	message = "",
	custom_data = {},
	open = true
) {
	dispatch({
		type: TOGGLE_SNACK_BAR,
		open,
		success,
		error,
		info,
		message,
		...custom_data,
	});
}

/**
 * @returns {string}
 */
export function js_yyyy_mm_dd_hh_mm_ss(): string {
	const now = new Date();
	const year = "" + now.getFullYear();
	let month = "" + (now.getMonth() + 1);

	if (month.length === 1) {
		month = "0" + month;
	}
	let day = "" + now.getDate();

	if (day.length === 1) {
		day = "0" + day;
	}
	let hour = "" + now.getHours();

	if (hour.length === 1) {
		hour = "0" + hour;
	}
	let minute = "" + now.getMinutes();

	if (minute.length === 1) {
		minute = "0" + minute;
	}
	let second = "" + now.getSeconds();
	if (second.length === 1) {
		second = "0" + second;
	}

	return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
}

/**
 * @param email
 * @returns {boolean}
 */
export function validateEmail(email): boolean {
	const re = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
}

/**
 * @returns {boolean}
 * @param date
 */
export function validateDate(date): boolean {
	const regexddmmyyyy = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;
	return regexddmmyyyy.test(date);
}

/**
 * @param birthday
 * @returns {boolean}
 */
export function validBirthday(birthday: string = ""): boolean {
	if (birthday === "" || birthday === undefined || birthday === false) {
		return false
	}

	if (typeof birthday.replace === "function") {
		if (birthday.trim().replace(/[^\w\s]/gi, '').length < 8) {
			return false;
		}
	}

	birthday = birthday.split("/").reverse().join("-")

	const years = moment().diff(birthday, 'years', false);

	return !(isNaN(years) || years <= 17 || years > 90);
}

/**
 * @param str
 * @returns {boolean}
 */
export function checkPassword(str = ""): boolean {
	if (str.length < 6) {
		return false;
	} else if (str.length > 50) {
		return false;
	} else if (str.search(/\d/) === -1) {
		return false;
	} else if (str.search(/[a-zA-Z]/) === -1) {
		return false;
	}
	// else if (str.search(/[^a-zA-Z0-9\!\@\#\$\%\^\&\*\(\)\_\+\.\,\;\:]/) !== -1) {
	//     return false;
	// }

	return true;
}

/**
 * @returns {string|boolean}
 */
export const retrieveCollegiaAccessToken = (): string | boolean => localStorage.getItem("token") ??
	localStorage.getItem("collegiaToken") ?? false;

export function MathMonthObject(t = key => key) {
	return [
		{
			monthName: t("system.jan"),
			monthFullName: t("system.january"),
			monthNumber: 0,
			total: 0,
		},
		{
			monthName: t("system.feb"),
			monthFullName: t("system.february"),
			monthNumber: 1,
			total: 0,
		},
		{
			monthName: t("system.mar"),
			monthFullName: t("system.march"),
			monthNumber: 2,
			total: 0,
		},
		{
			monthName: t("system.apr"),
			monthFullName: t("system.april"),
			monthNumber: 3,
			total: 0,
		},
		{
			monthName: t("system.may"),
			monthFullName: t("system.may"),
			monthNumber: 4,
			total: 0,
		},
		{
			monthName: t("system.jun"),
			monthFullName: t("system.june"),
			monthNumber: 5,
			total: 0,
		},
		{
			monthName: t("system.jul"),
			monthFullName: t("system.july"),
			monthNumber: 6,
			total: 0,
		},
		{
			monthName: t("system.aug"),
			monthFullName: t("system.august"),
			monthNumber: 7,
			total: 0,
		},
		{
			monthName: t("system.sept"),
			monthFullName: t("system.september"),
			monthNumber: 8,
			total: 0,
		},
		{
			monthName: t("system.oct"),
			monthFullName: t("system.october"),
			monthNumber: 9,
			total: 0,
		},
		{
			monthName: t("system.nov"),
			monthFullName: t("system.november"),
			monthNumber: 10,
			total: 0,
		},
		{
			monthName: t("system.dec"),
			monthFullName: t("system.december"),
			monthNumber: 11,
			total: 0,
		},
	];

}

/**
 * @returns {""|false|boolean}
 */
export function isSafari(): "" | false | boolean {
	return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
		navigator.userAgent &&
		navigator.userAgent.indexOf('CriOS') === -1 &&
		navigator.userAgent.indexOf('FxiOS') === -1;
}

/**
 * @param componentRef
 * @param forceUp
 */
export function forceScrollUp(componentRef: any = false, forceUp = true) {

	if (forceUp) {
		if (componentRef && componentRef.current !== null) {
			componentRef.current.scrollTo(0, 0);
		}

		const root = document.getElementById("root");

		if (root) {
			root.scrollTo(0, 0);
		}

		window.scrollTo(0, 0);
	}
}

/**
 * @param componentRef
 * @param forceUp
 */
export function removeDefaultLoggedLayout(componentRef: any = false, forceUp = true): void {
	const menu = document.getElementById("menu-logged");
	const menu2 = document.getElementById("menu-logged-wrapper");
	const footer = document.getElementById("footer-logged")

	if (menu) {
		menu.style.display = "none";
	}

	if (footer) {
		footer.style.display = "none";
	}

	if (menu2) {
		menu2.style.display = "none";
	}

	forceScrollUp(componentRef ?? false, forceUp);
}

/**
 * @param props
 * @returns {*}
 */
export const getYearlyIncomeFormattedValue = (props: any[] = {
	totalYearlyIncome: "",
}): number => {
	let value;
	if (typeof props.totalYearlyIncome.replace === "function") {
		value = props.totalYearlyIncome.replace(/[^0-9]/gi, '');
		if (typeof value === "function") {
			value = value.replace(/^0+/, '');
			value = parseInt(props.totalYearlyIncome.replace(",", ""));
		}
	} else {
		value = parseInt(props.totalYearlyIncome);
	}

	return value;
}

/**
 * @param ruler
 * @param progress
 * @returns {string}
 */
export function setLifestyleOpacity(
	ruler = "minimum",
	progress = 0,
): string {
	return "";
}

/**
 * @param progress
 * @param cardHeader
 * @param yearlySetUp
 * @param props
 * @returns {string|*}
 */
export function setExpectPensionPotClassName(
	progress: number = 0,
	cardHeader: boolean = false,
	yearlySetUp = false,
	props = false
): string | * {
	if (props.progressLevelClassName) {
		return props.progressLevelClassName;
	}

	if (yearlySetUp && props.totalYearlyIncome) {
		let value = 0;

		if (typeof props.totalYearlyIncome.replace === "function") {
			value = getYearlyIncomeFormattedValue(props ?? false);
		} else {
			value = parseInt(props.totalYearlyIncome);
		}

		if (value >= 0 && value <= 10899) {
			return "progress-not-there pot-critical pot-still-not-there";
		} else if (value > 10899 && value <= 20800) {
			return "progress-minimum pot-minimum";
		} else if (value >= 20801 && value < 33600) {
			return "progress-moderate pot-moderate";
		} else if (value >= 33600) {
			return "progress-comfortable pot-comfortable";
		}
	} else {
		if (progress >= 0 && progress <= 23.61 && !cardHeader) {
			return "pot-critical";
		} else if (progress >= 23.62 && progress < 46.76) {
			return "pot-minimum";
		} else if (progress >= 46.76 && progress < 76.39) {
			return "pot-moderate";
		} else if (progress > 76.39) {
			return "pot-comfortable";
		}
	}

	return "progress-not-there pot-critical";
}

/**
 * @param baseClass
 * @param mixins
 * @returns {base}
 */
export const aggregation = (baseClass, ...mixins) => {
	class base extends baseClass {
		constructor(...args) {
			super(...args);
			mixins.forEach((mixin) => {
				/*eslint-disable */
				copyProps(this, (new mixin));
				/*eslint-enable */
			});
		}
	}

	// this function copies all properties and symbols, filtering out some special ones
	let copyProps = (target, source) => {
		Object.getOwnPropertyNames(source)
			.concat(Object.getOwnPropertySymbols(source))
			.forEach((prop) => {
				if (!prop.match(/^(?:constructor|prototype|arguments|caller|name|bind|call|apply|toString|length)$/))
					Object.defineProperty(target, prop, Object.getOwnPropertyDescriptor(source, prop));
			})
	}

	// outside contructor() to allow aggregation(A,B,C).staticFunction() to be called etc.
	mixins.forEach((mixin) => {
		copyProps(base.prototype, mixin.prototype);
		copyProps(base, mixin);
	});
	return base;
}

/***
 * @param totalIncome
 * @returns {number}
 */
export const progressRulerProgress = (totalIncome = 0): number => {
	let progress = 0;
	for (let i = 0; i <= 1000; i++) {
		if (parseInt(totalIncome) >= (parseInt((i * 43.20)))) {
			progress = i;
		}
	}

	return progress / 10;
};

/**
 * @param props
 * @returns {number}
 */
export function yearlyIncomeFloatValue(props = false): number {
	let value = 0;
	if (props.totalYearlyIncome) {
		value = parseFloat(props.totalYearlyIncome.replace(",", ""));
	} else if (!isNaN(props)) {
		value = props;
	}

	return value;
}

/**
 * @param DOB
 * @returns {number}
 */
export function getAge(DOB): number {
	const today = new Date(),
		birthDate = new Date(DOB);
	let age = today.getFullYear() - birthDate.getFullYear();
	const m = today.getMonth() - birthDate.getMonth();
	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
		age = age - 1;
	}

	return age;
}

/**
 * @param number
 * @param decPlaces
 * @param decSep
 * @param thouSep
 * @returns {string}
 */
export function formatMoney(number, decPlaces = 2, decSep = ".", thouSep = ","): string {
	decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces;
	decSep = typeof decSep === "undefined" ? "." : decSep;
	thouSep = typeof thouSep === "undefined" ? "," : thouSep;

	let j = 0;
	let sign = number < 0 ? "-" : "";
	let i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));

	j = (j = i.length) > 3 ? j % 3 : 0;

	return sign +
		(j ? i.substr(0, j) + thouSep : "") +
		i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
		(decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
}

/**
 * @param start
 * @param end
 * @returns {unknown[]}
 */
export function range(start, end) {
	return Array(end - start + 1).fill().map((_, idx) => start + idx)
}

/**
 * @param url
 * @returns {{}}
 */
export function getParams(url) {
	let params = {};
	let parser = document.createElement('a');
	parser.href = url;
	let query = parser.search.substring(1);
	let vars = query.split('&');
	for (let i = 0; i < vars.length; i++) {
		let pair = vars[i].split('=');
		params[pair[0]] = decodeURIComponent(pair[1]);
	}
	return params;
}

/**
 * @param dataURI
 * @returns {*}
 */
export const convertDataURIToBinary = (dataURI) => {
	const BASE64_MARKER = ';base64,';
	const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
	const base64 = dataURI.substring(base64Index);
	const raw = window.atob(base64);
	const rawLength = raw.length;
	const array = new Uint8Array(new ArrayBuffer(rawLength));

	for (let i = 0; i < rawLength; i++) {
		array[i] = raw.charCodeAt(i);
	}
	return array;
};

/**
 * @param blob
 * @returns {Promise<unknown>}
 */
export function blobToDataURL(blob): Promise<unknown> {
	return new Promise((fulfill, reject) => {
		let reader = new FileReader();
		reader.onerror = reject;
		reader.onload = (e) => fulfill(reader.result);
		reader.readAsDataURL(blob);
	})
}

/**
 * @param fileVar
 * @param imgElem
 */
export function loadImgFromFile(fileVar, imgElem) {
	const fileReader = new FileReader();
	fileReader.onload = onFileLoaded;
	fileReader.readAsBinaryString(fileVar);

	function onFileLoaded(fileLoadedEvent) {
		let result, data;
		data = fileLoadedEvent.target.result;
		result = "data:";
		result += fileVar.type;
		result += ";base64,";
		result += btoa(data);
		imgElem.src = result;
	}
}

/**
 * @param b64Data
 * @param contentType
 * @param sliceSize
 * @returns {Blob}
 */
export function b64toBlob(b64Data, contentType, sliceSize): Blob {
	contentType = contentType || '';
	sliceSize = sliceSize || 512;

	const byteCharacters = atob(b64Data);
	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);

		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);

		byteArrays.push(byteArray);
	}

	return new Blob(byteArrays, {type: contentType});
}

/**
 * @param dataurl
 * @param filename
 * @returns {File}
 */
export function dataURLtoFile(dataurl, filename): File {
	let arr = dataurl.split(','),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new File([u8arr], filename, {type: mime});
}

/**
 * @param props
 * @param perMonth
 * @returns {number}
 */
export function pensionIncomeNominalValues(props: any[] = {
	projection: {
		total_value_of_future_pot: 0,
	},
	totalYearlyIncome: 0,
}, perMonth: boolean = false): number {
	let value = 0;
	if (props) {
		if (perMonth) {
			if (props.totalYearlyIncome) {
				if (typeof props.totalYearlyIncome.replace === "function") {
					value = parseFloat(props.totalYearlyIncome.replace(/,/g, ''));
				} else {
					value = parseFloat(props.totalYearlyIncome);
				}
			}
		} else {
			if (props.projection && props.projection.total_value_of_future_pot) {
				if (typeof props.projection.total_value_of_future_pot.replace === "function") {
					value = parseFloat(props.projection.total_value_of_future_pot.replace(/,/g, ''));
				} else {
					value = parseFloat(props.projection.total_value_of_future_pot);
				}
			}
		}
	}

	return value;
}

/**
 *
 * @param props
 * @returns {JSX.Element|*[]}
 */
export const renderInvestmentDetailsImage = (props: any = false): JSX.Element | *[] => {
	if (props) {
		const currentInvestment = props.investment_approaches.filter(i => props.name === i.name);

		if (currentInvestment.length > 0) {
			const investment = currentInvestment[0];

			switch (investment.name) {
				case props.t("system.investment_approaches.sustainable"):
					return (
						<img
							src={sustainableStrategy}
							alt={investment.name}
						/>
					)
				case props.t("system.investment_approaches.standard"):
				case props.t("system.investment_approaches.ifa_managed"):
					return (
						<img
							src={standardStrategy}
							alt={investment.name}
						/>
					)
				case props.t("system.investment_approaches.high_growth"):
					return (
						<img
							src={highGrowthStrategy}
							alt={investment.name}
						/>
					)
				case props.t("system.investment_approaches.low_growth"):
					return (
						<img
							src={lowGrowthStrategy}
							alt={investment.name}
						/>
					)
				case props.t("system.investment_approaches.islamic_finance"):
					return (
						<img
							src={islamicStrategy}
							alt={investment.name}
						/>
					)
				default:
					return <LocalAtmIcon/>;
			}
		}
	}

	return [];
}

/**
 * @param dateFormat
 * @param minYear
 * @param maxYear
 * @returns {(function(*): (boolean|{value: *, indexesOfPipedChars: []}))|*}
 */
export const createAutoCorrectedDatePipe = (dateFormat = 'dd/mm/yyyy', {
	minYear = 1,
	maxYear = 9999
} = {}) => {
	const dateFormatArray = dateFormat
		.split(/[^dmyHMS]+/)
		.sort((a, b) => formatOrder.indexOf(a) - formatOrder.indexOf(b));

	return function (conformedValue) {
		const indexesOfPipedChars = [],
			maxValue = {'dd': 31, 'mm': 12, 'yy': 99, 'yyyy': maxYear, 'HH': 23, 'MM': 59, 'SS': 59},
			minValue = {'dd': 1, 'mm': 1, 'yy': 0, 'yyyy': minYear, 'HH': 0, 'MM': 0, 'SS': 0},
			conformedValueArr = conformedValue.split('');

		// Check first digit
		dateFormatArray?.forEach((format) => {
			const position = dateFormat.indexOf(format);
			const maxFirstDigit = parseInt(maxValue[format].toString().substr(0, 1), 10);

			if (parseInt(conformedValueArr[position], 10) > maxFirstDigit) {
				conformedValueArr[position + 1] = conformedValueArr[position];
				conformedValueArr[position] = 0;
				indexesOfPipedChars.push(position);
			}
		});

		// Check for invalid date
		let month = 0;
		const isInvalid = dateFormatArray.some((format) => {
			const position = dateFormat.indexOf(format);
			const length = format.length;
			const textValue = conformedValue.substr(position, length).replace(/\D/g, '');
			const value = parseInt(textValue, 10);
			if (format === 'mm') {
				month = value || 0;
			}
			const maxValueForFormat = format === 'dd' ? maxValueMonth[month] : maxValue[format];
			if (format === 'yyyy' && (minYear !== 1 || maxYear !== 9999)) {
				const scopedMaxValue = parseInt(maxValue[format].toString().substring(0, textValue.length), 10),
					scopedMinValue = parseInt(minValue[format].toString().substring(0, textValue.length), 10);
				return value < scopedMinValue || value > scopedMaxValue;
			}
			return value > maxValueForFormat || (textValue.length === length && value < minValue[format]);
		})

		if (isInvalid) {
			return false;
		}

		return {
			value: conformedValueArr.join(''),
			indexesOfPipedChars
		};
	}
}

/**
 * @param props
 * @returns {{icon: *, text: string, textClassName: string, financialContainerClassName: string}}
 */
export function renderHeaderInfo(props) {
	const renderObject = {
		icon: StillNotThereIcon,
		text: "Still not there",
		textClassName: "still-not-there-class",
		financialContainerClassName: "still-not-there-financial-class"
	};

	if (props.activeStep) {
		switch (props.activeStep) {

			case 2:
				renderObject.icon = ModerateIcon;
				renderObject.text = props.t("individual.understand_lifestyle.moderate.title");
				renderObject.textClassName = "moderate-class";
				renderObject.financialContainerClassName = "moderate-financial-class";
				break;

			case 3:
				renderObject.icon = ComfortableIcon;
				renderObject.text = props.t("individual.understand_lifestyle.comfortable.title");
				renderObject.textClassName = "comfortable-class";
				renderObject.financialContainerClassName = "comfortable-financial-class";
				break;

			case 1:
				renderObject.icon = MinimumIcon;
				renderObject.text = "Minimum";
				renderObject.textClassName = "minimum-class";
				renderObject.financialContainerClassName = "minimum-financial-class";
				break;

			case 0:
			default:
				renderObject.icon = StillNotThereIcon;
				renderObject.text = "Still not there";
				renderObject.textClassName = "still-not-there-class";
				renderObject.financialContainerClassName = "still-not-there-financial-class";
				break;
		}
	}

	return renderObject;
}

/**
 * @returns {Promise<T>}
 */
export const notSignedMandates = (): Promise<T> => (
	new Transactions()
		.getNonSignedMandates()
		.then(response => {
			const {
				data = {
					data: [],
				}
			} = response;


			if (data.length > 0) {
				window.location.href = process.env.REACT_APP_INDIVIDUAL_DEFAULT_APP_LINK + "/confirm-monthly-contribution/" + data[0]?.end_to_end_verification
			}
		})
		.catch(error => {
			if (error.response) {
				if (error.response.status === 401) {
					localStorage.clear();
					window.location = "/login";
				}
			}
			console.error(error);
		})
);

/**
 *
 * @param date
 * @returns {moment.Moment}
 */
export function calculateNextOnDate(date): moment.Moment {
	let contributionDate = moment(date).format("YYYY-MM-DD");
	let currentDate = moment(new Date()).format("YYYY-MM-DD");

	if (moment(currentDate).isAfter(contributionDate)) {
		contributionDate = moment(contributionDate).month(moment(currentDate).month());
		if (moment(currentDate).isAfter(contributionDate)) {
			contributionDate = moment(contributionDate).add(1, 'M');
		}
	}

	return moment(contributionDate);
}

const getLocation = (href) => {
	var l = document.createElement("a");
	l.href = href;
	return l;
};

export function mobiles() {
	const playStoreLink = 'https://play.google.com/store/apps/details?id=com.collegia.mobile.application';
	const appStoreLink = 'https://apps.apple.com/gb/app/collegia/id1554864077';
	let path = getLocation(window.location.href).pathname;

	if (path === "/") {
		path = "/login";
	}

	const deepLink = `collegia:/${path}`;

	const isAndroid = /Android/i.test(navigator.userAgent);
	const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

	var clickedAt = +new Date();
	// During tests on 3g/3gs this timeout fires immediately if less than 500ms.
	if (isIOS || isAndroid) {
		setTimeout(function () {
			// To avoid failing on return to MobileSafari, ensure freshness!
			if (+new Date() - clickedAt < 2000) {
				if (isIOS) {
					window.location = appStoreLink;
				} else {
					window.location = playStoreLink;
				}
			}
		}, 2500);
		window.location = `${deepLink}`;
	}
}

export function iOS() {
	const currentLocation = getLocation(window.location.href).pathname.split("/");
	if (currentLocation.filter(location => location === "activation").length > 0) {
		return;
	}

	var userAgent = window.navigator.userAgent.toLowerCase(),
		safari = /safari/.test(userAgent),
		ios = /iphone|ipod|ipad/.test(userAgent);

	if (ios) {
		if (safari) {
			var clickedAt = +new Date();
			// During tests on 3g/3gs this timeout fires immediately if less than 500ms.
			setTimeout(function () {
				// To avoid failing on return to MobileSafari, ensure freshness!
				if (+new Date() - clickedAt < 2000) {
					window.location = "https://apps.apple.com/gb/app/collegia/id1554864077";
				}
			}, 2500);
			window.location = `collegia://${getLocation(window.location.href).pathname}`;
		}
	}
}

/**
 * @param date
 * @returns {boolean}
 */
export const isDDMMYYY = (date: string = ""): boolean => {
	const date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
	return date_regex.test(date);
}

/**
 * @param dispatch
 * @param object
 * @param response
 * @param type
 * @returns {{}|*}
 */
export function resolveRequestResponse(
	dispatch = false,
	object = false,
	response = false,
	type = false
) {

	if (dispatch && object && response && type) {
		return dispatch({
			type,
			...object,
			code: response.status,
		});
	}

	return {};
}


/**
 *
 * @param birthdate
 * @param setBirthdate
 * @param id
 * @param t
 * @param id
 * @returns {JSX.Element}
 */
export const renderBirthDateTextBox = (
	birthdate = "",
	setBirthdate = () => {
	},
	id = "",
	t = key => key,
): JSX.Element => (
	<FormControl
		variant={"outlined"}
		className={"input-field"}
		id={id}
		fullWidth
	>
		<MaskedInput
			className={"mask-input-style"}
			mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
			placeholderChar={'\u2000'}
			placeholder={`${t("individual.birthdate")} *`}
			variant={"outlined"}
			name={"birthdate"}
			id={"birthdate"}
			value={birthdate ?? new Date()}
			onChange={event => setBirthdate(event.target.value)}
			pipe={
				createAutoCorrectedDatePipe("dd mm yyyy", {
					minYear: 1900,
					maxYear: new Date().getFullYear() - 16
				})}
		/>
	</FormControl>
);

/**
 * @param string
 * @returns {string}
 */
export const capitalizeFirstLetter = (string = ""): string => {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 *
 * @param strings
 * @returns {string}
 * Removes null elements and join with white space
 */
export const generateFriendlyString = (...strings): string => {
	return strings.filter(n => n).join(" ");
}

/**
 * @returns {array | boolean}
 */
export function returnPhoneCountriesCode() {
	return false;
}
