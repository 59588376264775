import Me from './Me';
import CompleteWalkthrough from "./CompleteWalkthrough";
import Login from './Login';
import UpdateRetirementAge from "./UpdateRetirementAge";
import UpdateStatePension from "./UpdateStatePension";
import MyEmployers from "./MyEmployers";
import Projections from "./Projections";
import UpdateProfilePicture from "./UpdateProfilePicture";
import Update from "./Update";
import RequestChangeEmail from "./RequestChangeEmail";
import ConfirmChangeProfileCode from "./ConfirmChangeProfileCode";
import CheckProfileChangeCode from './CheckProfileChangeCode';
import UpdateMyEmail from "./UpdateMyEmail";
import UpdateMyPassword from "./UpdateMyPassword";
import CloseAccount from "./CloseAccount";
import UpdateMyAddress from "./UpdateMyAddress";
import LogOut from "./LogOut";
import BankAccounts from "./BankAccounts";
import CreateBankAccount from "./CreateBankAccount";
import UpdateBankAccount from "./UpdateBankAccount";
import DeleteBankAccount from "./DeleteBankAccount";
import RetrieveBankAccountDetails from "./RetrieveBankAccountDetails";
import RequestNewPassword from "./RequestNewPassword";
import CheckForgotPasswordToken from "./CheckForgotPasswordToken";
import UpdatePassword from "./UpdatePassword";
import RetrieveOptionsValues from "./RetrieveOptionsValues";
import CheckDuplicateNin from "./CheckDuplicateNin";
import isValidNationalInsuranceNumber from "./isValidNationalInsuranceNumber";
import ChangePersonalData from "./RequestChangePersonalData";
import uploadDdiFile from "./uploadDdiFile";
import GetActivationToken from "./GetActivationToken";
import ModifyIfaAccess from "./ModifyIfaAccess";
import GenerateHMRCAccessStateToken from './GenerateHMRCAccessStateToken';
import ProcessPastEmployments from "./ProcessPastEmployments";
import ProcessResidencyStatus from "./ProcessResidencyStatus";
import CreateFirstPassword from "./CreateFirstPassword";
import ReturnMonthlyProjections from "./ReturnMonthlyProjections";
import MainPage from "./MainPage";

const ProfileRequests = {
	Me,
	CompleteWalkthrough,
	Login,
	UpdateRetirementAge,
	UpdateStatePension,
	ModifyIfaAccess,
	MyEmployers,
	Projections,
	UpdateProfilePicture,
	Update,
	RequestChangeEmail,
	ConfirmChangeProfileCode,
	CheckProfileChangeCode,
	UpdateMyEmail,
	UpdateMyPassword,
	CloseAccount,
	UpdateMyAddress,
	LogOut,
	BankAccounts,
	CreateBankAccount,
	UpdateBankAccount,
	DeleteBankAccount,
	RetrieveBankAccountDetails,
	RequestNewPassword,
	CheckForgotPasswordToken,
	UpdatePassword,
	RetrieveOptionsValues,
	CheckDuplicateNin,
	isValidNationalInsuranceNumber,
	ChangePersonalData,
	uploadDdiFile,
	GetActivationToken,
	CreateFirstPassword,
	GenerateHMRCAccessStateToken,
	ProcessPastEmployments,
	ProcessResidencyStatus,
	ReturnMonthlyProjections,
	MainPage
};

export default ProfileRequests;