import React from "react";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const OnePensionManyBenefits = (): JSX.Element => (
	<div id="Section-4" className="section-6 wf-section">
		<div className="second-cta-especial">
			<h1 className="heading-3-black">One pension, <br />many benefits</h1>
			<p className="paragraph-3-black">We automatically collect contributions from your employer and<strong> add
				the 25% pension top up from the UK Government.</strong></p>
		</div>
	</div>
);

export default OnePensionManyBenefits;