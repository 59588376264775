import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";

/**
 * @param props
 * @returns {JSX.Element|*[]}
 * @private
 */
const _renderCardContent = (props: any[] = {
	supportedCurrencies: "GBP",
	account_number: "",
	branch_code: "",
	iban: "",
	logo: "",
	accountName: "",
	name: "",
}): JSX.Element | *[] => {

	if (props.supportedCurrencies && props.supportedCurrencies.length > 0) {
		return (
			<CardContent>
				<Grid
					xs={12}
					sm={12}
					lg={12}
					md={12}
					alignContent={"center"}
					alignItems={"center"}
					direction={"column"}
					item
					container
				>
					<img
						src={props.logo}
						alt={props.name
							?.replaceAll("Personal", "")
							?.replaceAll(" Personal", "")
						}
					/>
				</Grid>

				<Grid
					xs={12}
					sm={12}
					lg={12}
					md={12}
					alignContent={"center"}
					alignItems={"center"}
					direction={"column"}
					item
					container
				>
					<Typography
						variant="body2"
						color="textSecondary"
						component="p"
						align={"center"}
					>
						{
							props.name
								?.replaceAll("Personal", "")
								?.replaceAll(" Personal", "")
						}
					</Typography>
				</Grid>
			</CardContent>
		);
	} else if (props.account_number && props.branch_code) {
		return (
			<CardContent>
				<Typography variant="body2" color="textSecondary" component="p">
					Account info:
				</Typography>
				<ul>
					<li>
						Account number: {props.account_number}
					</li>
					<li>
						Branch code: {props.branch_code}
					</li>
				</ul>
			</CardContent>
		);
	} else if (props.iban) {

		return (
			<CardContent>
				<Typography variant="body2" color="textSecondary" component="p">
					Account info:
				</Typography>
				<ul>
					<li>
						IBAN: {props.iban}
					</li>
				</ul>
			</CardContent>
		);
	}


	return [];
};

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const BankListCard = (props = {
	id: null,
	account_name: "",
	logo: "",
}): JSX.Element => (
	<Grid
		lg={2}
		md={2.45}
		sm={6}
		xs={6}
		className={"bank-list"}
		item
		container
	>
		<Card
			className={`bank-card-list`}
			onClick={() => props.selectMyBank(props.id ?? "", props.name ?? props.account_name ?? "")}
		>
			{_renderCardContent(props ?? false)}
		</Card>
	</Grid>
);

/**
 * @stateless
 * @param props
 * @returns {JSX.Element}
 */
export default BankListCard;