import {
	ACCEPT_INVITATION,
	REFER_EMPLOYER_MANUALLY,
	REJECT_INVITATION,
	RETRIEVE_INVITATION_DATA
} from "../../actions/Invitations/types";
import {resolveReduxState} from "../../utils/Helpers";


const initial_state = {
	invitation_loading: false,
	refer_employer_manually_success: false,
	invite: {
		name: "",
		email: "",
	},
	error_data: {},
};

const InvitationsStore = (state = initial_state, action = {
	type: "",
}) => {
	switch (action?.type) {

		case RETRIEVE_INVITATION_DATA:
		case REJECT_INVITATION:
		case ACCEPT_INVITATION:
		case REFER_EMPLOYER_MANUALLY:
			return resolveReduxState(state, action);

		default:
			return state;
	}
};

export default InvitationsStore;