import {
	CHECK_NIN_DUPLICATE,
	COMPLETE_WALKTHROUGH,
	CONFIRM_REQUEST_CHANGE_PROFILE_CODE,
	FETCH_EMPLOYERS_DATA,
	FETCH_PROFILE_DATA,
	GENERATE_HMRC_ACCESS_TOKEN_STATE,
	GET_ACTIVATION_TOKEN_CLEAR,
	GET_ACTIVATION_TOKEN_ERROR,
	GET_ACTIVATION_TOKEN_STARTED,
	GET_ACTIVATION_TOKEN_SUCCESS,
	LOGIN,
	MY_PROJECTIONS,
	PROCESS_PAST_EMPLOYMENTS,
	PROFILE_CHANGE_CODE_CHECKED,
	REQUEST_CHANGE_EMAIL,
	RETRIEVE_NEW_PASSWORD,
	RETRIEVE_OPTIONS_VALUES, RETURN_MONTHLY_PROJECTION,
	UPDATE_MY_STATE_PENSION,
	UPDATE_PROFILE,
	UPDATE_PROFILE_PICTURE,
	UPDATE_RETIREMENT_AGE
} from "../../actions/Profile/types";
import {resolveReduxState} from "../../utils/Helpers";
import {SIMULATE_PROJECTION} from "../../actions/Projections/types";

/**
 * @type {{profile_loading: boolean, walkthrough_complete: boolean, login_text: string, profile_data: boolean}}
 */
const initial_state = {
	profile_loading: true,
	slider_loading: false,
	profile_data: {
		generic_projection_parameter: {
			generic_projection_parameter: {
				weekly_state_pension_value: 0,
			}
		},
		projection: {
			active: true,
			annuity_income: "0",
			badge: "",
			formatted_annuity_income: "00.00",
			formatted_nominal_value_of_future_pot: "00.00",
			formatted_real_total_return_rate_month_over_month: "0.21",
			formatted_total_ae_monthly_contribution: "00.00",
			formatted_total_ae_net_of_contribution_charge: "0.00",
			formatted_total_extra_net_of_contribution_charge: "0.00",
			formatted_total_value_of_future_pot: "00.00",
			formatted_total_yearly_income: "00.00",
			fund_charge_amc_month_over_month: "0.00",
			fund_charge_amc_year_over_year: "0.00",
			id: null,
			individual_extra_contribution_id: null,
			main_page_container: "minimum-container",
			nominal_value_of_future_pot: "",
			progress_level_classname: "progress-minimum pot-minimum",
			real_total_return_rate_month_over_month: "0.21",
			ruler_progress_classname: "progress-minimum",
			total_ae_monthly_contribution: "0",
			total_ae_net_of_contribution_charge: "0",
			total_extra_net_of_contribution_charge: "0.00",
			total_value_of_future_pot: "0",
			total_yearly_income: "0",
			transaction_fee_id: null,
		},
		portfolio: {
			created_at: "",
			fund_price_id: null,
			id: null,
			individual_id: null,
			investment_return: "",
			portfolio_date: "",
			total_units: "",
			total_value_invested: "",
			updated_at: null,
		},
		comfort_ruler_projection_parameters: {
			comfortable: 43100,
			lower_bound: 0,
			minimum: 14400,
			moderate: 31300,
			upper_limit: 64000,
		},
		individual_employers: [],
		extra_contributions: [],
		other_pensions: [],
		third_party_software_connections: [],
		ifa_connections: [],
		policy_number: "",
		my_ip: "",
		id: null,
	},
	walkthrough_complete: false,
	valid_nin: false,
	login_text: "",
	employers: [],
	my_projections: [],
	options_value: [],
	request_change_email: false,
	request_change_password: false,
	operation: "",
	request_change_email_code_confirmed: false,
	request_change_password_code_confirmed: false,
	exist_nin: false,
	token_loading: false,
	token: false,
	state_pension_loading: false,
	first_login: false,
	token_active: false,
	individual_id: null,
	code_confirmed: null,
	message_error: "",
	language: "en",
	monthly_projections_loading: false,
	validate_nin_loading: false,
};

/**
 * Default user's profile state store handler.
 * @param state
 * @param action
 * @returns {{profile_loading: boolean, walkthrough_complete: boolean, login_text: string, profile_data: boolean}|{valueOf, (): boolean}|boolean}
 */
const ProfileStore = (state = initial_state, action = false) => {

	if (action) {
		const {type = false} = action;
		switch (type) {
			case FETCH_PROFILE_DATA:
			case COMPLETE_WALKTHROUGH:
			case LOGIN:
			case UPDATE_RETIREMENT_AGE:
			case UPDATE_MY_STATE_PENSION:
			case FETCH_EMPLOYERS_DATA:
			case MY_PROJECTIONS:
			case UPDATE_PROFILE_PICTURE:
			case REQUEST_CHANGE_EMAIL:
			case CONFIRM_REQUEST_CHANGE_PROFILE_CODE:
			case PROFILE_CHANGE_CODE_CHECKED:
			case SIMULATE_PROJECTION:
			case RETRIEVE_NEW_PASSWORD:
			case RETRIEVE_OPTIONS_VALUES:
			case CHECK_NIN_DUPLICATE:
			case GET_ACTIVATION_TOKEN_STARTED:
			case GET_ACTIVATION_TOKEN_SUCCESS:
			case GET_ACTIVATION_TOKEN_ERROR:
			case GET_ACTIVATION_TOKEN_CLEAR:
			case GENERATE_HMRC_ACCESS_TOKEN_STATE:
			case PROCESS_PAST_EMPLOYMENTS:
			case UPDATE_PROFILE:
			case RETURN_MONTHLY_PROJECTION:
				return resolveReduxState(state, action);

			default:
				return state;
		}
	}

	return state;
};

export default ProfileStore;