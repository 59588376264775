import React, {useState} from "react";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FirstConfirmation from "../../components/Home/OptOutPage/FirstConfirmation";
import {useNavigate} from "react-router-dom";

/**
 * @param employer
 * @returns {number}
 */
const calculateCurrentCollegiaAccountValue = (employer = false) => {
	let totalInvested = 0;

	if (employer) {
		const {
			papdis = []
		} = employer;
		if (papdis.length > 0) {
			const governmentContribution = (parseFloat(papdis[0].employee_contributions_amount)) * 0.25;
			totalInvested = parseFloat(papdis[0].employer_contributions_amount) +
				parseFloat(papdis[0].employee_contributions_amount) + governmentContribution
				+ parseFloat(papdis[0].additional_voluntary_contributions_amount);
		}
	}

	return totalInvested;
};

/**
 * @param profile_data
 * @param employer_data
 * @returns {JSX.Element|*[]}
 * @private
 */
const showRefundInfo = (
	profile_data: any[] = {
		canCloseAccount: false,
	},
	employer_data: any[] = {
		employer: {},
	}
) => {
	if (profile_data) {
		if (profile_data.canCloseAccount) {
			return (
				<React.Fragment>
					<Grid
						xs={12}
						md={12}
						lg={12}
						className={"contributions-refund-container"}
						item
					>
						<Typography
							variant={"caption"}
							component={"p"}
							align={"center"}
							className={"refund-info force-normal-font"}
						>
							Contributions will be refunded to:
						</Typography>
					</Grid>

					<Grid
						xs={12}
						md={12}
						lg={12}
						className={"employer-container-identifier"}
						item
					>
						<Typography
							variant={"caption"}
							component={"p"}
							align={"center"}
						>
							{employer_data?.employer?.title ?? ""}
						</Typography>
					</Grid>
				</React.Fragment>
			)
		}
	}
	return [];
}

/**
 * @param my_projections
 * @returns {string|number|number}
 * @private
 */
const retrieveTotalValueOfFuturePot = (my_projections = [{
	active: true,
	individual_extra_contribution_id: null,
	transaction_fee_id: null,
	annuity_income: "",
	badge: "",
	fund_charge_amc_month_over_month: 0,
	fund_charge_amc_year_over_year: "",
	individual_id: 11,
	main_page_container: "",
	nominal_value_of_future_pot: "",
	progress: 0,
	progress_level_classname: "",
	real_total_return_rate_month_over_month: 0,
	ruler_progress_classname: "",
	total_ae_monthly_contribution: "",
	total_ae_net_of_contribution_charge: "",
	total_value_of_future_pot: "",
	total_yearly_income: "",
}] | {
	active: true,
	individual_extra_contribution_id: null,
	transaction_fee_id: null,
	annuity_income: "",
	badge: "",
	fund_charge_amc_month_over_month: 0,
	fund_charge_amc_year_over_year: "",
	individual_id: 11,
	main_page_container: "",
	nominal_value_of_future_pot: "",
	progress: 0,
	progress_level_classname: "",
	real_total_return_rate_month_over_month: 0,
	ruler_progress_classname: "",
	total_ae_monthly_contribution: "",
	total_ae_net_of_contribution_charge: "",
	total_value_of_future_pot: "",
	total_yearly_income: "",
}): * | number => {

	if (my_projections?.length === 1) {
		return my_projections?.formatted_total_value_of_future_pot ?? 0;
	} else if (my_projections?.length > 1) {
		return my_projections[0]?.formatted_total_value_of_future_pot ?? 0;
	} else if (my_projections?.total_value_of_future_pot) {
		return my_projections?.total_value_of_future_pot;
	}

	return 0;
}

/**
 * @param employer_data
 * @param my_projections
 * @param profile_data
 * @param code
 * @param optOut
 * @param t
 * @param insideApp
 * @returns {JSX.Element}
 * @constructor
 */
const SecondStep = (
	{
	employer_data = [],
	my_projections = [],
	profile_data = {
		expected_retirement_age: 67,
	},
	code = "",
    optOut = () => {
    },
	t = key => key,
	insideApp = false,
}
) => {
	const [showModal, setShowModal] = useState(false),
		history = useNavigate();

	return (
		!showModal ?
			(
				<Grid
					xl={8.5}
					lg={9.5}
					md={9.5}
					sm={10}
					xs={12}
					item
					container
					direction={"row"}
					justifyContent={"flex-start"}
				>
					<Grid
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
						item
					>
						<Typography
							className={"upper-grid-title"}
						>
							{t('individual.opt_out.employer.its_sad_to_say')}
						</Typography>
						<Typography
							className={"upper-grid-subtitle"}
						>
							{t("individual.opt_out.employer.opting_out_now_affect_retirement")}
						</Typography>
					</Grid>
					<Grid
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
						alignContent={"center"}
						alignItems={"center"}
						direction={"row"}
						className={"money-info-container"}
						item
						container
					>
						<Grid
							xs={6}
							lg={6}
							md={6}
							item
							padding={0.5}
						>
							<Grid
								xs={12}
								md={12}
								lg={12}
								item
							>
								<Typography
									variant={"caption"}
									component={"p"}
									align={"left"}
									className={"upper-subtitle"}
								>
									{t("individual.opt_out.employer.the_future_you_is_giving_up")}
								</Typography>
							</Grid>
							<Grid
								xs={12}
								md={12}
								lg={12}
								item
							>
								<Typography
									variant={"caption"}
									component={"p"}
									className={"value color2"}
								>
									£ {retrieveTotalValueOfFuturePot(my_projections)}
								</Typography>
							</Grid>
						</Grid>
						<Grid
							xs={6}
							lg={6}
							md={6}
							item
							padding={0.5}
						>
							<Grid
								xs={12}
								md={12}
								lg={12}
								item
							>
								<Typography
									variant={"caption"}
									component={"p"}
									align={"left"}
									className={"upper-subtitle"}
								>
									{t("individual.opt_out.employer.you_are_giving_up_today")}
								</Typography>
							</Grid>
							<Grid
								xs={12}
								md={12}
								lg={12}
								item
							>
								<Typography
									variant={"caption"}
									component={"p"}
									className={"value color1"}
								>
									{
										"£ " +
										calculateCurrentCollegiaAccountValue(
											employer_data.employer ?? false
										).toFixed(2)
									}
									<br/>
								</Typography>
							</Grid>
						</Grid>
					</Grid>

					<Grid
						xs={12}
						md={12}
						lg={12}
						paddingTop={12}
						item
					>
						<Typography
							variant={"caption"}
							align={"left"}
							component={"p"}
							className={"projection"}
							maxWidth={"70%"}
							dangerouslySetInnerHTML={{
								__html: t("individual.opt_out.employer.projection_info", {
									expected_retirement_age: profile_data?.expected_retirement_age ?? 67
								})
							}}
						/>
					</Grid>

					{showRefundInfo(profile_data ?? false, employer_data ?? false)}
					<Grid
						xs={12}
						lg={12}
						md={12}
						id={"SecondStepOptOutContainerNoMargin"}
						item
					>
						<Grid
							xs={12}
							md={12}
							lg={12}
							sm={12}
							xl={11}
							item
							container
							paddingTop={12}
							justifyContent={"space-between"}
						>
							<Button
								className={"want-to-opt-out-button"}
								onClick={() =>
									insideApp ?
										setShowModal(true)
										:
										optOut({
											profiles: {
												code
											}}
										)
								}
							>
								{t("individual.yes_i_want_to_stop_contributions")}
							</Button>
							<Button
								className={"want-to-stay-button"}
								onClick={() => history(`/auto-enrolment`)}
							>
								{t("individual.no_i_want_to_stay")}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			)
			:
			<FirstConfirmation
				history={history}
				individual={profile_data}
				optOut={optOut}
				code={code}
				insideApp={true}
			/>
	);
}

export default SecondStep;