import React, {useState, useEffect} from "react";
import Wrapper from "../layout/Logged/Wrapper";
import {createLead, searchEmployer} from "../actions/Employers";
import {connect} from "react-redux";
import ReferEmployerDesktop from "../components/Employers/ReferEmployerDesktop";
import ReferEmployerMobile from "../components/Employers/ReferEmployerMobile";
import {useTranslation} from "react-i18next";
import {returnUser} from "../actions/Profile";
import Loading from "../components/Loading";

/**
 * @param company
 * @param t
 * @returns {string|*}
 */
const renderCompanyNumber = (company: any = false, t = key => key,): string => {
	if (company) {
		if (company.company_number) {
			return company.company_number;
		}
	}
	return t("individual.refer_employer.retrieving_company_number");
};

/**
 * @param company
 * @param t
 * @returns {*}
 */
const renderCompanyName = (company: any = false, t = key => key,): string => {
	if (company) {
		if (company.title) {
			return company.title;
		}
	}
	return t("individual.refer_employer.retrieving_company_name");
};

/**
 * @param company
 * @param t
 * @returns {string|*}
 */
const renderIncorporatedInfo = (company: any = false, t = key => key): string => {
	if (company) {
		if (company.description) {
			if (typeof company.description.split === "function") {
				let info = company.description.split("-");

				if (info.length > 1) {
					return info[1].trim();
				}
				return info[0].trim();
			}
		}
	}
	return t("individual.refer_employer.retrieving_company_info");
};

/**
 * @returns {string|*}
 * @param company
 */
const renderAddressSnippet = (company: any = {
	address_snippet: "",
}): string => {
	if (company) {
		if (company.address_snippet) {
			return company.address_snippet;
		}
	}
	return "";
};

/**
 * @param search_loading
 * @param search_results
 * @param searchTrigger
 * @param searchEmployers
 * @param createLead
 * @param history
 * @param showModal
 * @param employer_loading
 * @returns {JSX.Element}
 * @constructor
 */
const ReferEmployer = (
	{
		search_loading = false,
		search_results = [],
		searchTrigger = false,
		searchEmployers = () => {
		},
		createLead = () => {
		},
		history = () => {
		},
		showModal = false,
		employer_loading = false,
	}
) => {

	const [searchCompany, setSearchCompany] = useState(""),
		[employerTrigger, setEmployerTrigger] = useState(false),
		[selectedEmployer, setSelectedEmployer] = useState({}),
		[email, setEmail] = useState(""),
		[open, setOpen] = useState(false),
		{t} = useTranslation();

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if (searchCompany.length > 0) {
				searchEmployers({
					q: searchCompany
				});
			}
		}, 1000);
		return () => clearTimeout(delayDebounceFn);
	}, [searchCompany, searchEmployers]);

	useEffect(() => {
		if (showModal) {
			setOpen(true);
		}
	}, [showModal]);

	if (employer_loading) {
		return <Loading absolute/>
	}

	return (
		<Wrapper
			history={history}
			hideFooter={true}
			textHeader={t("individual.refer_employer.title")}
			t={t}
		>
			<ReferEmployerDesktop
				search_results={search_results}
				employerTrigger={employerTrigger}
				email={email}
				selectedEmployer={selectedEmployer}
				search_loading={search_loading}
				setEmail={setEmail}
				setSelectedEmployer={setSelectedEmployer}
				setEmployerTrigger={setEmployerTrigger}
				setSearchCompany={setSearchCompany}
				renderCompanyNumber={renderCompanyNumber}
				renderCompanyName={renderCompanyName}
				renderIncorporatedInfo={renderIncorporatedInfo}
				renderAddressSnippet={renderAddressSnippet}
				createLead={createLead}
				showModal={showModal}
				open={open}
				setOpen={setOpen}
				t={t}
			/>
			<ReferEmployerMobile
				search_results={search_results}
				search_loading={search_loading}
				searchTrigger={searchTrigger}
				searchCompany={searchCompany}
				employerTrigger={employerTrigger}
				email={email}
				selectedEmployer={selectedEmployer}
				setEmail={setEmail}
				setSelectedEmployer={setSelectedEmployer}
				setEmployerTrigger={setEmployerTrigger}
				setSearchCompany={setSearchCompany}
				renderCompanyNumber={renderCompanyNumber}
				renderCompanyName={renderCompanyName}
				renderIncorporatedInfo={renderIncorporatedInfo}
				renderAddressSnippet={renderAddressSnippet}
				createLead={createLead}
				t={t}
			/>
		</Wrapper>
	);
}

/**
 * @param state
 */
const mapStateToProps = state => state.Employers;

/**
 * @param dispatch
 * @returns {{createLead: (function(*[]=): *), searchEmployers: (function(*): *)}}
 */
const mapDispatchToProps = dispatch => ({
	searchEmployers: (params) => dispatch(searchEmployer(params ?? false)),
	createLead: (data: any[] = false) => dispatch(createLead(data ?? false)),
	returnUser: dispatch(returnUser()),
});


export default connect(mapStateToProps, mapDispatchToProps)(ReferEmployer);
