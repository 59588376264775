import Slide from "@mui/material/Slide";
import DefaultHeader from "../../layout/Header/DefaultHeader";
import MinimalFooter from "../../layout/MinimalFooter";
import Wrapper from "../../layout/Wrapper";
import React, {useState} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import {connect} from "react-redux";
import {createFirstPassword} from "../../actions/Profile";
import Loading from "../../components/Loading";

const FirstPassword = (
	{
		token = "",
		token_loading = false,
		createFirstPassword = () => {},
		individual_id = 0,
	}
) => {

	const [state, setState] = useState({
		password: "",
		confirmPassword: "",
	});

	if (!token) {
		window.location.href = "/login"
	}

	if (token_loading) {
		return <Loading absolute/>
	}

	return (
		<Wrapper>
			<Slide
				direction={"up"}
				in={true}
				mountOnEnter
				unmountOnExit
			>
				<div id={"login-wrapper"}>
					<DefaultHeader/>

					<Grid
						margin={"0 auto"}
						maxWidth={"720px"}
						justifyContent={"center"}
						direction={"column"}
						container
					>
						<Typography
							variant={"h4"}
							compoennt={"h2"}
							align={"center"}
							color={"black"}
						>
							Create your password
						</Typography>

						<Grid
							item
							xl={6}
							lg={6}
							md={6}
							margin={"15px 0"}
						>
							<FormControl
								className={"login-form-control"}
								fullWidth
							>
								<TextField
									type={"password"}
									label={"Password"}
									variant={"outlined"}
									name={"password"}
									id={"password"}
									value={state.password}
									onChange={e => setState({...state, password: e.target.value})}
								/>

								{
									state.password !== "" && state.password.length < 6 &&

									<small
										className={"red-text"}
									>
										Password must be at least 6 characters.
									</small>
								}
							</FormControl>
						</Grid>

						<Grid
							item
							xl={6}
							lg={6}
							md={6}
							margin={"15px 0"}
						>
							<FormControl
								className={"login-form-control"}
								fullWidth
							>
								<TextField
									type={"password"}
									label={"Confirm Password"}
									variant={"outlined"}
									name={"confirmPassword"}
									id={"confirmPassword"}
									value={state.confirmPassword}
									onChange={e => setState({...state, confirmPassword: e.target.value})}
									error={state.confirmPassword !== "" && state.password !== state.confirmPassword}
								/>

								{
									state.confirmPassword !== "" && state.password !== state.confirmPassword &&

									<small
										className={"red-text"}
									>
										Passwords does not match
									</small>
								}
							</FormControl>
						</Grid>

						<Grid
							container
							justifyContent={"center"}
							item
							xl={6}
							lg={6}
							md={6}
							margin={"15px 0"}
						>
							<Button
								variant={"contained"}
								color={"primary"}
								disabled={
									state.password.length < 6 ||
									state.confirmPassword.length < 6 ||
									state.password !== state.confirmPassword
								}
								onClick={() => {
									createFirstPassword({
										password: state.password,
										confirmPassword: state.confirmPassword,
										individual_id: individual_id
									})
								}}
							>
								Create Password
							</Button>
						</Grid>
					</Grid>
					<MinimalFooter/>
				</div>
			</Slide>
		</Wrapper>
	)
};

const mapStateToProps = state => state.Profile;

const mapDispatchToProps = dispatch => ({
	createFirstPassword: (data) => dispatch(createFirstPassword(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(FirstPassword);