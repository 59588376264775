import React, {useState, useEffect} from "react";

// material ui components
import Grid from "@mui/material/Grid";

// custom collegia components.
import Wrapper from "../../layout/Logged/Wrapper";
import RetirementAgeSlider from "../../components/YourPension/AdjustRetirementAge/RetirementAgeSlider";

//redux functions.
import {connect} from "react-redux";
import {returnMonthlyProjections, updateRetirementAge} from "../../actions/Profile";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ExpectedRetirementLifestyleCard from "../../components/YourPension/ExpectedRetirementLifestyleCard";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Modal from "@mui/material/Modal";
import Loading from "../../components/Loading";

/**
 * @param profile_data
 * @param my_projections
 * @param slider_loading
 * @param updateRetirementAge
 * @param history
 * @returns {JSX.Element}
 * @constructor
 */
const AdjustYourRetirementAge = (
	{
		profile_data = [],
		my_projections = [],
		slider_loading = false,
		updateRetirementAge = () => {
		},
		monthly_projections_loading = false,
		returnMonthlyProjections = () => {}
	}
): JSX.Element => {
	const [retirementAge, setRetirementAge] = useState(null);
	const [simulation, setSimulation] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const {t} = useTranslation(),
		history = useNavigate();

	useEffect(() => {
		returnMonthlyProjections({
			profiles: {
				isStatePension: false,
				isExpectedRetirementAge: true,
				isContributionsStatistics: false
			}
		});
	}, [returnMonthlyProjections]);

	useEffect(() => {
		if (profile_data?.expected_retirement_age !== undefined && !retirementAge) {
			setRetirementAge(profile_data?.expected_retirement_age);
			updateRetirementAge({
				profiles: {
					expected_retirement_age: profile_data?.expected_retirement_age,
					simulation: true
				}
			});
		}
	}, [
		profile_data?.expected_retirement_age,
		retirementAge,
		updateRetirementAge,
	]);

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if (profile_data?.id) {
				if (retirementAge !== profile_data?.expected_retirement_age && simulation) {
					updateRetirementAge({
						profiles: {
							expected_retirement_age: retirementAge,
							simulation: true
						},
					});
					setSimulation(false);
				}
			}
		}, 1000);
		return () => clearTimeout(delayDebounceFn);
	}, [
		retirementAge,
		profile_data?.expected_retirement_age,
		updateRetirementAge,
		profile_data?.id,
		simulation,
	]);
	if (monthly_projections_loading) {
		return <Loading absolute/>
	}

	return (
		<Wrapper
			history={history}
			hideFooter={true}
			t={t}
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				justifyContent={"center"}
				id={"retirement-age-style"}
			>
				<Grid
					xl={12}
					lg={12}
					sm={12}
					md={12}
					xs={12}
					item
					textAlign={'center'}
					className={'first-section-grid'}
				>
					<Typography
						className={'upper-grid-title'}
					>
						{t("individual.adjust_retirement_age.when_do_you_intend_question")}
					</Typography>
					<Typography
						className={'upper-grid-subtitle'}
					>
						{t("individual.adjust_retirement_age.advice")}
					</Typography>
				</Grid>
				<Grid
					xl={9.3}
					lg={9.3}
					md={10}
					sm={11.5}
					xs={11.5}
					item
					container
					direction={"row"}
					className={"second-section-grid"}
					justifyContent={"space-between"}
				>
					<Grid
						xl={7.05}
						lg={7.05}
						md={7.05}
						sm={12}
						xs={12}
						item
						container
						direction={"column"}
						justifyContent={"flex-start"}
						className={"retirement-age-grid"}
					>
						<Grid>
							<Typography
								align={"center"}
								className={"retirement-age-grid-title"}
							>
								{t("individual.adjust_retirement_age.expected_retirement_age")}
							</Typography>
							<Typography
								align={"center"}
								className={"retirement-age-grid-value"}
							>
								{retirementAge}
							</Typography>
						</Grid>
						<Grid>
							<RetirementAgeSlider
								profile_data={profile_data}
								retirementAge={retirementAge}
								setRetirementAge={setRetirementAge}
								setSimulation={setSimulation}
							/>
						</Grid>
						{
							((retirementAge !== profile_data?.expected_retirement_age &&
								undefined !== profile_data?.expected_retirement_age) && my_projections?.save === false) && (

								<Grid
									marginBottom={5}
									justifyContent={'space-between'}
									item
									container
								>
									<Button
										className={"modal-button save"}
										onClick={() => setOpenModal(true)}
									>
										Save
									</Button>
									<Button
										className={"modal-button discard"}
										onClick={() => {
											setRetirementAge(profile_data?.expected_retirement_age);
											setOpenModal(false);
										}}
									>
										Discard
									</Button>
								</Grid>
							)
						}
						<Typography
							align={"left"}
							variant={"p"}
							display={"block"}
							className={"retirement-age-subtitle"}
						>
							<span className={"note"}>{t("individual.note")}: </span>
							{t("individual.expected_retirement_age.note.text")}
						</Typography>
					</Grid>
					<ExpectedRetirementLifestyleCard
						my_projections={my_projections}
						loading={slider_loading}
						history={history}
						t={t}
						xl={4.8}
						lg={4.8}
						md={4.8}
						sm={12}
						xs={12}
					/>
					<Modal
						open={openModal}
						onClose={() => {
							setRetirementAge(profile_data?.expected_retirement_age);
							setOpenModal(false);
							updateRetirementAge({
								profiles: {
									expected_retirement_age: profile_data?.expected_retirement_age,
									simulation: true
								}
							});
						}}
					>
						<Grid
							container
						>
							<div
								className={"generic-modal-style"}
							>
								<Grid
									container
									justifyContent={"flex-start"}
								>
									<Grid
										item
									>
										<h2
											className={"generic-modal-title"}
										>
											Change investment option?
										</h2>
									</Grid>
								</Grid>
								<Grid
									sm={12}
									lg={12}
									md={12}
									xl={12}
									xs={12}
									justifyContent={"flex-start"}
									container
									item
								>
									<Grid
										item
										className={"generic-modal-body"}
									>
										<Typography
											component={"div"}
											variant={"subtitle1"}
										>
											Do you want to change your invested fund to match your expected retirement date?
										</Typography>
									</Grid>
								</Grid>
								<Grid
									sm={12}
									lg={12}
									md={12}
									xl={12}
									xs={12}
									justifyContent={"space-between"}
									container
									item
								>
									<Button
										variant={"contained"}
										color={"primary"}
										style={{
											padding: "15px 60px",
											fontWeight: "500",
											border: "none",
										}}
										onClick={() => {
											setOpenModal(false);
											updateRetirementAge({
												profiles: {
													expected_retirement_age: retirementAge,
												}
											});
										}}
									>
										Save
									</Button>
									
									<Button
										variant={"outlined"}
										style={{
											padding: "15px 60px",
											fontWeight: "500",
											border: "none",
										}}
										onClick={() => {
											setRetirementAge(profile_data?.expected_retirement_age);
											setOpenModal(false);
											updateRetirementAge({
												profiles: {
													expected_retirement_age: profile_data?.expected_retirement_age,
													simulation: true
												}
											});
										}}
									>
										Discard
									</Button>
								</Grid>
							</div>
						</Grid>
					</Modal>
				</Grid>
			</Grid>
		</Wrapper>
	);
}

const mapStateToProps = state => {
	const {
		Profile = [],
		Languages = {},
	} = state;

	return {
		...Profile,
		...Languages,
	};
};

const mapDispatchToProps = dispatch => ({
	updateRetirementAge: (data = false) => dispatch(updateRetirementAge(data)),
	returnMonthlyProjections: (data = false) => dispatch(returnMonthlyProjections(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdjustYourRetirementAge);
