import React from "react";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const YourPensionIsSafe = (): JSX.Element => (
	<div id="Section-3" className="section-5 wf-section">
		<div className="second-cta">
			<h1 className="heading-3">Your pension is safe <br />with Collegia</h1>
			<p className="paragraph-3">We are FCA regulated, invest only with one of the world’s best investment
				managers and 100% of your pension is covered by the<strong> Financial Services Compensation
					Scheme.</strong></p>
		</div>
	</div>
);

export default YourPensionIsSafe;