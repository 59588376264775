import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

/**
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const TextHolder = (
	{
		t = key => key,
	}
): JSX.Element => (
	<Grid
		xs={12}
		md={12}
		lg={12}
		sm={12}
		container
		item
		direction={"column"}
		alignItems={"center"}
		alignContent={"center"}
	>
		<Grid
			className={"text-holder"}
		>
			<Typography
				className={"title"}
				align={"center"}
				paragraph
			>
				{t("individual.other_pensions.subtitle")}
			</Typography>
			<Typography
				className={"info"}
				align={"center"}
				paragraph
			>
				{t("individual.other_pensions.information")}
			</Typography>
		</Grid>
	</Grid>
);

export default TextHolder;
