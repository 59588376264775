import React from "react";

import PropTypes from 'prop-types';
import {NumericFormat as NumberFormat} from 'react-number-format';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import {formatMoney, onChange} from "../../utils/Helpers";


/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function NumberFormatCustom(props): JSX.Element {
	const {inputRef, onChange, ...other} = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				if (typeof values.value === "function") {
					values.value = values.value.replace(/^0+/, '')
				}
				onChange({
					target: {
						name: props.name,
						value: values.floatValue,
					},
				});
			}}
			thousandSeparator={","}
			decimalSeparator={"."}
			prefix="£ "
			allowNegative={false}
			allowLeadingZeros={false}
			isNumericString
		/>
	);
}

NumberFormatCustom.propTypes = {
	inputRef: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

/**
 * @param event
 * @param props
 * @param value
 * @returns {Promise<void>}
 * @private
 */
const _onChange = async (event: any[] = {
	target: {
		value: ""
	},
}, props: any = {
	component: {
		setState: () => {
		},
	}
}, value: number = 0): Promise<void> => {
	if (parseFloat(event.target.value) > _retrieveMaximumAmount(props.step ?? "Monthly")) {
		event.target.value = _retrieveMaximumAmount(props.step ?? "Monthly");
	}

	if (event && props.component) {
		onChange(event, props.component, true);

		props.calculateGovTopUp(event.target.value ?? 0);
	}
}

/**
 * @param value
 * @param props
 * @param step
 * @returns {boolean}
 * @private
 */
const _doSimulation = (value: number = 0, props: any[] = {
	simulation: (value: number = 0, step: string = "") => {
	},
	step: "",
	component: {
		setState: () => {
		},
	}
}, step: string = "One-off"): boolean => {
	if (!props) return false;

	if (value > _retrieveMaximumAmount(props.step ?? "Monthly")) {
		const event = {
			target: {
				value: _retrieveMaximumAmount(props.step ?? "Monthly")
			}
		};
		onChange(event, props.component, true);
	}

	if (value !== 0 && value !== "" && parseInt(value) > 9) {
		props.simulation({
			projections: {
				value,
				step,
			}
		});
	}
}

/**
 * @param firstContribution
 * @param govTopUpValue
 * @param props
 * @returns {JSX.Element|*[]}
 * @private
 */
const _renderTopUpButtonModal = (
	firstContribution: boolean = false,
	govTopUpValue: number = 0,
	props: any[] = false,
): JSX.Element | *[] => {
	if (firstContribution) {
		return (
			<Grid
				xs={12}
				md={12}
				lg={12}
				sm={12}
				id={"gov-top-up-container"}
				container
				item
			>
				<Button
					variant={"contained"}
					fullWidth
					onClick={() => props.toggleModal(props.modalOpen)}
				>
					<Grid
						xs={12}
						md={12}
						lg={12}
						sm={12}
						container
						item
					>
						<Grid
							xs={6}
							md={6}
							lg={6}
							sm={6}
							container
							item
							alignContent={"flex-start"}
							direction={"column"}
						>
							{props.t("individual.government_top_up")}
						</Grid>
						<Grid
							xs={6}
							md={6}
							lg={6}
							sm={6}
							className={"gov-top-up-value"}
							alignContent={"flex-end"}
							direction={"column"}
							container
							item
						>
							+ {formatMoney(govTopUpValue ?? 0, 2, ".", ",")}
						</Grid>
					</Grid>
				</Button>
			</Grid>
		);
	}

	return [];
}

/**
 * @param step
 * @returns {number}
 * @private
 */
const _retrieveMaximumAmount = (step: string = "Monthly"): number => {
	if (step === "Monthly") {
		return 2600;
	}

	return 30000;
}

/**
 * @param props
 * @returns {JSX.Element|*[]}
 * @private
 */
const _renderIsOther = (props): JSX.Element | *[] => {
	if (props?.component?.state?.is_other) {
		return (
			<Grid
				className={"source-of-funds-other-text-holder"}
				xs={12}
				sm={12}
				lg={12}
				md={12}
				item
				container
			>
				<TextField
					name={"other"}
					id={"other"}
					variant={"outlined"}
					placeholder={props.t("individual.extra_contributions.new.please_inform_source_of_fund")}
					defaultValue={props?.component?.state?.other ?? ""}
					onChange={(event) => onChange(event,props?.component)}
					fullWidth
				/>
			</Grid>
		);
	}

	return [];
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const InsertTheMoneyInfo = (props = {
	contributionValue: 0,
	step: "Monthly",
	sourceOfFunds: [],
	source_of_fund_id: null,
	other: "",
	t: key => key,
}): JSX.Element => (
	<Grid
		spacing={0}
		direction={"column"}
		alignItems={"center"}
		alignContent={"center"}
		container
		item
		className={"intermediary-container"}
		id={"input-money-container"}
	>
		<Grid
			xs={12}
			sm={12}
			md={12}
			lg={12}
			item
		>
			<Typography
				paragraph
			>
				{props.t("individual.extra_contributions.new.choose_amount_message", {
					step: props.t(`individual.${props?.step?.toLowerCase().replace("-","_")}`),
				})}
			</Typography>
		</Grid>
		<Grid
			xs={12}
			sm={12}
			md={12}
			lg={12}
			item
			id={"money-input-text-field"}
		>
			<TextField
				id={"contributionValue"}
				name={"contributionValue"}
				variant="outlined"
				placeholder={props.t("individual.extra_contributions.new.min_of_10_pounds", {
					value: "10,00"
				})}
				label={props.t("individual.extra_contributions.new.min_of_10_pounds", {
					value: "10,00"
				})}
				value={props.contributionValue > 0 ? props.contributionValue : null}
				InputProps={{
					inputComponent: NumberFormatCustom,
				}}
				onChange={event => _onChange(event ?? false, props ?? false, props.contributionValue ?? 0)}
				onKeyUp={() => _doSimulation(props.contributionValue ?? 0, props ?? false, props.step ?? "One-off")}
				error={props.contributionValue < 10 && props.contributionValue !== 0}
			/>
			<small
				dangerouslySetInnerHTML={{
					__html: props.t("individual.extra_contributions.new.max_value_contribution_label", {
						value: formatMoney(
							_retrieveMaximumAmount(props.step ?? "Monthly"), 2, ".", ","
						),
					})
				}}
			/>
		</Grid>

		<Grid
			xs={12}
			sm={12}
			md={12}
			lg={12}
			className={"select-range-holder"}
			item
		>
			<select
				name={"source_of_fund_id"}
				id={"source_of_fund_id"}
				onChange={(event) => {
					if (parseInt(event.target.value) === 7) {
						props?.component?.setState({
							is_other: true,
						});
					} else {
						props?.component?.setState({
							is_other: false,
						});
					}

					onChange(event, props?.component);
				}}
				value={props?.component?.state?.source_of_fund_id ?? ""}
			>
				<option value={""}>
					{props.t("individual.extra_contributions.new.select_source_of_funds_select_default_input")}
				</option>
				{props.sourceOfFunds.map(s => (
					<option
						key={s.id}
						value={s.id}
					>
						{s.name}
					</option>
				))}
			</select>
			<small>
				{props.t("individual.extra_contributions.new.you_need_inform_collegia_message")}
			</small>
			{
				_renderIsOther(props)
			}
		</Grid>
		{_renderTopUpButtonModal(true, props.govTopUpValue ?? 0, props)}
		<Grid
			xs={12}
			sm={12}
			md={12}
			lg={12}
			item
			id={"continue-button-container"}
		>
			<Button
				variant={"outlined"}
				color={"primary"}
				onClick={() => props.proceed()}
				disabled={
					parseFloat(props?.contributionValue) < 10 || !props?.component?.state?.source_of_fund_id || (props?.component?.state?.is_other && props?.component?.state?.other === "")}
			>
				{props.t("individual.login.continue_button")}
			</Button>
		</Grid>
	</Grid>
);

export default InsertTheMoneyInfo;
