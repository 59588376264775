import React from 'react';

import Grid from '@mui/material/Grid';
import DateRangeIcon from '@mui/icons-material/DateRange';

/**
 * @returns {JSX.Element}
 * @constructor
 */
const IconHead = (): JSX.Element => (
	<Grid
		xs={12}
		sm={12}
		md={12}
		lg={12}
		item
		container
		id={"contribution-header-icon-container"}
	>
		<DateRangeIcon/> <span>
            Monthly contribution
        </span>
	</Grid>
);

export default IconHead;