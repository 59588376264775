import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import {connect} from "react-redux";
import {getContributionByDate, getContributionsHistoryYears} from "../actions/Transactions";
import Loading from "../components/Loading";
import ContributionHistoryAccordionColumn from "../components/ContributionsHistory/ContributionHistoryAccordionColumn";
import ContributionHistoryIcon from "../images/ContributionsHistoryIcon.svg";
import React, {useEffect, useState} from "react";
import Wrapper from "../layout/Logged/Wrapper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import calendarIcon from "../images/contributionHistoryCalendarIcon.svg";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import {useTranslation} from "react-i18next";
import SideMenu from "../components/CollegiaAccount/SideMenu";
import {useNavigate, useParams} from "react-router-dom";
import {returnUser} from "../actions/Profile";

/**
 * @param profile_data
 * @param data
 * @param transaction_loading
 * @param years_loading
 * @param years_contributions_history
 * @param getContributionByDate
 * @param history
 * @param getContributionsHistoryYears
 * @returns {JSX.Element}
 * @constructor
 */
const ContributionsHistory = (
	{
		profile_data = [],
		data = [],
		transaction_loading = true,
		years_loading = false,
		years_contributions_history = [],
		getContributionByDate = () => {
		},
		getContributionsHistoryYears = () => {
		},
		returnUser = () => {
		},
	}
): JSX.Element => {
	const [selectedYear, setSelectedYear] = useState(""),
		{t} = useTranslation(),
		history = useNavigate();

	const employerCode = useParams().code;

	useEffect(() => {
		returnUser();
	}, [returnUser]);

	useEffect(() => {
		getContributionsHistoryYears({employer_code: employerCode ?? null});
	}, [getContributionsHistoryYears, employerCode]);

	useEffect(() => {
		if (!selectedYear && years_contributions_history?.length > 0) {
			setSelectedYear(years_contributions_history[0])
		}
	}, [
		selectedYear,
		years_contributions_history
	]);

	useEffect(() => {
		if (selectedYear) {
			getContributionByDate({
				year: selectedYear,
				code: employerCode ?? null
			});
		}
	}, [selectedYear, getContributionByDate, employerCode]);

	if (!profile_data || transaction_loading || years_loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<Wrapper
			history={history}
			profile_data={profile_data}
			hideFooter={true}
			t={t}
		>
			<Slide in={true} direction={"up"}>
				<Grid
					xs={12}
					lg={12}
					md={12}
					sm={12}
					direction={"row"}
					container
					item
				>
					<SideMenu
						history={history}
						t={t}
						contributionsHistory={""}
					/>
					<Grid
						xl={9.5}
						lg={9.5}
						md={12}
						sm={12}
						xs={12}
						direction={"column"}
						id={"contribution-history-style"}
						container
						item
						alignItems={"center"}
						alignContent={"center"}
					>
						<Paper
							id={"contribution-history-paper-holder"}
						>
							<Grid
								xs={12}
								sm={12}
								lg={12}
								md={12}
								item
								container
							>
								{
									data.length === 0 &&
									<Typography
										fontSize={25}
										textAlign={"center"}
									>
										{t("individual.contributions_history.you_dont_have_contributions_yet")}
									</Typography>
								}
								{
									data.length !== 0 &&
									<Grid
										direction={"row"}
										container
										item
										marginBottom={4}
									>
										<Grid
											xs={6}
											md={6}
											lg={6}
											sm={6}
											item
											container
											alignItems={"center"}
										>
											<Typography className={"typography-calendar-style"}>
												{t("individual.contributions_history.contributions_in", {
													year: selectedYear
												})}
											</Typography>
										</Grid>
										<Grid
											xs={6}
											md={6}
											lg={6}
											sm={6}
											container
											item
											justifyContent={"flex-end"}
										>
											<Grid
												item
												container
												alignItems={"center"}
												direction={"row"}
												className={"select-date-background-color"}
											>
												<Grid
													item
													xs={3}
													sm={3}
												>
													<img
														className={"icon-margin-top"}
														width={"20px"}
														height={"20px"}
														src={calendarIcon}
														alt={""}
													/>
												</Grid>

												<Grid
													xs={3}
													sm={3}
													item
												>
													<TextField
														className={"select-style"}
														value={selectedYear}
														select
														onChange={(event) =>
															setSelectedYear(event.target.value)
														}
													>
														{
															years_contributions_history.map((year, index) => {
																return (
																	<MenuItem
																		value={year}
																		key={index}
																	>
																		{year}
																	</MenuItem>
																)
															})
														}
													</TextField>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								}
								<ContributionHistoryAccordionColumn
									data={Object.entries(data).map(([_, d]) => d) ?? []}
									transaction_loading={transaction_loading}
									contribution_icon={ContributionHistoryIcon}
									t={t}
								/>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			</Slide>
		</Wrapper>
	);
}


const mapStateToProps = state => {
	const {
		Transactions = [],
		Profile,
	} = state;

	return {
		...Transactions,
		...Profile,
	};
};

const mapDispatchToProps = dispatch => ({
	getContributionByDate: (data = {}) => dispatch(getContributionByDate(data)),
	getContributionsHistoryYears: (data = {}) => dispatch(getContributionsHistoryYears(data)),
	returnUser: () => dispatch(returnUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContributionsHistory);