import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import your_lifestyle from "../../images/investment-approaches/your-lifestyle.svg";
import {capitalizeFirstLetter} from "../../utils/Helpers";

/**
 * @param icon
 * @param name
 * @param yearly_charge
 * @param active
 * @param link
 * @param history
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const InvestmentApproach = (
	{
		icon = "",
		name = "",
		yearly_charge = 0,
		active = false,
		link = "",
		history = {
			push: () => {
			}
		},
		t = key => key,
	}
): JSX.Element => {

	let sub1 = "";
	let sub2 = ""
	let sub3 = "";

	switch (name) {
		case "Sustainable":
			sub1 = "SDG allocation";
			sub2 = "Exclude controversial industries";
			sub3 = "Rigorous ESG research";
			break;
		case "Standard":
			sub1 = "Rigorous ESG research";
			sub2 = "Match your expected retirement date";
			sub3 = "Appropriate investments";
			break;
		case "Islamic Finance":
			sub1 = "Shariah-compliant investments";
			sub2 = "Shariah committee oversight";
			sub3 = "Ethical investments";
			break;
		case "High Growth":
			sub1 = "SDG allocation";
			sub2 = "Be risk loving";
			sub3 = "Exclude controversial industries";
			break;
		default:
			 sub1 = "SDG allocation";
			 sub2 = "Be risk averse";
			 sub3 = "Exclude controversial industries";
			break;
	}

	return (
		<Grid
			className={"investment-approach-card"}
			container
			item
			xs={12}
			onClick={() => history(link)}
		>
			<Grid
				className={"investment-approach-icon"}
				container
				justifyContent={"space-between"}
			>
				<img
					src={icon}
					alt={name}
				/>

				{
					active &&
					<img
						src={your_lifestyle}
						alt={"Your lifestyle"}
					/>
				}
			</Grid>

			<Grid
				className={"investment-approach-name"}
				container
				direction={"column"}
				justifyContent={"flex-start"}
			>
				<Typography
					className={"ff-large"}
					align={"left"}
					component={"h6"}
				>
					{name}
				</Typography>

				<Typography
					className={"ff-medium"}
					align={"left"}
					component={"span"}
				>
					{capitalizeFirstLetter(t("individual.yearly_charge"))}: <strong>{yearly_charge}%</strong>
				</Typography>
			</Grid>

			<Grid>
				<ul
					className={"ff-medium"}
					style={{ textAlign: "left" }}
				>
					<li>
						{sub1}
					</li>

					<li>
						{sub2}
					</li>

					<li>
						{sub3}
					</li>
				</ul>
			</Grid>
		</Grid>
	);
}


export default InvestmentApproach;