import React from "react";
import Grid from "@mui/material/Grid";
import YourContributionImage from "../../images/YourContributionHistoryIcon.svg";
import UkTopUpContributionImage from "../../images/UkTopUpContributionHistory.svg";
import ContributionsExpandedList from "./ContributionsExpandedList";
import Divider from "@mui/material/Divider";

/**
 * @param transactions
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const ContributionsHistoryAccordion = (
	{
		transactions = null,
		t = key => key,
	}
): JSX.Element => {
	return (
		<Grid
			item
			container
			xs={12}
			sm={12}
			lg={12}
			md={12}
			minWidth={350}
			marginTop={2}
		>
			<Grid
				item
				container
				xs={12}
				sm={12}
				lg={12}
				md={12}
				className={"style-grid-modal"}
				minWidth={300}
			>
				{
					transactions?.map((d, index = {
						formatted_government_top_up_contribution: "",
						formatted_individual_contribution: 0,
						fee: {},
						custom_transaction_code: 0,
						date_of_payment: "",
						is_pot_transfer: false,
						is_redemption: false,
					}) => (
						<Grid
							justifyContent={"space-between"}
							item
							container
							xs={12}
							sm={12}
							lg={12}
							md={12}
							key={index}
						>
							{
								(d?.is_pot_transfer || d?.is_redemption || d?.is_refund || d?.is_flat_fee) ?
									<ContributionsExpandedList
										contributions={d?.total_contribution}
										employer_contribution={d?.formatted_employer_contribution}
										contributions_collegia_fee={d?.individual_fee_value}
										formatted_amount={d?.formatted_amount}
										date={d?.date_of_payment ?? null}
										one_off_date={d?.date_of_payment}
										contributions_government={d?.formatted_government_top_up_contribution}
										government_collegia_fee={d?.government_top_up_fee_value}
										employer_fee_value={d?.employer_fee_value}
										individual_fee_value={d?.individual_fee_value}
										transaction_status={d?.government_status}
										government_contributions_image={UkTopUpContributionImage}
										contributions_image={YourContributionImage}
										employer_title={d?.employer_title}
										pay_period_start_date={d?.payroll_start_date}
										pay_period_end_date={d?.payroll_end_date}
										transaction={d}
										t={t}
									/>
									:
									<ContributionsExpandedList
										contributions={d?.formatted_individual_contribution}
										employer_contribution={d?.formatted_employer_contribution}
										contributions_collegia_fee={d?.individual_fee_value}
										formatted_amount={d?.fee?.formatted_amount}
										date={d?.date_of_payment ?? null}
										one_off_date={d?.date_of_payment}
										contributions_government={d?.formatted_government_top_up_contribution}
										government_collegia_fee={d?.government_top_up_fee_value}
										employer_fee_value={d?.employer_fee_value}
										individual_fee_value={d?.individual_fee_value}
										transaction_status={d?.government_status}
										government_contributions_image={UkTopUpContributionImage}
										contributions_image={YourContributionImage}
										employer_title={d?.employer_title}
										pay_period_start_date={d?.payroll_start_date}
										pay_period_end_date={d?.payroll_end_date}
										transaction={d}
										t={t}
									/>
							}
							{
								index === transactions?.length - 1 ?
									""
									:
									<Divider
										className={"divider-style"}
									/>
							}
						</Grid>
					))
				}
			</Grid>
		</Grid>
	);
};

export default ContributionsHistoryAccordion;