import React, {useEffect, useState} from "react";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Dropzone from "react-dropzone";
import {connect, useDispatch} from "react-redux";
import Typography from "@mui/material/Typography";
import {warn} from "../../actions/SnackBar";
import UploadDNDDrawing from "../../images/UploadDNDDrawing.svg";
import ProcessingFileDdi from "../../images/ProcessingFileDdi.svg";
import PdfIcon from "../../images/PdfIcon.svg";
import Delete from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import {returnUser, uploadDdiFile} from "../../actions/Profile";
import Loading from "../Loading";

const renderCloseButton = (props) => {
	return (
		<Grid
			container
			justifyContent={"flex-end"}
		>
			<Button
				color={"primary"}
				onClick={ () => props.handleClose() }
			>
				X
			</Button>
		</Grid>
	)
};

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const UploadDdiFile = (props = {
	history: () => {
	},
	handleClose: () => {
	},
	open: false,
	source_of_fund_id: null,
	contributionValue: 0,
	selectedDay: "",
	returnUser: () => {
	},
}): JSX.Element => {

	const {
		profile_data,
		profile_loading,
		returnUser,
		loading
	} = props;

	useEffect(() => {
		if (!profile_data && !profile_loading){
			returnUser();
		}
	}, [
		returnUser,
		profile_loading,
		profile_data,
	]);

	const initialState = {alt: "", srcPdf: null};
	const [{alt, srcPdf}, setPreview] = useState(initialState);

	let dispatch = useDispatch();

	const handleDrop = event => {
		const files = event;
		let file_size = files[0].size;
		if (file_size > 3145728) {
			dispatch(warn("The file must be less than 3 Megabytes"));
		} else {
			if (files.length > 0) {
				setPreview(
					files.length ?
						{
							srcPdf: files[0],
							alt: files[0].name
						}
						:
						initialState
				);
			}
		}
	};

	if(!profile_data) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<Modal
			open={props.open ?? false}
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			className={"upload-ddi-history-modal"}
			closeAfterTransition
			onBackdropClick={ () => props.handleClose() }
		>
			{
				profile_data?.ddi_pdf_link ?
					<Grid
						xs={12}
						sm={12}
						lg={12}
						md={12}
						alignItems={"center"}
						alignContent={"center"}
						direction={"row"}
						item
						container
						className={"grid-modal-with-borders"}
						padding={4}
					>
						{ renderCloseButton(props) }
						<Grid
							container
							justifyContent={"center"}
						>
							<Typography
								variant={"body1"}
							>
								We are processing your ddi file, please come back later
							</Typography>
						</Grid>
						<Grid
							container
						>
							<img
								alt=""
								src={ProcessingFileDdi}
								className={"upload-bank-ddi-file-img-drawing"}
							/>
						</Grid>
						<Grid
							container
							justifyContent={"flex-end"}
							marginTop={2}
						>
							<Button
								variant={"contained"}
								color={"primary"}
								onClick={ () => window.location = "/" }
							>
								Go back to the main page
							</Button>
						</Grid>
					</Grid>
					:
					<Grid
						xs={12}
						sm={12}
						lg={12}
						md={12}
						alignItems={"center"}
						alignContent={"center"}
						direction={"row"}
						item
						container
						className={"grid-modal-with-borders"}
						paddingLeft={3}
						paddingRight={3}
						paddingBottom={3}
					>
						{ renderCloseButton(props) }
						<Grid
							container
						>
							<Dropzone
								accept={{
									'text/pdf': ['.pdf'],
								}}
								onDrop={e => handleDrop(e)}
							>
								{( {getRootProps, getInputProps} ) => (
									<Grid
										container
										justifyContent={"center"}
										className={!srcPdf ? "drag-and-drop-bank-ddi-file" : ""}
										{ ...getRootProps() }
									>
										<Grid
											container
											justifyContent={"center"}
										>
											<Typography
												variant={"body1"}
											>
												{srcPdf ? "" : "Upload your completed DDI file here"}
											</Typography>
										</Grid>

										<input
											{...getInputProps()}
											onChange={ e => handleDrop(e.target.files) }
											disabled={srcPdf ? "disabled" : ""}
										/>

										<img
											alt=""
											src={!srcPdf ? UploadDNDDrawing : PdfIcon}
											className={
												!srcPdf ?
													"upload-bank-ddi-file-img-drawing"
													:
													""
											}
										/>
										<Grid
											container
											justifyContent={"center"}
										>
											{
												!srcPdf ?
													<Typography
														variant={"body1"}
														className={"company-info-drag-and-drop"}
													>
														Drag and drop your file here
													</Typography>
													:
													<Typography
														variant={"body1"}
													>
														{alt}
													</Typography>
											}
											{
												srcPdf ?
													<Delete
														className={"upload-bank-ddi-deleted"}
														onClick={() =>
															setPreview({
																srcPdf: null,
																alt: ""
															})
														}
													/>
													:
													""
											}
										</Grid>
									</Grid>
								)}
							</Dropzone>
						</Grid>

						{
							!srcPdf ?
								<Grid
									xs={12}
									item
									textAlign={"center"}
									marginTop={3}
								>
									<a
										href={process.env.REACT_APP_API + "download-ddi-file"}
										target={"_blank"}
										rel="noreferrer"
									>
										Click here to download the DDI PDF file
									</a>
								</Grid>
								:
								<Grid
									xs={12}
									item
									textAlign={"center"}
									marginTop={3}
								>
									<Button
										variant={"contained"}
										color={"primary"}
										onClick={() => dispatch(uploadDdiFile(
											{
												pdf: srcPdf,
												contributionData: {
													contributionValue: props.contributionValue,
													selectedDay: props.selectedDay,
													source_of_fund_id: props.source_of_fund_id,
												}
											}
										))}
										disabled={loading}
									>
										Upload your file
									</Button>
								</Grid>
						}
					</Grid>
			}
		</Modal>
	);
};

const mapStateToProps = state => {
	const {
		Profile,
	} = state;

	return {
		...Profile,
	};
};

const mapDispatchToProps = dispatch => ({
	returnUser: () => dispatch(returnUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadDdiFile);