import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import GmoIcon from "../../images/investment-approaches/gmo-icon.svg";
import NuclearIcon from "../../images/investment-approaches/nuclear-icon.svg";
import AlcoholIcon from "../../images/investment-approaches/alcohol-icon.svg";
import FossilIcon from "../../images/investment-approaches/fossil-icon.svg";
import GamblingIcon from "../../images/investment-approaches/gambling-icon.svg";
import PrivateIcon from "../../images/investment-approaches/private-icon.svg";
import TobaccoIcon from "../../images/investment-approaches/tobacco-icon.svg";
import GunIcon from "../../images/investment-approaches/gun-icon.svg";
import AdultIcon from "../../images/investment-approaches/adult-icon.svg";
import CoalIcon from "../../images/investment-approaches/coal-icon.svg";
import GoalsIcon from "../../images/investment-approaches/sustainable_development_goals.svg";
import LowIcon from "../../images/investment-approaches/low-growth-icon.svg";
import React from "react";
import StickerIcon from "../../images/investment-approaches/sticker-strategy.svg";

/**
 * @param currentStrategy
 * @param name
 * @param updateInvestmentApproach
 * @param investment
 * @param hasIfa
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const LowGrowthDetails = (
	{
		currentStrategy = "",
		name = "",
		updateInvestmentApproach = () => {
		},
		investment = "",
		hasIfa = false,
		t = key => key,
	}
): JSX.Element => (
	<Grid
		container
		item
		justifyContent={"center"}
		id={"low-growth-details"}
	>
		<Grid
			id={'upper-component'}
			xl={8}
			lg={9}
			md={10}
			sm={11}
			xs={11}
			item
		>
			<Grid>
				<img
					src={LowIcon}
					alt={"LowIcon"}
					className={"icon"}
				/>
				{
					currentStrategy === name && (
						<img
							src={StickerIcon}
							alt={"StickerIcon"}
							className={"sticker"}
						/>
					)
				}
			</Grid>
			<Grid>
				<Typography
					className={"title"}
				>
					{t("individual.investment_approaches.details.seeks_to", {
						strategy: name,
					})}
				</Typography>
			</Grid>
			<Grid
				xl={7}
				lg={9}
				md={10}
				sm={10}
				xs={10}
				item
			>
				<Typography
					className={"sub-title"}
				>
					{t("individual.investment_approaches.details.investment_allocation.high_growth.subtitle")}
					<br/>
					<br/>
					{t("individual.investment_approaches.details.investment_allocation.high_growth.subtitle_2")}
				</Typography>
			</Grid>
			{
				(currentStrategy !== name && hasIfa === false) && (
					<Grid
						item
						xl={3.5}
						lg={3.5}
						md={5}
						sm={6}
						xs={12}
						textAlign={"center"}
						onClick={() => updateInvestmentApproach({
							individuals: {
								investment_approach_id: investment.id,
							}
						})}
					>
						<Typography
							className={"button"}
						>
							{t("individual.investment_approaches.details.select_this_strategy")}
						</Typography>
					</Grid>
				)
			}
		</Grid>
		<Grid
			id={'body'}
			xl={8}
			lg={9}
			md={10}
			sm={11}
			xs={11}
			item
			container
			justifyContent={"center"}
		>
			<Grid
				xl={10}
				lg={10}
				md={10}
				sm={11}
				xs={11}
				item
			>
				<Grid>
					<Typography
						className={"title"}
					>
						{t("individual.investment_approaches.details.sustainable.how_we_do_it")}
					</Typography>
				</Grid>
				<Grid
					xl={10}
					lg={10}
					md={12}
					sm={12}
					xs={12}
					item
				>
					<Typography
						className={"sub-title"}
					>
						{t("individual.investment_approaches.details.sustainable.how.subtitle")}
					</Typography>
				</Grid>
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
					container
					spacing={8}
				>
					<Grid
						xl={6}
						lg={6}
						md={6}
						sm={12}
						xs={12}
						item
					>
						<img
							src={GmoIcon}
							alt={"GMO Icon"}
						/>
						<Typography
							className={"icons"}
							textAlign={"left"}
						>
							{t("individual.GMO")}
						</Typography>
						<Typography
							className={"details"}
							textAlign={"left"}
						>
							{t("individual.GMO_description")}
						</Typography>
					</Grid>
					<Grid
						xl={6}
						lg={6}
						md={6}
						sm={12}
						xs={12}
						item
					>
						<img
							src={NuclearIcon}
							alt={"NuclearIcon"}
						/>
						<Typography
							className={"icons"}
							textAlign={"left"}
						>
							{t("individual.nuclear_power")}
						</Typography>
						<Typography
							className={"details"}
							textAlign={"left"}
						>
							{t("individual.nuclear_power_description")}
						</Typography>
					</Grid>
					<Grid
						xl={6}
						lg={6}
						md={6}
						sm={12}
						xs={12}
						item
					>
						<img
							src={AlcoholIcon}
							alt={"AlcoholIcon"}
						/>
						<Typography
							className={"icons"}
							textAlign={"left"}
						>
							{t("individual.alcohol")}
						</Typography>
						<Typography
							className={"details"}
							textAlign={"left"}
						>
							{t("individual.alcohol_description")}
						</Typography>
					</Grid>
					<Grid
						xl={6}
						lg={6}
						md={6}
						sm={12}
						xs={12}
						item
					>
						<img
							src={FossilIcon}
							alt={"FossilFuels"}
						/>
						<Typography
							className={"icons"}
							textAlign={"left"}
						>
							{t("individual.fossil_fuels")}
						</Typography>
						<Typography
							className={"details"}
							textAlign={"left"}
						>
							{t("individual.fossil_fuels_description")}
						</Typography>
					</Grid>
					<Grid
						xl={6}
						lg={6}
						md={6}
						sm={12}
						xs={12}
						item
					>
						<img
							src={GamblingIcon}
							alt={"GamblingIcon"}
						/>
						<Typography
							className={"icons"}
							textAlign={"left"}
						>
							{t("individual.gambling")}
						</Typography>
						<Typography
							className={"details"}
							textAlign={"left"}
						>
							{t("individual.gambling_description")}
						</Typography>
					</Grid>
					<Grid
						xl={6}
						lg={6}
						md={6}
						sm={12}
						xs={12}
						item
					>
						<img
							src={PrivateIcon}
							alt={"PrivateIcon"}
						/>
						<Typography
							className={"icons"}
							textAlign={"left"}
						>
							{t("individual.private_prisons")}
						</Typography>
						<Typography
							className={"details"}
							textAlign={"left"}
						>
							{t("individual.private_prisons_description")}
						</Typography>
					</Grid>
					<Grid
						xl={6}
						lg={6}
						md={6}
						sm={12}
						xs={12}
						item
					>
						<img
							src={TobaccoIcon}
							alt={"TobaccoIcon"}
						/>
						<Typography
							className={"icons"}
							textAlign={"left"}
						>
							{t("individual.tobacco")}
						</Typography>
						<Typography
							className={"details"}
							textAlign={"left"}
						>
							{t("individual.tobacco_description")}
						</Typography>
					</Grid>
					<Grid
						xl={6}
						lg={6}
						md={6}
						sm={12}
						xs={12}
						item
					>
						<img
							src={GunIcon}
							alt={"GunIcon"}
						/>
						<Typography
							className={"icons"}
							textAlign={"left"}
						>
							{t("individual.weapons")}
						</Typography>
						<Typography
							className={"details"}
							textAlign={"left"}
						>
							{t("individual.weapons_description")}
						</Typography>
					</Grid>
					<Grid
						xl={6}
						lg={6}
						md={6}
						sm={12}
						xs={12}
						item
					>
						<img
							src={AdultIcon}
							alt={"AdultIcon"}
						/>
						<Typography
							className={"icons"}
							textAlign={"left"}
						>
							{t("individual.adult_entertainment")}
						</Typography>
						<Typography
							className={"details"}
							textAlign={"left"}
						>
							{t("individual.adult_entertainment_description")}
						</Typography>
					</Grid>
					<Grid
						xl={6}
						lg={6}
						md={6}
						sm={12}
						xs={12}
						item
					>
						<img
							src={CoalIcon}
							alt={"CoalIcon"}
						/>
						<Typography
							className={"icons"}
							textAlign={"left"}
						>
							{t("individual.coal_thermal_coal")}
						</Typography>
						<Typography
							className={"details"}
							textAlign={"left"}
						>
							{t("individual.coal_thermal_coal_description")}
						</Typography>
					</Grid>
				</Grid>
				<Grid
					xl={11}
					lg={11}
					md={11}
					sm={11}
					xs={11}
					item
				>
					<img
						src={GoalsIcon}
						alt={"UN Goals"}
					/>
					<Typography
						className={"un-goals"}
					>
						{t("individual.investment_approaches.details.sustainable.goal_message")}
					</Typography>
				</Grid>
				<Grid>
					<Typography
						className={"title"}
					>
						{t("individual.investment_approaches.details.investment_allocation.title")}
					</Typography>
				</Grid>
				<Grid
					xl={11}
					lg={11}
					md={11}
					sm={11}
					xs={11}
					item
				>
					<Typography
						className={"sub-title"}
					>
						{t("individual.investment_approaches.details.investment_allocation.sustainable.information")}
					</Typography>
				</Grid>
				{
					(currentStrategy !== name && hasIfa === false) && (
						<Grid
							container
							item
							justifyContent={"center"}
						>
							<Grid
								item
								xl={3.5}
								lg={3.5}
								md={5}
								sm={6}
								xs={12}
								textAlign={"center"}
								className={'button-grid'}
								onClick={() => updateInvestmentApproach({
									individuals: {
										investment_approach_id: investment.id,
									}
								})}
							>
								<Typography
									className={"button"}
								>
									{t("individual.investment_approaches.details.select_this_strategy")}
								</Typography>
							</Grid>
						</Grid>
					)
				}
			</Grid>
		</Grid>
	</Grid>
);

export default LowGrowthDetails;