import mobileImage from "../images/iphone-c.png";
import appStoreButton from "../images/App-Store-Badge-US-Black2x.png";
import googlePlayButton from "../images/Google-Play-Badge-US2x.png";
import React from "react";


const DownloadMobileApp = () => {
	return (
		<div className="body-2 margin-0-auto">
			<div className="section-10">
				<div className="div-block-4">
					<img
						src={mobileImage}
						width="446"
						srcSet={`
							${mobileImage} 500w, 
							${mobileImage} 800w, 
							${mobileImage} 1080w, 
							${mobileImage} 1600w, 
							${mobileImage} 2000w, 
							${mobileImage} 2196w
						`}
						sizes={"(max-width: 479px) 87vw, (max-width: 991px) 330px, 446px"}
						alt="Collegia mobile app"
						className="image-5"
					/>
				</div>
				<div className="div-block-5">
					<h1 className="heading-4">
						<strong
							className="bold-text"
						>
							We detected that you're using an mobile device.
						</strong>
					</h1>
					<p
						className="paragraph-4"
					>
						For better experience with Collegia we ask to you
						to please proceed to download the mobile app to continue.
					</p>
					<div
						className="div-block-6"
					>
						<a
							href={
								"https://apps.apple.com/" +
								"us/app/collegia/id1554864077"
							}
							target={"_blank"}
							rel="noreferrer"
						>
							<img
								src={appStoreButton}
								width="135"
								alt=""
							/>
						</a>

						<a
							href={"https://play.google.com/store/apps/details?id=com.collegia.mobile.application"}
							target={"_blank"}
							rel="noreferrer"
						>
							<img
								src={googlePlayButton}
								width="135"
								alt=""
								className="image-4"
							/>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DownloadMobileApp;
