import React from 'react';
import {connect} from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Wrapper from "../layout/Logged/Wrapper";
import teste1 from "../images/02-bg-6-p-1600.png";

/**
 * @param history
 * @param retrieveByToken
 * @param language
 * @returns {JSX.Element}
 * @constructor
 */
const MandateCreated = (
	{
		history = {
			push: () => {
			}
		},
		language = "GB",
	}
): JSX.Element => {

	return (
		<Wrapper
			hideFooter={true}
			individual_setup={true}
			t={""}
			removeRightMenu={true}
		>
			<Grid
				xs={12}
				container
				item
				direction="column"
				alignContent={'flex-start'}
				paddingTop={9}
				paddingLeft={5}
			>
				<img
					src={teste1}
					alt="teste"
					style={{
						width: '100%',
						height: '100%',
						position: 'absolute',
						top: '0',
						left: '0',
						zIndex: '0',
					}}
				/>
				<Grid
					marginTop={'200px'}
					zIndex={2}
				>
					<Typography
						sx={{
							fontSize: '42px',
							fontWeight: 'bold',
							color: '#FFF',
						}}
					>
						Thanks for setting up <br/>
						your direct debit instruction.
					</Typography>
				</Grid>
				<Grid
					zIndex={2}
				>
					<Typography
						sx={{
							color: '#FFF',
						}}
					>
						You can close this tab now, and continue setting up your account.
					</Typography>
				</Grid>
				<Grid>
					<Button
						sx={{
							backgroundColor: '#275EF3',
							color: '#fff',
							fontSize: '18px',
							fontWeight: 'bold',
							padding: '10px 30px',
							marginTop: '30px',
							borderRadius: '0px',
							'&:hover': {
								backgroundColor: '#275EF3',
								color: '#fff',
							}
						}}
						onClick={() => {
							try {
								window.close()
							} catch {
								alert("Please close this tab manually to complete the setup.");
							}
						}}
					>
						Finish setup
					</Button>
				</Grid>
			</Grid>
		</Wrapper>
	);
};

/**
 * @returns {*}
 */
const mapStateToProps = () => ({})

/**
 * @returns {{retrieveByToken: (function(*=, *=): *)}}
 */
const mapDispatchToProps = ()=> ({});

export default connect(mapStateToProps, mapDispatchToProps)(MandateCreated);