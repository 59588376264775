import FindByCode from "./FindByCode";
import RejectInvitation from "./RejectInvitation";
import AcceptInvitation from "./AcceptInvitation";
import ReferEmployerManually from "./ReferEmployerManually";

const InvitationsRequest = {
	FindByCode,
	RejectInvitation,
	AcceptInvitation,
	ReferEmployerManually,
};

export default InvitationsRequest;