import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param endToEnd
 * @returns {Promise<AxiosResponse<any>>}
 */
const CancelDirectDebitSigningPromise = (endToEnd: string = ""): Promise<AxiosResponse<any>> =>
	Request.post(`/individual/contributions/cancel-direct-debit-signing/${endToEnd}`);

export default CancelDirectDebitSigningPromise;