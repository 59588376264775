import React, {useEffect} from "react";
import Wrapper from "../layout/Logged/Wrapper";
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import {connect} from "react-redux";
import {paginate} from "../actions/UpcomingTransactions";
import Loading from "../components/Loading";
import Pagination from "@mui/material/Pagination";
import {PrimaryHeader} from "../components/Headers/PrimaryHeader";
import Typography from "@mui/material/Typography";
import UpcomingContributionCard from "../components/UpcomingTransactions/UpcomingContributionCard";
import Paper from "@mui/material/Paper";
import ContributionHistoryIcon from "../images/ContributionsHistoryIcon.svg";
import {useTranslation} from "react-i18next";
import {returnUser} from "../actions/Profile";

/**
 * @param last_page
 * @param current_page
 * @returns {JSX.Element|*[]}
 * @private
 */
const _renderPaginationButtons = (
	last_page = 1,
	current_page = 1,
): JSX.Element | *[] => {
	if (last_page > 1) {
		return (
			<Pagination
				page={current_page}
				count={last_page}
				onChange={(event, page) => paginate({
					page
				})}
			/>
		);
	}

	return [];
};

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const UpcomingContribution = (props = {
	history: () => {
	},
}): JSX.Element => {
	const {
			data = [],
			transaction_loading = true,
			last_page = 0,
			current_page = 1,
			profile_data,
			profile_loading,
			paginate,
		} = props,
		{t} = useTranslation();

	useEffect(() => {
		paginate();
	}, [
		profile_loading,
		paginate
	]);

	if (profile_loading || transaction_loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<Wrapper
			history={props?.history}
			profile_data={profile_data}
			hideFooter={true}
			t={t}
		>
			<Slide in={true} direction={"left"}>
				<div>
					<Grid
						spacing={0}
						direction={"column"}
						alignItems={"center"}
						alignContent={"center"}
						id={"contributions-history-wrapper"}
						container
						item
					>
						<PrimaryHeader
							history={props?.history}
							backIcon
							backPage={"/collegia-account"}
						>
							{t("individual.upcoming_contribution.title")}
						</PrimaryHeader>
						<Paper
							id={"paper-holder"}
						>
							{
								data?.length === 0 &&
								<Typography
									fontSize={25}
									textAlign={"center"}
								>
									{t("individual.upcoming_contribution.you_dont_have_yet")}
								</Typography>
							}
							<UpcomingContributionCard
								data={data}
								transaction_loading={transaction_loading}
								contribution_icon={ContributionHistoryIcon}
							/>
							{_renderPaginationButtons(last_page, current_page)}
						</Paper>
					</Grid>
				</div>
			</Slide>
		</Wrapper>
	);
}

const mapStateToProps = state => {
	const {
		UpcomingTransactions = [],
		Profile,
	} = state;

	return {
		...UpcomingTransactions,
		...Profile,
	};
};

/**
 * @param dispatch
 * @returns {{paginate: (function(*=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	returnUser: dispatch(returnUser(false)),
	paginate: (data = {}) => dispatch(paginate(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingContribution);