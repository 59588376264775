import {useEffect} from 'react';

import HomeHeader from "../components/Home/HomeHeader";

import {retrieveByToken} from "../actions/ActivationToken";

import {connect} from 'react-redux';
import Loading from "../components/Loading";

import "../styles/wlecome-page-webflow/webflow.css";
import "../styles/wlecome-page-webflow/collegia-welcome.webflow.css";

import Wrapper from "../layout/Wrapper";
import WhyCollegiaSection from "../components/Setup/WhyCollegiaSection";
import DifferentKindOfPension from "../components/Setup/DifferentKindOfPension";
import CollegiaIsYours from "../components/Setup/CollegiaIsYours";
import YourPensionIsSafe from "../components/Setup/YourPensionIsSafe";
import OnePensionManyBenefits from "../components/Setup/OnePensionManyBenefits";
import SamePensionDifferentJobs from "../components/Setup/SamePensionDifferentJobs";
import LetsStart from "../components/Setup/LetsStart";
import SetupFooter from "../components/Setup/SetupFooter";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";

/**
 * @param retrieveByToken
 * @param token_loading
 * @param token_data
 * @param language
 * @returns {JSX.Element}
 * @constructor
 */
const ActivationPage = (
	{
		retrieveByToken = () => {
		},
		token_loading = false,
		token_data = {
			individual: {
				status: 0
			}
		},
		language = "GB",
	}
): JSX.Element => {
	const token = useParams().token;
	const menu = document.getElementById("menu"),
		{t, i18n} = useTranslation(),
		history = useNavigate();

	useEffect(() => {
		i18n.changeLanguage(language);
	}, [
		language,
		i18n,
	]);

	useEffect(() => {
		retrieveByToken(token, false);

		if (token === "") {
			history("/home");
		}

		const translationDiv = document.getElementById("country-language-selector");

		if (translationDiv) {
			translationDiv.style.display = "none";
			translationDiv.remove();
		}
	}, [retrieveByToken, token, history]);

	if (token_data) {
		const {
			individual
		} = token_data;

		if (individual) {
			if (individual.status === 5) {
				history(`/login`);
			}
			// else if (individual.status === 1) {
			// 	history("/login");
			// }
			if (individual.status === -1) {
				history("/home");
			}
		}
	}

	if (menu) {
		menu.style.display = "block";
	}

	if (token_loading) {
		return (
			<Loading
				absolute
			/>
		);
	}

	if (Object.keys(token_data).length === 0) {
		history("/home");
	}

	return (
		<Wrapper
			individual_setup={true}
			t={t}
		>
			<div className="body">
				<HomeHeader
					token={token}
					{...token_data}
					history={history}
					t={t}
				/>
				<WhyCollegiaSection t={t}/>
				<DifferentKindOfPension t={t}/>
				<CollegiaIsYours t={t}/>
				<YourPensionIsSafe t={t}/>
				<OnePensionManyBenefits t={t}/>
				<SamePensionDifferentJobs t={t}/>
				<LetsStart
					history={history}
					token={token}
					t={t}
				/>
				<SetupFooter t={t}/>
			</div>
		</Wrapper>
	);
};

/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => {
	const {
		ActivationToken
	} = state;

	return {
		...ActivationToken
	};
};

/**
 * @param dispatch
 * @returns {{retrieveByToken: (function(*=, *=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	retrieveByToken: (token = false, modalLoading = true) => dispatch(retrieveByToken(token, modalLoading)),
});


export default connect(mapStateToProps, mapDispatchToProps)(ActivationPage);
