import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param code
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
const AcceptRequest = (code: string = ""): Promise<AxiosResponse<any>> => Request.post(`/individual/connections/accept/${code}`);

export default AcceptRequest;