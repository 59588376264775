import Request from "../../utils/Request";

/**
 * @request
 * @param data
 * @param contributionData
 * @returns {Promise<AxiosResponse<any>>}
 */
const uploadDdiFile = (data: any[] => false, contributionData: string = "") =>
	Request.post(`/individual/upload-ddi-file/${contributionData}`, data);


export default uploadDdiFile