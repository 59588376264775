import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {generateFriendlyString, validateEmail} from "../../utils/Helpers";
import React, {useState} from "react";
import {connect} from "react-redux";
import {referEmployerManually} from "../../actions/Invitations";
import Loading from "../Loading";
import {useTranslation} from "react-i18next";


const ReferEmployerManually = ({
	profile_data = {},
	invitation_loading = false,
	referEmployerManually = () => {},
	refer_employer_manually_success = false,
	setOpen = () => {},
}) => {
	const {t} = useTranslation();
	const [referManuallyData, setReferManuallyData] = useState({
		companyName: "",
		companyEmail: ""
	});

	if (invitation_loading) {
		return (<Loading/>);
	}

	if (refer_employer_manually_success) {
		setOpen(true);
	}

	return (
		<div id={"refer-manually-div"}>
			<TextField
				fullWidth
				className={"auto-complete-style refer-manually-input text-field-style"}
				placeholder={t("individual.company")}
				variant={"outlined"}
				value={referManuallyData.companyName}
				onChange={(event) => setReferManuallyData(
					{...referManuallyData, companyName: event.target.value}
				)}
			/>

			<TextField
				fullWidth
				className={"auto-complete-style refer-manually-input text-field-style"}
				placeholder={t("individual.refer_employer.company_email_input")}
				variant={"outlined"}
				value={referManuallyData.companyEmail}
				onChange={(event) => setReferManuallyData(
					{...referManuallyData, companyEmail: event.target.value}
				)}
			/>

			<Button
				fullWidth
				color={"primary"}
				variant={"contained"}
				disabled={
					!referManuallyData.companyName ||
					!validateEmail(referManuallyData.companyEmail)
				}
				onClick={() =>
					referEmployerManually({
						employeeName: generateFriendlyString(
							profile_data["forename"],
							profile_data["middle_name"],
							profile_data["surname"]
						),
						...referManuallyData
					}
				)}
			>
				{t("individual.refer_employer.title")}
			</Button>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		...state.Profile,
		...state.Invitations,
	}
};

const mapDispatchToProps = dispatch => ({
	referEmployerManually: (data) => dispatch(referEmployerManually(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReferEmployerManually);