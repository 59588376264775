import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {
	checkDuplicateNin,
	isValidNationalInsuranceNumber,
	returnUser,
	update,
	updateProfilePicture
} from "../../actions/Profile";
import Wrapper from "../../layout/Logged/Wrapper";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import {
	capitalizeFirstLetter,
	createAutoCorrectedDatePipe,
	retrieveCollegiaAccessToken,
	validateDate
} from "../../utils/Helpers";
import Loading from "../../components/Loading";
import MaskedInput from "react-text-mask/dist/reactTextMask";
import HeaderInformation from "../../components/Profile/MyAccount/HeaderInformation";
import moment from "moment";
import {useTranslation} from "react-i18next";
import MyAccountLeftMenu from "../../components/MyAccountLeftMenu";
import {useNavigate} from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";

function _disable(mobile, gender, forename, surname, birthdate, nin, profile_data, validate_nin_loading, inputLoading, valid_nin, exist_nin) {
	if (
		(
			profile_data?.mobile === mobile &&
			profile_data?.gender === gender &&
			profile_data?.forename === forename &&
			profile_data?.surname === surname &&
			profile_data?.national_insurance_number === nin &&
			moment(profile_data?.birthdate).format("DD/MM/YYYY") === birthdate
		) ||
		!mobile ||
		!gender ||
		!forename ||
		!surname ||
		!birthdate ||
		!validateDate(birthdate) ||
		validate_nin_loading ||
		inputLoading ||
		exist_nin ||
		valid_nin
	) {
		return "button-2";
	}

	return "button-1";
}

/**
 * @param birthdate
 * @param setBirthdate
 * @returns {JSX.Element}
 * @private
 */
const renderBirthDateTextBox = (
	birthdate = "",
	setBirthdate = () => {
	}
): JSX.Element => (
	<Grid
		className={"input"}
	>
		<MaskedInput
			className={"closure"}
			mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
			placeholder="Date of birth (dd/mm/yyyy format). *"
			value={birthdate ?? new Date()}
			onChange={event => setBirthdate(event.target.value)}
			pipe={
				createAutoCorrectedDatePipe("dd mm yyyy", {
					minYear: 1900,
					maxYear: new Date().getFullYear() - 16
				})}
		/>
	</Grid>
);

/**
 * @param profile_data
 * @param profile_loading
 * @param loading
 * @param update
 * @param history
 * @param updateProfilePicture
 * @param activation_code_loading
 * @returns {JSX.Element}
 * @constructor
 */
const PersonalInformation = (
	{
		profile_data = [],
		profile_loading = false,
		loading = false,
		update = () => {
		},
		updateProfilePicture = () => {
		},
		activation_code_loading = false,
		isValidNationalInsuranceNumber = () => {},
		checkDuplicateNin = () => {},
		valid_nin = false,
		exist_nin = false,
		validate_nin_loading = false,
	}
): JSX.Element => {

	const [mobile, setMobile] = useState(""),
		[gender, setGender] = useState(""),
		[surname, setSurname] = useState(""),
		[forename, setForename] = useState(""),
		[birthdate, setBirthdate] = useState(""),
		[nin, setNin] = useState(""),
		{t} = useTranslation(),
		history = useNavigate(),
		[inputLoading, setInputLoading] = useState(false);

	const initialState = {alt: "", srcPdf: null};
	const [{src, picture}, setPreview] = useState(initialState);
	const hiddenFileInput = React.useRef(null);

	const handleUpload = e => {
		if (e.target.files && e.target.files.length > 0) {
			setPreview({
				src: e.target.files[0],
				picture: URL.createObjectURL(e.target.files[0])
			});
		}
	};

	const handleClick = () => {
		hiddenFileInput.current.click();
	};

	useEffect(() => {
		if (profile_data?.id) {
			setMobile(profile_data?.mobile);
			setGender(profile_data?.gender);
			setSurname(profile_data?.surname);
			setForename(profile_data?.forename);
			setNin(profile_data?.national_insurance_number);
			let formatted_birthdate = profile_data?.birthdate?.toString()?.split("-") ?? "";
			setBirthdate((formatted_birthdate[2] + "/" +
				formatted_birthdate[1] + "/" + formatted_birthdate[0]).toString());
		}
	}, [
		profile_data,
	]);

	useEffect(() => {
		if (nin.length > 0) {
			setInputLoading(true);
			const delayDebounceFn = setTimeout(() => {
				checkDuplicateNin({
					individuals: {
						national_insurance_number: nin,
					},
				});

				isValidNationalInsuranceNumber({
					profiles: {
						national_insurance_number: nin,
					},
				});

				setInputLoading(false); // Set loading to false after processing
			}, 1000);

			return () => {
				clearTimeout(delayDebounceFn);
			};
		} else {
			setInputLoading(false);
		}
	}, [nin, checkDuplicateNin, isValidNationalInsuranceNumber]);


	if (!retrieveCollegiaAccessToken()) {
		window.location.href = "/login";
	}

	if (profile_loading || loading || activation_code_loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<Wrapper
			hideFooter={true}
			history={history}
			textHeader={"Personal Information"}
			t={t}
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				item
				direction={"row"}
				container
				className={"grid"}
			>
				<MyAccountLeftMenu
					history={history}
					currentPage={"Personal"}
					t={t}
					capitalizeFirstLetter={capitalizeFirstLetter}
					myHeight={window.innerHeight}
				/>
				<Grid
					xl={9.5}
					lg={9.5}
					md={12}
					sm={12}
					xs={12}
					item
					direction={"row"}
					container
					justifyContent={"center"}
					maxHeight={"750px"}
				>
					<Grid
						xl={9}
						lg={9}
						md={9}
						sm={9}
						xs={11}
						item
						justifyContent={"center"}
						direction={"row"}
						container
					>
						<HeaderInformation
							profile_data={profile_data}
							src={src}
							picture={picture}
							hiddenFileInput={hiddenFileInput}
							setPreview={setPreview}
							handleClick={handleClick}
							handleUpload={handleUpload}
							updateProfilePicture={updateProfilePicture}
							t={t}
						/>
						<Grid
							xl={6}
							lg={6}
							md={6}
							sm={6}
							xs={12}
							className={"grid-text"}
							item
						>
							<Typography
								className={"label"}
							>
								{t("individual.my_account.personal_information.forename_input")}
							</Typography>
							<TextField
								className={"textfield"}
								value={forename}
								onChange={(e) => setForename(e.target.value)}
								fullWidth
							/>
						</Grid>
						<Grid
							xl={6}
							lg={6}
							md={6}
							sm={6}
							xs={12}
							className={"grid-text"}
							item
						>
							<Typography
								className={"label"}
							>
								{t("individual.my_account.personal_information.surname")}
							</Typography>
							<TextField
								className={"textfield"}
								value={surname}
								onChange={(e) => setSurname(e.target.value)}
								fullWidth
							/>
						</Grid>
						<Grid
							xl={6}
							lg={6}
							md={6}
							sm={6}
							xs={12}
							className={"grid-text"}
							item
						>
							<Typography
								className={"label"}
							>
								{t("individual.birthdate")}
							</Typography>
							{renderBirthDateTextBox(birthdate, setBirthdate)}
						</Grid>
						<Grid
							xl={6}
							lg={6}
							md={6}
							sm={6}
							xs={12}
							className={"grid-text"}
							item
						>
							<Typography
								className={"label"}
							>
								{t("individual.my_account.personal_information.gender")}
							</Typography>
							<TextField
								id={"gender"}
								className={"textfield"}
								value={gender}
								fullWidth
								onChange={event => setGender(event.target.value)}
								SelectProps={{
									native: true,
								}}
								select
							>
								{[
									{
										value: "Male",
										label: t("individual.gender_male"),
									},
									{
										value: "Female",
										label: t("individual.gender_female"),
									},
									{
										value: "Non-Binary",
										label: t("individual.gender_non_binary"),
									},
								].map((option) => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</TextField>
						</Grid>
						<Grid
							xl={6}
							lg={6}
							md={6}
							sm={6}
							xs={12}
							className={"grid-text"}
							item
						>
							<Typography
								className={"label"}
							>
								{t("individual.my_account.personal_information.national_insurance_number")}
							</Typography>
							<TextField
								className={"textfield"}
								onChange={(e) => setNin(e.target.value)}
								value={nin}
								fullWidth
								InputProps={{
									endAdornment: inputLoading || validate_nin_loading ? (
										<InputAdornment position="end">
											<CircularProgress size={20} />
										</InputAdornment>
									) : null,
								}}
							/>
							<Grid container>
								{
									valid_nin &&
										<small
											className={"red-text"}
											key={t}
										>
											{t("individual.wrong_nin_number")}
										</small>
								}

							</Grid>
							<Grid container>
								{
									exist_nin &&
									<small
										className={"red-text"}
										key={t}
									>
										{t("individual.nin_already_exists")}
									</small>
								}

							</Grid>
						</Grid>
						<Grid
							xl={6}
							lg={6}
							md={6}
							sm={6}
							xs={12}
							className={"grid-text"}
							item
						>
							<Typography
								className={"label"}
							>
								{t("individual.my_account.personal_information.mobile_phone_number_input")}
							</Typography>
							<TextField
								className={"textfield"}
								value={mobile ?? ""}
								onChange={(e) => setMobile(e.target.value)}
								fullWidth
							/>
						</Grid>
						<Grid
							xl={6}
							lg={6}
							md={6}
							sm={6}
							xs={12}
							className={"grid-text"}
							item
						>
							<Typography
								className={"label"}
							>
								{t("individual.my_account.personal_information.policy_number")}
							</Typography>
							<TextField
								className={"textfield"}
								value={profile_data?.policy_number}
								disabled
								fullWidth
							/>
						</Grid>
						<Grid
							xl={6}
							lg={6}
							md={6}
							sm={6}
							xs={12}
							className={"grid-text"}
							item
						>
							<Typography
								className={"label"}
							>
								{t("individual.login.email_input")}
							</Typography>
							<TextField
								className={"textfield"}
								value={profile_data?.email ?? ""}
								fullWidth
								disabled
							/>
						</Grid>
						<Grid
							xl={6}
							lg={6}
							md={6}
							sm={6}
							xs={12}
							className={"grid-text"}
							item
							onClick={() => {
								const individual = {
									profiles: {
										forename: forename,
										surname: surname,
										gender: gender,
										birthdate: birthdate,
										mobile: mobile,
										national_insurance_number: nin
									}
								}
								_disable(
									mobile,
									gender,
									forename,
									surname,
									birthdate,
									nin,
									profile_data,
									validate_nin_loading,
									inputLoading,
									valid_nin,
									exist_nin

								) === "button-1" &&
								update(profile_data?.id, individual, false);
							}}
						>
							<Typography
								className={
									_disable(
										mobile,
										gender,
										forename,
										surname,
										birthdate,
										nin,
										profile_data,
										validate_nin_loading,
										inputLoading,
										valid_nin,
										exist_nin
									)
								}
								textAlign={"center"}
							>
								{t("individual.my_account.personal_information.change_information")}
							</Typography>
						</Grid>
						<Grid
							xl={6}
							lg={6}
							md={6}
							sm={6}
							xs={12}
							item
							onClick={() => {
								_disable(
									mobile,
									gender,
									forename,
									surname,
									birthdate,
									nin,
									profile_data,
									validate_nin_loading,
									inputLoading,
									valid_nin,
									exist_nin
								) === "button-1" &&
								setMobile(profile_data?.mobile);
								setGender(profile_data?.gender);
								setSurname(profile_data?.surname);
								setForename(profile_data?.forename);
								let formatted_birthdate = profile_data?.birthdate?.toString()?.split("-") ?? "";
								setBirthdate((formatted_birthdate[2] + "/" +
									formatted_birthdate[1] + "/" + formatted_birthdate[0]).toString());
							}}
						>
							<Typography
								className={
									_disable(
										mobile,
										gender,
										forename,
										surname,
										birthdate,
										nin,
										profile_data,
										validate_nin_loading,
										inputLoading,
										valid_nin,
										exist_nin
									)}
								textAlign={"center"}
							>
								{t("individual.my_account.personal_information.discard")}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Wrapper>
	);
}

const mapStateToProps = state => {
	const {
		Profile = [],
		ActivationCode = []
	} = state;

	return {
		...Profile,
		...ActivationCode
	};
}

/**
 * @param dispatch
 * @returns {{returnUser: (function(): *), update: (function(*=, *[]=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	returnUser: dispatch(returnUser()),
	update: (id: any = false, data: any[] = false) => dispatch(update(id, data, false)),
	updateProfilePicture: (data: any[] = false) => dispatch(updateProfilePicture(data)),
	isValidNationalInsuranceNumber: (data: {} = {
		national_insurance_number: "",
	}) => dispatch(isValidNationalInsuranceNumber(data)),
	checkDuplicateNin: (data: any[] = false) => dispatch(checkDuplicateNin(data ?? false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInformation);
