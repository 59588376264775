import {
	NOTIFICATIONS_SET,
	NOTIFICATIONS_SET_VISUALIZED,
	NOTIFICATIONS_CLEAR_ALL,
	NOTIFICATIONS_GET,
} from "../../actions/Notifications/types";
import {resolveReduxState} from "../../utils/Helpers";

/**
 * @type {[*]}
 */
const initial_state = {notifications : []};

/**
 * @param state
 * @param action
 * @returns {[*]}
 * @constructor
 */
const NotificationsReducer = (state = initial_state, action = false) => {
	if (!action) return state;
	const {type = false, id} = action;

	switch (type) {
		case NOTIFICATIONS_SET:
			return state.notifications;

		case NOTIFICATIONS_SET_VISUALIZED:
			return {
				...state,
				notifications: state.notifications.map((x, notification_id) =>
					(
						notification_id === id
							? { ...x, visualized: true }
							: x
					))
			};

		case NOTIFICATIONS_CLEAR_ALL:
			return {
				notifications: [],
			}

		case NOTIFICATIONS_GET:
			return resolveReduxState(state, action);

		default: return state;
	}
};

export default NotificationsReducer;
