import Request from "../../utils/Request";

/**
 * Retrieve my profile data based on collegiaToken in the
 * default's browser's localstorage.
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
const MePromise = () => Request.get("/profile/me");

export default MePromise;