import {DELETE_BENEFICIARY, GET_BENEFICIARY_TYPES, GET_RELATIONSHIP_TYPES, UPDATE_BENEFICIARY} from "./types";
import BeneficiariesService from "../../services/Beneficiaries";

/**
 * @returns {(function(*): void)|*}
 */
export const getBeneficiaryTypes = () => dispatch => {
	dispatch({
		type: GET_BENEFICIARY_TYPES,
		beneficiaries_loading: true,
		beneficiaries: [],
		beneficiary: null,
	});

	new BeneficiariesService()
		.getBeneficiaryTypes()
		.then(response => dispatch({
			type: GET_BENEFICIARY_TYPES,
			beneficiaries_loading: false,
			beneficiary_types: response?.data?.data ?? [],
		}))
		.catch(error => {
			dispatch({
				type: GET_BENEFICIARY_TYPES,
				beneficiaries_loading: true,
				beneficiaries: [],
				beneficiary: null,
			});
			console.error(error);
		});
}


/**
 * @returns {(function(*): void)|*}
 */
export const getRelationshipTypes = () => dispatch => {
	dispatch({
		type: GET_RELATIONSHIP_TYPES,
		beneficiaries_loading: true,
	});

	new BeneficiariesService()
		.getRelationshipTypes()
		.then(response => dispatch({
			type: GET_RELATIONSHIP_TYPES,
			beneficiaries_loading: false,
			beneficiary_relationship_types: response?.data?.data ?? [],
		}))
		.catch(error => {
			dispatch({
				type: GET_RELATIONSHIP_TYPES,
				beneficiaries_loading: true,
			});
			console.error(error);
		});
}

/**
 * @param data
 * @returns {(function(*): void)|*}
 */
export const create = (data = {}) => dispatch => {
	dispatch({
		type: GET_RELATIONSHIP_TYPES,
		beneficiaries_loading: true,
		beneficiaries: [],
		beneficiary: null,
	});

	new BeneficiariesService()
		.insert(data)
		.then(() => {
			dispatch(get(["address"]));
			dispatch({
				type: GET_RELATIONSHIP_TYPES,
				beneficiaries_loading: false,
			});
		})
		.catch(error => {
			dispatch({
				type: GET_RELATIONSHIP_TYPES,
				beneficiaries_loading: true,
				beneficiaries: [],
				beneficiary: null,
			});
			console.error(error);
		});
}

/**
 * @param relationships
 * @returns {(function(*): void)|*}
 */
export const get = (relationships = ["address"]) => dispatch => {
	dispatch({
		type: GET_RELATIONSHIP_TYPES,
		beneficiaries_loading: true,
		beneficiary: null,
	});

	new BeneficiariesService()
		.get(relationships ?? [])
		.then(response => dispatch({
				type: GET_RELATIONSHIP_TYPES,
				beneficiaries_loading: false,
				beneficiaries: response?.data?.data.data ?? [],
				beneficiary: null,
			})
		)
		.catch(error => {
			dispatch({
				type: GET_RELATIONSHIP_TYPES,
				beneficiaries_loading: true,
				beneficiary: null,
			});
			console.error(error);
		});
}

export const deleteBeneficiary = (id) => dispatch => {
	if (id) {
		dispatch({
			type: DELETE_BENEFICIARY,
			beneficiaries_loading: true,
			beneficiaries: [],
			beneficiary: null,
		});

		new BeneficiariesService()
			.delete(id)
			.then(() => dispatch(get(["address"])))
			.catch(error => {
				dispatch({
					type: DELETE_BENEFICIARY,
					beneficiaries_loading: true,
					beneficiaries: [],
					beneficiary: null,
				});
				console.error(error);
			});
	}
}

/**
 * @param id
 * @param relationships
 * @returns {(function(*): void)|*}
 */
export const find = (id, relationships = ["address"]) => dispatch => {
	if (id) {
		dispatch({
			type: GET_RELATIONSHIP_TYPES,
			beneficiaries_loading: true,
			beneficiary: null,
		});

		new BeneficiariesService()
			.find(id, relationships)
			.then(response => dispatch({
				type: GET_RELATIONSHIP_TYPES,
				beneficiaries_loading: false,
				beneficiary: response.data.data,
			}))
			.catch(error => {
				dispatch({
					type: GET_RELATIONSHIP_TYPES,
					beneficiaries_loading: true,
					beneficiary: null,
				});
				console.error(error);
			});
	}
}

/**
 * @param id
 * @param data
 * @returns {(function(*): void)|*}
 */
export const update = (id, data) => dispatch => {
	if (id && data) {
		dispatch({
			type: UPDATE_BENEFICIARY,
			beneficiaries_loading: true,
			beneficiary: null,
			beneficiaries: [],
		});

		new BeneficiariesService()
			.update(id, data)
			.then(() => {
				dispatch(get());
				dispatch({
					type: UPDATE_BENEFICIARY,
					beneficiaries_loading: false,
				});
			})
			.catch(error => {
				console.error(error);
			});
	}
}