import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import StillNotThere from "../../images/newStillNotThere.svg";
import Badge from "../../images/your-pension-badge.svg";

/**
 * @param active
 * @param t
 * @param comfortRulerProjectionParameters
 * @returns {JSX.Element}
 * @constructor
 */
const StillNotThereCard = (
	{
		active = 0,
		t = key => key,
		comfortRulerProjectionParameters = {
			active: true,
			comfortable: 43100,
			created_at: "",
			id: null,
			lower_bound: 0,
			minimum: 14400,
			moderate: 31300,
			upper_limit: 64000,
		},
	}
): JSX.Element => (
	<Grid
		lg={12}
		xl={12}
		sm={12}
		md={12}
		item
		className={"still-not-there-grid"}
	>
		{
			active === 0 && (
				<Grid
					lg={12}
					xl={12}
					sm={12}
					xs={12}
					md={12}
					item
					textAlign={"end"}
					paddingRight={2}
				>
					<img
						src={Badge}
						alt={"Badge"}
					/>
				</Grid>
			)
		}
		<img
			src={StillNotThere}
			alt={t("individual.lifestyle_ruler.still_not_there")}
		/>
		<Grid
			lg={12}
			xl={12}
			sm={12}
			xs={12}
			md={12}
			item
		>
			<Typography
				className={"title"}
			>
				{t("individual.understand_lifestyle.still_no_there.title")}
			</Typography>
		</Grid>
		<Grid
			lg={11}
			xl={10}
			sm={10}
			xs={12}
			md={12}
			item
		>
			<Typography
				className={"sub-title"}
			>
				{t("individual.understand_lifestyle.still_no_there.caption")}
			</Typography>
			<Typography
				className={"description"}
			>
				{t("individual.understand_lifestyle.still_no_there.message")}
			</Typography>
		</Grid>
		<Grid
			lg={12}
			xl={12}
			sm={12}
			xs={12}
			md={12}
			item
		>
			<Typography
				className={"single"}
			>
				{t("individual.understand_lifestyles.single").toUpperCase()}
				<span
					className={"below"}
				>
						{t("individual.understand_lifestyles.below")} {" "}
					</span>
				<span
					className={"value"}
				>
						{comfortRulerProjectionParameters.minimum.toLocaleString('en-GB',{
					style: 'currency',
					currency: 'GBP',
				})} / {t("individual.year")}
					</span>
			</Typography>
		</Grid>

	</Grid>
)

export default StillNotThereCard