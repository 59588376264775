import {CREATE_EMPLOYER_LEAD, FETCH_EMPLOYER_DATA, SEARCH_EMPLOYER} from "../../actions/Employers/type";
import {resolveReduxState} from "../../utils/Helpers";

/**
 * @type {{employer_loading: boolean, search_results: [], search_loading: boolean, searchTrigger: boolean, employer_data: []}}
 */
const initial_state = {
	employer_data: {},
	search_results: [],
	employer_loading: false,
	search_loading: false,
	searchTrigger: false,
	showModal: false,
}

/**
 * @param state
 * @param action
 * @returns {{employer_loading: boolean, search_results: [], search_loading: boolean, searchTrigger: boolean, employer_data: []}|{valueOf, (): boolean}|boolean}
 */
const EmployersStore = (state = initial_state, action = false) => {

	if (action) {
		const {
			type = false
		} = action;

		if (type) {
			if (type === FETCH_EMPLOYER_DATA) return resolveReduxState(state ?? false, action ?? false);
			else if (type === SEARCH_EMPLOYER) return resolveReduxState(state ?? false, action ?? false);
			else if (type === CREATE_EMPLOYER_LEAD) return resolveReduxState(state ?? false, action ?? false);
		}
	}

	return state;
};

export default EmployersStore;