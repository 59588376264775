import Request from "../../utils/Request";

/**
 * Finish the walkthrough, there's no reason to send any kind of data
 * for it since the user's needs to be logged in in the system
 * to finish the walkthrough.
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
const CompleteWalkthroughPromise = () => Request.post("profile/complete-walkthrough");

export default CompleteWalkthroughPromise;