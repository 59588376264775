import {forceScrollUp} from "../../utils/Helpers";
import {FETCH_SOURCE_OF_FUNDS} from "./types";
import SourceOfFundsService from "../../services/SourceOfFunds";

/**
 * @action
 * @param scrollUp
 * @returns {function(...[*]=)}
 */
export const get = (scrollUp: boolean = false) => dispatch => {
	new SourceOfFundsService()
		.active()
		.then(response => {
			dispatch({
				type: FETCH_SOURCE_OF_FUNDS,
				sourceOfFunds: response.data.data,
			});

			if (scrollUp) {
				forceScrollUp();
			}
		})
		.catch(error => console.error(error));
};