import React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import minimumLeaf from "../images/minimum-leaf.svg";
import moderateTree from "../images/moderate-tree.svg";
import comfortableTree from "../images/confortable-tree.svg";
import PensionPotProgress from "./MainPage/PensionTab/PensionPotProgress";
import StillNoThereIcon from "../images/StillNotThereIcon.svg";
import {getYearlyIncomeFormattedValue, setLifestyleOpacity} from "../utils/Helpers";

/**
 * @param props
 * @returns {{img: *, className: string, label: *}}
 */
function renderRulerCurrentStage(props = {
	profile_data: {
		comfort_ruler_projection_parameters: {
			comfortable: 43100,
			lower_bound: 0,
			minimum: 14400,
			moderate: 31300,
			upper_limit: 64000,
		},
	},
	totalYearlyIncome: "",
	badge: "",
}) {
	if (!props.profile_data) return [];

	let value = 0,
		rulerDrawer = {
			img: StillNoThereIcon,
			className: ".still-not-there-container",
			label: props.t("individual.lifestyle_ruler.still_not_there"),
		};

	const {
		comfortable = 43100.00,
		lower_bound = 0,
		minimum = 14400.00,
		moderate = 31300.00,
	} = props.profile_data.comfort_ruler_projection_parameters;

	if (props.totalYearlyIncome) {

		if (typeof props.totalYearlyIncome.replace === "function") {
			value = getYearlyIncomeFormattedValue(props ?? false);
		} else {
			value = parseInt(props.totalYearlyIncome);
		}
	}

	// still not there
	if (
		value >= lower_bound &&
		value <= minimum
	) {
		rulerDrawer = {
			img: StillNoThereIcon,
			className: "still-not-there-container",
			label: props.t("individual.lifestyle_ruler.still_not_there"),
		};
		// minimum
	} else if (
		value > minimum &&
		value <= moderate
	) {

		rulerDrawer = {
			img: minimumLeaf,
			className: "minimum-container",
			label: props.t("individual.lifestyle_ruler.minimum"),
		};
		// moderate
	} else if (
		value >= moderate &&
		value < comfortable
	) {
		rulerDrawer = {
			img: moderateTree,
			className: "moderate-container",
			label: props.t("individual.lifestyle_ruler.moderate"),
		};
	}
	// comfortable.
	if (value >= comfortable) {
		rulerDrawer = {
			img: comfortableTree,
			className: "comfortable-container",
			label: props.t("individual.lifestyle_ruler.comfortable"),
		};
	}

	if (props.badge) {
		rulerDrawer.label = props.badge;

		switch (props.badge) {
			default:
				rulerDrawer.img = StillNoThereIcon;
				break;

			case props.t("individual.lifestyle_ruler.minimum"):
				rulerDrawer.img = minimumLeaf;
				break;

			case props.t("individual.lifestyle_ruler.moderate"):
				rulerDrawer.img = moderateTree;
				break;

			case props.t("individual.lifestyle_ruler.comfortable"):
				rulerDrawer.img = comfortableTree;
				break;
		}
	}

	if (props.mainPageContainer) {
		rulerDrawer.className = props.mainPageContainer;
	}

	return rulerDrawer;
}

/**
 * @param props
 * @returns {*}
 * @private
 */
function _renderContent(props) {

	if (props.showAllSteps) {
		return (
			<React.Fragment>
				<Grid
					md={3}
					lg={3}
					xs={3}
					alignItems={"center"}
					alignContent={"center"}
					className={`still-not-there-container`}
					item
					container
				>
					<Grid
						xs={12}
						md={12}
						lg={12}
						alignContent={"center"}
						alignItems={"center"}
						direction={"column"}
						container
						item
					>
						<img
							className={`comfortable-icons ${setLifestyleOpacity(
								"still-not-there",
								props.currentProgress,
								true,
								props
							)}`}
							src={StillNoThereIcon}
							alt={props.t("individual.lifestyle_ruler.still_not_there")}
						/>
					</Grid>

					{!props.noLegend ? <Grid
						xs={12}
						md={12}
						lg={12}
						alignContent={"center"}
						alignItems={"center"}
						direction={"column"}
						item
						container
					>
						<Typography
							variant={"caption"}
							align={"center"}
							className={`remove-padding-top-force ${setLifestyleOpacity(
								"still-not-there",
								props.currentProgress,
								true,
								props
							)}`}
						>
							{props.t("individual.lifestyle_ruler.still_not_there")}
						</Typography>
					</Grid> : []}
				</Grid>

				<Grid
					md={3}
					lg={3}
					xs={3}
					alignItems={"center"}
					alignContent={"center"}
					className={"minimum-container"}
					item
					container
				>
					<Grid
						xs={12}
						md={12}
						lg={12}
						alignContent={"center"}
						alignItems={"center"}
						direction={"column"}
						item
						container
					>
						<img
							className={`comfortable-icons ${setLifestyleOpacity(
								"minimum",
								props.currentProgress,
								true,
								props
							)}`}
							src={minimumLeaf}
							alt={props.t("individual.lifestyle_ruler.minimum")}
						/>
					</Grid>

					{!props.noLegend ? <Grid
						xs={12}
						md={12}
						lg={12}
						alignContent={"center"}
						alignItems={"center"}
						direction={"column"}
						item
						container
					>
						<Typography
							variant={"caption"}
							align={"center"}
							className={`${setLifestyleOpacity(
								"minimum",
								props.currentProgress,
								true,
								props
							)}`}
						>
							{props.t("individual.lifestyle_ruler.minimum")}
						</Typography>
					</Grid> : []}
				</Grid>

				<Grid
					md={3}
					lg={3}
					xs={3}
					alignItems={"center"}
					alignContent={"center"}
					className={"moderate-container"}
					item
					container
				>
					<Grid
						xs={12}
						md={12}
						lg={12}
						alignContent={"center"}
						alignItems={"center"}
						direction={"column"}
						item
						container
					>
						<img
							className={`comfortable-icons ${setLifestyleOpacity(
								"moderate",
								props.currentProgress,
								true,
								props
							)}`}
							src={moderateTree}
							alt={props.t("individual.lifestyle_ruler.moderate")}
						/>
					</Grid>

					{
						!props.noLegend ?
							<Grid
								xs={12}
								md={12}
								lg={12}
								alignContent={"center"}
								alignItems={"center"}
								direction={"column"}
								item
								container
							>
								<Typography
									variant={"caption"}
									align={"center"}
									className={`${setLifestyleOpacity(
										"moderate",
										props.currentProgress,
										true,
										props
									)}`}
								>
									{props.t("individual.lifestyle_ruler.moderate")}
								</Typography>
							</Grid>
							:
							[]
					}
				</Grid>
				<Grid
					md={3}
					lg={3}
					xs={3}
					alignItems={"center"}
					alignContent={"center"}
					className={`comfortable-icons ${setLifestyleOpacity(
						"comfortable",
						props.currentProgress,
						true,
						props
					)}`}
					item
					container
				>
					<Grid
						xs={12}
						md={12}
						lg={12}
						alignContent={"center"}
						alignItems={"center"}
						direction={"column"}
						item
						container
					>
						<img
							className={"comfortable-icons"}
							src={comfortableTree}
							alt={props.t("individual.lifestyle_ruler.comfortable")}
						/>
					</Grid>
					{!props.noLegend ?
						<Grid
							xs={12}
							md={12}
							lg={12}
							alignContent={"center"}
							alignItems={"center"}
							direction={"column"}
							item
							container
						>
							<Typography
								variant={"caption"}
								align={"center"}
								className={`${setLifestyleOpacity(
									"comfortable",
									props.currentProgress,
									true,
									props
								)}`}
							>
								{props.t("individual.lifestyle_ruler.comfortable")}
							</Typography>
						</Grid>
						:
						[]
					}
				</Grid>
			</React.Fragment>
		);
	}

	return (
		<Grid
			md={12}
			lg={12}
			xs={12}
			alignItems={"center"}
			alignContent={"center"}
			className={renderRulerCurrentStage(props).className}
			item
			container
		>
			<Grid
				xs={6}
				md={6}
				lg={6}
				alignContent={"flex-start"}
				alignItems={"flex-start"}
				direction={"column"}
				item
				container
			>
				<Typography
					variant={"caption"}
					align={"center"}
				>
					{renderRulerCurrentStage(props).label}
				</Typography>
			</Grid>
			<Grid
				xs={6}
				md={6}
				lg={6}
				alignContent={"flex-end"}
				alignItems={"flex-end"}
				direction={"column"}
				item
				container
			>
				<img
					className={"comfortable-icons"}
					src={renderRulerCurrentStage(props).img}
					alt={renderRulerCurrentStage(props).label}
				/>
			</Grid>
		</Grid>
	);
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const LifeStyleDrawRuler = (props): JSX.Element => (

	<Grid
		alignItems={"center"}
		alignContent={"center"}
		direction={"row"}
		item
		container
	>
		{_renderContent(props)}
		<PensionPotProgress
			comfort_ruler_projection={props.comfort_ruler_projection}
			projection={props.projection}
			progress={props.currentProgress}
			totalYearlyIncome={props.totalYearlyIncome}
			progressLevelClassName={props.progressLevelClassName ?? false}
		/>
	</Grid>
);

export default LifeStyleDrawRuler;