import React from "react";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const SamePensionDifferentJobs = (): JSX.Element => (
	<div id="Section-3" className="section-7 wf-section">
		<div className="second-cta">
			<h1 className="heading-3">Same pension, <br />different jobs</h1>
			<p className="paragraph-3"><strong>Your current and future employers can contribute for free to your
				personal Collegia account, </strong>instead of setting up a new pension for you every time you change
				jobs.</p>
		</div>
	</div>
);

export default SamePensionDifferentJobs;