import {resolveReduxState} from "../../utils/Helpers";
import {GET_COMFORT_RULER_PROJECTION_PARAMETERS} from "../../actions/ComfortRulerProjectionParameters/types";

const initial_state = {
	comfortRulerProjectionParameters: {
		active: true,
		comfortable: 43100,
		created_at: "",
		id: null,
		lower_bound: 0,
		minimum: 14400,
		moderate: 31300,
		upper_limit: 64000,
	},
};

const ComfortRulerProjectionParametersStore = (state = initial_state, action = false) => {
	if (action && action.type) {
		const {
			type = false
		} = action;

		if (type === GET_COMFORT_RULER_PROJECTION_PARAMETERS) return resolveReduxState(state, action);
	}


	return state;
};

export default ComfortRulerProjectionParametersStore;