import React from "react";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CreateIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Create';
import Button from "@mui/material/Button";

/**
 * @param profile_data
 * @param picture
 * @returns {JSX.Element}
 * @private
 */
function _renderAvatar(
	profile_data = {
		surname: "",
		forename: "",
		middle_name: "",
		avatar: "",
		email: "",
	},
	picture = null,
): JSX.Element {
	if (picture) {
		return (
			<img
				src={picture}
				className={"profile-picture"}
				alt={profile_data.forename}
			/>
		);
	} else if (profile_data && profile_data.avatar) {
		return (
			<img
				src={profile_data.avatar}
				alt={profile_data.forename}
				className={"profile-picture"}
			/>
		);
	}
	return (
		<AccountCircleIcon/>
	);
}

/**
 * @param profile_data
 * @param picture
 * @returns {string}
 * @private
 */
function _hasProfilePicture(
	profile_data = {
		surname: "",
		forename: "",
		middle_name: "",
		avatar: "",
		email: "",
	},
	picture = null,
): string {
	if (profile_data?.avatar || picture) {
		return "has-profile-picture";
	}

	return "dont-has-profile-picture";
}

/**
 * @param profile_data
 * @param hiddenFileInput
 * @param src
 * @param picture
 * @param handleUpload
 * @param handleClick
 * @param updateProfilePicture
 * @param setPreview
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const HeaderInformation = (
	{
		profile_data = {
			surname: "",
			forename: "",
			middle_name: "",
			avatar: "",
			email: "",
		},
		hiddenFileInput = null,
		src = null,
		picture = null,
		handleUpload = () => {
		},
		handleClick = () => {
		},
		updateProfilePicture = () => {
		},
		setPreview = () => {
		},
		t = (key) => key,
	}
): JSX.Element => (
	<Grid
		id={"default-container"}
		xs={12}
		sm={12}
		md={12}
		lg={12}
		item
		container
		alignContent={"center"}
		alignItems={"center"}
		justifyContent={"center"}
		direction={"row"}
	>
		<Grid
			className={"inner"}
			item
		>
			{_renderAvatar(profile_data ?? false, picture)}
			{
				profile_data?.avatar ?
					<>
						<EditIcon
							className={`change-picture-icon ${_hasProfilePicture(profile_data ?? false, picture)}`}
							onClick={handleClick}
						/>
						<input
							type="file"
							id="photo"
							name={"photo"}
							className={"hidden-input"}
							accept="image/*"
							onChange={(event) => handleUpload(event)}
							ref={hiddenFileInput}
						/>
					</>
					:
					<>
						<CreateIcon
							className={`change-picture-icon ${_hasProfilePicture(profile_data ?? false, picture)}`}
							onClick={handleClick}
						/>
						<input
							type="file"
							id="photo"
							name={"photo"}
							className={"hidden-input"}
							accept="image/*"
							onChange={(event) => handleUpload(event)}
							ref={hiddenFileInput}
						/>
					</>
			}
			{
				src ?
					<Grid
						marginTop={"5px"}
						marginBottom={"5px"}
					>
						<Button
							onClick={() => {
								updateProfilePicture({picture: src});
								setPreview({picture: null, src: null});
							}}
						>
							DONE
						</Button>
					</Grid>
					:
					""
			}
		</Grid>
		<Grid
			item
			paddingLeft={2}
		>
			<Typography
				className={"hello-individual"}
				align={"left"}
			>
				{t("individual.main_page.salute_user", {
					username: `${profile_data?.forename} ${profile_data?.surname}`
				})}
			</Typography>
			<Typography
				className={"email"}
				align={"left"}
			>
				{profile_data?.email}
			</Typography>
		</Grid>
	</Grid>
);

export default HeaderInformation;