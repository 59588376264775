import React, {useState} from "react";
import PropTypes from "prop-types";
import ContributionsTableList from "./ContributionsTableList";
import ContributionsHistoryAccordion from "./ContributionsHistoryAccordion";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Divider from "@mui/material/Divider";

/**
 * @param data
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const ContributionHistoryAccordionColumn = (
	{
		data = [{
			formatted_date: "",
			total: 0,
			total_fee: 0,
			formatted_total_contribution: 0,
			transactions: [{}],
			fee: [{}],
			id: null,
		}],
		t = key => key,
	}
): JSX.Element => {
	const [expandedList, setExpandedList] = useState([]);
	return (
		<Grid
			spacing={0}
			marginBottom={8}
			direction={"column"}
			alignItems="center"
			alignContent={"center"}
			id={"contribution-accordion-style"}
			container
			item
		>
			{
				data?.map((d = {
					formatted_date: "",
					total: 0,
					total_fee: 0,
					sum_fee: 0,
					transactions: [{}],
					formatted_total_contribution: 0,
					fee: [{}],
					id: null,
				}, index) => (
					<Accordion
						className={"min-height-accordion-list"}
						key={index}
						onClick={() =>
							expandedList.some(item => index === item) ?
								setExpandedList(expandedList.filter(item => item !== index))
								:
								setExpandedList([...expandedList, index])
						}
					>
						<AccordionSummary
							aria-controls={"panel1a-content"}
							id={"panel1a-header"}
						>
							<ContributionsTableList
								tableColumnDate={d?.formatted_date}
								tableColumnTotal={"£ " + d?.total}
								tableColumnFee={
									d?.total_fee === null ? "£ 0.00" : ("£ " + d.sum_fee)
								}
								expandedList={expandedList}
								index={index}
								t={t}
							/>
						</AccordionSummary>
						<Divider/>
						<AccordionDetails>
							<ContributionsHistoryAccordion
								total={d?.total}
								month={d?.formatted_date}
								transactions={JSON.parse(d?.transactions)}
								t={t}
							/>
						</AccordionDetails>
					</Accordion>
				))}

		</Grid>
	);
}

ContributionHistoryAccordionColumn.propTypes = {
	data: PropTypes.arrayOf(
		PropTypes.shape({
				formatted_date: PropTypes.string,
				total: PropTypes.oneOfType([
					PropTypes.string,
					PropTypes.number,
				]),
				total_fee: PropTypes.oneOfType([
					PropTypes.string,
					PropTypes.number,
				]),
				formatted_total_contribution: PropTypes.oneOfType([
					PropTypes.string,
					PropTypes.number,
				]),
				transactions: PropTypes.arrayOf(PropTypes.shape({})),
				fee: PropTypes.arrayOf(PropTypes.shape({})),
				id: PropTypes.number,
			}
		)).isRequired,
};

export default ContributionHistoryAccordionColumn;