import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import PayCaptainLogo from './../images/PayCaptainLogo.svg';
import Typography from "@mui/material/Typography";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const LoginSelection: React.PureComponent = (): JSX.Element => (
	<Grid
		id={"environment-buttons"}
		alignItems={"center"}
		alignContent={"center"}
		direction={"row"}
		container
	>
		<Grid
			className={"or-login-with-holder"}
			lg={12}
			xs={12}
			md={12}
			sm={12}
			item
		>
			<Typography textAlign={"center"}>
				OR LOGIN WITH
			</Typography>
		</Grid>
		<Grid
			className={"paycapatain-button-holder"}
			lg={12}
			xs={12}
			md={12}
			sm={12}
			item
		>
			<Button
				onClick={() => window.location.replace(`${process.env.REACT_APP_API}sso/paycaptain/init`)}
				fullWidth
			>
				<img src={PayCaptainLogo} alt={"Login with your PayCaptain account"} />
			</Button>
		</Grid>
	</Grid>
);

export default LoginSelection;