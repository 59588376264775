import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param code
 * @param data
 * @returns {Promise<AxiosResponse<*>>}
 * @constructor
 */
const RejectInvitationRequest = (code:string = "", data = {}): Promise<AxiosResponse<any>>|Promise<never> =>
	Request?.post(`/individual/invitations/accept/${code}`, data)
	??
	Promise.reject(new Error("Request is not defined"));

export default RejectInvitationRequest;