import Loading from "../components/Loading";
import {useParams} from "react-router-dom";
import {connect} from "react-redux";
import {useEffect, useState} from "react";
import {acceptInvitation, getByCode, rejectInvitation} from "../actions/Invitations";
import Grid from "@mui/material/Grid";
import Wrapper from "../layout/Wrapper";
import Typography from "@mui/material/Typography";
import GenericTermsAndConditions from "../components/GenericTermsAndConditions";
import BodyText from "../components/Home/SetUpPage/TermsAndConditions/BodyText";
import MinimalFooter from "../layout/MinimalFooter";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import MaskedInput from "react-text-mask";
import {capitalizeFirstLetter, createAutoCorrectedDatePipe, validateEmail} from "../utils/Helpers";
import Button from "@mui/material/Button";
import DefaultFormErrors from "../components/Forms/DefaultFormErrors";
import {CountryRegionData} from "react-country-region-selector";
import MenuItem from "@mui/material/MenuItem";
import {useTranslation} from "react-i18next";

/**
 * @param error_data
 * @param invitation_loading
 * @param findByCode
 * @param rejectInvitation
 * @param acceptInvitation
 * @param invite
 * @returns {JSX.Element}
 * @constructor
 */
const Invite = (
	{
		error_data = {},
		invitation_loading = false,
		findByCode = (code = "") => code,
		rejectInvitation = (code = "") => code,
		acceptInvitation = (code = "", data = {}) => {
			return {
				data,
				code,
			}
		},
		invite = {
			name: "",
			email: "",
			accepted: null,
			employer: {
				title: "",
			}
		},
		language = "GB",
	},
): JSX.Element => {
	const code = useParams().code;
	const [termsAccepted = false, setTermsAccepted] = useState(false);
	const [forename, setForename] = useState("");
	const [middleName, setMiddleName] = useState("");
	const [surname, setSurname] = useState("");
	const [email, setEmail] = useState("");
	const [dateOfBirth, setDateOfBirth] = useState("");
	const [password, setPassword] = useState("");
	const [passwordConfirm, setPasswordConfirm] = useState("");
	const [country, setCountry] = useState("GB");
	const [gender, setGender] = useState("");
	const [expanded, setExpanded] = useState([{
		section: "",
		read: false,
	}]);
	const [sectionTitle, setSectionTitle] = useState(""),
		{t, i18n} = useTranslation();

	useEffect(() => {
		findByCode(code);
		i18n.changeLanguage(language);
	}, [
		code,
		findByCode,
		language,
		i18n,
	]);

	if (invitation_loading) {
		return (
			<Loading absolute/>
		);
	}

	if (invite?.accepted === false) {
		return (

			<Wrapper
				xs={12}
				sm={12}
				lg={12}
				xl={12}
				md={12}
				t={t}
				item
				container
			>
				<Grid
					id={"invitation-page"}
					xs={12}
					sm={12}
					lg={12}
					xl={12}
					md={12}
					item
				>

					<Grid
						id={"body"}
						xs={12}
						sm={12}
						lg={12}
						xl={12}
						md={12}
						item
					>
						<Typography>
							{t("individual.invitations.rejected_employer", {
								employer: invite?.employer?.title
							})}
						</Typography>
					</Grid>
				</Grid>
			</Wrapper>
		);
	}

	return (
		<Wrapper
			xs={12}
			sm={12}
			lg={12}
			xl={12}
			md={12}
			t={t}
			item
			container
		>
			<Grid
				id={"invitation-page"}
				xs={12}
				sm={12}
				lg={12}
				xl={12}
				md={12}
				item
			>
				<Grid
					id={"body"}
					xs={12}
					sm={12}
					lg={12}
					xl={12}
					md={12}
					item
				>
					<h3>
						{t("individual.invitations.hello_welcome_collegia_way", {
							name: invite?.name,
						})}
					</h3>
					<Grid
						className={"information"}
						xs={12}
						sm={12}
						lg={12}
						xl={12}
						md={12}
						item
					>
						<Typography
							align={"center"}
						>
							{t("individual.invitation.employer_has_invited_you", {
								employer: invite?.employer?.title
							})}
						</Typography>
					</Grid>
					{
						!termsAccepted && (
							<Grid
								className={"information"}
								xs={12}
								sm={12}
								lg={12}
								xl={12}
								md={12}
								item
							>
								<Grid
									id={"initialSetUpModalTermsAndConditions"}
									xs={12}
									md={12}
									lg={12}
									sm={12}
									alignItems={"center"}
									alignContent={"center"}
									direction={"column"}
									className={"modal-container"}
									item
									container
								>
									<Grid
										xs={12}
										md={12}
										lg={12}
										sm={12}
										item
										container
									>
										<BodyText t={t}/>
										<GenericTermsAndConditions
											expanded={expanded}
											setExpanded={setExpanded}
											sectionTitle={sectionTitle}
											setSectionTitle={setSectionTitle}
											handleChange={setExpanded}
											proceed={() => setTermsAccepted(true)}
											noProceed={() => rejectInvitation(code)}
											noProceedButtonText={t("individual.no_i_do_not_want_to_join_collegia_button")}
											t={t}
											trial_period
										/>
									</Grid>
								</Grid>
							</Grid>
						)
					}

					{
						termsAccepted && (
							<Grid
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								item
								container
								justifyContent={"center"}
							>
								<Grid
									maxWidth={"600px"}
									item
								>
									<DefaultFormErrors errors={error_data}/>

									<FormControl
										className={"signup-form-control"}
										fullWidth
									>
										<TextField
											id={'forename'}
											label={t("individual.my_account.personal_information.forename_input")}
											placeholder={t("individual.my_account.personal_information.forename_input")}
											value={forename}
											onChange={e => setForename(e.target.value)}
											error={forename === ""}
										/>
									</FormControl>

									<FormControl
										className={"signup-form-control"}
										fullWidth
									>
										<TextField
											id={'middle_name'}
											label={t("individual.sign_up.create_your_account.middle_name_input")}
											placeholder={t("individual.sign_up.create_your_account.middle_name_input")}
											value={middleName}
											onChange={e => setMiddleName(e.target.value)}
										/>
									</FormControl>

									<FormControl
										className={"signup-form-control"}
										fullWidth
									>
										<TextField
											id={'surname'}
											label={t("individual.my_account.personal_information.surname")}
											placeholder={t("individual.my_account.personal_information.surname")}
											value={surname}
											onChange={e => setSurname(e.target.value)}
											error={surname === ""}
										/>
									</FormControl>

									<FormControl
										className={"signup-form-control"}
										fullWidth
									>
										<TextField
											type={'email'}
											id={'email'}
											label={t("individual.login.email_input")}
											placeholder={t("individual.login.email_input")}
											value={email}
											onChange={e => setEmail(e.target.value)}
											error={email === "" || !validateEmail(email)}
										/>
									</FormControl>

									<FormControl
										className={"signup-form-control"}
										fullWidth
									>
										<TextField
											type={'password'}
											id={'password'}
											label={t("individual.login.password_input")}
											placeholder={t("individual.login.password_input")}
											value={password}
											onChange={e => setPassword(e.target.value)}
											error={password === ""}
										/>
									</FormControl>

									<FormControl
										className={"signup-form-control"}
										fullWidth
									>
										<TextField
											type={'password'}
											id={'confirm_password'}
											label={t("individual.confirm_password_input")}
											placeholder={t("individual.confirm_password_input")}
											value={passwordConfirm}
											onChange={e => setPasswordConfirm(e.target.value)}
											error={passwordConfirm === "" || (passwordConfirm !== password)}
										/>
									</FormControl>

									<FormControl
										className={"signup-form-control"}
										fullWidth
									>
										<TextField
											type={"text"}
											onChange={event => setCountry(event.target.value)}
											label={capitalizeFirstLetter(t("individual.my_account.beneficiaries.country"))}
											variant="outlined"
											name={"country"}
											id={"country"}
											value={country}
											error={country === ""}
											fullWidth
											select
										>
											{CountryRegionData.map((option, index) => (
												<MenuItem key={option[1]} value={option[1]}>
													{option[0]}
												</MenuItem>
											))}
										</TextField>
									</FormControl>

									<FormControl
										className={"signup-form-control"}
										fullWidth
									>
										<TextField
											type={"text"}
											onChange={event => setGender(event.target.value)}
											label={t("individual.my_account.personal_information.gender")}
											variant="outlined"
											name={"gender"}
											id={"gender"}
											value={gender}
											error={gender === ""}
											fullWidth
											select
										>
											<MenuItem key={"Non-Binary"} value={"Non-Binary"}>
												{t("individual.gender_non_binary")}
											</MenuItem>
											<MenuItem key={"Male"} value={"Male"}>
												{t("individual.gender_male")}
											</MenuItem>
											<MenuItem key={"Female"} value={"Female"}>
												{t("individual.gender_female")}
											</MenuItem>
										</TextField>
									</FormControl>
									<FormControl
										className={"signup-form-control"}
										fullWidth
									>
										<MaskedInput
											mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
											placeholderChar={'\u2000'}
											placeholder={`${t("individual.birthdate")} *`}
											className={"signup-form-control"}
											label={t("individual.birthdate")}
											variant="outlined"
											name={"birthday"}
											id={"birthday"}
											value={dateOfBirth}
											onChange={event => setDateOfBirth(event.target.value)}
											pipe={createAutoCorrectedDatePipe("dd mm yyyy", {
												minYear: 1900, maxYear: new Date().getFullYear() - 16
											})}
										/>
									</FormControl>

									<FormControl
										className={"signup-form-control"}
										fullWidth
									>
										<Button
											variant={"contained"}
											onClick={() => acceptInvitation(code, {
												invitations: {
													forename,
													surname,
													password,
													email,
													country,
													gender,
													middle_name: middleName,
													password_confirmation: passwordConfirm,
													birthdate: dateOfBirth,
												}
											})}
											disabled={
												forename === "" ||
												surname === "" ||
												password === "" ||
												password !== passwordConfirm ||
												email === "" ||
												!validateEmail(email) ||
												dateOfBirth === "" ||
												country === "" ||
												gender === ""
											}
										>
											{t("individual.invitations.create_my_collegia_account_button")}
										</Button>
									</FormControl>

									<FormControl
										className={"signup-form-control"}
										fullWidth
									>
										<Button
											variant={"contained"}
											className={"button-danger"}
											onClick={() => setTermsAccepted(false)}
										>
											{t("individual.invitations.i_did_not_decide_yet")}
										</Button>
									</FormControl>
								</Grid>
							</Grid>
						)}
				</Grid>
			</Grid>
			<MinimalFooter t={t}/>
		</Wrapper>
	);
}

const mapStateToProps = state => state.Invitations;

const mapDispatchToProps = dispatch => ({
	findByCode: (code = "") => dispatch(getByCode(code)),
	rejectInvitation: (code = "") => dispatch(rejectInvitation(code)),
	acceptInvitation: (code = "", data = {}) => dispatch(acceptInvitation(code, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Invite);