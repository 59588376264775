import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

/**
 * @param props
 * @returns {boolean|*}
 * @private
 */
const _renderOnClickAction = (props = {
	customAction: () => {},
	customActionParams: () => {},
	backLink: "",
	history: {
		push: () => {},
	}
}): boolean | * => {

	if (typeof props?.customAction === "function") {
		return props?.customAction(props?.customActionParams);
	} else if (props?.history) {
		return props?.history(props.backLink);
	}


	return false;
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const BackIcons = (props = {
	showLeftArrow: false,
	customAction: () => {},
	customActionParams: false,
	backLink: "",
	history: {
		push: () => {},
	}
}): JSX.Element => {
	let icon;

	if (props.showLeftArrow) {
		icon = (
			<ChevronLeftIcon/>
		);
	} else {
		icon = (
			<CloseIcon/>
		);
	}

	return (
		<Grid
			xs={1}
			md={1}
			lg={1}
			sm={1}
			alignItems={"center"}
			alignContent={"center"}
			direction={"column"}
			justifyContent={"center"}
			container
			item
		>
			<Grid
				item
			>
				<IconButton
					onClick={() => _renderOnClickAction(props)}
					className={'header-close-button'}
					disableTouchRipple
					disableFocusRipple
					disableRipple
				>
					{icon}
				</IconButton>
			</Grid>
		</Grid>
	)
}

BackIcons.propTypes = {
	showLeftArrow: PropTypes.bool.isRequired,
	customActionParams: PropTypes.any.isRequired,
	backLink: PropTypes.string.isRequired,
	history: PropTypes.object.isRequired,
	customAction: PropTypes.any.isRequired,
}

export default BackIcons;