import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const AuthorizationInformation = (): JSX.Element => (
	<Grid
		xs={12}
		md={12}
		sm={12}
		xl={12}
		item
	>
		<Grid
			xs={12}
			md={12}
			sm={12}
			xl={12}
			item
		>
			<h3>
				Authorisation
			</h3>
			<Typography>
				For authorize Collegia retrieve your past employment data you'll need to log in into your
				HMRC portal and authorize our application retrieve this info from your
				HMRC portal information.
				<br/>
				<br/>
				Once you authorize us to retrieve this information we can retrieve data of your past employments
				for 18 months, after 18 months our access it'll be revoked and you'll need to authorize
				the Collegia application to gather this data again.
				<br/>
				<br/>
				For authorize Collegia application retrieve your previously employment
				please fill with your <a href={"https://www.gov.uk/find-lost-utr-number"}
				                         target={"_blank"}
				                         rel="noreferrer"
			>
				<strong>
					UTR (Unique Taxpayer Reference Number)
				</strong>
			</a> number and click in the button below.
			</Typography>
		</Grid>
	</Grid>
);

export default AuthorizationInformation;