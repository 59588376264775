import React from "react";

import Wrapper from "../../layout/Logged/Wrapper";
import Grid from '@mui/material/Grid';
import Slide from "@mui/material/Slide";
import Header from "../../components/YourPension/Header";
import {removeDefaultLoggedLayout, retrieveCollegiaAccessToken} from "../../utils/Helpers";
import NewContributionsTab from "../../components/NewContributions/NewContributionsTab";
import InsertTheMoneyInfo from "../../components/NewContributions/InsertTheMoneyInfo";
import ExpectedPensionIncomeCard from "../../components/YourPension/AdjustRetirementAge/ExpectedPensionIncomeCard";
import {myProjections} from "../../actions/Profile";
import {connect} from "react-redux";
import {clearPaymentObject, insert, retrieveBankList} from "../../actions/ExtraContributions";
import {simulation} from "../../actions/Projections";
import {withTranslation} from "react-i18next";

class NewContribution extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tabIndex: 0,
			step: "monthly",
			bankId: "",
			contributionValue: "",
			bankName: "",
			customer_ip: "",
		};
		this.timeout = 0;

		if (!retrieveCollegiaAccessToken()) {
			window.location = "/";
		}

		this.props.clearPaymentObject();
		this.props.myProjections(true);
	}

	componentDidMount() {
		removeDefaultLoggedLayout();
	}

	componentWillUnmount() {
		this.props.clearPaymentObject();
		this.props.myProjections();
	}

	/**
	 * @param event
	 * @param tabIndex
	 */
	#handleChange = (event: any, tabIndex: number = 0) => {
		if (tabIndex === 0) {
			this.props.getMyBankAccounts();
		} else {
			this.props.retrieveBankList();
		}
		this.setState({
			tabIndex,
			step: tabIndex > 0 ? "one-off" : "monthly",
		});
	};

	#_oneOffContribution = () => {
		const {
			step,
			bankId = "",
			bankName = "",
		} = this.state;
		let {
			contributionValue = 10,
		} = this.state;

		if (contributionValue > 9 && contributionValue !== "") {
			contributionValue = contributionValue.replace(/^0+/, '');

			const data = {
				contributions: {
					"amount": contributionValue,
					"redirect_uri": process.env.REACT_APP_TRUE_LAYER_PAYMENT,
					step,
					bankId,
					bankName,
				}
			};

			this.props.insert(data);
		}
	};

	/**
	 * @param bankList
	 * @param bankAccounts
	 * @returns {*[]}
	 * @private
	 */
	_retrieveAccountsList(bankList: any[] = false, bankAccounts: any[] = false): *[] {
		if (bankList.length !== 0) {
			return bankList
		} else if (bankAccounts.length !== 0) {
			return bankAccounts;
		}

		return [];
	}

	#doTheSimulation = (data: any[] = false) => {
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
		this.timeout = setTimeout(() => this.props.simulation(data), 300)
	};

	render() {
		const {
				tabIndex = 0,
				contributionValue = "",
				step = "monthly",
				bankId = "",
			} = this.state,
			{
				new_payment_object,
				bank_list = [],
				bank_accounts = [],
				my_projections = false
			} = this.props;
		let progress_level_classname = false,
			ruler_progress_classname = false;

		if (my_projections !== undefined) {
			progress_level_classname = my_projections.progress_level_classname;
			ruler_progress_classname = my_projections.ruler_progress_classname;
		}

		if (new_payment_object) {
			window.location.href = new_payment_object.aspspAuthUrl;
		}

		return (
			<Wrapper history={this.props.history}>
				<Slide in={true} direction={"left"}>
					<Grid
						xs={12}
						xl={12}
						md={12}
						lg={12}
						id={"new-contributions-wrapper"}
						container
					>
						<Grid
							spacing={0}
							direction={"column"}
							alignItems={"center"}
							alignContent={"center"}
							container
							item
							className={"intermediary-container"}
						>
							<Header
								history={this.props.history}
								title={this.props.t("individual.extra_contributions.new.how_much_add_question")}
								backLink={"/extra-contributions"}
								showLeftArrow
							/>
							<NewContributionsTab
								handleChange={this.#handleChange}
								tabIndex={tabIndex}
							/>
							<InsertTheMoneyInfo
								contributionValue={contributionValue ?? ""}
								component={this}
								history={this.props.history}
								step={step}
								my_projection={this.props.my_projections}
								oneOffContribution={this._oneOffContribution}
								simulation={this.#doTheSimulation}
								tabIndex={tabIndex ?? 0}
								bank_list={this._retrieveAccountsList(bank_list ?? [], bank_accounts ?? [])}
								selectedBankId={bankId}
								_renderBankList={this._renderBankList}
								timeout={this.timeout}
							/>
							<ExpectedPensionIncomeCard
								progress={this.props.my_projections.progress ?? 0}
								currentProgress={this.props.my_projections.progress ?? 0}
								totalYearlyIncome={this.props.my_projections.total_yearly_income ?? 0}
								progressLevelClassName={progress_level_classname ?? false}
								badge={this.props.my_projections.badge ?? false}
								rulerProgressClassName={ruler_progress_classname ?? false}
								retirementAge={[]}
								aliasClass
							/>
						</Grid>
					</Grid>
				</Slide>
			</Wrapper>
		);
	}
}

const mapStateToProps = state => {
	const {
		Profile = [],
		ExtraContributions = [],
	} = state;

	return {
		...Profile,
		...ExtraContributions,
	};
};

/**
 * @param dispatch
 * @returns {{myProjections: (function(): *), clearPaymentObject: (function(): *), insert: (function(*[]=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	myProjections: (scrollUp: boolean = false) => dispatch(myProjections(null, scrollUp)),
	insert: (data: any[] = false) => dispatch(insert(data)),
	clearPaymentObject: () => dispatch(clearPaymentObject()),
	simulation: (data: any[] = false) => dispatch(simulation(data)),
	retrieveBankList: () => dispatch(retrieveBankList()),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(NewContribution));