import PropTypes from "prop-types";

import Get from './Get';

const BeneficiariesRequests = {
	Get,
};

BeneficiariesRequests.propTypes = {
	Get: PropTypes.func.isRequired,
};

export default BeneficiariesRequests;