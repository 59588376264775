import Grid from '@mui/material/Grid';
import PensionPotProgress from "./../../MainPage/PensionTab/PensionPotProgress";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const AdjustRetirementRuler = (props): JSX.Element => (

	<Grid
		alignItems={"center"}
		alignContent={"center"}
		direction={"row"}
		item
		container
	>
		<Grid
			xs={12}
			lg={12}
			md={12}
			item
		>
			<PensionPotProgress
				comfort_ruler_projection={props.comfort_ruler_projection}
				projection={props.projection}
				progress={props.currentProgress}
				totalYearlyIncome={props.totalYearlyIncome}
				retirementAge={props.retirementAge}
				genericProjectionParameters={props.genericProjectionParameters}
				rulerProgressClassName={props.rulerProgressClassName ?? false}
				t={props.t}
			/>
		</Grid>
	</Grid>
);

export default AdjustRetirementRuler;