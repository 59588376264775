import {GET_COMFORT_RULER_PROJECTION_PARAMETERS} from "./types";
import ComfortRulerProjectionParameters from "../../services/ComfortRulerProjectionParameters";
import {forceScrollUp} from "../../utils/Helpers";

/**
 * @returns {function(...[*]=)}
 */
export const retrieveComfortRulerProjectionParameters = (scrollUp:boolean = false) => dispatch => {
	new ComfortRulerProjectionParameters()
		.active()
		.then(response => {
			dispatch({
				type: GET_COMFORT_RULER_PROJECTION_PARAMETERS,
				comfortRulerProjectionParameters: response.data.data,
			});

			if (scrollUp) {
				forceScrollUp();
			}
		})
		.catch(error => {
			console.error(error);
			if (error.response) {
				if (error.response.status === 401) {
					localStorage.clear();
					window.location = "/login";
				}
			}
		});
};