import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import Divider from "@mui/material/Divider";
import Edit from "../../images/edit-icon-white.svg";
import Bin from "../../images/trash-icon-white.svg";
import moment from "moment/moment";

/**
 * @param beneficiary
 * @param deleteBeneficiary
 * @param toggleFormModal
 * @param formModalOpen
 * @param setBeneficiaryTypeId
 * @param setBeneficiaryRelationshipTypeId
 * @param setName
 * @param setPostalCode
 * @param setTown
 * @param setCountry
 * @param setStreetName
 * @param setProportion
 * @param setAddress
 * @param setBirthdate
 * @param setPostCodeErrorText
 * @param setFoundAddress
 * @param setFormModalOpen
 * @param setId
 * @param find
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const BeneficiariesNewCard = (
	{
		beneficiary = [],
		deleteBeneficiary = () => {
		},
		toggleFormModal = () => {
		},
		formModalOpen = false,
		setBeneficiaryTypeId = () => {
		},
		setBeneficiaryRelationshipTypeId = () => {
		},
		setName = () => {
		},
		setPostalCode = () => {
		},
		setTown = () => {
		},
		setCountry = () => {
		},
		setStreetName = () => {
		},
		setProportion = () => {
		},
		setAddress = () => {
		},
		setBirthdate = () => {
		},
		setPostCodeErrorText = () => {
		},
		setFoundAddress = () => {
		},
		setFormModalOpen = () => {
		},
		setId = () => {
		},
		find = () => {
		},
		t = key => key,
	}
): JSX.Element => (
	<Grid
		xl={4.5}
		lg={5.5}
		md={5.5}
		sm={6.5}
		xs={8}
		item
		container
		id={'new-card'}
		marginRight={2}
		marginBottom={2}
		key={beneficiary?.id}
	>
		<Grid
			xl={12}
			lg={12}
			md={12}
			sm={12}
			xs={12}
			item
		>
			<Grid
				item
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				container
				className={"upper"}
			>
				<Grid
					item
					xl={6}
					lg={6}
					md={6}
					sm={6}
					xs={6}
					alignItems={"center"}
				>
					<Typography
						className={"name"}
					>
						{beneficiary.name}
					</Typography>
				</Grid>
				<Grid
					item
					xl={6}
					lg={6}
					md={6}
					sm={6}
					xs={6}
					alignSelf={"center"}
				>
					<Typography
						textAlign={"end"}
					>
						<img
							src={Edit}
							alt={"Edit"}
							className={"edit"}
							onClick={() =>
								toggleFormModal(
									formModalOpen,
									beneficiary?.id ?? null,
									setBeneficiaryTypeId,
									setBeneficiaryRelationshipTypeId,
									setName,
									setPostalCode,
									setTown,
									setCountry,
									setStreetName,
									setProportion,
									setAddress,
									setBirthdate,
									setPostCodeErrorText,
									setFoundAddress,
									setFormModalOpen,
									setId,
									find,
								)
							}
						/>
						<img
							src={Bin}
							alt={"Bin"}
							className={"trash"}
							onClick={() => deleteBeneficiary(beneficiary.id)}
						/>
					</Typography>
				</Grid>
			</Grid>
		</Grid>
		<Grid
			xl={12}
			lg={12}
			md={12}
			sm={12}
			xs={12}
			item
			direction={"column"}
			className={"body"}
		>

			{
				beneficiary.beneficiary_type_id === 1 &&
				<Grid
					item
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					container
				>
					<Grid
						item
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
					>
						<Typography
							className={"birthdate"}
						>
							{t("individual.my_account.personal_information.birthdate")}
						</Typography>
					</Grid>
					<Grid
						item
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
					>
						<Typography
							textAlign={"end"}
							className={"value"}
						>
							{moment(beneficiary?.birthdate).format("DD/MM/YYYY")}
						</Typography>
					</Grid>
				</Grid>
			}
			<Divider orientation={'horizontal'} flexItem variant={'fullWidth'}/>
			<Grid
				item
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				container
			>
				<Grid
					item
					xl={6}
					lg={6}
					md={6}
					sm={6}
					xs={6}
				>
					<Typography
						className={"birthdate"}
					>
						{t("individual.my_account.address.postal_code_input")}
					</Typography>
				</Grid>
				<Grid
					item
					xl={6}
					lg={6}
					md={6}
					sm={6}
					xs={6}
				>
					<Typography
						textAlign={"end"}
						className={"value"}
					>
						{beneficiary?.address?.postal_code}
					</Typography>
				</Grid>
			</Grid>
			<Divider orientation={'horizontal'} flexItem variant={'fullWidth'}/>
			<Grid
				item
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				container
			>
				<Grid
					item
					xl={6}
					lg={6}
					md={6}
					sm={6}
					xs={6}
				>
					<Typography
						className={"birthdate"}
					>
						{t("individual.my_account.beneficiaries.proportion")}
					</Typography>
				</Grid>
				<Grid
					item
					xl={6}
					lg={6}
					md={6}
					sm={6}
					xs={6}
				>
					<Typography
						textAlign={"end"}
						className={"value"}
					>
						{parseFloat(beneficiary?.proportion)}%
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	</Grid>
);


export default BeneficiariesNewCard;