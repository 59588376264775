import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
const getNonSignedMandatePromise = (): Promise<AxiosResponse<any>> => (
	Request.get("/profile/transactions/get-non-signed-mandates")
);

export default getNonSignedMandatePromise;