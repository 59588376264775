import React from "react";

import Wrapper from "../../layout/Logged/Wrapper";
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import {connect} from "react-redux";
import {removeDefaultLoggedLayout} from "../../utils/Helpers";
import Header from "../../components/YourPension/Header";
import Paper from "@mui/material/Paper";
import DDIHeaderImg from '../../images/CancelDirectDebitIlustration.svg';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {cancelDDI} from "../../actions/ExtraContributions";
import Loading from "../../components/Loading";
import {withRouter} from "../../utils/withRouter";

class ConfirmDirectDebitCancellation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: props.match.params && props.match.params.id ?
				props.match.params.id :
				false,
		};
	}

	componentDidMount() {
		removeDefaultLoggedLayout();
	}

	render() {
		const {
			payment_loading = false
		} = this.props;
		const { navigation } = this.props;

		if (payment_loading) {
			return (
				<Loading absolute/>
			);
		}

		return (
			<Wrapper>
				<Slide in={true} direction={"up"}>
					<div>
						<Grid
							direction={"column"}
							alignItems={"center"}
							alignContent={"center"}
							id={"extra-contribution-container"}
							container
							item
						>
							<Header
								history={navigation}
								title={"Stop contributions"}
								backLink={"/extra-contributions"}
								showLeftArrow
							/>
							<Paper
								id={"cancel-ddi-container"}
							>
								<Grid
									xs={12}
									md={12}
									sm={12}
									lg={12}
									alignContent={"center"}
									direction={"column"}
									id={"header-image-container"}
									item
									container
								>
									<img
										src={DDIHeaderImg}
										alt={"Do you really want to stop this contribution?"}
									/>
								</Grid>
								<Grid
									xs={12}
									md={12}
									sm={12}
									lg={12}
									alignContent={"center"}
									direction={"column"}
									id={"header-title"}
									item
									container
								>
									<Typography
										align={"center"}
										variant={"h5"}
									>
										Do you really want to stop this contribution?
									</Typography>
								</Grid>

								<Grid
									xs={12}
									md={12}
									sm={12}
									lg={12}
									alignContent={"center"}
									direction={"column"}
									id={"header-title"}
									item
									container
								>
									<Typography
										align={"center"}
										variant={"body1"}
									>
										If you want to change date or values, you can cancel it and make a new
										monthly or one-off contribution to improve your retirement lifestyle.
									</Typography>
								</Grid>
								<Grid
									xs={12}
									md={12}
									sm={12}
									lg={12}
									alignContent={"center"}
									direction={"column"}
									id={"buttons-container"}
									item
									container
								>
									<Button
										className={"cancel-ddi-button"}
										onClick={() => this.props.cancelDDI(this.state.id)}
									>
										YES, I WANT TO STOP IT
									</Button>
									<Button
										className={"cancel"}
										onClick={() => this.props.history("/extra-contributions")}
									>
										CANCEL
									</Button>
								</Grid>
							</Paper>
						</Grid>
					</div>
				</Slide>
			</Wrapper>
		);
	}
}


const mapStateToProps = state => {
	const {
		ExtraContributions = []
	} = state;

	return {
		...ExtraContributions
	};
}

const mapDispatchToProps = dispatch => ({
	cancelDDI: (orderId: string = "") => dispatch(cancelDDI(orderId ?? "")),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmDirectDebitCancellation));