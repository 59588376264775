import {useEffect} from 'react';

import {connect} from "react-redux";
import {logOut} from "../actions/Profile";
import Loading from "../components/Loading";

import * as SentryBrowser from "@sentry/browser";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const LogOut = (): JSX.Element => {
	useEffect(() => {
		window?.$crisp?.push(["do", "session:reset"]);
		SentryBrowser.setUser(null);
	}, []);

	return (
		<Loading absolute/>
	);
};

/**
 * @param dispatch
 * @returns {{logOut: (function(): *)}}
 */
const mapDispatchToProps = dispatch => ({
	logOut: dispatch(logOut()),
});

export default connect(null, mapDispatchToProps)(LogOut);