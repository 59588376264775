import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CardList from "./CardList";
import {getRandomInt} from "../../../../utils/Helpers";
import Button from "@mui/material/Button";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const SoftwareConnection = ({
	individual = {
		third_party_software_connections: [{
			id: null,
			software: {
				name: "",
				email: "",
			},
			accepted_at: "",
			deny_at: "",
			intermediary_token: "",
			active: false,
		}],
	},
	buttonActions = (code: string = "", action: string = "") => {
	},
	retrieveByToken = (token: string = "") => {
	},
	proceed = () => {
	},
	token = "",
}): JSX.Element => {
	return (
		<Grid
			xs={12}
			sm={12}
			md={12}
			lg={12}
			direction={"column"}
			alignContent={"center"}
			alignItems={"center"}
			id={"setup-software-connection-container"}
			item
			container
		>
			<Grid
				xs={12}
				sm={12}
				md={12}
				lg={12}
				className={"title-container"}
				item
			>
				<Typography variant={"h4"}>
					Your software connections request
				</Typography>
			</Grid>
			<Grid
				xs={12}
				sm={12}
				md={12}
				lg={12}
				className={"subtitle-container"}
				item
			>
				<Typography>
					Here's the list of software connection(s) request(s):
				</Typography>
			</Grid>
			{
				individual?.third_party_software_connections?.map(t => (
					<CardList
						key={t?.id ?? getRandomInt(0, 99999)}
						buttonActions={buttonActions}
						retrieveByToken={retrieveByToken}
						token={token}
						{...t}
					/>
				))
			}
			<Grid
				xs={12}
				sm={12}
				lg={12}
				md={12}
				direction={"column"}
				alignItems={"center"}
				alignContent={"center"}
				className={"continue-button"}
				item
				container
			>
				<Button
					variant={"contained"}
					fullWidth
					onClick={() =>
						proceed()
					}
					disabled={
						!individual.third_party_software_connections[0].accepted_at &&
						!individual.third_party_software_connections[0].deny_at
					}
				>
					CONTINUE
				</Button>
			</Grid>
		</Grid>
	);
}

export default SoftwareConnection;