import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {connect} from "react-redux";
import PersonalDetailsForm from "../components/SignUp/PersonalDetailsForm";
import LoginDetails from "../components/SignUp/LoginDetails";
import BodyText from "../components/Home/SetUpPage/TermsAndConditions/BodyText";
import GenericTermsAndConditions from "../components/GenericTermsAndConditions";
import {signUp, verifyEmail} from "../actions/SignUp";
import DefaultHeader from "../layout/Header/DefaultHeader";
import {useTranslation} from "react-i18next";
import {setCountry} from "../actions/Languages";
import {useNavigate} from "react-router-dom";

/**
 * @param date
 * @returns {string}
 * @private
 */
const _formatBirthday = (date) => {
	const day = date.split("/")[0];
	const month = date.split("/")[1];
	const year = date.split("/")[2];

	return year + '-' + ("0" + month).slice(-2) + '-' + ("0" + day).slice(-2);
}

/**
 * @param foreName
 * @param middleName
 * @param surname
 * @param birthdate
 * @param title
 * @param email
 * @param password
 * @param expanded
 * @param signUp
 * @returns {JSX.Element|*[]}
 * @private
 */
const proceedSignUp = (
	foreName: string = "",
	middleName: string = "",
	surname: string = "",
	birthdate: string = "",
	title: string = "",
	email: string = "",
	password: string = "",
	expanded: any = [],
	signUp: Function = () => {
	},
): JSX.Element => {
	expanded.shift();
	signUp({
		individuals: {
			forename: foreName,
			middle_name: middleName,
			surname: surname,
			birthdate: _formatBirthday(birthdate),
			title: title,
			email: email,
			password: password,
			read_terms: expanded,
		}
	});
};

/**
 * @param name
 * @param middleName
 * @param surname
 * @param birthday
 * @param title
 * @param titleOption
 * @param email
 * @param password
 * @param confirmPassword
 * @param expanded
 * @param stepIndex
 * @param sectionTitle
 * @param verify_email
 * @param setName
 * @param setMiddleName
 * @param setSurname
 * @param setBirthday
 * @param setTitle
 * @param setEmail
 * @param setPassword
 * @param setConfirmPassword
 * @param setExpanded
 * @param setStepIndex
 * @param setSectionTitle
 * @param verifyEmail
 * @param signUp
 * @param history
 * @param t
 * @returns {JSX.Element|*[]}
 * @private
 */
const _renderContent = (
	name: string = "",
	middleName: string = "",
	surname: string = "",
	birthday: string = "",
	title: string = "",
	titleOption: any = [],
	email: string = "",
	password: string = "",
	confirmPassword: string = "",
	expanded: any [] = [{}],
	stepIndex: number = 0,
	sectionTitle: string = "",
	verify_email: boolean = false,
	setName: Function = () => {
	},
	setMiddleName: Function = () => {
	},
	setSurname: Function = () => {
	},
	setBirthday: Function = () => {
	},
	setTitle: Function = () => {
	},
	setEmail: Function = () => {
	},
	setPassword: Function = () => {
	},
	setConfirmPassword: Function = () => {
	},
	setExpanded: Function = () => {
	},
	setStepIndex: Function = () => {
	},
	setSectionTitle: Function = () => {
	},
	verifyEmail: Function = () => {
	},
	signUp: Function = () => {
	},
	history: Function = () => {
	},
	t: Function = (key) => key,
): JSX.Element | *[] => {
	const proceedStep = () => {
		setStepIndex(stepIndex + 1);
	};
	const backStep = () => {
		setStepIndex(stepIndex - 1);
	};

	switch (stepIndex) {

		case 0:
			return (
				<PersonalDetailsForm
					name={name}
					surname={surname}
					middleName={middleName}
					birthday={birthday}
					title={title}
					titleOption={titleOption}
					setName={setName}
					setMiddleName={setMiddleName}
					setSurname={setSurname}
					setBirthday={setBirthday}
					setTitle={setTitle}
					history={history}
					proceedStep={proceedStep}
					t={t}
				/>
			);

		case 1:
			return (
				<LoginDetails
					email={email}
					password={password}
					confirmPassword={confirmPassword}
					verify_email={verify_email}
					setEmail={setEmail}
					setPassword={setPassword}
					setConfirmPassword={setConfirmPassword}
					verifyEmail={verifyEmail}
					history={history}
					proceedStep={proceedStep}
					backStep={backStep}
					t={t}
				/>
			);

		case 2:
			return (
				<Grid
					id={"signup-container"}
					xl={5.6}
					lg={5.8}
					md={5.8}
					sm={5.8}
					xs={11}
					justifyContent={"center"}
					item
					container
				>
					<Grid
						xs={12}
						md={12}
						lg={12}
						sm={12}
						item
						container
					>
						<BodyText t={t}/>
					</Grid>
					<GenericTermsAndConditions
						sectionTitle={sectionTitle}
						expanded={expanded ?? []}
						setExpanded={setExpanded}
						setSectionTitle={setSectionTitle}
						proceed={() => proceedSignUp(
							name,
							middleName,
							surname,
							birthday,
							title,
							email,
							password,
							expanded,
							signUp,
						)}
						history={history}
						t={t}
					/>
				</Grid>
			);

		default:
			return [];
	}
}

/**
 * @param verifyEmail
 * @param verify_email
 * @param signUp
 * @param setCountry
 * @param available_countries
 * @param selected_country
 * @param language
 * @returns {JSX.Element}
 * @constructor
 */
const SignUp = (
	{
		verifyEmail = () => {
		},
		verify_email = () => {
		},
		signUp = () => {
		},
		setCountry = (newCountry = "GB") => newCountry,
		available_countries = [],
		selected_country = "GB",
		language = "en",
	}
): JSX.Element => {

	const [name, setName] = useState(""),
		[middleName, setMiddleName] = useState(""),
		[surname, setSurname] = useState(""),
		[birthday, setBirthday] = useState(""),
		[email, setEmail] = useState(""),
		[password, setPassword] = useState(""),
		[confirmPassword, setConfirmPassword] = useState(""),
		[expanded, setExpanded] = useState([]),
		[stepIndex, setStepIndex] = useState(0),
		[sectionTitle, setSectionTitle] = useState(""),
		[title, setTitle] = useState(""),
		[titleOption] = useState([
			{title: 'Mr'},
			{title: 'Ms'},
			{title: 'Miss'},
			{title: 'Mrs'},
			{title: 'Sir'},
			{title: 'Dr'},
			{title: 'Lady'},
			{title: 'Lord'},
		]),
		{t, i18n} = useTranslation(),
		history = useNavigate();

	useEffect(() => {
		window.$crisp.push(["set", "session:data", [
			[
				["category", "Individual"],
				["trial_period", "null"]
			]
		]]);

		i18n.changeLanguage(language);
	}, [
		language,
		i18n,
	]);

	return (
		<Grid
			xl={12}
			lg={12}
			xs={12}
			md={12}
			sm={12}
			item
			container
			direction={"row"}
			justifyContent={"center"}
			alignContent={"flex-start"}
			id={"signup-wrapper"}
		>
			<DefaultHeader
				history={history}
				isLogin={true}
				language={language}
				setCountry={setCountry}
				available_countries={available_countries}
				selected_country={selected_country}
			/>
			{
				_renderContent(
					name,
					middleName,
					surname,
					birthday,
					title,
					titleOption,
					email,
					password,
					confirmPassword,
					expanded,
					stepIndex,
					sectionTitle,
					verify_email,
					setName,
					setMiddleName,
					setSurname,
					setBirthday,
					setTitle,
					setEmail,
					setPassword,
					setConfirmPassword,
					setExpanded,
					setStepIndex,
					setSectionTitle,
					verifyEmail,
					signUp,
					history,
					t
				)
			}
		</Grid>

	);
}

/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => {
	const {
		SignUp,
		Languages,
	} = state;

	return {
		...SignUp,
		...Languages,
	}
};

/**
 * @param dispatch
 * @returns {{checkEmailAddress: (function(*[]=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	verifyEmail: (data: any[] = false, proceedStep: any[] = false) =>
		dispatch(verifyEmail(data ?? false, proceedStep ?? false)),
	signUp: (data: any[] = false) => dispatch(signUp(data ?? false)),
	setCountry: (newCountry = "GB") => dispatch(setCountry(newCountry, null, false)),
});

export default connect(mapStateToProps ?? {}, mapDispatchToProps ?? {})(SignUp);