import React, {useEffect} from 'react';
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import AccountCircleIcon from "@mui/icons-material/AccountCircleRounded";
import {connect} from "react-redux";
import ListItemButton from "@mui/material/ListItemButton";
import CloseMenuIcon from "../../images/main-menu/close-menu.svg";
import DataSharingPermissionsIcon from "../../images/main-menu/data-sharing-permissions.svg";
import LegalIcon from "../../images/main-menu/legal.svg";
import YourDocuments from "../../images/main-menu/your-documents.svg";
import LogoutIcon from "../../images/main-menu/logout.svg";
import MyAccountIcon from "../../images/main-menu/my-account.svg";
import MyDashboardIcon from "../../images/main-menu/my-dashboard.svg";
import ReferYourEmployerIcon from "../../images/main-menu/refer-your-employer.svg";
import {capitalizeFirstLetter} from "../../utils/Helpers";

/**
 * @private
 * @param profile_data
 */
const renderAvatar = (profile_data: any[] = false) => {
	if (profile_data && profile_data?.avatar) {
		return (
			<img
				src={profile_data.avatar}
				alt={profile_data.forename}
				className={"drawer-profile-picture"}
			/>
		)
	}
	return <AccountCircleIcon/>;
}

/**
 * @param profile_data
 * @param t
 */
const renderMenuOptions = (profile_data: any = [], t=key=>key,) => {

	let myAccountLink = window.screen.width < 1200 ? "/my-account" : "/my-account/personal-information";
	
	let menu = [
		{
			text: t("individual.right_navigation_menu.my_dashboard"),
			link: '/',
			external: false,
			icon: MyDashboardIcon,
		},
		{
			text: t("individual.right_navigation_menu.my_account"),
			link: myAccountLink,
			external: false,
			icon: MyAccountIcon,
		},
		{
			text: t("individual.mobile.side_menu.refer_your_employer"),
			link: "/refer-employer",
			external: false,
			icon: ReferYourEmployerIcon,
		},
		{
			text: capitalizeFirstLetter(t("individual.data_sharing_permissions")),
			link: "/software-connections",
			external: false,
			icon: DataSharingPermissionsIcon,
		},
		{
			text: t("individual.mobile.side_menu.your_documents"),
			link: "/your-documents",
			external: false,
			icon: YourDocuments,
		},
		{
			text: t("individual.right_navigation_menu.legal"),
			link: "https://www.collegia.co.uk/legal",
			external: true,
			icon: LegalIcon,
		},
		{
			text: t("individual.right_navigation_menu.log_out"),
			link: "/logout",
			external: false,
			icon: LogoutIcon,
		},
	];

	if (!profile_data?.register_completed) {
		menu = [
			{
				text: 'Log out',
				link: "/logout",
				external: false,
			},
		];
	}

	return menu;
}

/**
 * @param params
 * @returns {{href: string, target: string}|{onClick: (function(): *)}}
 * @private
 */
const renderTextLinks = (
	params: {
		external: boolean,
		link: string
	} = {
		external: false,
		link: "",
	},
) => {
	if (params) {
		if (params.external) {
			return {
				onClick: () => window.open(params.link, '_blank').focus()
			}
		}

		return {
			onClick: () => window.location.href = params.link
		};
	}
};

/**
 * @param drawer
 * @param profile_data
 * @param handleDrawer
 * @param returnUser
 * @param history
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const RightLoggedNavigation = (
	{
		drawer = false,
		profile_data = [],
		handleDrawer = () => {
		},
		returnUser = () => {
		},
		history = () => {
		},
		t = (key) => key,
}): JSX.Element => {
	useEffect(() => {
		returnUser();
	}, [
		returnUser,
	]);

	return (
		<Drawer
			id={"drawer-style"}
			anchor={"right"}
			open={drawer}
			onClose={() => handleDrawer()}
		>
			<Grid
				className={"drawer-main-grid"}
				container
			>
				<Grid
					container
					justifyContent={"flex-end"}
					padding={2}
				>
					<IconButton
						onClick={() => handleDrawer()}
						disableTouchRipple
						disableFocusRipple
						disableRipple
					>
						<img
							src={CloseMenuIcon}
							alt={""}
							width={"12.9px"}
							height={"12.9px"}
						/>
					</IconButton>
				</Grid>
				<Grid
					container
					item
					alignContent={"center"}
					alignItems={"center"}
					className={"second-grid-style"}
					direction={"column"}
				>
					<Grid
						alignContent={"center"}
						alignItems={"center"}
						direction={"row"}
						container
						item
						spacing={3}
						paddingLeft={"45px"}
						className={"grid-style-margin"}
					>
						<Grid
							item
						>
							{renderAvatar(profile_data ?? false)}
						</Grid>
						<Grid
							item
						>
							<Typography
								variant={"subtitle1"}
								align={"center"}
								className={"drawer-user-name-style"}
							>
								{profile_data?.forename + " " + profile_data?.surname}
							</Typography>
						</Grid>
					</Grid>
					<Grid
						container
						direction={"column"}
						className={"align-grid"}
					>
						<List>
							<Grid
								xs={12}
								sm={12}
								md={12}
								lg={12}
								direction={"row"}
								item
								container
								marginTop={4}
							>
								<Grid
									xs={12}
									sm={12}
									md={12}
									lg={12}
									item
								>
									{
										renderMenuOptions(profile_data, t).map((links, index) => (
											<ListItemButton
												key={index}
												{...renderTextLinks(links, history)}
											>
												<Grid
													xs={12}
													sm={12}
													md={12}
													lg={12}
													direction={"row"}
													alignContent={"center"}
													alignItems={"center"}
													item
													container
													marginBottom={4}
													spacing={2}
												>
													<Grid
														item
													>
														<img
															src={links.icon}
															alt={""}
															width={"19.91px"}
															height={"18.87px"}
														/>
													</Grid>
													<Grid
														item
													>
														<Typography
															className={"text-list-style"}
														>
															{links.text}
														</Typography>
													</Grid>
												</Grid>
											</ListItemButton>
										))
									}
								</Grid>
							</Grid>
						</List>
					</Grid>
				</Grid>
			</Grid>
		</Drawer>
	);
}

const mapStateToProps = (state = {
	Profile: [],
}) => {
	const {
		Profile = []
	} = state;

	return {
		...Profile
	};
}

export default connect(mapStateToProps, null)(RightLoggedNavigation);