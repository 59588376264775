import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {capitalizeFirstLetter, formatMoney} from "../../utils/Helpers";
import moment from "moment";
import CompanyBlack from "../../images/company-black.svg";
import CompanyGray from "../../images/company-gray.svg";
import arrow from "../../images/arrow-down-culture.svg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Divider from "@mui/material/Divider";

/**
 * @param trial_period
 * @param recurring_day
 * @param transaction
 * @param details
 * @param t
 * @returns {JSX.Element|string}
 */
function renderContributions(
	trial_period = false,
	recurring_day = 0,
	transaction = {
		total_contribution: 0,
		created_at: "",
	},
	details = false,
	t = key => key,
): JSX.Element|string {

	let total = formatMoney(transaction?.total_contribution ?? 0, 2);
	if (details) {
		return total
	}
	let transactionDate = moment(transaction?.created_at);
	const futureContribution = moment(transactionDate).add(1, 'M');

	let currentDate = new Date();
	let month = new Date();

	// if the current day is after the employer's recurring day add a month to the next contribution date
	if (recurring_day - currentDate.getDate() < 0) {
		month = moment(month).add(1, 'M');
	}

	if (transaction && transactionDate && futureContribution && recurring_day > 0) {
		return (
			<span dangerouslySetInnerHTML={{
				__html: t("individual.ae.cards.next_contribution_on", {
					value: total,
					date: moment(month).format("MMM"),
					recurring_day
				})
			}}
			/>
		);
	}

	return "";
}


/**
 * @param employers
 * @param profile_data
 * @param history
 * @param t
 * @returns {false|unknown[]}
 * @constructor
 */
const EmployersCard = (
	{
		employers = [],
		profile_data = {},
		history = {},
		t = key => key,
	}
) => {

	return (
		employers.length > 0 && (
			employers.map((data) =>
				<Grid
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
					item
					container
					marginBottom={2}
					className={"employer-card"}
					key={data?.id}
				>
					<Grid
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
						item
						container
						justifyContent={"space-between"}
					>
						<img
							alt={data?.active ? 'company-black' : 'company-gray'}
							src={data?.active ? CompanyBlack : CompanyGray}
						/>
						<Typography
							className={data?.active ? 'active-text' : 'inactive-text'}
						>
							{data?.active ?
								t("individual.ae.cards.active_connection")
								:
								t("individual.ae.cards.inactive_label")
							}
							<Grid
								className={data?.active ? 'active' : 'inactive'}
							/>
						</Typography>
					</Grid>
					<Grid
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
						item
						textAlign={"start"}
					>
						<Typography
							className={data?.active ? "company-title" : "company-title-inactive"}
						>
							{data?.employer?.title}
						</Typography>
						<Typography
							className={data?.active ? "next-contribution" : "next-contribution-inactive"}
						>
							{
								data?.active ?
									renderContributions(
										profile_data?.trial_period,
										data?.employer?.contribution_date,
										data?.employer?.papdis[0]?.transaction,
										false,
										t
									)
									:
									data?.status === 'Opted-Out' ?
										t("individual.ae.cards.opt_information")
										:
										t("individual.ae.cards.disconnected_from_employer")

							}
						</Typography>
					</Grid>
					<Grid
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
						item
						textAlign={"start"}
						display={"inline-flex"}
						paddingTop={"10px"}
					>
						<Accordion>
							<AccordionSummary
								expandIcon={
									<img
										className={'img-mobile-arrow'}
										src={arrow}
										alt={"Arrow Icon"}
									/>
								}
								className={"acordion-summary"}
								disabled={!data?.active}
							>
								<Typography
									className={"details"}
								>
									{t("individual.connection_details.title")}
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid
									xs={12}
									sm={12}
									md={12}
									lg={12}
									xl={12}
									item
									container
									justifyContent={"space-between"}
									paddingBottom={"10px"}
								>
									<Typography
										className={"next-contribution"}
									>
										{t("individual.connection_details.employer_id")}
									</Typography>
									<Typography
										className={"next-contribution"}
									>
										{data?.employer?.code}
									</Typography>
								</Grid>
								<Divider/>
								<Grid
									xs={12}
									sm={12}
									md={12}
									lg={12}
									xl={12}
									item
									container
									justifyContent={"space-between"}
									paddingBottom={"10px"}
									paddingTop={"10px"}

								>
									<Typography
										className={"next-contribution"}
									>
										{capitalizeFirstLetter(t("individual.extra_contributions.monthly.title"))}
									</Typography>
									<Typography
										className={"next-contribution"}
									>
										£ <span>
										{
											renderContributions(
												profile_data?.trial_period,
												data?.employer?.contribution_date,
												data?.employer?.papdis[0]?.transaction,
												true,
												t,
											)
										}
										</span>
									</Typography>
								</Grid>
								<Divider/>
								<Grid
									xs={12}
									sm={12}
									md={12}
									lg={12}
									xl={12}
									item
									container
									justifyContent={"space-between"}
									paddingBottom={"10px"}
									paddingTop={"10px"}
								>
									<Typography
										className={"next-contribution"}
									>
										{t("individual.connection_details.connected_since")}
									</Typography>
									<Typography
										className={"next-contribution"}
									>
										{moment(data?.created_at ?? new Date()).format("D/MM/Y")}
									</Typography>
								</Grid>
								<Divider/>
								<Grid
									xs={12}
									sm={12}
									md={12}
									lg={7}
									xl={7}
									item
									container
									paddingTop={"10px"}
									justifyContent={"space-between"}
								>
									<Grid
										xs={5.8}
										sm={5.8}
										md={5.8}
										lg={5.8}
										xl={5.8}
										item
										className={"button-history"}
										textAlign={"center"}
										onClick={() => history(`/contributions-history/${data?.employer?.code}`)}
									>
										{t("individual.contribution_history.title")}
									</Grid>
									<Grid
										xs={5.8}
										sm={5.8}
										md={5.8}
										lg={5.8}
										xl={5.8}
										item
										className={"button-opt"}
										textAlign={"center"}
										onClick={() => history(`/opt-out/employer/${data?.employer?.code}`)}
									>
										{capitalizeFirstLetter(t("individual.i_want_to_opt_out").toLowerCase())}
									</Grid>
								</Grid>
							</AccordionDetails>
						</Accordion>
					</Grid>
				</Grid>
			)
		)
	)
};


export default EmployersCard;