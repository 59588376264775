import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import CountUp from "react-countup";
import {
	setExpectPensionPotClassName,
	setLifestyleOpacity
} from "../../../utils/Helpers";
import AdjustRetirementRuler from "./AdjustRetirementRuler";

// importing the status icons
import comfortableTree from "../../../images/confortable-tree.svg";
import moderateTree from "../../../images/moderate-tree.svg";
import minimumLeaf from "../../../images/minimum-leaf.svg";
import StillNotThereIcon from '../../../images/StillNotThereIcon.svg';

/**
 * @param option
 * @param props
 * @returns {JSX.Element}
 * @private
 */
const _switchBadges = (option: number = 0, props: any[] = {
	t: key => key,
}): JSX.Element => {
	switch (option) {
		default:
			return (
				<Grid
					md={4}
					lg={4}
					xs={4}
					alignItems={"flex-end"}
					alignContent={"flex-end"}
					className={"minimum-container"}
					item
					container
				>
					<Grid
						xs={12}
						md={12}
						lg={12}
						alignContent={"center"}
						alignItems={"center"}
						direction={"column"}
						item
						container
					>
						<img
							className={`comfortable-icons still-not-there ${setLifestyleOpacity(
								"still-not-there",
								props?.currentProgress,
								true,
								props
							)}`}
							src={StillNotThereIcon}
							alt={props.t("individual.still_not_there")}
						/>
					</Grid>
					<Grid
						xs={12}
						md={12}
						lg={12}
						alignContent={"center"}
						alignItems={"center"}
						direction={"column"}
						item
						container
					>
						<Typography
							variant={"caption"}
							align={"center"}
							className={`${setLifestyleOpacity(
								"still-not-there",
								props?.currentProgress,
								true,
								props
							)}`}
						>
							<strong>
								{props.t("individual.still_not_there")}
							</strong>
						</Typography>
					</Grid>
				</Grid>
			);

		case 1:
			return (
				<Grid
					md={4}
					lg={4}
					xs={4}
					alignItems={"flex-end"}
					alignContent={"flex-end"}
					className={"minimum-container"}
					item
					container
				>
					<Grid
						xs={12}
						md={12}
						lg={12}
						alignContent={"center"}
						alignItems={"center"}
						direction={"column"}
						item
						container
					>
						<img
							className={`comfortable-icons ${setLifestyleOpacity(
								"minimum",
								props?.currentProgress,
								true,
								props
							)}`}
							src={minimumLeaf}
							alt={props.t("individual.lifestyle_ruler.minimum")}
						/>
					</Grid>
					<Grid
						xs={12}
						md={12}
						lg={12}
						alignContent={"center"}
						alignItems={"center"}
						direction={"column"}
						item
						container
					>
						<Typography
							variant={"caption"}
							align={"center"}
							className={`${setLifestyleOpacity(
								"minimum",
								props?.currentProgress,
								true,
								props
							)}`}
						>
							<strong>
								{props.t("individual.lifestyle_ruler.minimum")}
							</strong>
						</Typography>
					</Grid>
				</Grid>
			);

		case 2:
			return (
				<Grid
					md={4}
					lg={4}
					xs={4}
					alignItems={"flex-end"}
					alignContent={"flex-end"}
					className={"moderate-container"}
					item
					container
				>
					<Grid
						xs={12}
						md={12}
						lg={12}
						alignContent={"center"}
						alignItems={"center"}
						direction={"column"}
						item
						container
					>
						<img
							className={`comfortable-icons ${setLifestyleOpacity(
								"moderate",
								props?.currentProgress,
								true,
								props
							)}`}
							src={moderateTree}
							alt={props.t("individual.lifestyle_ruler.moderate")}
						/>
					</Grid>
					<Grid
						xs={12}
						md={12}
						lg={12}
						alignContent={"center"}
						alignItems={"center"}
						direction={"column"}
						item
						container
					>
						<Typography
							variant={"caption"}
							align={"center"}
							className={`${setLifestyleOpacity(
								"moderate",
								props?.currentProgress,
								true,
								props
							)}`}
						>
							<strong>
								{props.t("individual.lifestyle_ruler.moderate")}
							</strong>
						</Typography>
					</Grid>
				</Grid>
			);

		case 3:
			return (
				<Grid
					md={4}
					lg={4}
					xs={4}
					alignItems={"flex-end"}
					alignContent={"flex-end"}
					className={`comfortable-icons ${setLifestyleOpacity(
						"comfortable",
						props?.currentProgress,
						true,
						props
					)}`}
					item
					container
				>
					<Grid
						xs={12}
						md={12}
						lg={12}
						alignContent={"center"}
						alignItems={"center"}
						direction={"column"}
						item
						container
					>
						<img
							className={"comfortable-icons"}
							src={comfortableTree}
							alt={props.t("individual.lifestyle_ruler.comfortable")}
						/>
					</Grid>
					<Grid
						xs={12}
						md={12}
						lg={12}
						alignContent={"center"}
						alignItems={"center"}
						direction={"column"}
						item
						container
					>
						<Typography
							variant={"caption"}
							align={"center"}
							className={`${setLifestyleOpacity(
								"comfortable",
								props?.currentProgress,
								true,
								props
							)}`}
						>
							<strong>
								{props.t("individual.lifestyle_ruler.comfortable")}
							</strong>
						</Typography>
					</Grid>
				</Grid>
			);
	}
}

/**
 * @param props
 * @returns {JSX.Element}
 */
function setCurrentStateBadge(props): JSX.Element {
	const {
		progress = 0
	} = props;

	if (progress >= 0 && progress <= 23.61) {
		return _switchBadges(0, props ?? false);
	} else if (progress >= 23.62 && progress < 46.76) {
		return _switchBadges(1, props ?? false);
	} else if (progress >= 46.76 && progress < 76.39) {
		return _switchBadges(2, props ?? false);
	} else {
		return _switchBadges(3, props ?? false);
	}
}

/**
 * @param props
 * @returns {JSX.Element}
 * @private
 */
function _yearlyIncome(props = false): JSX.Element {

	let totalYearlyIncome = props.totalYearlyIncome ?? 0,
		shouldRemoveComma = false;

	if (typeof totalYearlyIncome.indexOf === "function") {
		shouldRemoveComma = totalYearlyIncome.indexOf(",") <= 1;
	}

	if (props.totalYearlyIncome) {
		if (typeof props.totalYearlyIncome.replace === "function") {
			if (shouldRemoveComma) {
				totalYearlyIncome = props.totalYearlyIncome.replace(",", '');
			} else if (typeof totalYearlyIncome.replace === "function") {
				totalYearlyIncome = totalYearlyIncome.replace(/^0+/, '');
				totalYearlyIncome = totalYearlyIncome.replace(",", '');
			}
		} else {
			totalYearlyIncome = props.totalYearlyIncome;
		}
	}

	totalYearlyIncome = parseFloat(totalYearlyIncome);

	if (totalYearlyIncome <= -1) {
		totalYearlyIncome = Math.abs(totalYearlyIncome);
	}


	return (
		<Grid
			xs={12}
			md={12}
			lg={12}
			sm={12}
			item
			container
		>
			<Grid
				xs={8}
				sm={8}
				md={8}
				lg={8}
				item
				container
			>
				<CountUp
					start={0}
					end={totalYearlyIncome}
					separator=","
					decimals={2}
					decimal="."
					duration={1}
					prefix={`£ `}
				/>
				<span className={'per-month-show'}>
                    / {props.t("individual.year")}
                </span>
			</Grid>
			{setCurrentStateBadge(props)}
		</Grid>
	);
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ExpectedPensionIncomeCard = (props): JSX.Element => (
	<Card
		id={'expected-pension-pot-card-container'}
		className={"collegia-border"}
		elevation={0}
	>
		<CardHeader
			className={setExpectPensionPotClassName(props.progress, false, true, props)}
			title={props.t("individual.expected_pension_income")}
			subheader={_yearlyIncome(props)}
		/>
		<CardContent
			className={"comfortable-card-container"}
		>
			<AdjustRetirementRuler
				{...props}
			/>
		</CardContent>
	</Card>
);

export default ExpectedPensionIncomeCard;
