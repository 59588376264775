import GenericProjectionParameters from "../../services/GenericProjectionParameters";
import {GET_GENERIC_PROJECT_PARAMETER} from "./types";
import {returnUser} from "../Profile";

/**
 * @param reloading
 * @returns {function(...[*]=)}
 */
export const retrieveGenericProjectionParameters = (reloading = true) => dispatch => {
	new GenericProjectionParameters()
		.active()
		.then(response => {
			dispatch({
				type: GET_GENERIC_PROJECT_PARAMETER,
				genericProjectionParameters: response.data.data,
			});
			if (reloading) {
				dispatch(returnUser());
			}
		})
		.catch(error => {
			console.error(error);
			if (error.response) {
				if (error.response.status === 401) {
					localStorage.clear();
					window.location = "/login";
				}
			}
		});
};