import {
	GET_ALL_TRANSACTIONS,
	GET_ALL_YEARS,
	GET_TRANSACTIONS
} from "./types";
import Transactions from "../../services/Transactions";

/**
 * @returns {function(...[*]=)}
 * @param date
 */
export const getContributionByDate = (date:string = "") => dispatch => {
	dispatch({
		type: GET_TRANSACTIONS,
		transaction_loading: true,
	});

	new Transactions()
		.getContributionByDate(date)
		.then(response => {
			dispatch({
				type: GET_TRANSACTIONS,
				transaction_loading: false,
				data: response.data.data,
			});
		})
		.catch(error => {
			if (error.response) {
				if (error.response.status === 401) {
					localStorage.clear();
					window.location = "/login";
				}
			}
			dispatch({
				type: GET_TRANSACTIONS,
				transaction_loading: false,
			});
			console.error(error);
		});
};

/**
 * @returns {function(...[*]=)}
 */
export const all = () => dispatch => {
	new Transactions()
		.all()
		.then(response => {
			dispatch({
				type: GET_ALL_TRANSACTIONS,
				transactions: response.data.data,
			});
		})
		.catch(error => {

			console.error(error);
			if (error.response) {
				if (error.response.status === 401) {
					localStorage.clear();
					window.location = "/login";
				}
			}
		});
};

export const getContributionsHistoryYears = (data) => dispatch => {
	dispatch({
		type: GET_ALL_YEARS,
		years_loading: true,
	});
	new Transactions()
		.getContributionsHistoryYears(data)
		.then(response => {
			dispatch({
				type: GET_ALL_YEARS,
				years_loading: false,
			});
			dispatch({
				type: GET_ALL_TRANSACTIONS,
				years_contributions_history: response.data.data,
			});
		})
		.catch(error => {
			dispatch({
				type: GET_ALL_YEARS,
				years_loading: false,
			});
			console.error(error);
			if (error.response) {
				if (error.response.status === 401) {
					localStorage.clear();
					window.location = "/login";
				}
			}
		});
};