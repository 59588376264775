import React from "react";

import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

// importing the icons
import activePoundIcon from '../../../images/OtherPensionActive.svg';
import inactivePoundIcon from '../../../images/OtherPensionInactive.svg';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';


/**
 * @param props
 * @returns {string}
 * @private
 */
function _renderHeaderClassName(props: any = false): string {
	if (props) {
		if (props.other_pensions) {
			return props.other_pensions.length > 0 ? "active" : "inactive";
		}
	}

	return "inactive";
}

/**
 * @param props
 * @returns {string}
 * @private
 */
function _renderSubHeaderInfo(props: any[] = {
	other_pensions: [],
	t: key => key,
}) {
	if (props) {
		if (props.other_pensions && props.other_pensions.length > 0) {
			return `${props.other_pensions.length} Included`;
		}
	}

	return props.t("individual.tap_to_include");
}

/**
 * @param props
 * @returns {string}
 * @private
 */
function _renderPensionValues(props: any[] = {
	other_pensions: [{
		formatted_sum_total: 0,
		formatted_total: 0,
	}],
}) {
	if (props) {
		if (props.other_pensions && props.other_pensions.length > 0) {
			return `£ ${props.other_pensions[0].formatted_total}`;
		}
	}
	return "N/A";
}

/**
 * @stateless
 * @param props
 * @returns {*}
 */
const OtherPensionsCard = (props = {
	t: key => key
}) => (
	<Card
		id={"extra-contributions-card"}
		onClick={() => props.history("/other-pensions")}
	>
		<CardHeader
			className={_renderHeaderClassName(props)}
			action={
				<IconButton
					aria-label="settings"
					disableTouchRipple
					disableFocusRipple
					disableRipple
				>
					<KeyboardArrowRightIcon/>
				</IconButton>
			}
			title={props.t("individual.other_pensions.title")}
			subheader={_renderSubHeaderInfo(props)}
		/>
		<CardContent>
			<Grid
				xs={12}
				sm={12}
				md={12}
				lg={12}
				item
				container
			>
				<Grid
					className={`${_renderHeaderClassName(props)} line-padding`}
					xs={6}
					sm={6}
					md={6}
					lg={6}
					item
					container
				>
					<Grid
						xs={1}
						sm={1}
						md={1}
						lg={1}
						item
					>
						<TrackChangesIcon className={`lock-icon ${_renderHeaderClassName(props)}`}/>
					</Grid>
					<Grid
						xs={10}
						sm={10}
						md={10}
						lg={10}
						item
					>
                        <span
	                        className={_renderHeaderClassName(props)}
	                        style={{
		                        paddingLeft: "5px",
	                        }}
                        >
                            {_renderPensionValues(props)}
                        </span>
					</Grid>
				</Grid>
				<Grid
					xs={6}
					sm={6}
					md={6}
					lg={6}
					alignItems={"flex-end"}
					alignContent={"flex-end"}
					direction={"column"}
					item
					container
				>
					<img
						src={props.other_pensions.length > 0 ? activePoundIcon : inactivePoundIcon}
						alt={props.t("individual.other_pensions.title")}
					/>
				</Grid>
			</Grid>
		</CardContent>
	</Card>
);

export default OtherPensionsCard;