import EmployersService from "../../services/Employers";
import {
	FETCH_EMPLOYER_DATA,
	SEARCH_EMPLOYER,
} from "./type";

/**
 * @param code
 * @returns {function(*): void}
 */
export const employerInfo = (code:string = ""): void => dispatch => {
	dispatch({
		type: FETCH_EMPLOYER_DATA,
		employer_data: {},
		employer_loading: true,
	});
	if (code !== "") {
		new EmployersService()
			.employerInfo(code)
			.then(response => dispatch({
				type: FETCH_EMPLOYER_DATA,
				employer_data: response?.data?.data ?? {},
				employer_loading: false,
			}))
			.catch(error => {
				console.error(error);
				if (error.response) {
					if (error.response.status === 401) {
						localStorage.clear();
						window.location = "/login";
					}
				}
				dispatch({
					type: FETCH_EMPLOYER_DATA,
					employer_data: {},
					employer_loading: false,
				})
			});
	}
};

/**
 * @param data
 * @returns {function(*): void}
 */
export const optOut = (data: any = false): void => dispatch => {
	dispatch({
		type: SEARCH_EMPLOYER,
		employer_loading: true,
	});
	new EmployersService()
		.optOut(data)
		.then(response => {
			if (response.data.data) {
				window.location = `/auto-enrolment`;
				dispatch({
					type: SEARCH_EMPLOYER,
					employer_loading: false,
				});
			}
			dispatch({
				type: SEARCH_EMPLOYER,
				employer_loading: false,
			});
		})
		.catch(error => {

			if (error.response) {
				if (error.response.status === 401) {
					localStorage.clear();
					window.location = "/login";
				}
			}
			dispatch({
				type: SEARCH_EMPLOYER,
				employer_loading: false,
			});

			console.error(error);
		});
};

/**
 * @param params
 * @returns {function(*): void}
 */
export const searchEmployer = (params:any[] = false): void => dispatch => {
	if (!params) {
		dispatch({
			search_loading: false,
			type: SEARCH_EMPLOYER,
			searchTrigger: false,
		});
	} else {
		dispatch({
			search_loading: true,
			type: SEARCH_EMPLOYER,
			searchTrigger: false,
		});
	}

	if (params) {
		new EmployersService()
			.search(params ?? false)
			.then(response => {
				if (response.data.data) {
					const {
						companies
					} = response.data.data;

					if (companies) {
						const {
							items
						} = companies;
						dispatch({
							type: SEARCH_EMPLOYER,
							search_results: items,
							search_loading: false,
							searchTrigger: true,
						})
					}
				}
			})
			.catch(error => console.error(error));
	}
};

/**
 * @param data
 * @returns {function(*): void}
 */
export const createLead = (data:any = false): void => dispatch => {
	dispatch({
		type: FETCH_EMPLOYER_DATA,
		employer_loading: true,
	});
	if (data) {
		new EmployersService()
			.createLead(data ?? false)
			.then(response => {
				if(response.data.data) {
					dispatch({
						type: FETCH_EMPLOYER_DATA,
						showModal: true,
						employer_loading: false,
					});
				}
			})
			.catch(error => console.error(error));
	}
}