import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Icon from "../images/Grupo 6844.svg";
import {useNavigate} from "react-router-dom";
import {DialogContent} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";


/**
 * @param open
 * @param setOpen
 * @param link
 * @returns {JSX.Element}
 * @constructor
 */
const NewModalDesign = (
	{
		open = false,
		setOpen = () => {},
		link = "",
	}
): JSX.Element => {

	const push = useNavigate();

	return (
		<Grid
			sm={12}
			xl={12}
			lg={12}
			md={12}
			xs={12}
			item
			container
		>
			<Dialog
				id={"main-page-modal"}
				open={open}
			>
				<DialogTitle>
					<Grid
						lg={12}
						xl={12}
						sm={12}
						md={12}
						textAlign={"right"}
						direction={"column"}
						alignSelf={"flex-end"}
						alignContent={"flex-end"}
						alignItems={"flex-end"}
						container
						item
					>
						<Button
							onClick={() => push("/logout")}
							variant={"outlined"}
							className={"close-model"}
							disableRipple
						>
							x
						</Button>
					</Grid>
					<Grid
						item
						lg={12}
						xl={12}
						sm={12}
						md={12}
						textAlign={"center"}
						marginTop={5}
						marginBottom={2}
						marginRight={2}
					>
						<img
							alt={""}
							src={Icon}
						/>

						<Grid
							lg={12}
							xl={12}
							sm={12}
							md={12}
							item
						>
							<Typography
								align={"center"}
								className={"modal-title"}
							>
								You need to complete your registration data
							</Typography>
						</Grid>
					</Grid>
				</DialogTitle>
				<DialogContent>
					<Grid
						xs={12}
						sm={12}
						md={12}
						lg={12}
						direction={"row"}
						justifyContent={"center"}
						container
						item
					>
						<Grid
							lg={12}
							xl={12}
							sm={12}
							md={12}
							item
						>
							<Typography
								align={"center"}
							>
								In order to comply with UK pensions legislation and regulations,
								we need more information from you.
							</Typography>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid
						lg={12}
						xl={12}
						sm={12}
						md={12}
						xs={12}
						spacing={2}
						container
						item
					>
						<Grid
							lg={6}
							xl={6}
							sm={6}
							md={6}
							xs={6}
							textAlign={"center"}
							marginBottom={5}
							marginTop={5}
							item
							container
						>
							<Button
								className={"modal-button"}
								onClick={() => push(link)}
								fullWidth
							>
								Finish Now
							</Button>
						</Grid>
						<Grid
							lg={6}
							xl={6}
							sm={6}
							md={6}
							xs={6}
							textAlign={"center"}
							marginBottom={5}
							marginTop={5}
							item
							container
						>
							<Button
								className={"exit-finish-later-button"}
								onClick={() => push("/logout")}
								fullWidth
							>
								Exit and finish later
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		</Grid>
	)
};

export default NewModalDesign;