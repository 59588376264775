import ProjectionsServiceClass from "../../services/Projections";
import {SIMULATE_PROJECTION} from "./types";

/**
 * @param data
 * @returns {function(...[*]=)}
 */
export const simulation = (data: any[] = false) => dispatch => {
	if (data) {

		new ProjectionsServiceClass()
			.simulations(data)
			.then(response => {
				dispatch({
					type: SIMULATE_PROJECTION,
					my_projections: false,
				});
				dispatch({
					type: SIMULATE_PROJECTION,
					my_projections: response.data.data,
				});
			})
			.catch(error => {
				console.error(error);
				if (error.response) {
					if (error.response.status === 401) {
						localStorage.clear();
						window.location = "/login";
					}
				}
			});
	}
}