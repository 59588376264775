// importing the default actions.
import {CONFIRM_CODE, EMAIL_CHANGED, RESEND_CODE} from "../../actions/ActivationCode/types";
import {resolveReduxState} from "../../utils/Helpers";

/**
 * @type {{activation_code_loading: boolean, activationCodeData: boolean}}
 */
const initial_state = {
	activation_code_loading: false,
	activationCodeData: false,
	errorMessage: "",
	canProceed: false,
	canProceedEmail: false,
};

/**
 * @param state
 * @param action
 * @returns {{activation_code_loading: boolean}|{valueOf, (): boolean}|boolean}
 */
const ActivationCodeStore = (state = initial_state, action = false) => {
	if (action) {
		const {type = false} = action;
		
		if (type) {
			switch (type) {
				case RESEND_CODE:
				case CONFIRM_CODE:
				case EMAIL_CHANGED:
					return resolveReduxState(state, action);
				default:
					break;
			}
		}
	}

	return state;
};

export default ActivationCodeStore;