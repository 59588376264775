import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {checkPassword, validateEmail} from "../../utils/Helpers";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import PasswordCheck from "../PasswordCheck";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {useState} from "react";
import OutlinedInput from "@mui/material/OutlinedInput";

/**
 * @param email
 * @param password
 * @param confirmPassword
 * @param verify_email
 * @param setEmail
 * @param setPassword
 * @param setConfirmPassword
 * @param verifyEmail
 * @param error
 * @param proceedStep
 * @param backStep
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const LoginDetails = (
	{
		email = "",
		password = "",
		confirmPassword = "",
		verify_email = "",
		setEmail = () => {
		},
		setPassword = () => {
		},
		setConfirmPassword = () => {
		},
		verifyEmail = () => {
		},
		error = false,
		proceedStep = () => {
		},
		backStep = () => {
		},
		t = (key) => key,
	}
): JSX.Element => {
	const [showPassword, setShowPassword] = useState(false);
	return (
		<Grid
			id={"signup-container"}
			xl={3.75}
			lg={4.5}
			md={5.5}
			sm={7.5}
			xs={11}
			justifyContent={"center"}
			container
			item
		>
			<form
				noValidate
				autoComplete={"off"}
			>
				<FormControl
					fullWidth
				>
					<Typography
						align={"center"}
						className={"page-title case1"}
					>
						{t("individual.signup.login_details.title")}
					</Typography>
				</FormControl>

				<FormControl
					className={"signup-form-control"}
					fullWidth
				>
					<TextField
						type={"email"}
						onChange={event => setEmail(event.target.value)}
						label={t('individual.signup.login_details.personal_email_input')}
						variant={"outlined"}
						name={"email"}
						id={"email"}
						value={email}
						error={
							(!validateEmail(email) || verify_email) &&
							email !== ''
						}
					/>

					<small className={"red-text"}>
						{
							(!validateEmail(email) && email !== '') ?
								t('individual.errors.please_enter_valid_email')
								:
								verify_email ?
									t('individual.errors.email_already_taken')
									:
									""
						}
					</small>
				</FormControl>
				<FormControl
					className={"signup-form-control"}
					fullWidth
				>
					<OutlinedInput
						type={showPassword ? 'text' : 'password'}
						onChange={event => setPassword(event.target.value)}
						label={t("individual.login.password_input")}
						variant={"outlined"}
						name={"password"}
						id={"password"}
						value={password}
						error={
							(!checkPassword(password) && password !== '')
						}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									onClick={() => setShowPassword(!showPassword)}
									aria-label="toggle password visibility"
									disableFocusRipple
									disableRipple
									disableTouchRipple
								>
									{showPassword ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</InputAdornment>
						}
					/>
					<PasswordCheck
						password={password}
					/>
				</FormControl>
				<FormControl
					className={"signup-form-control"}
					fullWidth
				>
					<OutlinedInput
						type={showPassword ? 'text' : 'password'}
						onChange={event => setConfirmPassword(event.target.value)}
						label={t("individual.confirm_password_input")}
						variant={"outlined"}
						name={"confirm_password"}
						id={"confirm_password"}
						value={confirmPassword}
						error={
							confirmPassword !== password ||
							(!checkPassword(confirmPassword) &&
								confirmPassword !== '')
						}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									onClick={() => setShowPassword(!showPassword)}
									aria-label="toggle password visibility"
									disableFocusRipple
									disableRipple
									disableTouchRipple
								>
									{showPassword ? <Visibility/> : <VisibilityOff/>}
								</IconButton>
							</InputAdornment>
						}
					/>
					<small className={"red-text"}>
						{
							confirmPassword !== password ? t("individual.errors.passwords_does_not_match") : ""
						}
					</small>
				</FormControl>
				<FormControl
					className={"signup-form-control-2 forgot-password"}
					fullWidth
				>
					<small className={"red-text"}>
						{
							confirmPassword === "" ||
							password === "" ||
							email === "" ? t("individual.all_fields_required") : ""
						}
					</small>

				</FormControl>
				<FormControl
					className={"signup-form-control"}
					fullWidth
				>
					<Button
						variant={"contained"}
						className={"button"}
						onClick={() =>
							verifyEmail({
								individuals: {
									email: email
								}
							}, proceedStep)
						}
						disabled={
							!email ||
							!password ||
							!confirmPassword ||
							error ||
							confirmPassword !== password ||
							!checkPassword(password) ||
							!validateEmail(email)
						}
					>
						{t("individual.login.continue_button")}
					</Button>
				</FormControl>
				<FormControl
					fullWidth
					className={"signup-form-control"}
				>
					<Button
						variant={"contained"}
						className={"button"}
						onClick={() => backStep()}
					>
						{t("individual.enrolled_by_employer.enter_personal_information.back_button")}
					</Button>
				</FormControl>
			</form>
		</Grid>
	)
};

export default LoginDetails;