import Request from '../../request';
import {AxiosResponse} from "axios";
import AbstractServiceRequestClass from "../../classes/AbstractServiceRequestsClass";

export default class ConnectionsService extends AbstractServiceRequestClass {
	constructor() {
		super(Request.Connections);
	}

	/**
	 * @param code
	 * @returns {Promise<AxiosResponse<*>>|*}
	 */
	getByCode(code: string = ""): Promise<AxiosResponse<*>> | * {
		if (this.request && code) {
			return this.request.GetByCode(code ?? false);
		}

		return Promise.reject("No request handler found.");
	}

	/**
	 * @param code
	 * @returns {Promise<AxiosResponse<*>>|*}
	 */
	authorize(code: string = ""): Promise<AxiosResponse<*>> | * {
		if (this.request && code) {
			return this.request.Authorize(code ?? false);
		}

		return Promise.reject("No request found");
	}

	/**
	 * @param code
	 * @returns {Promise<AxiosResponse<*>>|*}
	 */
	deny(code: string = ""): Promise<AxiosResponse<*>> | * {
		if (this.request && code) {
			return this.request.Deny(code ?? false);
		}

		return Promise.reject("No request found");
	}
}