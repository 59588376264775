import Request from "../../utils/Request";

/**
 * Retrieve my profile data based on collegiaToken in the
 * default's browser's localstorage.
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
const MainPage = () => Request.get("/profile/main-page");

export default MainPage;