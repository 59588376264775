import React from "react";
import Grid from "@mui/material/Grid";
import check from '../images/password-check/check.svg';
import error from '../images/password-check/error.svg';
import Typography from "@mui/material/Typography";


/**
 * @returns {JSX.Element}
 * @constructor
 */
const PasswordCheck = ({
	password = "",
}): JSX.Element => {
	let regexNumber= /.*[0-9].*/;
	let regexCharacter = /[a-zA-Z]/;
	return (
		<Grid>
			<Grid
				className={'password-check'}
				container
				alignItems={'center'}
			>
				<img
					src={password.length >= 8 ? check : error}
					alt={'Use 8 or more characters'}
				/>

				<Typography className={password.length >= 8 ? 'check' : 'error'}>
					Use 8 or more characters.
				</Typography>
			</Grid>

			<Grid
				className={'password-check'}
				container
				alignItems={'center'}
			>
				<img
					src={regexCharacter.test(password) ? check : error}
					alt={'use a minimum of one letter'}
				/>

				<Typography className={regexCharacter.test(password) ? 'check' : 'error'}>
					Use a minimum of one letter.
				</Typography>
			</Grid>

			<Grid
				className={'password-check'}
				container
				alignItems={'center'}
			>
				<img
					src={regexNumber.test(password) ? check : error}
					alt={'use a minimum of one number'}
				/>

				<Typography className={regexNumber.test(password) ? 'check' : 'error'}>
					Use a minimum of one number.
				</Typography>
			</Grid>
		</Grid>
	)
};

export default PasswordCheck;