import ContributionsList from "./ContributionsList";
import New from './New';
import Step from "./Step";
import Details from "./Details";
import SelectBanks from "./SelectBanks";
import InsertValue from "./InsertValue";
import ConfirmMonthlyContributions from './ConfirmMonthlyContributions';
import ConfirmDirectDebitCancellation from "./ConfirmDirectDebitCancellation";

const ExtraContributionsPages = {
	ContributionsList,
	New,
	Step,
	Details,
	SelectBanks,
	InsertValue,
	ConfirmMonthlyContributions,
	ConfirmDirectDebitCancellation,
};

export default ExtraContributionsPages;