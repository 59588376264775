import AbstractServiceRequestsClass from "../../classes/AbstractServiceRequestsClass";

import Request from '../../request';

export default class InvestmentApproachService extends AbstractServiceRequestsClass {
	constructor() {
		super(Request.InvestmentApproach);
	}

	/**
	 * @param data
	 */
	updateInvestmentApproach(data: any = false) {
		if (this.request && data) {
			return this.request.UpdateInvestmentApproach(data);
		}
	}
}