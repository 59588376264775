import AbstractServiceRequestsClass from "../../classes/AbstractServiceRequestsClass";

import Request from "../../request";
import {AxiosResponse} from "axios";

export default class ProfileService extends AbstractServiceRequestsClass {
	constructor() {
		super(Request.Profile);
	}

	/**
	 * @returns {Promise<AxiosResponse<T>>|null}
	 */
	me(): Promise<AxiosResponse<T>> | null {
		if (this.request) {
			return this.request.Me();
		}

		return null;
	}

	/**
	 * @returns {Promise<AxiosResponse<T>>|null}
	 */
	completeWalkthrough(): Promise<AxiosResponse<T>> | null {

		if (this.request) {
			return this.request.CompleteWalkthrough()
		}

		return null;
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	updateRetirementAge(data = false): Promise<AxiosResponse<*>> | null {
		if (this.request && data) {
			return this.request.UpdateRetirementAge(data);
		}

		return null;
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	updateMyStatePension(data = false): Promise<AxiosResponse<*>> | null {
		if (this.request && data) {
			return this.request.UpdateStatePension(data);
		}

		return null;
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	modifyIfaAccess(data = []): Promise<AxiosResponse<*>> | null {
		if (this.request && data) {
			return this.request.ModifyIfaAccess(data);
		}

		return null;
	}

	/**
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	myEmployers(): Promise<AxiosResponse<*>> | null {
		if (this.request) {
			return this.request.MyEmployers();
		}

		return null;
	}

	/**
	 * @returns {null|Promise<AxiosResponse<*>>|*}
	 */
	myProjections(employerId: mixed = null): null | Promise<AxiosResponse<*>> | * {
		if (this.request) {
			return this.request.Projections(employerId);
		}

		return null;
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	updateProfilePicture(data: any[] = false): Promise<AxiosResponse<*>> | null {
		if (data && this.request) {
			return this.request.UpdateProfilePicture(data);
		}

		return null;
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	requestChangeEmail(data: any[] = false): Promise<AxiosResponse<*>> | null {
		if (data && this.request) {
			return this.request.RequestChangeEmail(data);
		}

		return null;
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	confirmChangeProfileCode(data: any[] = false): Promise<AxiosResponse<*>> | null {
		if (data && this.request) {
			return this.request.ConfirmChangeProfileCode(data);
		}

		return null;
	}

	/**
	 * @param code
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	checkProfileChangeCode(code: string = ""): Promise<AxiosResponse<*>> | null {
		if (code !== "" && this.request) {
			return this.request.CheckProfileChangeCode(code);
		}

		return null;
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	updateMyEmail(data: any[] = false): Promise<AxiosResponse<*>> | null {
		if (data && this.request) {
			return this.request.UpdateMyEmail(data);
		}

		return null;
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	updateMyPassword(data: any[] = false): Promise<AxiosResponse<*>> | null {
		if (data && this.request) {
			return this.request.UpdateMyPassword(data);
		}

		return null;
	}

	/**
	 *
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	closeAccount(): Promise<AxiosResponse<*>> | null {
		if (this.request) {
			return this.request.CloseAccount();
		}

		return null;
	}

	/**
	 *
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	updateMyAddress(data: any[] = false): Promise<AxiosResponse<*>> | null {
		if (data && this.request) {
			return this.request.UpdateMyAddress(data);
		}

		return null;
	}

	/**
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	logOut(): Promise<AxiosResponse<*>> | null {
		if (this.request) {
			return this.request.LogOut();
		}

		return null;
	}

	/**
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	bankAccounts(): Promise<AxiosResponse<*>> | null {
		if (this.request) {
			return this.request.BankAccounts();
		}

		return null;
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	createBankAccount(data: any[] = false): Promise<AxiosResponse<*>> | null {
		if (this.request && data) {
			return this.request.CreateBankAccount(data);
		}

		return null;
	}

	/**
	 * @param id
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	updateBankAccount(id: any = false, data: any[] = false) {
		if (id && data) {
			return this.request.UpdateBankAccount(id ?? false, data ?? false);
		}

		return null;
	}

	/**
	 * @param id
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	erase(id: any = false) {
		if (id) {
			return this.request.DeleteBankAccount(id ?? false);
		}

		return null;
	}

	/**
	 * @param id
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	retrieveBankAccountDetails(id: any = false): Promise<AxiosResponse<*>> | null {
		if (this.request && id) {
			return this.request.RetrieveBankAccountDetails(id ?? false);
		}

		return null;
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	requestNewPassword(data: any[] = false): Promise<AxiosResponse<*>> | null {
		if (this.request && data) {
			return this.request.RequestNewPassword(data ?? false);
		}

		return null;
	}

	/**
	 * @param token
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	checkForgotPasswordToken(token: string = ""): Promise<AxiosResponse<*>> | null {
		if (this.request && token) {
			return this.request.CheckForgotPasswordToken(token ?? "");
		}

		return null;
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	updatePassword(data: any[] = false): Promise<AxiosResponse<*>> | null {
		if (data && this.request) {
			return this.request.UpdatePassword(data);
		}

		return null;
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	retrieveOptionsValues(data: any[] = false): Promise<AxiosResponse<*>> | null {
		if (data && this.request) {
			return this.request.RetrieveOptionsValues(data ?? false);
		}

		return null;
	}

	/**
	 *
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	checkDuplicateNin(data: any[] = false): Promise<AxiosResponse<*>> | null {
		if (this.request && data) {
			return this.request.CheckDuplicateNin(data ?? false);
		}

		return null;
	}

	/**
	 *
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	isValidNationalInsuranceNumber(data: any[] = false): Promise<AxiosResponse<*>> | null {
		if (this.request && data) {
			return this.request.isValidNationalInsuranceNumber(data ?? false);
		}

		return null;
	}

	/**
	 *
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	changePersonalData(data: any[] = false): Promise<AxiosResponse<*>> {
		if (data && this.request) {
			return this.request.ChangePersonalData(data ?? false);
		}

		return Promise.reject("Something went wrong.");
	}

	/**
	 * @param data
	 * @param contributionData
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	uploadDdiFile(data: any[] = false, contributionData: string = ""): Promise<AxiosResponse<*>> | null {
		if (this?.request && data) {
			return this?.request?.uploadDdiFile(data ?? false, contributionData ?? "");
		}

		return Promise?.reject("Request wasn't completed.");
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	getActivationToken(data: any[] = false): Promise<AxiosResponse<*>> | null {
		if (this?.request && data) {
			return this?.request?.GetActivationToken(data ?? false);
		}

		return Promise?.reject("Request wasn't completed.");
	}

	createFirstPassword(data: any[] = false): Promise<AxiosResponse<*>> | null {
		if (this?.request && data) {
			return this?.request?.CreateFirstPassword(data ?? false);
		}

		return Promise?.reject("Request wasn't completed.");
	}

	/**
	 * @returns {*}
	 */
	generateHMRCAccessTokenState = (data) => this.request.GenerateHMRCAccessStateToken(data);

	processPastEmployments = (data) => this.request.ProcessPastEmployments(data);

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	returnMonthlyProjections(data = false): Promise<AxiosResponse<*>> | null {
		if (this.request && data) {
			return this.request.ReturnMonthlyProjections(data);
		}

		return null;
	}

	/**
	 * @returns {Promise<AxiosResponse<T>>|null}
	 */
	mainPage(): Promise<AxiosResponse<T>> | null {
		if (this.request) {
			return this.request.MainPage();
		}

		return null;
	}
}