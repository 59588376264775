import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const AddNewPensionLinkButton = (props): JSX.Element => (
	<Grid
		xs={12}
		md={12}
		lg={12}
		sm={12}
		container
		item
		direction={"column"}
		alignItems={"center"}
		alignContent={"center"}
		id={"add-new-pension-button-container"}
	>
		<Grid
			xs={12}
			md={12}
			lg={12}
			sm={12}
			container
			item
			className={"add-other-pension-button-container"}
		>
			<Button
				variant={"contained"}
				color={"primary"}
				onClick={() => props.history("/other-pensions/new")}
			>
				{props.t("individual.other_pensions.add_other_pension")}
			</Button>
		</Grid>
	</Grid>
);

export default AddNewPensionLinkButton;