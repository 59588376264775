import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import ArrowIcon from "../../images/Arrow.svg";
import {capitalizeFirstLetter} from "../../utils/Helpers";

/**
 * @param age
 * @param history
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const ExpectedRetirementAgeCard = (
	{
	age = "",
	history = () => {},
		t=key=>key,
}
) => {
	return (
		<Grid
			xl={2.4}
			lg={2.8}
			md={6}
			sm={6}
			item
			justifyContent={'center'}
			className={"expected-retirement-age-card"}
			onClick={() => history("/adjust-retirement-age")}
		>
			<Grid
				lg={12}
				xl={12}
				sm={12}
				xs={12}
				md={12}
				item
			>
				<Typography
					className={"title"}
					align={"center"}
				>
					{capitalizeFirstLetter(t("individual.your_pension.expected_retirement_age").toLowerCase())}
				</Typography>
			</Grid>
			<Grid
				lg={12}
				xl={12}
				sm={12}
				xs={12}
				md={12}
				item
			>
				<Typography
					className={"age"}
					align={"center"}
				>
					{age}
				</Typography>
			</Grid>
			<Grid
				lg={12}
				xl={12}
				sm={12}
				xs={12}
				md={12}
				item
			>
				<Typography
					className={"lore-ipsum"}
					align={"center"}
				>
					{t("individual.main_page.is_selected_age")}
				</Typography>
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					textAlign={"-webkit-center"}
					item
				>
					<Typography
						className={"sub-text"}
						align={"center"}
					>
						{t("individual.main_page.expected_retirement_age.age_explanation")}
					</Typography>
				</Grid>
			</Grid>
			<Grid
				lg={12}
				xl={12}
				sm={12}
				xs={12}
				md={12}
				item
			>
				<Typography
					className={"cta"}
					align={"center"}
				>
					{t("individual.review_or_change_it")}
					<img
						className={"icon-arrow"}
						src={ArrowIcon}
						alt={"Review or change it"}
					/>
				</Typography>
			</Grid>
		</Grid>
	);
};

export default ExpectedRetirementAgeCard;