import React from "react";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import Wrapper from "../layout/Logged/Wrapper";
import {returnThirdPartySoftware} from "../actions/Profile";
import {connect} from "react-redux";
import DataSharingConnectionsCardList from "../components/DataSharingConnections/DataSharingConnectionsCardList";
import {buttonActions} from "../actions/Connections";
import Loading from "../components/Loading";
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";

/**
 * @param loading
 * @param profile_data
 * @param profile_loading
 * @param buttonActions
 * @param history
 * @returns {JSX.Element}
 * @constructor
 */
const SoftwareConnections = (
	{
		loading = false,
		profile_data = [],
		profile_loading = false,
		buttonActions = () => {
		},
		history = () => {
		}
	}
): JSX.Element => {
	const {t} = useTranslation();

	if (loading || profile_loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<Wrapper
			history={history}
			hideFooter={true}
			t={t}
		>
			<Slide in={true} direction={"left"}>
				<Grid>
					<Grid
						xl={12}
						lg={12}
						md={12}
						sm={12}
						textAlign={"center"}
						direction={"row"}
						display={"block"}
						container
						item
						id={"data-sharing-upper"}
					>
						<Typography
							className={"data-sharing-title"}
						>
							Data Sharing Connections
						</Typography>
						<Typography
							className={"data-sharing-subtitle"}
							paragraph
						>
							{t("individual.software_connections.subtitle")}
						</Typography>
						<Typography
							className={"data-sharing-subtitle"}
							paragraph
						>
							{t("individual.software_connections.subtitle_2")}
						</Typography>
					</Grid>
					<Grid
						paddingTop={"60px"}
						direction={"column"}
						alignItems={"center"}
						alignContent={"center"}
						container
						item
					>
						<DataSharingConnectionsCardList
							third_party_software_connections={profile_data?.third_party_software_connections ?? []}
							buttonActions={buttonActions}
							t={t}
						/>
					</Grid>
				</Grid>
			</Slide>
		</Wrapper>
	);
}


const mapStateToProps = state => {
	const {
		Profile = [],
		Connections = [],
	} = state;

	return {
		...Profile,
		...Connections,
	};
};

/**
 * @param dispatch
 * @returns {{get: (function(): *)}}
 */
const mapDispatchToProps = dispatch => ({
	returnThirdPartySoftware: dispatch(returnThirdPartySoftware()),
	buttonActions: (code: string = "", action: string = "") => dispatch(buttonActions(code, action, false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SoftwareConnections);