// importing the actions.
import {resolveReduxState} from "../../utils/Helpers";
import {
	DELETE_BENEFICIARY,
	GET_BENEFICIARY_TYPES,
	GET_RELATIONSHIP_TYPES,
	UPDATE_BENEFICIARY
} from "../../actions/Beneficiaries/types";

const initial_state = {
	beneficiaries_loading: false,
	beneficiary_types: [],
	beneficiary_relationship_types: [],
	beneficiaries: [],
	beneficiary: null,
}

const BeneficiariesStore = (state = initial_state, action = {
	type: "",
}) => {
	switch (action?.type) {

		case GET_BENEFICIARY_TYPES:
		case GET_RELATIONSHIP_TYPES:
		case DELETE_BENEFICIARY:
		case UPDATE_BENEFICIARY:
			return resolveReduxState(state, action);

		default:
			return state;
	}
};

export default BeneficiariesStore;