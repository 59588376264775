import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import {validateEmail} from "../../utils/Helpers";
import TextField from "@mui/material/TextField";

/**
 * @param email
 * @param errorMessage
 * @param profile_data
 * @param setEmail
 * @param setFlagToBack
 * @param update
 * @param setRequestMailChange
 * @param t
 * @param current_email
 * @returns {JSX.Element}
 * @constructor
 */
const EmailChangeForm = (
	{
		email = "",
		errorMessage = "",
		profile_data = {
			id: null,
			email: "",
		},
		setEmail = () => {
		},
		setFlagToBack = () => {
		},
		update = () => {
		},
		setRequestMailChange = () => {
		},
		t = key => key,
		current_email = "",
	}
): JSX.Element => (
	<Grid
		xl={12}
		lg={12}
		md={12}
		container
		item
		direction={"row"}
		justifyContent={"center"}
		id={"change-email-wrapper"}
	>
		<Grid
			xl={9.5}
			lg={9.5}
			md={12}
			sm={12}
			xs={12}
			item
			direction={"row"}
			container
			justifyContent={"center"}
			className={"grid"}
		>
			<Grid
				xl={6.55}
				lg={7.2}
				md={8}
				sm={12}
				xs={12}
				item
				container
				direction={"row"}
				display={"inline-block"}
				textAlign={"-webkit-center"}
			>
				<Typography
					className={"title"}
				>
					{t("individual.my_account.login_details.verify_account.email.title")}
				</Typography>
				<Typography
					className={"subtitle"}
					dangerouslySetInnerHTML={{
						__html: t("individual.confirm_your_email.form.top_information", {
							email: profile_data.email,
						})
					}}
				/>
				<Grid
					xl={8.2}
					lg={8.2}
					md={8.2}
					sm={6}
					xs={12}
					item
					container
				>
					<Typography
						className={"label-code"}
					>
						Current email
					</Typography>
					<TextField
						id={"current_email"}
						className={"textfield2 extra"}
						value={current_email}
						disabled
						fullWidth
					/>
					<Typography
						className={"label-code"}
					>
						{t("individual.login.email_input")}
					</Typography>
					<TextField
						id={"email"}
						className={"textfield2 extra"}
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						fullWidth
					/>
					<Fade in={errorMessage !== "" ?? false}>
						<small className={"collegia-text-danger default-error"}>
							{errorMessage}
						</small>
					</Fade>
					<Button
						fullWidth
						onClick={() => {
							update(profile_data?.id, {
								profiles: {
									email: email,
									update_email_activation: true,
								}
							});
							setFlagToBack(true);
							setRequestMailChange(false);
						}}
						disabled={!email || !validateEmail(email)}
					>
						{t("individual.login.continue_button")}
					</Button>
				</Grid>
			</Grid>
		</Grid>
	</Grid>
);

export default EmailChangeForm;