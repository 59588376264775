import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

/**
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const HeaderInfo = (
	{
		t = key => key,
	}
): JSX.Element => (
	<Paper id={"investment-approach-container-header-info"}>
		<Typography paragraph>
			{t("individual.ifa_connections.page.caption_1")}
		</Typography>
		<Typography
			className={"last-paragraph"}
			paragraph
		>
			{t("individual.ifa_connections.page.caption_2")}
		</Typography>
	</Paper>
);

export default HeaderInfo;