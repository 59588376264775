import OtherPensionsHeaderImage from "../../images/OtherPensionsHeaderImage.svg";
import Grid from '@mui/material/Grid';

/**
 * @returns {JSX.Element}
 * @constructor
 */
const OtherPensionsImageHeader = (): JSX.Element => (
	<Grid
		xs={12}
		md={12}
		lg={12}
		sm={12}
		container
		item
		direction={"column"}
		alignItems={"center"}
		alignContent={"center"}
		id={"image-no-results-header"}
	>
		<img src={OtherPensionsHeaderImage}
		     alt={"other pensions"}
		/>
	</Grid>
);

export default OtherPensionsImageHeader;