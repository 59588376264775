import React from 'react';

// importing the default app bar component.
import AppBar from "../Header/Logged/AppBar";

/**
 * @stateless
 * @param props
 * @returns {*}
 */
const Header = (props = {
	history: {
		push: () => {},
	}
}) => (
	<AppBar {...props} />
);

export default Header;