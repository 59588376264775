import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import MaskedInput from "react-text-mask";
import {createAutoCorrectedDatePipe, validBirthday} from "../../utils/Helpers";
import Button from "@mui/material/Button";
import React, {useState} from "react";
import PropTypes from "prop-types";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const EnrolledByEmployerForm = (props: {
	setStep: () => {},
	getActivationToken: () => {},
	t: key => key,
}): JSX.Element => {
	const [data, setData] = useState({
		name: "",
		surname: "",
		nin: "",
		birthdate: ""
	});

	return (
		<>
			<Grid
				id={"enrolled-by-employer-text"}
				container
				justifyContent={"center"}
				direction={"column"}
			>
				<Typography
					variant={"h6"}
					align={"center"}
				>
					{props.t('individual.enrolled_by_employer.enter_personal_information.title')}
				</Typography>

				<Typography
					variant={"subtitle2"}
					align={"center"}
				>
					{props.t('individual.enrolled_by_employer.enter_personal_information.subtitle')}
				</Typography>

				<form
					noValidate
					autoComplete="off"
				>
					<FormControl
						className={"enrolled-by-employer-form-control"}
						fullWidth
					>
						<TextField
							type={"text"}
							onChange={event => setData({...data, name: event.target.value})}
							label={`${props.t("individual.my_account.beneficiaries.name")} *`}
							variant={"outlined"}
							name={"name"}
							id={"name"}
							value={data.name}
						/>
					</FormControl>

					<FormControl
						className={"enrolled-by-employer-form-control"}
						fullWidth
					>
						<TextField
							type={"text"}
							onChange={event => setData({...data, surname: event.target.value})}
							label={`${props.t("individual.my_account.personal_information.surname")} *`}
							variant={"outlined"}
							name={"surname"}
							id={"surname"}
							value={data.surname}
						/>
					</FormControl>

					<FormControl
						className={"enrolled-by-employer-form-control"}
						fullWidth
					>
						<TextField
							type={"text"}
							onChange={event =>
								setData(
									{
										...data,
										nin: event.target.value.toUpperCase().replaceAll(' ', '')
									}
								)
							}
							label={`${props.t("individual.my_account.personal_information.national_insurance_number")} *`}
							variant={"outlined"}
							name={"nin"}
							id={"nin"}
							value={data.nin}
						/>
					</FormControl>

					<FormControl
						className={"enrolled-by-employer-form-control"}
						fullWidth
					>
						<MaskedInput
							mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
							placeholderChar={'\u2000'}
							placeholder={`${props.t("individual.birthdate")} *`}
							className={"signup-form-control"}
							label={`${props.t("individual.birthdate")} *`}
							variant={"outlined"}
							name={"birthdate"}
							id={"birthday"}
							value={data.birthdate}
							onChange={event => setData({...data, birthdate: event.target.value})}
							pipe={createAutoCorrectedDatePipe("dd mm yyyy", {
								minYear: 1900,
								maxYear: new Date().getFullYear() - 16
							})}
						/>
					</FormControl>
				</form>
			</Grid>

			<Grid
				id={"enrolled-by-employer-buttons"}
				container
				justifyContent={"center"}
				direction={"column"}
			>
				<FormControl
					fullWidth
				>
					<Button
						variant={"contained"}
						color={"primary"}
						onClick={() => props?.getActivationToken(data)}
						disabled={
							!data.name ||
							!data.nin ||
							!data.surname ||
							!validBirthday(data.birthdate)
						}
					>
						{props.t("individual.login.continue_button")}
					</Button>
				</FormControl>

				<FormControl
					fullWidth
				>
					<Button
						variant={"outlined"}
						color={"primary"}
						onClick={() => props?.setStep(0)}
					>
						{props.t("individual.enrolled_by_employer.enter_personal_information.back_button")}
					</Button>
				</FormControl>
			</Grid>
		</>
	);
};

EnrolledByEmployerForm.propTypes = {
	setStep: PropTypes.func.isRequired,
	getActivationToken: PropTypes.func.isRequired,
};

export default EnrolledByEmployerForm;