import React from 'react';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import Wrapper from "../layout/Wrapper";
import MinimalFooter from "../layout/MinimalFooter";
import ForgotPasswordImage from '../images/ForgotPassword.svg';
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import Button from "@mui/material/Button";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const PasswordRequestSent = (): JSX.Element => {
	const history = useNavigate();
	const {t} = useTranslation();
	return (
		<Wrapper t={t}>
			<Slide direction="up" in={true} mountOnEnter unmountOnExit>
				<div
					id={"forgot-password-requested-wrapper"}
				>
					<Grid
						id={"login-container"}
						lg={12}
						xs={12}
						md={12}
						sm={12}
						alignItems={"center"}
						alignContent={"center"}
						direction={"column"}
						spacing={0}
						container
						item
					>
						<Grid
							className={"back-arrow-grid"}
							lg={12}
							xs={12}
							md={12}
							sm={12}
							container
							item
						>
							<ArrowBackIos
								className={"back-arrow-icon"}
								cursor={"pointer"}
								onClick={() => history("/login")}
							/>
						</Grid>
						<form
							noValidate
							autoComplete="off"
						>
							<FormControl
								className={"login-form-control-2 first-title"}
								fullWidth
							>
								<Grid
									lg={12}
									xs={12}
									md={12}
									sm={12}
									alignItems={"center"}
									alignContent={"center"}
									direction={"column"}
									spacing={0}
									container
									item
								>
									<img
										src={ForgotPasswordImage}
										alt={"Password forgot e-mail sent."}
										width={315}
									/>
								</Grid>
							</FormControl>
							<FormControl
								className={"login-form-control-2 first-title"}
								fullWidth
							>
								<Typography align={"center"}>
									Done, instructions sent!
								</Typography>
							</FormControl>
							<FormControl
								className={"login-form-control-2 second-title"}
								fullWidth
							>
								<Typography align={"center"}>
									Check your
									email <strong>{localStorage.getItem("passwordRetrieveEmail")}</strong> and
									follow the instructions.
								</Typography>
							</FormControl>
						</form>
						<Grid
							className={"button-back-grid"}
							lg={12}
							xs={12}
							md={12}
							sm={12}
							justifyContent={"center"}
							container
							item
						>
							<Button
								className={"button-back"}
								onClick={() => history("/login")}
								fullWidth
							>
								Back
							</Button>
						</Grid>
					</Grid>
					<MinimalFooter t={t}/>
				</div>
			</Slide>
		</Wrapper>
	);
}

export default connect(null, null)(PasswordRequestSent);