import {
	FETCH_INVESTMENT_APPROACHES,
	UPDATE_INVESTMENT_APPROACHES
} from "../../actions/InvestmentApproach/types";
import {resolveReduxState} from "../../utils/Helpers";

/**
 * @type {{investment_approaches: [], investment_approach_loading: boolean}}
 */
const initial_state = {
	investment_approach_loading: false,
	investment_approaches: [],
}

/**
 * @param state
 * @param action
 * @returns {{investment_approaches: [], investment_approach_loading: boolean}|{valueOf, (): boolean}|boolean}
 */
const InvestmentApproachStore = (state = initial_state,  action = {
	type: "",
}) => {
	switch (action?.type) {
		case FETCH_INVESTMENT_APPROACHES:
		case UPDATE_INVESTMENT_APPROACHES:
			return resolveReduxState(state, action);
		default:
			return state;
	}
};

export default InvestmentApproachStore;