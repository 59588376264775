import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import IconButton from "@mui/material/IconButton";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const LifeStyleCardHeader = (props = {
	t: key => key,
}): JSX.Element => (
	<IconButton
		aria-label="settings"
		disableTouchRipple
		disableFocusRipple
		disableRipple
	>
		<Grid
			alignContent={"flex-end"}
			alignItems={"flex-end"}
			direction={"column"}
			container
		>

			<Grid
				xs={12}
				sm={12}
				md={12}
				lg={12}
				item
			>
				<Typography
					variant={"caption"}
				>
					{props.t("individual.your_pension.pot_income")}
				</Typography>

			</Grid>

			<Grid
				xs={12}
				sm={12}
				md={12}
				lg={12}
				item
			>
				<Switch
					disableRipple
					checked={props.checked}
					onChange={() => props.onChange(props.checked)}
					color="primary"
					name="potChecked"
					id={"potChecked"}
					inputProps={{'aria-label': 'primary checkbox'}}
				/>
			</Grid>
		</Grid>
	</IconButton>
);

export default LifeStyleCardHeader;