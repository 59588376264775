import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import React from "react";

function _className(subtitle2) {
	return subtitle2 === "" ? "subtitle2" : "subtitle1"
}

/**
 * @param title
 * @param subtitle1
 * @param subtitle2
 * @returns {JSX.Element}
 * @constructor
 */
const UpperInstructionCard = (
	{
		title = "",
		subtitle1 = "",
		subtitle2 = ""
	}
): JSX.Element => (
	<Grid
		md={12}
		sm={12}
		xl={12}
		xs={12}
		lg={12}
		item
		textAlign={'center'}
		className={'upper-instruction'}
	>
		<Typography
			align={"left"}
			sx={{fontWeight: "bold"}}
			variant={"h5"}
			className={"title"}
		>
			{title}
		</Typography>
		<Typography
			align={"left"}
			variant={"p"}
			display={"block"}
			width={"90%"}
			className={_className(subtitle2)}
		>
			{subtitle1}
		</Typography>
		{
			subtitle2 !== "" && (
				<Typography
					align={"left"}
					variant={"p"}
					display={"block"}
					width={"90%"}
					className={"subtitle2"}
				>
					{subtitle2}
				</Typography>
			)
		}
	</Grid>
);

export default UpperInstructionCard;