import Proceed from "./Proceed";
import Update from "./Update";
import UpdatePersonalInfo from "./UpdatePersonalInfo";
import ReadTermsAndConditions from "./ReadTermsAndConditions";
import AgreeTermsAndConditions from "./AgreeTermsAndConditions";
import ConfirmMobileCode from "./ConfirmMobileCode";

const StepRequests = {
	Proceed,
	Update,
	UpdatePersonalInfo,
	ReadTermsAndConditions,
	AgreeTermsAndConditions,
	ConfirmMobileCode,
};

export default StepRequests;