import React from "react";

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import OptOutContentImage1 from "../../../images/background-16.svg";
import {connect} from "react-redux";
import {optOut} from "../../../actions/OptOut";
import {withTranslation} from "react-i18next";

class SecondConfirmation extends React.Component {

	_confirmOptOut = () => {
		const {
			individual = false
		} = this.props;

		if (individual) {
			this.props.optOut({
				individuals: {
					individual_id: individual.id,
				}
			});
		}

	};

	/**
	 * @returns {string|*}
	 * @private
	 */
	_getEmployerName = () => {

		const {
			individual = false
		} = this.props;

		if (individual) {
			const {
				papdis = []
			} = individual;
			if (papdis.length > 0) {
				const {
					employer = false
				} = papdis[0];
				if (employer) {
					return employer.title;
				}
			}
		}

		return "";
	};

	/**
	 * @param individual
	 * @returns {number}
	 */
	calculateCurrentCollegiaAccountValue = (individual = {
        papdis: [],
    }) => {
		let totalInvested = 0;

		if (individual) {
			const {
				papdis = false
			} = individual;
			if (papdis) {
				const governmentContribution = (parseFloat(papdis[0]?.employee_contributions_amount ?? 0)) * 0.25;
				totalInvested = parseFloat(papdis[0]?.employer_contributions_amount ?? 0) +
					parseFloat(papdis[0]?.employee_contributions_amount ?? 0) + governmentContribution
					+ parseFloat(papdis[0]?.additional_voluntary_contributions_amount ?? 0);
			}
		}

		return totalInvested;
	};

	/**
	 * @param individual
	 * @returns {number}
	 * @private
	 */
	_renderIndividualProjections = (individual: any = {
        projection: {
            formatted_total_value_of_future_pot: "0.00",
        }
    }): number => {
		let total = 0;

		if (individual) {
			const {
				projection = {
                    formatted_total_value_of_future_pot: "0.00"
                }
			} = individual;

			if (projection) {
				return projection.formatted_total_value_of_future_pot;
			}
		}

		return total;
	};

	/**
	 * @returns {*}
	 */
	render() {
		const {
			individual = false,
		} = this.props;

		return (
			<React.Fragment>
				<Grid
					xs={12}
					md={12}
					lg={12}
					alignContent={"center"}
					alignItems={"center"}
					direction={"column"}
					className={'no-padding-content'}
					item
					container
				>
					<Grid
						item
					>
						<img
							alt={"Opt out and cancellation"}
							src={OptOutContentImage1}
							id={"OptOutContentImage1"}
						/>
					</Grid>
				</Grid>
				<Grid
					xs={12}
					lg={12}
					md={12}
					alignContent={"center"}
					alignItems={"center"}
					direction={"row"}
					className={"money-info-container"}
					spacing={2}
					item
					container
				>
					<Grid
						xs={6}
						lg={6}
						md={6}
						item
					>
						<Grid
							xs={12}
							md={12}
							lg={12}
							item
						>
							<Typography
								variant={"caption"}
								component={"p"}
								align={"right"}
								className={'font-size-11'}
							>
								YOU ARE GIVING UP TODAY
							</Typography>
						</Grid>
						<Grid
							xs={12}
							md={12}
							lg={12}
							item
						>
							<Typography
								variant={"caption"}
								component={"p"}
								align={"right"}
								className={"current-contribution-amount"}
							>
								£ {this.calculateCurrentCollegiaAccountValue(individual).toFixed(2)}
							</Typography>
						</Grid>
					</Grid>
					<Grid
						xs={6}
						lg={6}
						md={6}
						item
					>
						<Grid
							xs={12}
							md={12}
							lg={12}
							item
						>
							<Typography
								variant={"caption"}
								component={"p"}
								align={"left"}
								className={'font-size-11'}
							>
								THE FUTURE YOU IS GIVING UP
							</Typography>
						</Grid>
						<Grid
							xs={12}
							md={12}
							lg={12}
							item
						>
							<Typography
								variant={"caption"}
								component={"p"}
								align={"left"}
								className={"future-contribution-amount"}
							>
								£ {this._renderIndividualProjections(individual)}
							</Typography>
						</Grid>
					</Grid>
				</Grid>

				<Grid
					xs={12}
					md={12}
					lg={12}
					item
				>
					<Typography
						variant={"caption"}
						align={"center"}
						component={"p"}
						className={"projection"}
					>
						*This projection assumes that: (1) you will retire at {individual.retirement_age ?? 67}; (2) the current
						pension contributions will be maintained; and (3) your pension will have an average yearly
						investment return of 5%.
					</Typography>
				</Grid>

				<Grid
					xs={12}
					md={12}
					lg={12}
					className={"contributions-refund-container"}
					item
				>
					<Typography
						variant={"caption"}
						component={"p"}
						align={"center"}
						className={"current-contribution-amount force-normal-font"}
					>
						Contributions will be refunded to:
					</Typography>
				</Grid>

				<Grid
					xs={12}
					md={12}
					lg={12}
					className={"employer-container-identifier"}
					item
				>
					<Typography
						variant={"caption"}
						component={"p"}
						align={"center"}
					>
						{this._getEmployerName()}
					</Typography>
				</Grid>

				<Grid
					xs={12}
					md={12}
					lg={12}
					item
				>
					<Typography
						variant={"caption"}
						component={"p"}
						align={"center"}
						className={"sad-to-say"}
					>
						{this.props.t("individual.opt_out.employer.its_sad_to_say")}
					</Typography>
					<Typography
						variant={"caption"}
						component={"p"}
						align={"center"}
						className={"opting-out"}
					>
						Opting out now will delete your Collegia account.
					</Typography>
				</Grid>

				<Grid
					xs={12}
					lg={12}
					md={12}
					id={"SecondStepOptOutContainerNoMargin"}
					item
				>
					<Grid
						xs={12}
						md={12}
						lg={12}
						className={"dialog-contained-button"}
						item
					>
						<Button
							className={"want-to-opt-out-button"}
							type={'button'}
							variant="contained"
							color="primary"
							onClick={() => this._confirmOptOut()}
						>
							I confirm that I want to stop contributions
						</Button>
					</Grid>
					<Grid
						xs={12}
						md={12}
						lg={12}
						className={'dialog-contained-button'}
						item
					>
						<Button
							className={"want-to-stay-button"}
							variant={"outlined"}
							type={'button'}
							onClick={() =>
								window.location = '/activation/setup/' + this.props.token}
						>
							Cancel
						</Button>
					</Grid>
				</Grid>
			</React.Fragment>
		)
	}
}

/**
 * @param dispatch
 * @returns {{optOut: (function(*=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	optOut: (data = false) => dispatch(optOut(data)),
});

/**
 * @param state
 * @returns {{}}
 */
const mapStateToProps = (state = {}) => state;

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SecondConfirmation));