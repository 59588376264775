import React from "react";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

/**
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const MinimalFooter = (
	{
		t = (key) => key
	}
): JSX.Element => (
	<Grid
		alignItems={"center"}
		alignContent={"center"}
		id={"footer"}
		direction={"column"}
		item
		container
	>
		<Grid
			lg={12}
			className={"copyrights"}
			item
		>
			<Typography
				align={"center"}
				component={"p"}
				variant={"subtitle1"}
			>
				<Link
					href={"https://www.collegia.co.uk/"}
					target={"_blank"}
				>
					© {new Date().getFullYear()} Collegia Partners Limited. All rights reserved.
				</Link>
				<br/>
				{t("individual.login.all_rights_reserved_footer")}
			</Typography>
		</Grid>
	</Grid>
);

export default MinimalFooter;