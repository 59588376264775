// importing the actions.

import {resolveReduxState} from "../../utils/Helpers";
import {GET_ALL_UPCOMING_TRANSACTIONS, GET_UPCOMING_TRANSACTIONS} from "../../actions/UpcomingTransactions/types";

/**
 * @type {{per_page: number, data: [], last_page: number, next_page_url: null, transactions: [], prev_page_url: null, first_page_url: string, path: string, total: number, last_page_url: string, from: number, to: number, transaction_loading: boolean, current_page: number}}
 */
const initial_state = {
	transaction_loading: false,
	transactions: [],
	current_page: 1,
	data: [],
	first_page_url: "",
	from: 1,
	last_page: 1,
	last_page_url: "",
	next_page_url: null,
	path: "",
	per_page: 15,
	prev_page_url: null,
	to: 1,
	total: 1,
}

/**
 * @param state
 * @param action
 * @returns {{valueOf, (): boolean}|boolean|{per_page: number, data: *[], last_page: number, next_page_url: null, transactions: *[], prev_page_url: null, first_page_url: string, path: string, total: number, last_page_url: string, from: number, to: number, transaction_loading: boolean, current_page: number}}
 * @constructor
 */
const UpcomingTransactions = (state = initial_state, action = false) => {
	if (action) {
		const {
			type = false
		} = action;
		if (type) {
			if (type === GET_UPCOMING_TRANSACTIONS) return resolveReduxState(state, action);
			else if (type === GET_ALL_UPCOMING_TRANSACTIONS) return resolveReduxState(state, action);
		}
	}

	return state;
};

export default UpcomingTransactions;