import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from "@mui/material/TextField";
import {connect} from "react-redux";
import {requestNewPassword} from "../actions/Profile";
import DefaultHeader from "../layout/Header/DefaultHeader";
import {useTranslation} from "react-i18next";
import {setCountry} from "../actions/Languages";
import {useNavigate} from "react-router-dom";

/**
 * @param login_text
 * @param requestNewPassword
 * @param language
 * @param setCountry
 * @param available_countries
 * @param selected_country
 * @returns {JSX.Element}
 * @constructor
 */
const ForgotPassword = (
	{
		login_text = "",
		requestNewPassword = () => {
		},
		language = "",
		setCountry = (newCountry = "GB") => newCountry,
		available_countries = [],
		selected_country = "GB",
	}
): JSX.Element => {
	const [email, setEmail] = useState(""),
		{t, i18n} = useTranslation(),
		history = useNavigate();
	useEffect(() => {
		window.$crisp.push(["set", "session:data", [
			[
				["category", "Individual"],
				["trial_period", "null"]
			]
		]]);

		i18n.changeLanguage(language);
	}, [
		language,
		i18n,
	]);
	return (
		<Grid
			xl={12}
			lg={12}
			md={12}
			sm={12}
			xs={12}
			container
			item
			justifyContent={"center"}
			alignContent={"flex-start"}
			id={"forgot-password-wrapper"}
		>
			<DefaultHeader
				language={language}
				setCountry={setCountry}
				available_countries={available_countries}
				selected_country={selected_country}
				history={history}
			/>
			<Grid
				xl={4}
				lg={5.5}
				md={6}
				sm={4}
				xs={11}
				alignItems={"center"}
				alignContent={"center"}
				direction={"column"}
				container
				item
			>
				<form
					noValidate
					autoComplete={"off"}
				>
					<FormControl
						fullWidth
					>
						<Typography
							align={"center"}
							className={"page-title"}
						>
							{t("individual.forgot_password.header_text")}
						</Typography>
					</FormControl>
					<FormControl
						fullWidth
					>
						<Typography
							align={"center"}
							className={"page-subtitle"}
						>
							{t("individual.forgot_password.sub_header")}
						</Typography>
					</FormControl>
					<FormControl
						className={"forgot-form-control"}
						fullWidth
					>
						<TextField
							type={"email"}
							label={t("individual.login.email_input")}
							variant={"outlined"}
							name={"email"}
							id={"email"}
							value={email}
							error={login_text !== ""}
							onChange={event => setEmail(event.target.value.toLowerCase())}
						/>
						<small>
							{login_text}
						</small>
					</FormControl>
					<FormControl
						fullWidth
					>
						<Button
							fullWidth
							className={"button"}
							variant={"contained"}
							onClick={() =>
								requestNewPassword({
									forgot_password: {
										email,
									}
								})
							}
							disabled={!email}
						>
							{t("individual.login.continue_button")}
						</Button>
					</FormControl>

					<FormControl
						fullWidth
						className={"extra"}
					>
						<Button
							className={"button"}
							fullWidth
							onClick={() => history("/login")}
						>
							{t("individual.cancel.button")}
						</Button>
					</FormControl>
				</form>
			</Grid>
		</Grid>

	);
}

/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => {

	return {
		...state.Profile,
		...state.Languages,
	}
};

/**
 * @param dispatch
 * @returns {{login: (function(*=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	requestNewPassword: (data: any[] = false) => dispatch(requestNewPassword(data ?? false)),
	setCountry: (newCountry = "GB") => dispatch(setCountry(newCountry, null, false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);