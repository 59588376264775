import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {validateEmail} from "../../utils/Helpers";
import Button from "@mui/material/Button";

/**
 * @param email
 * @param selectedEmployer
 * @param setEmail
 * @param renderCompanyName
 * @param renderCompanyNumber
 * @param renderIncorporatedInfo
 * @param renderAddressSnippet
 * @param createLead
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const ConfirmEmailRefer = (
	{
		email = "",
		selectedEmployer = {},
		setEmail = () => {
		},
		renderCompanyName = () => {
		},
		renderCompanyNumber = () => {
		},
		renderIncorporatedInfo = () => {
		},
		renderAddressSnippet = () => {
		},
		createLead = () => {
		},
		setShowModal = () => {
		},
		t = key => key,
	}
): JSX.Element => (
	<Grid
		xs={12}
		sm={12}
		md={12}
		lg={12}
		xl={12}
		item
		container
		id={"confirm-employer-email"}
	>
		<Grid
			xs={12}
			sm={12}
			md={12}
			lg={12}
			xl={12}
			item
		>
			<Grid
				id={"company-style"}
			>
				<Typography
					className={"company-typography-style"}
				>
					{t("individual.company")}:
				</Typography>
				<Typography
					className={"company-title"}
				>
					{renderCompanyName(selectedEmployer, t)}
				</Typography>
				<Typography
					className={"company-number-and-address-style"}
				>
					{"Company number: "}
					<span
						className={"company-subtitle"}
					>
							{
								renderCompanyNumber(selectedEmployer, t) +
								" - " +
								renderIncorporatedInfo(selectedEmployer, t) + "."
							}
						</span>
				</Typography>
				<Divider/>
				<Typography
					className={"company-number-and-address-style"}
				>
					{"Address: "}
					<span
						className={"company-subtitle"}
					>
							{renderAddressSnippet(selectedEmployer) + "."}
						</span>
				</Typography>
				<Divider/>

				<Typography
					className={"company-typography-style"}
				>
					{t("individual.refer_employer.body_2")}
				</Typography>
				<Grid
					container
				>
					<Grid
						item
						lg={10}
						xs={12}
						md={10}
						sm={12}
					>
						<FormControl
							fullWidth
							className={"email-style"}
						>
							<TextField
								type={"email"}
								name={"email"}
								variant={"outlined"}
								id={"email"}
								placeholder={t("individual.refer_employer.company_email_input")}
								value={email}
								error={!validateEmail(email) && email.length > 0}
								onChange={event => setEmail(event.target.value)}
								onKeyDown={
									e => e.key === 'Enter' &&
										createLead({
											employer_leads: {
												employer_data: selectedEmployer,
												companyEmail: email,
											}
										})
								}
							/>
						</FormControl>
					</Grid>
					<Grid
						lg={2}
						xs={12}
						md={2}
						sm={12}
						item
						textAlign={"center"}
						className={"button-grid-style"}
					>
						<Button
							className={"button-style"}
							fullWidth
							onClick={() =>
								createLead({
									employer_leads: {
										employer_data: selectedEmployer,
										companyEmail: email,
									}
								})
							}
							disabled={!email || !validateEmail(email)}
						>
							{t("individual.refer_employer.invite_to_auto_enrol_button")}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	</Grid>
);

export default ConfirmEmailRefer;