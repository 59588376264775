import React from "react";

import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CountUp from "react-countup";
import stateActiveIcon from "../../../images/StatePensionActive.svg";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import inactiveExtraContributionIcon from "../../../images/StatePensionInactive.svg";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

/**
 * @param props
 * @returns {number}
 * @private
 */
function _yearlyIncome(props = {
	genericProjectionParameters: {
		full_yearly_state_pension: "0",
	},
}): number {
	let totalYearlyIncome = 0;

	if (props.genericProjectionParameters) {
		if (props.genericProjectionParameters.full_yearly_state_pension &&
			typeof props.genericProjectionParameters.full_yearly_state_pension.replace === "function") {

			totalYearlyIncome = parseFloat(props.genericProjectionParameters.full_yearly_state_pension.replace(",", ""));
		} else {
			totalYearlyIncome = props.genericProjectionParameters.full_yearly_state_pension;
		}
	}

	if (totalYearlyIncome > 0) {
		totalYearlyIncome = totalYearlyIncome / 52;
	}

	if (props.profile_data && props.profile_data.state_pension) {
		if (props.profile_data.state_pension.percentage) {
			totalYearlyIncome = totalYearlyIncome * (parseInt(props.profile_data.state_pension.percentage) / 100);
		}
	}

	if (totalYearlyIncome <= -1) {
		totalYearlyIncome = Math.abs(totalYearlyIncome);
	}

	if (isNaN(totalYearlyIncome)) {
		totalYearlyIncome = 0;
	}

	return totalYearlyIncome ?? 0;
}

/**
 * @param props
 * @returns {*}
 */
function renderStateActivePension(props = {
	profile_data: {
		state_pension_included: {},
	},
	t: key => key,
}) {
	if (props.profile_data.state_pension_included) {

		return (
			<Grid
				xs={12}
				sm={12}
				md={12}
				lg={12}
				item
				container
			>
				<Grid
					xs={8}
					sm={8}
					md={8}
					lg={8}
					item
					className={"line-padding"}
					container
					spacing={0}
				>
					<Grid
						xs={1}
						sm={1}
						md={1}
						lg={1}
						item
					>
						<LockOutlinedIcon className={"lock-icon"}/>
					</Grid>

					<Grid
						xs={10}
						sm={10}
						md={10}
						lg={10}
						item
					>
						<CountUp
							className={"count-number"}
							start={0}
							end={_yearlyIncome(props)}
							separator=","
							decimals={2}
							decimal="."
							duration={1}
							prefix={`£ `}
							suffix={" "}
						/>
						<span
							className={"count-number"}
						>
                            / {props.t("individual.week")}
                        </span>
					</Grid>
				</Grid>
				<Grid
					xs={4}
					sm={4}
					md={4}
					lg={4}
					alignItems={"flex-end"}
					alignContent={"flex-end"}
					direction={"column"}
					item
					container
				>
					<img
						src={stateActiveIcon}
						alt={props.t("individual.include_state_pensions_question")}
					/>
				</Grid>
			</Grid>
		);
	}

	return (

		<Grid
			xs={12}
			sm={12}
			md={12}
			lg={12}
			item
			container
		>
			<Grid
				className={"inactive line-padding"}
				xs={8}
				sm={8}
				md={8}
				lg={8}
				item
			>
				<LockOutlinedIcon className={"lock-icon inactive"}/>
				{" "}
				<span className={"inactive"}>
                    {props.t("individual.tap_to_include")}
                </span>
			</Grid>
			<Grid
				xs={4}
				sm={4}
				md={4}
				lg={4}
				alignItems={"flex-end"}
				alignContent={"flex-end"}
				direction={"column"}
				item
				container
			>
				<img
					src={inactiveExtraContributionIcon}
					alt={props.t("individual.extra_contributions.title")}
				/>
			</Grid>
		</Grid>
	);
}

/**
 *
 * @param props
 * @returns {string}
 */
function renderSubHeaderTitle(props = {
	profile_data: {
		state_pension: {},
	},
	t: key => key,
}): string {
	if (props) {
		if (props.profile_data.state_pension) {
			if (parseInt(props.profile_data.state_pension.percentage) < 100) {
				return props.t("individual.partial_state_pension");
			}
		}
	}

	return props.t("individual.full_state_pension");
}

/**
 * @stateless
 * @returns {*}
 */
const FullStatePensionCard = (props = {
	t: key => key,
}) => (
	<Card
		id={"full-state-pension-card"}
		onClick={() => {
			// if (props.profile_data.expected_retirement_age > 67) {
			//     return props.history("/state-pension");
			// }
			// return false;
			return props.history("/state-pension")
		}}
	>
		<CardHeader
			action={
				<IconButton
					aria-label="settings"

					disableTouchRipple
					disableFocusRipple
					disableRipple
				>
					<KeyboardArrowRightIcon/>
				</IconButton>
			}
			title={props.t("individual.include_state_pensions_question")}
			subheader={renderSubHeaderTitle(props)}
		/>
		<CardContent>
			{renderStateActivePension(props)}
		</CardContent>
	</Card>
);

export default FullStatePensionCard;
