import Create from "./Create";
import RetrievePaymentInfo from "./RetrievePaymentInfo";
import Active from "./Active";
import Details from "./Details";
import BankList from "./BankList";
import ConfirmDirectDebit from "./ConfirmDirectDebit";
import GetEndToEnd from "./GetEndToEnd";
import CancelDirectDebitSigning from "./CancelDirectDebitSigning";
import CancelDDI from "./CancelDDI";
import UpdateSourceOfFunds from "./UpdateSourceOfFunds";

const ExtraContributionsRequests = {
	Create,
	RetrievePaymentInfo,
	Active,
	Details,
	BankList,
	ConfirmDirectDebit,
	GetEndToEnd,
	CancelDirectDebitSigning,
	CancelDDI,
	UpdateSourceOfFunds,
};

export default ExtraContributionsRequests;