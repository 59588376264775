import Grid from "@mui/material/Grid";
import enrolled_by_employer_error from "../../images/signup/enroled_by_employer_error.svg";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import React from "react";
import PropTypes from "prop-types";

/**
 * @param history
 * @param setStep
 * @param clearActivationToken
 * @returns {JSX.Element}
 * @constructor
 */
const EnrolledByEmployerError = (
	{
		history = {
			push: () => {
			}
		},
		setStep = () => {
		},
		clearActivationToken = () => {
		}

	}
): JSX.Element => (
	<>

		<Grid
			id={"enrolled-by-employer"}
			container
			justifyContent={"center"}
		>
			<img
				id={"enrolled-by-employer-img-error"}
				src={enrolled_by_employer_error}
				alt="enroled by employer error"
			/>
		</Grid>

		<Grid
			id={"enrolled-by-employer-text"}
			container
			justifyContent={"center"}
			direction={"column"}
		>
			<Typography
				align={"center"}
				variant={"h6"}
			>
				We are sorry!
			</Typography>

			<Typography
				align={"center"}
				variant={"body2"}
			>
				<b>We couldn't find any record matching the information provided.</b>
				<br/>
				If you still believe that you were enroled by your employer, please contact customer support
				through our live chat on the bottom right of the page.
			</Typography>
		</Grid>

		<Grid
			id={"enrolled-by-employer-buttons"}
			container
			justifyContent={"center"}
		>
			<FormControl
				fullWidth
			>
				<Button
					variant={"contained"}
					color={"primary"}
					onClick={() => {
						history("/sign-up")
					}}
				>
					Sign Up
				</Button>
			</FormControl>

			<FormControl
				fullWidth
			>
				<Button
					variant={"outlined"}
					color={"primary"}
					onClick={() => {
						setStep(0);
						clearActivationToken();
						history("/");
					}}
				>
					Back
				</Button>
			</FormControl>

		</Grid>
	</>
);

EnrolledByEmployerError.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func
	}).isRequired,
	setStep: PropTypes.func.isRequired,
	clearActivationToken: PropTypes.func.isRequired
};

export default EnrolledByEmployerError;