import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import CalendarIcon from "../../images/calendar-icon-black.svg";
import OneOffIcon from "../../images/one-off-icon-black.svg";

/**
 * @param status
 * @param type
 * @param bank
 * @param value
 * @param date
 * @returns {JSX.Element}
 * @constructor
 */
const ExtraContributionCard = (
	{
		status = "",
		type = "",
		bank = "",
		value = "",
		date = "",
	}
): JSX.Element => (
	<Grid
		lg={12}
		xl={12}
		sm={12}
		md={12}
		item
		container
		justifyContent={'center'}
		className={"extra-contributions-card-grid"}
	>
		<Grid
			lg={9}
			xl={7.75}
			sm={11}
			xs={11}
			md={11}
			item
			marginBottom={1}
			textAlign={'left'}
		>
			<Typography
				className={"contributions-date"}
				align={"left"}
				variant={"p"}
			>
				{date}
			</Typography>
		</Grid>
		<Grid
			lg={9}
			xl={7.75}
			sm={11}
			xs={11}
			md={11}
			item
			container
			justifyContent={'center'}
			paddingTop={1.5}
			className={"extra-contributions-card"}
		>
			<Grid
				md={12}
				sm={12}
				xl={12}
				xs={12}
				lg={12}
				item
				container
				justifyContent={'space-between'}
				alignItems={"center"}
				className={"parent"}
			>
				<Grid className={"grid"}>
					{
						type === 'Monthly' ?
							<img
								src={CalendarIcon}
								alt={""}
							/>
							:
							<img
								src={OneOffIcon}
								alt={""}
							/>
					}
					<Typography
						className={"contributions-type"}
						variant={"p"}
					>
						{type + " Contribution"}
					</Typography>
				</Grid>
				<Typography
					className={status === "PAYMENT_RECEIVED" ? "contributions-status accepted" : "contributions-status other"}
					variant={"p"}
				>
					{status}
				</Typography>
			</Grid>
			<Grid
				md={12}
				sm={12}
				xl={12}
				xs={12}
				lg={12}
				paddingTop={5}
				paddingBottom={2}
				item
				container
				justifyContent={'space-between'}
			>
				<Typography
					className={"bank-name"}
					variant={"p"}
				>
					{bank}
				</Typography>
				<Typography
					className={"contributions-value"}
					variant={"p"}
				>
					{"£ " + value}
				</Typography>
			</Grid>
		</Grid>
	</Grid>

);

export default ExtraContributionCard;