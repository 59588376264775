import React, {useState, useEffect} from 'react';

import {checkProfileChangeCode, returnUser, update, updateMyEmail, updateMyPassword} from "../../actions/Profile";
import {connect} from "react-redux";
import {checkPassword, validateEmail} from "../../utils/Helpers";
import Wrapper from "../../layout/Logged/Wrapper";
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {PrimaryHeader} from "../../components/Headers/PrimaryHeader";
import Loading from "../../components/Loading";

/**
 * @param operation
 * @returns {string}
 * @private
 */
const renderInfoHeader = (operation: string = "") => {
	if (operation !== "") {
		if (operation === "E-mail") {
			return "Please inform a new valid e-mail to your Collegia account.";
		}
	}
	return "";
}

/**
 * @param operation
 * @param email
 * @param password
 * @param confirmPassword
 * @param message
 * @param showPassword
 * @param setEmail
 * @param setPassword
 * @param setConfirmPassword
 * @param setShowPassword
 * @returns {*[]|*}
 * @private
 */
const renderFormFields = (
	operation: string = "",
	email: string = "",
	password: string = "",
	confirmPassword: string = "",
	message: string = "",
	showPassword = false,
	setEmail = () => {
	},
	setPassword = () => {
	},
	setConfirmPassword = () => {
	},
	setShowPassword = () => {
	},
) => {
	if (operation === "E-mail") {
		return (
			<FormControl
				variant={"outlined"}
				className={"verify-code-field"}
				fullWidth
			>
				<OutlinedInput
					type={"email"}
					variant={"outlined"}
					id={"email"}
					name={"email"}
					placeholder={"New e-mail address"}
					value={email ?? ""}
					error={!validateEmail(email) && email.length > 0}
					onChange={event => setEmail(event.target.value)}
				/>
				<small className={"collegia-text-danger"}>
					{message}
				</small>
				<small className={"collegia-text-danger"}>
					{!validateEmail(email) && email.length > 0 ? "Enter with a valid email!" : ""}
				</small>
			</FormControl>
		);
	} else if (operation === "Password") {
		return (
			<div>
				<FormControl
					variant={"outlined"}
					className={"verify-code-field"}
					fullWidth
				>
					<OutlinedInput
						type={showPassword ? 'text' : 'password'}
						value={password}
						name={'password'}
						id={'password'}
						onChange={event => setPassword(event.target.value)}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={() => setShowPassword(!showPassword)}
									onMouseDown={event => event.preventDefault()}
								>
									{showPassword ? <Visibility/> : <VisibilityOff/>}
								</IconButton>
							</InputAdornment>
						}
						placeholder={"6-character password"}
					/>
					<small className={"red-text"}>
						* Please type min. 6-character password containing letters and at least a number.
					</small>
				</FormControl>
				<FormControl
					variant={"outlined"}
					className={"verify-code-field"}
					fullWidth
				>
					<OutlinedInput
						type={showPassword ? 'text' : 'password'}
						variant={"outlined"}
						id={"confirmPassword"}
						name={"confirmPassword"}
						placeholder={"Confirm password"}
						value={confirmPassword ?? ""}
						onChange={event => setConfirmPassword(event.target.value)}
					/>
					<small className={"collegia-text-danger"}>
						{
							(password !== confirmPassword) && password.length > 0 && confirmPassword.length > 0 ?
								"Password doesn't match"
								:
								""
						}
					</small>
				</FormControl>
			</div>
		);
	}

	return [];
}

/**
 * @param profile_data
 * @param operation
 * @param email
 * @param password
 * @param confirmPassword
 * @param code
 * @param setMessage
 * @param updateMyPassword
 * @param updateMyEmail
 * @private
 */
const save = (
	profile_data = [],
	operation: string = "",
	email: string = "",
	password: string = "",
	confirmPassword: string = "",
	code: string = "",
	setMessage = () => {
	},
	updateMyPassword = () => {
	},
	updateMyEmail = () => {
	}
) => {
	setMessage("");
	if (code === "") {
		window.location.href = "/my-account";
	}
	if (operation === "E-mail") {
		if (email === "") {
			setMessage("Please fill your new e-mail");
		} else {
			if (email === profile_data.email) {
				setMessage("This is already your current email, please provide a new one");
			} else {
				updateMyEmail({
					profiles: {
						email,
						code,
					}
				});
			}
		}
	} else if (operation === "Password") {
		if (password !== confirmPassword) {
			setMessage("The password's doesn't match!");
		} else if (!checkPassword(password)) {
			setMessage("You password must be at least 6 characters with at least one number on it!");
		} else {
			updateMyPassword({
				profiles: {
					password,
					confirmPassword,
					code,
				}
			})
		}
	}
};

const UpdateLoginDetails = ({
	profile_data = [],
	profile_loading = false,
	operation = "",
	message_error = "",
	match = {
		params: {
			token: ""
		}
	},
	checkProfileChangeCode = () => {
	},
	returnUser = () => {
	},
	updateMyEmail = () => {
	},
	updateMyPassword = () => {
	},
	history = () => {
	}
}) => {
	const [email, setEmail] = useState(""),
		  [password, setPassword] = useState(""),
		  [confirmPassword, setConfirmPassword] = useState(""),
		  [showPassword, setShowPassword] = useState(false),
		  [message, setMessage] = useState(""),
		  code = match.params && match.params.code ?
			    match.params.code
			  :
			    false;

	useEffect(() => {
		checkProfileChangeCode(code);
		returnUser();
	}, [
		returnUser,
		checkProfileChangeCode,
		code
	]);

	useEffect(() => {
		if (message_error) {
			setMessage(message_error);
		}
	}, [
		message_error
	]);

	if (profile_loading) {
		<Loading absolute/>
	}

	return (
		<Wrapper hideFooter={true}>
			<Slide in={true} direction={"left"}>
				<div>
					<Grid
						direction={"column"}
						alignItems={"center"}
						alignContent={"center"}
						id={"tax-relief-container"}
						container
						item
					>
						<PrimaryHeader
							history={history}
							backPage={"/my-account"}
							backIcon
						>
							{`Change my ${operation.toLowerCase()}`}
						</PrimaryHeader>
						<Paper
							id={"paper-holder"}
						>
							<Typography
								className={"hint-type-the-code"}
								paragraph
							>
								{renderInfoHeader(operation)}
							</Typography>
							<Grid marginBottom={"15px"}>
								{
									renderFormFields(
										operation,
										email,
										password,
										confirmPassword,
										message,
										showPassword,
										setEmail,
										setPassword,
										setConfirmPassword,
										setShowPassword,
									)
								}
							</Grid>

							<FormControl
								variant={"standard"}
								fullWidth
							>
								<Button
									variant={"contained"}
									className={"continue-button with-margin-top"}
									onClick={() =>
										save(
											profile_data,
											operation,
											email,
											password,
											confirmPassword,
											code,
											setMessage,
											updateMyPassword,
											updateMyEmail
										)
									}
									disabled={
										operation === "E-mail" ?
											(!email || !validateEmail(email))
										:
											(!password || !confirmPassword || password !== confirmPassword)
									}
									fullWidth
								>
									SAVE
								</Button>
							</FormControl>
						</Paper>
					</Grid>
				</div>
			</Slide>
		</Wrapper>
	);
}

const mapStateToProps = state => {
	const {
		Profile = [],
	} = state;

	return {
		...Profile,
	};
};

const mapDispatchToProps = dispatch => ({
	checkProfileChangeCode: (code: string = "") => dispatch(checkProfileChangeCode(code)),
	returnUser: () => dispatch(returnUser()),
	update: (id: any = false, data: any[] = false) => dispatch(update(id, data)),
	updateMyEmail: (data: any[] = false) => dispatch(updateMyEmail(data)),
	updateMyPassword: (data: any[] = false) => dispatch(updateMyPassword(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateLoginDetails);